import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import CustomButton from "./CustomButton/CustomButton";

export default function TicketsNoResults() {
	const navigate = useNavigate();
	return (
		<div>
			<div className="opacity-73">
				<div className={"grid gap-8 grid-cols-2 md:grid-cols-3 opacity-[6%]"}>
					<div className="bg-gradient-to-b from-[#01338D] h-56 rounded-lg"></div>
					<div className="bg-gradient-to-b from-[#01338D] h-56 rounded-lg"></div>
					<div className="hidden md:block bg-gradient-to-b from-[#01338D] h-56 rounded-lg"></div>
				</div>
			</div>
			<div className={"grid justify-items-center -mt-44"}>
				<div className={"md:w-[28rem]"}>
					<div className={"grid justify-items-center"}>
						<div className="bg-primary/5 rounded-full h-32 w-32 mb-4">
							<div className={"grid place-items-center h-32"}>
								<img src={"/no-results-icon.svg"} alt={"Magnifying glass icon for no search results"} className={"w-12 h-12"} />
							</div>
						</div>
						<div className="text-altblack font-rubik-medium text-xl font-medium tracking-normal text-center mb-4">Nothing to show</div>
						<div className="opacity-80 text-altblack font-rubik text-base font-normal tracking-normal text-center mb-4">
							Try adjusting your filters, using a new search term, or creating a new ticket below
						</div>
						<CustomButton
							onClick={() => {
								navigate("/helpdesk/newTicket");
							}}
							variant={"contained"}
							ariaLabel={"Create a Help Ticket"}
						>
							Create a Help Ticket <AddIcon className={"ml-1"} />
						</CustomButton>
					</div>
				</div>
			</div>
		</div>
	);
}
