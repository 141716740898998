function getDateFormatString(locale = "default") {
	const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());
	return formatObj
		.map((obj) => {
			switch (obj.type) {
				case "day":
					return "DD";
				case "month":
					return "MM";
				case "year":
					return "YYYY";
				default:
					return obj.value;
			}
		})
		.join("");
}
const userLocale = navigator.language || "en-US";

let dateFormat = getDateFormatString(userLocale);

const currentUserData: {
	userGuid: string | undefined;
	userName: string | undefined;
	userLastName: string | undefined;
	userFirstName: string | undefined;
	districtAppId: string | undefined;
	requestCounter: number;
	siteGuid: string | undefined;
	districtCustomerNumber: string | undefined;
	isDestinyAdmin: boolean;
	sub: string | undefined;
	dateFormat: string;
	dateOnlyFormat: string;
	permissions: string[];
	featureFlags: any;
} = {
	userGuid: undefined,
	userName: undefined,
	userLastName: undefined,
	userFirstName: undefined,
	districtAppId: undefined,
	requestCounter: 0,
	siteGuid: undefined,
	districtCustomerNumber: undefined,
	isDestinyAdmin: false,
	sub: undefined,
	dateFormat: "MM/DD/YYYY hh:mm a",
	dateOnlyFormat: dateFormat || "MM/DD/YYYY",
	permissions: [],
	featureFlags: {},
};

export function nextRequestId(apiTag: string): string {
	currentUserData.requestCounter++;
	return apiTag + ":" + currentUserData.requestCounter;
}
export function isDestinyAdmin() {
	return currentUserData.isDestinyAdmin;
}
export function getCurrentUserGuid() {
	return currentUserData.userGuid;
}
export function getUserName(): string {
	return currentUserData.userName || "";
}
export function getUserFirstName(): string {
	return currentUserData.userFirstName || "";
}
export function getUserLastName(): string {
	return currentUserData.userLastName || "";
}
export function getLastFirstName(): string {
	let retval = getUserLastName() + ", " + getUserFirstName();
	if (retval.trim().charAt(0) === ",") {
		retval = retval.trim().substring(1);
	}
	if (retval.trim().charAt(retval.trim().length - 1) === ",") {
		retval = retval.trim().substring(0, retval.trim().length - 1);
	}
	if (retval.trim() === ",") {
		return getUserName();
	}
	return retval.trim();
}
export function getDistrictAppId() {
	return currentUserData.districtAppId;
}
export function getDistrictCustomerNumber() {
	return currentUserData.districtCustomerNumber;
}
export function getSiteGuid() {
	return currentUserData.siteGuid;
}
export function getSub() {
	return currentUserData.sub;
}
export function getDateFormat() {
	return currentUserData.dateFormat;
}
export function getDateOnlyFormat() {
	return currentUserData.dateOnlyFormat;
}
export function getPermissions(): string[] {
	return currentUserData.permissions;
}
export function permissionsContain(perm: string): boolean {
	return currentUserData.permissions.includes(perm);
}
export function getFeatureFlags(): any {
	return currentUserData.featureFlags;
}
// SETTERS
export function setIsDestinyAdmin(value: boolean) {
	currentUserData.isDestinyAdmin = value;
}
export function setUserGuid(guid: string | undefined) {
	currentUserData.userGuid = guid;
}
export function setUserName(name: string | undefined) {
	currentUserData.userName = name;
}
export function setUserLastName(name: string | undefined) {
	currentUserData.userLastName = name;
}
export function setUserFirstName(name: string | undefined) {
	currentUserData.userFirstName = name;
}
export function setDistrictAppId(districtAppId: string | undefined) {
	currentUserData.districtAppId = districtAppId;
}
export function setDistrictCustomerNumber(districtCustomerNumber: string | undefined) {
	currentUserData.districtCustomerNumber = districtCustomerNumber;
}
export function setSiteGuid(siteGuid: string | undefined) {
	currentUserData.siteGuid = siteGuid;
}
export function setSub(sub: string | undefined) {
	currentUserData.sub = sub;
}
export function setDateFormat(dateformat: string | undefined) {
	currentUserData.dateFormat = dateformat ? dateformat : currentUserData.dateFormat;
}
export function setPermissions(permissions: string[]) {
	currentUserData.permissions = permissions;
}
export function setFeatureFlags(featureFlags: any) {
	currentUserData.featureFlags = featureFlags;
}
