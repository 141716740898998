export interface ClaimsModel {
	[claimKey: string]: string | number;
}

export enum ClaimKey {
	SUB = "sub",
	USERNAME = "http://fss.follett.com/claims/userName",
	LOCATION_TYPE = "http://fss.follett.com/claims/locationType",
	USER_GUID = "http://fss.follett.com/claims/userguid",
	FSS_DISTRICT_CUSTOMER_NUMBER = "http://fss.follett.com/claims/fssDistrictCustNumber",
	FSS_SITE_NUMBER = "http://fss.follett.com/claims/fssSiteCustNumber",
	PATRON_TYPE = "http://fss.follett.com/claims/userPatronType",
	FIRST_NAME = "http://fss.follett.com/claims/userFirstName",
	LAST_NAME = "http://fss.follett.com/claims/userLastName",
	DISTRICT_NAME = "http://fss.follett.com/claims/districtName",
	LOCATION_NAME = "http://fss.follett.com/claims/locationName",
	ROLES = "http://fss.follett.com/claims/roles",
	NAME = "name",
	APP_ID = "http://fss.follett.com/claims/appId",
	SITE_GUID = "http://fss.follett.com/claims/locationid",
	USER_IS_GUEST = "http://fss.follett.com/claims/userIsGuest",
	IS_ANONYMOUS = "http://fss.follett.com/claims/userIsAnonymous",
	DISTRICT_USER = "http://fss.follett.com/claims/districtUser",
	DESTINY_ADMIN = "http://fss.follett.com/claims/destinyAdmin",
	FOLLETT_SHELF_ID = "http://fss.follett.com/claims/follettShelfId",
	DAP_ACCESS_GRANTS = "http://fss.follett.com/claims/dapAccessGrants", // This will be a comma-delimited list of values indicating which apps a user can access. Supplied from DAP.
	FOLLETT_SHELF_ADMIN = "http://fss.follett.com/claims/userFSAdmin",
	USER_SESSION_TIMEOUT_SECONDS = "http://fss.follett.com/claims/aaspSessionTimeoutSeconds",
}

export enum DapAccessGrant {
	CLASS_SET = "CLASS_SET",
	COLLECTIONS = "COLLECTIONS",
	ENGAGE = "ENGAGE",
	REVIEWS = "REVIEWS",
	RATINGS = "RATINGS",
	SHARING = "SHARING",
	CITATIONS = "CITATIONS",
	READING_CHALLENGE = "READING_CHALLENGE",
	READING_PROGRAM = "READING_PROGRAM",
}
