import { createSlice } from "@reduxjs/toolkit";
import { Health } from "../models/health.model";
import { RootState } from "../store";

// Define a type for the slice state
interface HealthState {
	health: Health;
	createDate: String;
}

// Define the initial state using that type
const initialState: Health = {
	message: "No Health Status",
	createDate: "",
};

const healthSlice = createSlice({
	name: "health",
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		update: (state, payload) => {
			return payload.payload;
		},
	},
});

export const selectMessage = (state: RootState) => state.health.message;
export const selectCreateDate = (state: RootState) => state.health.createDate;
export const { update } = healthSlice.actions;

export default healthSlice.reducer;
