import { Add, AddCircleOutlineOutlined, ArrowBack, DeleteForeverOutlined, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { FC, useState } from "react";
import { blankReport, Report, ReportFilter } from "../../models/reports.model";
import { useCreateReportMutation, useDeleteReportMutation, useReportFacetsQuery, useReportsQuery, useUpdateReportMutation } from "../../utilities/apiApi";
import CommonProgress from "../CommonProgress/CommonProgress";
import CustomButton from "../CustomButton/CustomButton";
import GenericDialog from "../GenericDialog/GenericDialog";
import { notify } from "../Notification/Notification";
import ReportsDataGridForm from "./ReportsDataGridForm";
import SaveReportsDialog from "./ReportsDialogs";

interface ReportsConfigurationProps {
	setPageMode: Function;
}

const ReportsConfiguration: FC<ReportsConfigurationProps> = (props) => {
	const [dataGridOpen, setDataGridOpen] = useState(false);
	const [exportDialogOpen, setExportDialogOpen] = useState(false);
	const [saveDialogOpen, setSaveDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [createReportMutation, { isLoading }] = useCreateReportMutation();
	const [updateReportMutation, { isLoadingUpdate }] = useUpdateReportMutation();
	const [deleteReportMutation, { isDeleteLoading }] = useDeleteReportMutation();
	const [currentReport, setCurrentReport] = useState(blankReport);

	// Reports
	const reportsQuery = useReportsQuery();
	let reports: Report[] = [];
	if (reportsQuery.isSuccess) {
		reports = reportsQuery.data;
	} else if (reportsQuery.isLoading) {
		return <CommonProgress message={"Loading reports"} />;
	}

	const updateReport = async (report: Report) => {
		if (report.name != currentReport.name || !currentReport.helpTicketSavedReportViewID) {
			if (!isLoading) {
				try {
					await createReportMutation({ report: report }).unwrap();
					notify("Report Created!", `"${report.name}" is available and ready to use.`, false);
				} catch (error) {
					console.error("Failed to create the report: " + error);
					notify("Failed to create report!", `"${report.name}" was not created.`, true);
				}
			}
		} else {
			if (!isLoadingUpdate) {
				try {
					await updateReportMutation({ report: report }).unwrap();
					notify("Report Updated!", `"${report.name}" is available and ready to use.`, false);
				} catch (error) {
					console.error("Failed to update the report: " + error);
					notify("Failed to update report!", `"${report.name}" was not updated.`, true);
				}
			}
		}
	};

	const deleteReport = async (input: number) => {
		if (!isDeleteLoading) {
			try {
				await deleteReportMutation({ helpTicketSavedReportViewID: input }).unwrap();
			} catch (error) {
				console.error("Failed to delete the report: " + error);
			}
		}
	};

	let facetMap: Map<string, ReportFilter> = new Map();
	const getFacets = async (column: string) => {
		if (!facetMap.has(column)) {
			const facetsQuery = useReportFacetsQuery();
			let facets: ReportFilter = {} as ReportFilter;
			if (facetsQuery.isSuccess) {
				facets = facetsQuery.data.find((rf: ReportFilter) => rf.column === column);
			}

			facetMap.set(column, facets);
		}

		return facetMap.get(column);
	};

	return (
		<div className={"mt-2"}>
			<IconButton
				className="text-primary text-2xl"
				onClick={() => {
					if (dataGridOpen) {
						setDataGridOpen(false);
					} else {
						props.setPageMode("Settings");
					}
				}}
				disableRipple={true}
			>
				<div className={"flex flex-row items-center mb-4"}>
					<ArrowBack />
					<span className="text-altblack">{" " + (dataGridOpen ? currentReport.name : "Reports")}</span>
				</div>
			</IconButton>

			{reports.length == 0 && !dataGridOpen && (
				<div>
					<div className={"grid justify-items-center"}>
						<div className={"md:w-[28rem]"}>
							<div className={"grid justify-items-center"}>
								<div className="bg-primary/5 rounded-full h-32 w-32 mb-4">
									<div className={"grid place-items-center h-32"}>
										<img src={"/reports-icon.svg"} alt={"No report icon "} className={"w-12 h-12"} />
									</div>
								</div>
								<div className="text-altblack font-rubik-medium text-xl font-medium tracking-normal text-center mb-4">
									No reports at this time
								</div>
								<div className="opacity-80 text-altblack font-rubik text-base font-normal tracking-normal text-center mb-4">
									Reports track trends and assist management! To get started, please create a report by clicking below.
								</div>
								<CustomButton
									onClick={() => {
										setCurrentReport(blankReport);
										setDataGridOpen(true);
									}}
									variant={"contained"}
									ariaLabel={"Create a report"}
								>
									Create a report <Add className={"ml-1"} />
								</CustomButton>
							</div>
						</div>
					</div>
				</div>
			)}
			{reports.length > 0 && !exportDialogOpen && !saveDialogOpen && !deleteDialogOpen && !dataGridOpen && (
				<div className={"flex flex-wrap flex-row gap-4"}>
					<button
						className={
							"flex flex-col items-center justify-center w-[200px] h-44 rounded-[8px] bg-[rgba(1,51,141,0.06)] drop-shadow-[0_2px_8px_rgba(138,146,163,0.16)]"
						}
						onClick={() => {
							setCurrentReport(blankReport);
							setDataGridOpen(true);
						}}
					>
						<AddCircleOutlineOutlined
							sx={{
								fill: "#01338D",
								width: "48px",
								height: "48px",
							}}
						/>
						<div className="text-primary text-lg text-center">New Report</div>
					</button>
					{reports.map((report: Report) => {
						return (
							<button
								className={"text-left flex flex-col p-3 w-[200px] h-44 rounded-[8px] bg-white drop-shadow-[0_2px_8px_rgba(138,146,163,0.16)]"}
								onClick={() => {
									setCurrentReport(report);
									setDataGridOpen(true);
								}}
							>
								<div className={"text-lg font-semibold mb-2 break-words"}>{report.name}</div>
								<div className={"grow"}>{report.description}</div>
								<div className={"flex flex-row"}>
									<div className={"grow"} />
									<IconButton
										onMouseDown={(e) => e.stopPropagation()}
										onClick={(e) => {
											e.stopPropagation();
											setCurrentReport(report);
											setSaveDialogOpen(true);
										}}
									>
										<Edit />
									</IconButton>
									<IconButton
										onMouseDown={(e) => e.stopPropagation()}
										onClick={(e) => {
											e.stopPropagation();
											setCurrentReport(report);
											setDeleteDialogOpen(true);
										}}
									>
										<DeleteForeverOutlined
											sx={{
												fill: "red",
											}}
										/>
									</IconButton>
								</div>
							</button>
						);
					})}
				</div>
			)}
			{dataGridOpen && !saveDialogOpen && !exportDialogOpen && (
				<ReportsDataGridForm
					setDataGridOpen={setDataGridOpen}
					setSaveDialogOpen={setSaveDialogOpen}
					setExportDialogOpen={setExportDialogOpen}
					setCurrentReport={setCurrentReport}
					report={currentReport}
					getFacets={getFacets}
				/>
			)}
			{saveDialogOpen && (
				<SaveReportsDialog
					report={currentReport}
					reports={reports}
					onSave={(report: Report) => {
						updateReport(report);
						setSaveDialogOpen(false);
						setDataGridOpen(false);
					}}
					onClose={() => {
						setSaveDialogOpen(false);
					}}
				/>
			)}
			{deleteDialogOpen && (
				<GenericDialog
					open={deleteDialogOpen}
					title={"Delete Report?"}
					onClose={() => {
						setDeleteDialogOpen(false);
					}}
					onConfirm={() => {
						setDeleteDialogOpen(false);
						deleteReport(currentReport.helpTicketSavedReportViewID as number);
					}}
					confirmLabel={"Delete Report"}
					content={`Are you sure you want to delete the report "${currentReport.name}"?`}
					confirmColor={"red"}
					isError={true}
				/>
			)}
		</div>
	);
};

export default ReportsConfiguration;
