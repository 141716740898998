import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Step, StepContent, StepLabel, Stepper, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import _ from "lodash";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hook";
import { blankDevice } from "../../models/device.model";
import { Form } from "../../models/forms.model";
import HelpTicketFull, { CreateHelpTicket, IssueType } from "../../models/helpticket.model";
import { blankHelpTicketForm, HelpTicketForm } from "../../models/helpTicketForm.model";
import { resetHelpTicketForm, selectHelpTicketForm } from "../../slices/HelpTicketFormSlice";
import { useCreateHelpTicketMutation, useGetFormsForResourceQuery, useHelpTicketQuery, useUploadFileMutation } from "../../utilities/apiApi";
import { helpTicketForm2Create } from "../../utilities/helpTicketUtils";
import CommonProgress from "../CommonProgress/CommonProgress";
import CustomButton from "../CustomButton/CustomButton";
import { validateCustomFieldTypes } from "../CustomField/CustomField";
import NewTicketCategoryForm from "../NewTicketCategoryForm/NewTicketCategoryForm";
import NewTicketStepFourForm from "../NewTicketStepFourForm/NewTicketStepFourForm";
import NewTicketStepThreeForm from "../NewTicketStepThreeForm/NewTicketStepThreeForm";
import NewTicketStepTwoForm from "../NewTicketStepTwoForm/NewTicketStepTwoForm";
import { notify, notifyDialog } from "../Notification/Notification";

const steps = [
	{
		title: "Issue Category / Type",
		description: "Specify a category / type suiting the issue best",
	},
	{
		title: "User and Item",
		description: "Identify the user and the specific item",
	},
	{
		title: "Description",
		description: "Write a clear and concise description of the problem",
	},
	{
		title: "Details",
		description: "Define additional data fields",
	},
];

const headers = ["Specify an Issue Category", "Select the User and Item", "Description", "Details", "Step 5"];

const FormStep: FC<{ children: ReactNode }> = (props: { children: ReactNode }) => {
	return <Paper elevation={3}>{props.children}</Paper>;
};
const renderStep1: FC<{ stepIndex: number; ht: HelpTicketForm }> = (props) => {
	if (props.ht.categoryId === blankHelpTicketForm.categoryId && props.ht.issueTypeId === blankHelpTicketForm.issueTypeId) {
		return <p>{steps[0].description}</p>;
	} else {
		return (
			<div>
				{props.ht.categoryId !== blankHelpTicketForm.categoryId && <span className="text_bold">{props.ht.categoryName}</span>}
				{props.ht.issueTypeId !== blankHelpTicketForm.issueTypeId && <span className="text_bold"> : {props.ht.issueTypeName}</span>}
			</div>
		);
	}
};
const renderStep2: FC<{ stepIndex: number; ht: HelpTicketForm }> = (props) => {
	if (props.ht.forWhoId === blankHelpTicketForm.forWhoId && props.ht.item.resource.name === blankHelpTicketForm.item.resource.name) {
		return <p>{steps[1].description}</p>;
	} else {
		return (
			<div>
				{props.ht.forWhoName !== blankHelpTicketForm.forWhoName && <span className="text_bold">{props.ht.forWhoName}</span>}
				{props.ht.item.resource.name !== blankHelpTicketForm.item.resource.name && <span className="text_bold"> : {props.ht.item.resource.name}</span>}
			</div>
		);
	}
};
const renderStep3: FC<{ stepIndex: number; ht: HelpTicketForm }> = (props) => {
	return <p>{steps[2].description}</p>;
};
const renderStep4: FC<{ stepIndex: number; ht: HelpTicketForm }> = (props) => {
	return <p>{steps[3].description}</p>;
};
const renderStep: React.FC<{ stepIndex: number; ht: HelpTicketForm }>[] = [renderStep1, renderStep2, renderStep3, renderStep4];
export interface TicketWizardProps {}

const WizardHeader: FC<{ text: string }> = (props) => {
	return <div className={"text-2xl my-4"}>{props.text} </div>;
};

const TicketWizard: FC<TicketWizardProps> = (props) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [stepIndex, setStepIndex] = useState(0);
	const [saveDisabled, setSaveDisabled] = useState(false);
	const [createDisabled, setCreateDisabled] = useState(false);
	const { register, handleSubmit } = useForm();
	const [isError, setIsError] = useState(false);
	const [isStep2Error, setIsStep2Error] = useState(false);
	const [isStep3Error, setIsStep3Error] = useState(false);
	const [isStep4Error, setIsStep4Error] = useState(false);
	const [errorMessages, setErrorMessages] = useState(undefined);
	const [hasCustomFieldError, setHasCustomFieldError] = useState(false);
	const [cancelDialog, setCancelDialog] = useState(false);
	const [helpTicketGUID, setHelpTicketGuid] = useState();
	let helpTicketForm: HelpTicketForm = useAppSelector(selectHelpTicketForm);
	const [uploadFile, uploadFileResult] = useUploadFileMutation();
	const [createHelpTicket, createResult] = useCreateHelpTicketMutation();
	let hasSpecial = false; // when we have additional fields customer configured.
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
	let helpTicketFull: HelpTicketFull | undefined = undefined;

	function getResourceGUID(form: HelpTicketForm): string | undefined {
		return _.get(form, "item.resource.resourceType.guid", undefined);
	}
	function getIssueTypeId(form: HelpTicketForm): number | undefined {
		return _.get(form, "issueTypeId", undefined);
	}
	function getCategoryId(form: HelpTicketForm): number | undefined {
		return _.get(form, "categoryId", undefined);
	}
	let stepLimit = 2;
	let customForms: Form[] = [];
	let formsResult: any = useGetFormsForResourceQuery(
		{ resourceTypeGUID: getResourceGUID(helpTicketForm) },
		{ skip: getResourceGUID(helpTicketForm) === undefined ? true : false },
	);
	if (formsResult.isSuccess) {
		let id = getIssueTypeId(helpTicketForm);
		let cId = getCategoryId(helpTicketForm);
		let compareArray: number[] = [];
		if (id !== undefined) {
			compareArray.push(id);
		}
		if (cId !== undefined) {
			compareArray.push(cId);
		}
		let forms: Form[] = formsResult.data;
		let retval: Form[] = [];
		forms.forEach((f, idx) => {
			let found: IssueType | undefined = _.find(f.issueTypes, (item) =>
				item.helpTicketIssueTypeID ? compareArray.includes(item.helpTicketIssueTypeID) : false,
			);
			if (found) {
				retval.push(f);
			}
		});
		customForms = retval;
		if (customForms && customForms.length > 0) {
			stepLimit = 3;
		}
	}
	if (formsResult.isLoading) {
		<CommonProgress message="Checking for custom fields..." />;
	}

	useEffect(() => {
		setIsError(false);
	}, [helpTicketForm]);

	const onSubmit = (data: any) => {
		// console.log(data);
	};

	function step1Validated() {
		if (
			helpTicketForm.categoryId === blankHelpTicketForm.categoryId ||
			helpTicketForm.categoryName === blankHelpTicketForm.categoryName ||
			(helpTicketForm.issueTypeRequired &&
				(helpTicketForm.issueTypeId === blankHelpTicketForm.issueTypeId || helpTicketForm.issueTypeName === blankHelpTicketForm.issueTypeName))
		) {
			return false;
		}
		return true;
	}

	function step2Validated() {
		if (
			helpTicketForm.forWhoId === blankHelpTicketForm.forWhoId ||
			helpTicketForm.item === undefined ||
			helpTicketForm.item.resource === undefined ||
			helpTicketForm.item.resource?.guid === blankDevice.resource.guid
		) {
			return false;
		}
		if (helpTicketForm.trackResponsible && helpTicketForm.responsibleUserId === blankHelpTicketForm.responsibleUserId) {
			return false;
		}
		return true;
	}

	function step3Validated() {
		if (helpTicketForm.summary === blankHelpTicketForm.summary || helpTicketForm.details === blankHelpTicketForm.details) {
			return false;
		}
		return true;
	}

	function step4Validated() {
		let errMessages = validateCustomFieldTypes(customForms, helpTicketForm.fieldValues);
		if (errMessages) {
			let retval: boolean = true;
			errMessages.forEach((item) => {
				if (item !== undefined) {
					retval = false;
				}
			});
			setErrorMessages(errMessages as any);
			return retval;
		} else {
			setErrorMessages(undefined);
			return true;
		}
	}

	function goNext() {
		let increment = 1;
		switch (stepIndex) {
			case 0:
				if (step1Validated()) {
					increment = 1;
					setIsError(false);
				} else {
					increment = 0;
					setIsError(true);
				}
				break;
			case 1:
				if (step2Validated()) {
					increment = 1;
					setIsStep2Error(false);
				} else {
					increment = 0;
					setIsStep2Error(true);
				}
				break;
			case 2:
				if (step3Validated()) {
					if (customForms.length > 0) {
						increment = 1;
					}
					setIsStep3Error(false);
				} else {
					increment = 0;
					setIsStep3Error(true);
				}
				break;
			case 3:
				if (step4Validated()) {
					increment = 1;
					setIsStep4Error(false);
				} else {
					increment = 0;
					setIsStep4Error(true);
				}
				break;
			default:
				break;
		}
		let stepIdx = stepIndex + increment;
		if (stepIdx < 4) {
			setStepIndex(stepIdx);
		}
	}

	let response = useHelpTicketQuery(
		{
			helpTicketGUID: helpTicketGUID || "",
		},
		{ skip: helpTicketGUID === undefined },
	);
	if (response.isSuccess) {
		helpTicketFull = response.data as any;
	}
	if (response.isError) {
		notifyDialog("Network Error", "Unable to retrieve just created HelpTicket", true, () => {
			navigate(-1);
		});
	}

	useEffect(() => {
		if (helpTicketFull) {
			let ht: HelpTicketFull = helpTicketFull || {
				ticketNumber: "",
				item: _.cloneDeep(blankDevice),
			};
			let buffer = `Ticket #${ht.ticketNumber} is now in the queue `;
			buffer = buffer + `for ${ht.item.resource.name} (Item Barcode: ${ht.item.barcode}).`;
			buffer = buffer + " An email will be sent to you shortly with more information.";
			notify("New Ticket added!", buffer, false);
			navigate(-1);
		}
	}, [helpTicketFull]);

	return (
		<div className={"min-w-screen min-h-screen flex items-center flex-col"}>
			{createDisabled && <CommonProgress />}
			<div
				data-testid="TicketWizard"
				// className={"px-4 md:px-16 lg:px-40 2xl:px-64 grid grid-cols-3 md:grid-cols-4 gap-x-4 h-[90vh] 2xl:h-[93vh] overflow-y-auto"}
				className={"flex flex-col px-4 md:px-16 lg:px-32 xl:px-64 2xl:px-96 3xl:px-128 w-full h-[90vh] 2xl:h-[93vh] overflow-y-auto"}
			>
				<div className="flex flex-row gap-4 w-full grow ">
					<div className="flex flex-col">
						<div className={"grow-0 h-16 hidden md:block"} />
						<div className={"grow-0 shrink-0 hidden md:block"}>
							<Stepper
								orientation="vertical"
								activeStep={stepIndex}
								sx={{
									"& .MuiStepper-root": {
										height: "26rem",
									},
									"& .MuiStepLabel-iconContainer": { order: "1" },
									"& .MuiStepLabel-labelContainer": {
										textAlign: "right",
										paddingRight: "12px",
									},
									"& .MuiStepContent-root": {
										textAlign: "end",
										marginLeft: "0",
										paddingLeft: "0",
										marginRight: "20px",
										paddingRight: "20px",
										borderLeft: "0",
										borderRight: "1px solid #9AB8C0",
									},
									"& .MuiStepConnector-root": {
										marginLeft: "0",
										marginRight: "20px",
									},
									"& .MuiStepConnector-line": {
										borderLeftWidth: "0",
										borderRightStyle: "solid",
										borderRightWidth: "1px",
										height: "100%",
									},
									"& .MuiSvgIcon-root": {
										color: "transparent",
										border: "2px solid #9AB8C0",
										borderRadius: "50%",
										"& .MuiStepIcon-text": {
											fill: "transparent",
										},
									},
									"& .MuiSvgIcon-root.Mui-active": {
										color: "transparent",
										border: "2px solid #01338D",
										borderRadius: "50%",
										"& .MuiStepIcon-text": {
											fill: "#01338D",
										},
									},
									"& .MuiSvgIcon-root.Mui-completed": {
										color: "lightgrey",
										border: "2px solid #00766F",
										borderRadius: "50%",
										backgroundColor: "#00766F",
									},
									"& .MuiStepIcon-text": {
										fontFamily: "Rubik",
										fontSize: "1.2rem",
										fontWeight: "600",
									},
									"& .MuiStepLabel-root": {
										padding: "0",
									},
									"& .MuiCollapse-wrapperInner": {
										fontFamily: "Rubik",
										fontSize: ".8rem",
										color: "#171646",
									},
									"& .MuiStepLabel-label": {
										fontFamily: "Rubik",
										fontSize: "1rem",
										color: "#171646",
										"& .Mui-disabled": {},
									},
									"& .MuiStepContent-last": {
										borderRight: "0",
									},
								}}
							>
								{steps.map((step, index) => {
									if (step.title === "Details" && customForms.length > 0) {
										// let rType = _.get(helpTicketForm, "item.resource.resourceType.name", "these kinds of");
										// let iType = _.get(helpTicketForm, "issueTypeName");
										// let message = step.description;
										// if (iType) {
										// 	message = `Additional Fields available for ${rType} items with
										// 					 the issue type of : '${iType}'`;
										// }
										let message = "Define additional data fields";
										return (
											<Step key={step.title} expanded={true}>
												<StepLabel>{step.title}</StepLabel>
												{stepIndex > index ? (
													<StepContent>
														{renderStep[index]({
															stepIndex,
															ht: helpTicketForm,
														})}
													</StepContent>
												) : (
													<StepContent>
														<p>{message}</p>
													</StepContent>
												)}
											</Step>
										);
									} else if (step.title !== "Details") {
										return (
											<Step key={step.title} expanded={true}>
												<StepLabel>{step.title}</StepLabel>
												{stepIndex > index ? (
													<StepContent>
														{renderStep[index]({
															stepIndex,
															ht: helpTicketForm,
														})}
													</StepContent>
												) : (
													<StepContent>{step.description}</StepContent>
												)}
											</Step>
										);
									}
								})}
							</Stepper>
						</div>
					</div>
					<div className="flex flex-col grow w-full ">
						<div className={" grow-0 h-16 flex font-rubik  "}>
							<WizardHeader text={headers[stepIndex]} />
						</div>
						<div className={"h-[80vh] flex flex-col grow "}>
							<form onSubmit={handleSubmit(onSubmit)}>
								{stepIndex === 0 && <NewTicketCategoryForm onNext={goNext} hasError={isError} />}
								{stepIndex === 1 && <NewTicketStepTwoForm onNext={goNext} hasError={isStep2Error} setHasError={setIsStep2Error} />}
								{stepIndex === 2 && <NewTicketStepThreeForm onNext={goNext} hasError={isStep3Error} setHasError={setIsStep3Error} />}
								{stepIndex === 3 && (
									// <FormStep>
									<NewTicketStepFourForm
										customForms={customForms as Form[]}
										onNext={goNext}
										errorMessages={errorMessages || []}
										onError={(value) => {}}
									/>
									// </FormStep>
								)}
							</form>
						</div>
					</div>
				</div>
			</div>
			<div
				className={
					"w-full h-[10vh] 2xl:h-[7vh] px-4 md:px-16 lg:px-32 xl:px-64  2xl:px-96 3xl:px-128 flex items-center bg-white drop-shadow-[0_2px_8px_rgba(138,146,163,0.16)]"
				}
			>
				<div className={"grow"}>
					<CustomButton
						onClick={() => {
							setCancelDialog(true);
						}}
						variant={"text"}
						ariaLabel="Cancel Ticket"
					>
						Cancel
					</CustomButton>
				</div>
				<div>
					<CustomButton
						onClick={() => {
							setStepIndex(stepIndex - 1);
						}}
						variant={"text"}
						disabled={stepIndex === 0}
						ariaLabel="Go to Previous Step"
					>
						Previous
					</CustomButton>
				</div>
				<div>
					{stepIndex < stepLimit ? (
						<CustomButton onClick={goNext} variant={"contained"} ariaLabel={"Go To Next Step"}>
							Next
						</CustomButton>
					) : (
						<CustomButton
							onClick={async () => {
								setCreateDisabled(true);
								setIsError(!step1Validated());
								setIsStep2Error(!step2Validated());
								setIsStep3Error(!step3Validated());
								setIsStep4Error(!step4Validated());
								if (step1Validated() && step2Validated() && step3Validated() && step4Validated()) {
									try {
										let helpTicketCreate: CreateHelpTicket = helpTicketForm2Create(helpTicketForm);
										await createHelpTicket({ helpTicketCreate })
											.unwrap()
											.then(async (response: { id: string; name: string }) => {
												if (helpTicketForm.addFiles && helpTicketForm.addFiles.length > 0) {
													let filesNotUploaded: string[] = [];
													for (let i = 0; i < helpTicketForm.addFiles.length; i++) {
														try {
															await uploadFile({ helpTicketGuid: response.id, file: helpTicketForm.addFiles[i] });
														} catch (error) {
															filesNotUploaded.push(helpTicketForm.addFiles[i].name);
															// console.log("failed to upload file: " + JSON.stringify(error));
														}
													}
													if (filesNotUploaded.length > 0) {
														let filesNotUploadedString: string = filesNotUploaded[0];
														if (filesNotUploaded.length > 1) {
															for (let i = 1; i < filesNotUploaded.length; i++) {
																filesNotUploadedString += ", " + filesNotUploaded[i];
															}
														}
														notify("Error", `Failed uploading the following files: ${filesNotUploadedString}`, true);
													}
												}
												setHelpTicketGuid(response.id as any);
											});
									} catch (error) {
										notify("Error", "Failed creating the help ticket", true);
									}
								} else {
									notify("Validation Failed", "Some fields are required or need correction", true, 3000);
								}
								setCreateDisabled(false);
							}}
							variant={"contained"}
							ariaLabel={"Save button"}
							disabled={createDisabled}
						>
							Create Ticket
						</CustomButton>
					)}
				</div>
			</div>
			{cancelDialog && (
				<Dialog
					open={cancelDialog}
					sx={{
						"& .MuiPaper-root": {
							width: {
								xs: 300, // theme.breakpoints.up('xs')
								sm: 478, // theme.breakpoints.up('sm')
							},
							height: {
								xs: 300, // theme.breakpoints.up('xs')
								sm: 215, // theme.breakpoints.up('sm')
							},
							borderRadius: "8px",
						},
						"& .MuiDialogActions-root": {
							padding: "16px 24px",
						},
						"& .MuiBackdrop-root": {
							backgroundColor: "#171646",
							opacity: "70% !important",
						},
					}}
					fullScreen={fullScreen}
				>
					<DialogTitle>
						<div className={"flex items-center"}>
							<div className="font-2xl text-[#171646] grow font-rubik">Cancel Ticket Submission?</div>
							<IconButton
								aria-label={"Close"}
								onClick={() => {
									setCancelDialog(false);
								}}
								disableRipple={true}
							>
								<CloseIcon />
							</IconButton>
						</div>
					</DialogTitle>
					<DialogContent>
						<p className="text-base text-left text-[#171646] font-rubik">
							Are you sure you want to cancel this ticket?
							<br />
							Your will lose all progress made.
						</p>
					</DialogContent>
					<DialogActions>
						<div className={"grow"}></div>
						<div>
							<CustomButton
								onClick={(e: any) => {
									setCancelDialog(false);
								}}
								variant={"text"}
								ariaLabel="Continue with Ticket"
							>
								Continue Ticket
							</CustomButton>
						</div>
						<div>
							<CustomButton
								onClick={(e: any) => {
									setCancelDialog(false);
									dispatch(resetHelpTicketForm());
									navigate(-1); // go Back
								}}
								variant={"contained"}
								ariaLabel="Cancel Ticket"
								bgColor={"red"}
							>
								Cancel Ticket
							</CustomButton>
						</div>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

export default TicketWizard;
