import { Button } from "@mui/base/Button";
import { Button as BaseButton } from "@mui/base/Button/Button";
import { CloseOutlined } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Backdrop, ButtonProps, Drawer, InputAdornment, TextField } from "@mui/material";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { FILE_SIZE_UPLOAD_LIMIT } from "../config/config";
import { useAppDispatch, useAppSelector } from "../hook";
import HelpTicketFull, {
	AssigneeMenuItem,
	AttachedFile,
	blankResolution,
	Category,
	HelpTicketUpdateVO,
	Resolution,
	TicketHistory,
} from "../models/helpticket.model";
import { selectAccessToken } from "../slices/AuthSlice";
import { useCategoriesQuery, useDeleteUploadedFileMutation, useHelpTicketQuery, useResolutionsQuery, useUpdateHelpTicketMutation } from "../utilities/apiApi";
import {
	convertAssigneesToMenu,
	getDuration,
	getFileSize,
	getHistoryType,
	getLastFirstMiddleNameDestinyUser,
	getLastFirstMiddleNamePatron,
	getPriority,
	getTicketPriority,
	getTicketStatus,
	getTicketStatusColor,
	getTicketStatusIcon,
	getTicketStatusTextColor,
} from "../utilities/helpTicketUtils";
import CommonProgress from "./CommonProgress/CommonProgress";
import CustomButton from "./CustomButton/CustomButton";
import { CustomFormValues } from "./CustomValue/CustomValue";
import { download } from "./Download/Download";
import { notify, notifyDialog } from "./Notification/Notification";
import ResourceImage from "./ResourceImage/ResourceImage";
import UploadSpinner, { UploadFile } from "./UploadSpinner/UploadSpinner";

// function Cookies() {
// 	return (
// 		<div className="bg-gray-400 ">
// 			{" "}
// 			<button
// 				onClick={() => {
// 					goToUrl("helpdesk/401page");
// 				}}
// 			>
// 				{" "}
// 				Logout
// 			</button>
// 		</div>
// 	);
// }
function getAssigneeIndex(helpTicketAssignees: AssigneeMenuItem[], row: HelpTicketFull | undefined) {
	let retval = -1;
	if (row !== undefined) {
		let guid = row.assignedDistrictUser
			? row.assignedDistrictUser.userGUID
			: row.assignedPatron
				? row.assignedPatron.userGuid
				: row.assignedServicingTeam
					? "" + row.assignedServicingTeam.id
					: -1;
		if (guid === -1 || helpTicketAssignees === undefined || helpTicketAssignees.length === 0) {
			return -1;
		}

		retval = _.findIndex(helpTicketAssignees, (item) => guid === item.id);
	}
	return retval;
}

function MultiLineText({ text }: { text: string }) {
	//  let count = text.split("\n").length;
	//  count = count < 5 ? count : 5;
	return (
		<TextField
			value={text}
			size="small"
			multiline
			maxRows={5}
			variant="outlined"
			InputProps={{
				readOnly: true,
			}}
			fullWidth
			sx={{
				"& .MuiOutlinedInput-root": {
					"& fieldset": { border: "none" },
				},
			}}
		/>
	);
}

export default function ViewTicket({
	helpTicketGUID,
	isViewingHelpTicket,
	setIsViewingHelpTicket,
	canEditHelpTicket,
	updateTicket,
}: {
	helpTicketGUID: string;
	isViewingHelpTicket: boolean;
	setIsViewingHelpTicket: React.Dispatch<React.SetStateAction<boolean>>;
	canEditHelpTicket: boolean;
	updateTicket: (preamble: string, update: any, shouldSkipNotify?: boolean) => void;
}) {
	// const [helpTicketBeingViewed, setHelpTicketBeingViewed] = useState(helpTicket)
	const [updateSkipFlag, setUpdateSkipFlag] = useState(true);
	const [tabIndex, setTabIndex] = useState(0);
	const [updateHelpTicket, updateStatus] = useUpdateHelpTicketMutation();

	const uploadInputRef = useRef<HTMLInputElement | null>(null);
	const toggleViewingTicketDrawer = (open: boolean, reason: String) => {
		setIsViewingHelpTicket(open);
	};
	const [showDropdown, setShowDropdown] = useState(false);
	const [showDropdownIndex, setShowDropdownIndex] = useState(0);
	const [newNoteTitle, setNewNoteTitle] = useState("");
	const [isClosingTicket, setIsClosingTicket] = useState(false);
	const [resolution, setResolution] = useState<Resolution>(_.cloneDeep(blankResolution));
	const [resolutionDescription, setResolutionDescription] = useState<string>("");
	// const [uploadFiles, uploadFileResult] = useUploadFileMutation();
	const [uploadFiles, setUploadFiles] = useState(undefined);
	const [deleteUploadedFile, deleteUploadedFileResult] = useDeleteUploadedFileMutation();
	const [descriptionError, setDescriptionError] = useState(undefined);
	const [resolutionError, setResolutionError] = useState(undefined);
	const [showDeleting, setShowDeleting] = useState(false);
	const authToken: string | undefined = useAppSelector(selectAccessToken);
	const disallowedFileTypes = ["application/x-msdownload"];

	let selectedCategory: Category | undefined;
	const dispatch = useAppDispatch();

	const CustomButtonUnique = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
		const { className, ...other } = props;

		return (
			<BaseButton
				ref={ref}
				className={"bg-[#F9F9F9] text-[#01338D] md:min-w-96 rounded-md border border-solid border-blue-700 p-2 flex justify-center items-center"}
				onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
				{...other}
			></BaseButton>
		);
	});

	// const updateTicket = async (helpTicketUpdateVO: HelpTicketUpdateVO) => {
	// 	if (!updateStatus.isLoading && helpTicketUpdateVO.helpTicketGUID) {
	// 		try {
	// 			await updateHelpTicket({ update: helpTicketUpdateVO }).unwrap();
	// 			setUpdateSkipFlag(false);
	// 		} catch (error) {
	// 			console.error("Failed to update the helpticket: " + error);
	// 		}
	// 	}
	// };

	const addFiles = async (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		let limitExceeded = false;
		let filesLimit: number = 0;
		let filesNotUploaded: string[] = [];
		if (helpTicketBeingViewed?.attachedFiles && helpTicketBeingViewed?.attachedFiles?.length > 0) {
			for (let i = 0; i < helpTicketBeingViewed?.attachedFiles.length; i++) {
				filesLimit += helpTicketBeingViewed?.attachedFiles[i].size;
			}
		}
		let result: Array<File> = [];
		// @ts-ignore;
		if (
			// @ts-ignore
			event.dataTransfer !== undefined
		) {
			// @ts-ignore
			if (
				// @ts-ignore
				event.dataTransfer.items && // @ts-ignore
				event.dataTransfer.items.length > 0
			) {
				// @ts-ignore
				[...event.dataTransfer.items].map((item) => {
					if (item.kind === "file") {
						const file = item.getAsFile();
						if (file !== null && filesLimit + file.size <= FILE_SIZE_UPLOAD_LIMIT) {
							if (!disallowedFileTypes.includes(file.type)) {
								result.push(file);
							} else {
								filesNotUploaded.push(file.name);
							}
						} else {
							limitExceeded = true;
							result = [];
						}
					}
				});
				// @ts-ignore
			}
		}
		let filesNotUploadedString = "";
		if (filesNotUploaded.length > 0) {
			filesNotUploadedString = filesNotUploaded[0];
			if (filesNotUploaded.length > 1) {
				for (let i = 0; i < filesNotUploaded.length; i++) {
					filesNotUploadedString += ", " + filesNotUploaded[i];
				}
			}
		}
		if (filesNotUploaded.length > 0) {
			notify("Error", "The following files were not uploaded because their file type is not allowed: " + filesNotUploadedString, true);
		}
		if (
			// @ts-ignore
			event.currentTarget.files && // @ts-ignore
			event.currentTarget.files.length > 0
		) {
			// @ts-ignore
			[...event.currentTarget.files].map((file: File) => {
				if (file !== null && filesLimit + file.size <= FILE_SIZE_UPLOAD_LIMIT) {
					result.push(file);
				} else {
					limitExceeded = true;
					result = [];
				}
			});
		}
		if (result.length > 0) {
			result = _.uniqWith(result, (a, b) => {
				return a.name === b.name && a.size === b.size && a.type === b.type && a.lastModified === b.lastModified;
			});
			if (helpTicketBeingViewed && result.length > 0) {
				let up: UploadFile[] = [];
				for (let i = 0; i < result.length; i++) {
					let file = result[i];
					up.push({ helpTicketGuid: helpTicketGUID, file: file });
				}
				setUploadFiles(up as any);
			}
			// if (helpTicketBeingViewed && result.length > 0) {

			// 	let errors: string[] = [];
			// 	for (let i = 0; i < result.length; i++) {
			// 		let file = result[i];
			// 		try {
			// 			let uploadResult = await uploadFile({ helpTicketGuid: helpTicketGUID, file: file });
			// 			if (uploadResult.error) {
			// 				errors.push("upload of '" + file.name + "' failed");
			// 			}
			// 		} catch (error) {
			// 			// console.log("failed to upload file: " + JSON.stringify(error));
			// 			errors.push(file.name);
			// 		}
			// 	}
			// 	if (errors.length > 0) {
			// 		let errorsString: string = errors[0];
			// 		if (errors.length > 1) {
			// 			for (let i = 1; i < errors.length; i++) {
			// 				errorsString += ", " + errors[i];
			// 			}
			// 		}
			// 		notify("Error", "The upload of " + errors.length + " file(s) failed: " + errorsString, true);
			// 	} else {
			// 		notify("Success", "All file(s) uploaded Successfully", false);
			// 	}
			// }
		} else {
			if (limitExceeded) {
				notifyDialog("Error", "Uploading these file(s) exceeds the upload limit of 25mb per ticket.", true, () => {});
			}
		}
	};

	function getHistoryDuration(hth: TicketHistory) {
		return _.get(hth, "statusCreatedDuration", getDuration(hth.dateCreated));
	}
	// @ts-ignore
	const deleteFile = async (index) => {
		let file: AttachedFile | undefined = helpTicketBeingViewed?.attachedFiles[index];
		if (file) {
			let errors: string[] = [];
			try {
				await deleteUploadedFile({ helpTicketGuid: helpTicketGUID, name: file.name });
				notify("File Removed", "'" + file.name + "' removed successfully", false);
			} catch (error) {
				notify("Error", `Failed to deleted the file: ${file.name}`, true);
				// console.log("failed to delete file: " + JSON.stringify(error));
			}
		}
	};

	function addNote() {
		if (newNoteTitle !== "" && helpTicketBeingViewed) {
			let result: HelpTicketFull = _.cloneDeep(helpTicketBeingViewed);
			result.note = newNoteTitle;
			let update: HelpTicketUpdateVO = {
				helpTicketGUID: result.helpTicketGUID,
				note: newNoteTitle,
			};
			let message = `Ticket #${result.ticketNumber} Updated`;
			updateTicket(message, update);
			setNewNoteTitle("");
		}
	}
	// async function doDownloadFile(helpTicketGUID: string, file: AttachedFile) {
	// 	try {
	// 		let result = await download(authToken || "", helpTicketGUID, file);
	// 		if (result.status === "success") {
	// 			notify("File Download Success", "The file '" + file.name + "' was downloaded", false);
	// 		} else {
	// 			notify("File Download Failed", "The file '" + file.name + "' was not downloaded", true);
	// 			// console.log("Failed to download '" + file.name + "' : " + JSON.stringify(result.error));
	// 		}
	// 	} catch (error) {
	// 		notify("File Download Failed", "The file '" + file.name + "' was not downloaded", true);
	// 		// console.log("Failed to download unexpectedly '" + file.name + "' : " + JSON.stringify(error));
	// 	}
	// }

	function getResolutionName(name: string) {
		let myName = name.trim();
		let first = myName.charAt(0).toUpperCase();
		myName = myName.substring(1).toLowerCase();
		myName = myName.replace("_", " ");
		myName = first + myName;
		return myName;
	}
	let helpTicketAssignees: AssigneeMenuItem[] = convertAssigneesToMenu([]);
	let helpTicketBeingViewed: HelpTicketFull | undefined = undefined;
	let helpTicketResult: any = useHelpTicketQuery({ helpTicketGUID: helpTicketGUID }, { skip: helpTicketGUID === undefined });
	if (helpTicketResult.isSuccess) {
		helpTicketBeingViewed = helpTicketResult.data as HelpTicketFull;
		helpTicketAssignees = convertAssigneesToMenu(helpTicketBeingViewed.helpTicketAssignees);
	}
	if (helpTicketResult.isError) {
		notifyDialog("Network error", "Failed retrieving the help ticket", true, () => {});
	}

	let categoriesResult: any = useCategoriesQuery();
	let categories = [] as Array<Category>;

	if (categoriesResult.isSuccess) {
		categories = categoriesResult.data as Array<Category>;
		if (categories && categories.length) {
			categories = _.sortBy(categories, ["name"]);
			selectedCategory =
				_.find(categories, (item) => item.helpTicketIssueTypeID === _.get(helpTicketBeingViewed, "category.helpTicketIssueTypeID")) || categories[0];
		}
	}

	let resolutionsQuery: any = useResolutionsQuery();
	let resolutions: Array<Resolution> = [];
	if (resolutionsQuery.isError) {
		resolutions = [];
	}
	if (resolutionsQuery.isSuccess) {
		resolutions = resolutionsQuery.data;
	}

	if (resolutionsQuery.isLoading || categoriesResult.isLoading || helpTicketResult.isLoading) {
		return <CommonProgress />;
	}

	function getSiteName(ht: HelpTicketFull | undefined) {
		return _.get(ht, "item.site.name", "");
	}
	function getBarcode(ht: HelpTicketFull | undefined) {
		return _.get(ht, "item.barcode", "");
	}
	function getLocation(ht: HelpTicketFull | undefined) {
		return _.get(ht, "item.location", "");
	}
	// if (htStatus.isCompleted) {
	// 	setUpdateSkipFlag(true);
	// }
	let filevalue = "";
	return (
		<div className={"fixed top-0 left-0"}>
			<Drawer
				open={isViewingHelpTicket}
				anchor={"right"}
				onClose={(event, reason) => {
					toggleViewingTicketDrawer(false, reason);
				}}
				sx={{
					"& .MuiBackdrop-root": {
						backgroundColor: "#171646",
						opacity: "70%!important",
					},
				}}
			>
				<div className={"flex flex-row min-w-[100%] min-h-screen fixed top-0 left-0 pointer-events-none"}>
					{uploadFiles && (
						<UploadSpinner
							uploadFiles={uploadFiles}
							onComplete={() => {
								setUploadFiles(undefined);
							}}
						/>
					)}
					<Backdrop
						sx={{ color: "#fff", backgroundColor: "transparent !important", zIndex: 3 }}
						open={showDropdown}
						onClick={(e: any) => setShowDropdown(false)}
					/>
					<div className={"grow"} />
					<div className={"bg-white w-full lg:w-3/4 pointer-events-auto p-4 overflow-auto max-h-[100vh] max-w-[100vw]"}>
						<div className={"flex flex-col"}>
							<div className={"w-full min-h-14 flex flex-row items-start"}>
								<div className={"bg-open-lighter text-open-dark w-fit p-2 rounded-[8px] border-[1px] break-all border-[#18487C]"}>
									{`Ticket #${helpTicketBeingViewed?.ticketNumber}: ${helpTicketBeingViewed?.summary}`}
								</div>
								<div className={"grow"} />
								<button
									onClick={() => {
										toggleViewingTicketDrawer(false, "backdropClick");
									}}
								>
									<CloseOutlined />
								</button>
							</div>
							{/* Hidden for now */}
							<div className={"flex flex-row ml-2 hidden"}>
								<BaseButton
									className={"" + (tabIndex === 0 ? "text-[#01338D] border-b-2 border-b-[#01338D]" : "text-[#171646] pb-0.5")}
									onClick={() => {
										setTabIndex(0);
									}}
								>
									<div>Ticket details</div>
								</BaseButton>
								<BaseButton
									className={"ml-4 " + (tabIndex === 1 ? "text-[#01338D] border-b-2 border-b-[#01338D]" : "text-[#171646] pb-0.5")}
									onClick={() => {
										setTabIndex(1);
									}}
								>
									<div>Device history</div>
								</BaseButton>
							</div>
							{tabIndex === 0 && (
								<div className={"grow w-full min-h-[34rem] flex flex-col md:flex-row"}>
									<div className={"grow border-[#D6D8DB] border rounded-[8px] md:w-max-3/4"}>
										{!isClosingTicket && (
											<div className={"flex flex-col xl:flex-row"}>
												<div className={"flex flex-col md:w-2/3 md:max-w-2/3 break-all p-4"}>
													<div className={"flex flex-col mb-3"}>
														<b>Device</b>
														<div className={"flex flex-row items-center"}>
															<ResourceImage
																id={"rimage"}
																key={"resourceImage_1"}
																resourceGUID={_.get(helpTicketBeingViewed, "item.resource.guid")}
															/>
															<div className={"ml-2 flex flex-col"}>
																<div>{`${helpTicketBeingViewed?.item.resource.name} (Barcode: ${getBarcode(helpTicketBeingViewed)})`}</div>
																{canEditHelpTicket && <div>Site: {getSiteName(helpTicketBeingViewed)}</div>}
																<div>Home Location: {getLocation(helpTicketBeingViewed)}</div>
															</div>
														</div>
													</div>
													<div className={"flex flex-col mb-3"}>
														<b>Summary</b>
														<div>{helpTicketBeingViewed?.summary}</div>
													</div>
													<div className={"flex flex-col mb-3"}>
														<b>Description</b>
														<div>{helpTicketBeingViewed?.details}</div>
													</div>
													{helpTicketBeingViewed && helpTicketBeingViewed.customFields && (
														<div className={"flex flex-col "}>
															<CustomFormValues fields={helpTicketBeingViewed.customFields} />
														</div>
													)}
													<div className={"flex flex-col mb-3"}>
														<b>Attached Files</b>
														{helpTicketBeingViewed?.status?.name !== "Closed" && (
															<>
																<div className={"mb-2 w-fit"}>
																	<input
																		hidden
																		multiple
																		type="file"
																		id={"fileUploadButton"}
																		ref={uploadInputRef}
																		value={filevalue}
																		onChange={(event) => {
																			addFiles(event);
																		}}
																	/>
																	<label htmlFor={"fileUploadButton"}>
																		<div
																			className={"w-fit"}
																			onDrop={(event) => {
																				event.preventDefault();
																				addFiles(event);
																			}}
																			onDragOver={(event) => {
																				event.preventDefault();
																				// 	// addFiles(event);
																			}}
																		>
																			<CustomButtonUnique>
																				<img
																					src={"/upload-icon.svg"}
																					alt={"Button to add a help ticket"}
																					className={"mr-2 font-roboto"}
																				/>
																				Drag and drop files here, or&nbsp;<u>browse</u>
																			</CustomButtonUnique>
																		</div>
																	</label>
																</div>
																<div className="opacity-70 text-gray-900 font-normal font-rubik text-sm tracking-normal text-left">
																	Max 25mb. Upload images or files that describe your problem
																</div>
															</>
														)}
														<div className={""}>
															{helpTicketBeingViewed &&
																helpTicketBeingViewed.attachedFiles &&
																helpTicketBeingViewed.attachedFiles.map((file, index) => (
																	<div
																		className={
																			"md:min-w-96 min-h-16 flex flex-row items-center gap-x-2 p-2 font-rubik border-t border-[#D3D3D4] " +
																			(index ===
																			(helpTicketBeingViewed?.attachedFiles ? helpTicketBeingViewed?.attachedFiles : [])
																				.length -
																				1
																				? "border-b border-[#D3D3D4]"
																				: "")
																		}
																	>
																		<div
																			className={"cursor-grab flex flex-row grow items-center"}
																			onClick={async () => {
																				download(helpTicketGUID, file);
																			}}
																			title={`Download ${file.name}`}
																		>
																			<div>
																				<img
																					src={"/common-file-text.svg"}
																					alt={"Uploaded image"}
																					className={"min-w-12 min-h-12 max-w-12 max-h-12 p-2"}
																				/>
																			</div>
																			<div className={"grow text-[#01338D] break-all relative"}>{file.name}</div>
																		</div>
																		<div className={"text-[#313135] text-xs"}>{getFileSize(file.size)}</div>
																		<div className={"min-h-5 h-5"}>
																			<button
																				onClick={async () => {
																					setShowDeleting(true);
																					try {
																						await deleteFile(index);
																					} catch (error) {
																					} finally {
																						setShowDeleting(false);
																					}
																				}}
																			>
																				<img
																					src={"/bin.svg"}
																					alt={"Red recycle bin icon for deleting the associated file"}
																					className={"min-w-4 min-h-4 max-w-4 max-h-4"}
																				/>
																			</button>
																		</div>
																	</div>
																))}
														</div>
													</div>
												</div>
												<div className={"flex flex-col p-4 lg:w-1/3"}>
													<div className={"flex flex-col mb-3"}>
														<b>Status</b>
														{canEditHelpTicket && helpTicketBeingViewed?.status?.name !== "Closed" ? (
															<div className={"dropdown-container relative"}>
																<Button
																	className={
																		"px-2 py-1 rounded-md border border-primary min-w-[12rem] max-w-[12rem] " +
																		getTicketStatusColor(helpTicketBeingViewed?.status?.name || "Open")
																	}
																	onClick={() => {
																		setShowDropdown(!showDropdown);
																		setShowDropdownIndex(1);
																	}}
																>
																	<div className={"ml-1 flex flex-row items-center"}>
																		<img
																			src={getTicketStatusIcon(helpTicketBeingViewed?.status?.name || ("Open" as string))}
																			alt={"Ticket status icon"}
																			className={"flex-none"}
																		/>
																		<div
																			className={
																				"text-left ml-4 font-medium md:text-nowrap grow text-" +
																				getTicketStatusColor(helpTicketBeingViewed?.status?.name || ("Open" as string))
																			}
																		>
																			{getTicketStatus(helpTicketBeingViewed?.status?.name as string).name}
																		</div>
																		<div className={"ml-auto pr-1"}>
																			<KeyboardArrowDownIcon />
																		</div>
																	</div>
																</Button>
																{showDropdown && showDropdownIndex === 1 && (
																	<div className="absolute top-full left-0 z-10 bg-white border border-gray-200 min-w-[12rem] rounded-[4px] drop-shadow-[0_2px_8px_rgba(138,146,163,0.16)]">
																		<ul>
																			<li>
																				<button
																					onClick={() => {
																						let result = _.cloneDeep(helpTicketBeingViewed as HelpTicketFull);
																						result.status = getTicketStatus(0);
																						let update: HelpTicketUpdateVO = {
																							helpTicketGUID: result.helpTicketGUID,
																							status: result.status.id,
																						};
																						let message = `Ticket #${result.ticketNumber} Updated`;
																						updateTicket(message, update);
																						setShowDropdown(false);
																					}}
																					className={"hover:bg-[#D3D3D4] w-full"}
																				>
																					<div className={"flex flex-row items-center p-3"}>
																						<img
																							src={"/sign-badge-circle-black.svg"}
																							alt={"Ticket status icon"}
																							className={"mr-4"}
																						></img>
																						<div className={"md:text-nowrap grow text-left"}>Open</div>
																					</div>
																				</button>
																			</li>
																			<li>
																				<button
																					onClick={() => {
																						let result = _.cloneDeep(helpTicketBeingViewed as HelpTicketFull);
																						result.status = getTicketStatus(3);
																						let update: HelpTicketUpdateVO = {
																							helpTicketGUID: result.helpTicketGUID,
																							status: result.status.id,
																						};
																						let message = `Ticket #${result.ticketNumber} Updated`;
																						updateTicket(message, update);
																						setShowDropdown(false);
																					}}
																					className={"hover:bg-[#D3D3D4] w-full"}
																				>
																					<div className={"flex flex-row items-center p-3"}>
																						<img
																							src={"/in-progress-icon-black.svg"}
																							alt={"Ticket status icon"}
																							className={"mr-4"}
																						></img>
																						<div className={"md:text-nowrap grow text-left"}>In Progress</div>
																					</div>
																				</button>
																			</li>
																			<li>
																				<button
																					onClick={() => {
																						let result = _.cloneDeep(helpTicketBeingViewed as HelpTicketFull);
																						result.status = getTicketStatus(1);
																						let update: HelpTicketUpdateVO = {
																							helpTicketGUID: result.helpTicketGUID,
																							status: result.status.id,
																						};
																						let message = `Ticket #${result.ticketNumber} Updated`;
																						updateTicket(message, update);
																						setShowDropdown(false);
																					}}
																					className={"hover:bg-[#D3D3D4] w-full"}
																				>
																					<div className={"flex flex-row items-center p-3"}>
																						<img
																							src={"/subtract-circle-black.svg"}
																							alt={"Ticket status icon"}
																							className={"mr-4"}
																						></img>
																						<div className={"text-left md:text-nowrap grow"}>On Hold</div>
																					</div>
																				</button>
																			</li>
																			<li>
																				<button
																					onClick={() => {
																						setShowDropdown(false);
																						setIsClosingTicket(true);
																					}}
																					className={"hover:bg-[#D3D3D4] w-full"}
																				>
																					<div className={"flex flex-row items-center p-3"}>
																						<img
																							src={"/check-circle-black.svg"}
																							alt={"Ticket status icon"}
																							className={"mr-4"}
																						></img>
																						<div className={"text-left md:text-nowrap grow"}>Closed</div>
																					</div>
																				</button>
																			</li>
																		</ul>
																	</div>
																)}
															</div>
														) : (
															<div className={"flex flex-row"}>
																<img
																	src={getTicketStatusIcon(helpTicketBeingViewed?.status?.name || ("Open" as string))}
																	alt={"Status icon for ticket being viewed"}
																	className={"mr-2"}
																/>
																<div
																	className={
																		"text-" +
																		getTicketStatusTextColor(helpTicketBeingViewed?.status?.name || ("Open" as string))
																	}
																>
																	{helpTicketBeingViewed?.status?.name || "Open"}
																</div>
															</div>
														)}
													</div>
													<div className={"flex flex-col mb-3"}>
														<b>Priority</b>
														{canEditHelpTicket && helpTicketBeingViewed?.status?.name !== "Closed" ? (
															<div className={"dropdown-container relative"}>
																<Button
																	className={"px-2 py-1 rounded-md border border-[#D3D3D4] min-w-[12rem] max-w-[12rem]"}
																	onClick={() => {
																		setShowDropdown(!showDropdown);
																		setShowDropdownIndex(2);
																	}}
																>
																	<div className={"flex flex-row items-center text-left"}>
																		<div className={"mr-2 grow"}>{helpTicketBeingViewed?.priority?.name}</div>
																		<KeyboardArrowDownIcon className={"mr-1"} />
																	</div>
																</Button>
																{showDropdown && showDropdownIndex === 2 && (
																	<div className="absolute top-full left-0 z-10 bg-white border border-gray-200 min-w-[12rem] rounded-[4px] drop-shadow-[0_2px_8px_rgba(138,146,163,0.16)]">
																		<ul>
																			<li>
																				<button
																					onClick={() => {
																						let result = _.cloneDeep(helpTicketBeingViewed as HelpTicketFull);
																						result.priority = getTicketPriority("Low");
																						let update: HelpTicketUpdateVO = {
																							helpTicketGUID: result.helpTicketGUID,
																							priority: result.priority.id,
																						};
																						let message = `Ticket #${result.ticketNumber} Updated`;
																						updateTicket(message, update);
																						setShowDropdown(false);
																					}}
																					className={"hover:bg-[#D3D3D4] w-full"}
																				>
																					<div className={"p-3"}>
																						<div className={"md:text-nowrap grow text-left"}>Low</div>
																					</div>
																				</button>
																			</li>
																			<li>
																				<button
																					onClick={() => {
																						let result = _.cloneDeep(helpTicketBeingViewed as HelpTicketFull);
																						result.priority = getTicketPriority("Medium");
																						let update: HelpTicketUpdateVO = {
																							helpTicketGUID: result.helpTicketGUID,
																							priority: result.priority.id,
																						};
																						let message = `Ticket #${result.ticketNumber} Updated`;
																						updateTicket(message, update);
																						setShowDropdown(false);
																					}}
																					className={"hover:bg-[#D3D3D4] w-full"}
																				>
																					<div className={"p-3"}>
																						<div className={"md:text-nowrap grow text-left"}>Medium</div>
																					</div>
																				</button>
																			</li>
																			<li>
																				<button
																					onClick={() => {
																						let result = _.cloneDeep(helpTicketBeingViewed as HelpTicketFull);
																						result.priority = getTicketPriority("High");
																						let update: HelpTicketUpdateVO = {
																							helpTicketGUID: result.helpTicketGUID,
																							priority: result.priority.id,
																						};
																						let message = `Ticket #${result.ticketNumber} Updated`;
																						updateTicket(message, update);
																						setShowDropdown(false);
																					}}
																					className={"hover:bg-[#D3D3D4] w-full"}
																				>
																					<div className={"p-3"}>
																						<div className={"md:text-nowrap grow text-left"}>High</div>
																					</div>
																				</button>
																			</li>
																		</ul>
																	</div>
																)}
															</div>
														) : (
															<div className={"flex flex-row"}>
																<div
																	className={
																		"" +
																		(helpTicketBeingViewed?.priority &&
																			(getPriority(helpTicketBeingViewed?.priority.id as number).name === "High"
																				? "text-[#FF0101]"
																				: ""))
																	}
																>
																	{helpTicketBeingViewed?.priority &&
																		getPriority(helpTicketBeingViewed?.priority.id as number).name}
																</div>
															</div>
														)}
													</div>
													<div className={"flex flex-col mb-3"}>
														<b>Assigned to</b>
														{canEditHelpTicket && helpTicketBeingViewed?.status?.name !== "Closed" ? (
															<div className={"dropdown-container relative"}>
																<Button
																	className={"px-2 py-1 rounded-md border border-[#D3D3D4] min-w-[12rem] max-w-[12rem]"}
																	onClick={() => {
																		setShowDropdown(!showDropdown);
																		setShowDropdownIndex(3);
																	}}
																>
																	<div className={"flex flex-row items-center text-left"}>
																		<div className={"mr-2 grow"}>
																			{getAssigneeIndex(helpTicketAssignees, helpTicketBeingViewed) >= 0
																				? helpTicketAssignees[
																						getAssigneeIndex(helpTicketAssignees, helpTicketBeingViewed)
																					].name
																				: "Not Assigned"}
																		</div>
																		<KeyboardArrowDownIcon className={"mr-1"} />
																	</div>
																</Button>
																{showDropdown && showDropdownIndex === 3 && (
																	<div className="absolute top-full left-0 z-10 bg-white border border-gray-200 min-w-[12rem] rounded-[4px] drop-shadow-[0_2px_8px_rgba(138,146,163,0.16)] overflow-y-auto max-h-[40vh]">
																		<ul>
																			{helpTicketAssignees &&
																				[
																					{
																						type: "team",
																						serviceTeamID: -1,
																						name: "Not Assigned",
																						id: "",
																						isDistrictUser: false,
																					},
																					...helpTicketAssignees,
																				].map((assignee, index) => {
																					return (
																						<li>
																							<button
																								onClick={() => {
																									if (helpTicketBeingViewed) {
																										if (assignee.type === "team") {
																											// serviceTeam selected
																											let update: HelpTicketUpdateVO = {
																												helpTicketGUID:
																													helpTicketBeingViewed.helpTicketGUID,
																												assignedServicingTeamID: assignee.serviceTeamID,
																												assignedDistrictUserGUID: undefined,
																												assignedPatronGUID: undefined,
																											};
																											let message = `Ticket #${helpTicketBeingViewed?.ticketNumber} Updated`;
																											updateTicket(message, update);
																										} else {
																											if (assignee.isDistrictUser) {
																												let update: HelpTicketUpdateVO = {
																													helpTicketGUID:
																														helpTicketBeingViewed.helpTicketGUID,
																													assignedServicingTeamID:
																														assignee.serviceTeamID,
																													assignedDistrictUserGUID:
																														assignee.id as string,
																													assignedPatronGUID: undefined,
																												};
																												let message = `Ticket #${helpTicketBeingViewed?.ticketNumber} Updated`;
																												updateTicket(message, update);
																											} else {
																												let update: HelpTicketUpdateVO = {
																													helpTicketGUID:
																														helpTicketBeingViewed.helpTicketGUID,
																													assignedServicingTeamID:
																														assignee.serviceTeamID,
																													assignedDistrictUserGUID: undefined,
																													assignedPatronGUID: assignee.id as string,
																												};
																												let message = `Ticket #${helpTicketBeingViewed?.ticketNumber} Updated`;
																												updateTicket(message, update);
																											}
																										}
																									}
																									setShowDropdown(false);
																								}}
																								className={
																									"hover:bg-[#D3D3D4] w-full disabled:bg-white disabled:text-gray-400"
																								}
																								disabled={
																									assignee.serviceTeamID === undefined &&
																									assignee.type === "team"
																								}
																							>
																								<div className={"p-3"}>
																									<div className={"md:text-nowrap grow text-left"}>
																										{assignee.type === "team" ? (
																											<span className={"text-wrap font-medium"}>
																												{assignee.name}
																											</span>
																										) : (
																											<span className={"text-wrap"}>{assignee.name}</span>
																										)}
																									</div>
																								</div>
																							</button>
																						</li>
																					);
																				})}
																		</ul>
																	</div>
																)}
															</div>
														) : (
															<div className={"flex flex-row items-center text-left"}>
																<div className={"mr-2 md:text-nowrap grow"}>
																	{helpTicketBeingViewed
																		? helpTicketBeingViewed.assignedDistrictUser
																			? getLastFirstMiddleNameDestinyUser(helpTicketBeingViewed.assignedDistrictUser)
																			: helpTicketBeingViewed.assignedPatron
																				? getLastFirstMiddleNamePatron(helpTicketBeingViewed.assignedPatron)
																				: helpTicketBeingViewed.assignedServicingTeam
																					? helpTicketBeingViewed.assignedServicingTeam.name
																					: ""
																		: ""}
																</div>
															</div>
														)}
													</div>
													<div className={"flex flex-col mb-3"}>
														<b>Issue category</b>
														{canEditHelpTicket &&
														helpTicketBeingViewed?.status &&
														helpTicketBeingViewed?.status.name !== "Closed" ? (
															<div className={"dropdown-container relative"}>
																<Button
																	className={"px-2 py-1 rounded-md border border-[#D3D3D4] min-w-[12rem] max-w-[12rem]"}
																	onClick={() => {
																		setShowDropdown(!showDropdown);
																		setShowDropdownIndex(4);
																	}}
																>
																	<div className={"flex flex-row items-center text-left"}>
																		<div className={"mr-2 grow"}>{_.get(helpTicketBeingViewed, "category.name", "")}</div>
																		<KeyboardArrowDownIcon className={"mr-1"} />
																	</div>
																</Button>
																{showDropdown && showDropdownIndex === 4 && (
																	<div className="absolute top-full left-0 z-10 bg-white border border-gray-200 min-w-[12rem] rounded-[4px] drop-shadow-[0_2px_8px_rgba(138,146,163,0.16)]">
																		<ul>
																			{categories &&
																				// @ts-ignore
																				_.filter(
																					categories,
																					(cat) =>
																						cat.active ||
																						cat.helpTicketIssueTypeID ===
																							helpTicketBeingViewed?.issueType?.helpTicketIssueTypeID,
																				).map((category) => {
																					return (
																						<li>
																							<button
																								onClick={() => {
																									if (helpTicketBeingViewed) {
																										// let issueType = helpTicketBeingViewed.issueType;
																										// if (
																										// 	issueType &&
																										// 	issueType.parentID !==
																										// 	category.helpTicketIssueTypeID
																										// ) {
																										// 	issueType = _.get(category, "issueTypes[0]");
																										// } else {
																										// 	issueType = undefined;
																										// }
																										let issueTypeID = _.get(
																											category,
																											"issueTypes[0].helpTicketIssueTypeID",
																										);
																										let update: HelpTicketUpdateVO = {
																											helpTicketGUID:
																												helpTicketBeingViewed.helpTicketGUID,
																											issueCategory:
																												category?.helpTicketIssueTypeID || undefined,
																											issueType: issueTypeID,
																										};
																										selectedCategory = category; // this should not be necessary
																										let message = `Ticket #${helpTicketBeingViewed?.ticketNumber} Updated`;
																										updateTicket(message, update);
																										setShowDropdown(false);
																									}
																								}}
																								className={"hover:bg-[#D3D3D4] w-full"}
																							>
																								<div className={"p-3"}>
																									<div className={"md:text-nowrap grow text-left"}>
																										{category.name}
																									</div>
																								</div>
																							</button>
																						</li>
																					);
																				})}
																		</ul>
																	</div>
																)}
															</div>
														) : (
															<div>{_.get(helpTicketBeingViewed, "category.name", "")}</div>
														)}
													</div>
													{helpTicketBeingViewed?.issueType && (
														<div className={"flex flex-col mb-3"}>
															<b>Issue type</b>
															{canEditHelpTicket &&
															helpTicketBeingViewed?.status &&
															helpTicketBeingViewed?.status.name !== "Closed" ? (
																<div className={"dropdown-container relative"}>
																	{selectedCategory?.issueTypes &&
																	selectedCategory.active &&
																	selectedCategory.issueTypes.length > 1 ? (
																		<div>
																			<Button
																				className={
																					"px-2 py-1 rounded-md border border-[#D3D3D4] min-w-[12rem] max-w-[12rem]"
																				}
																				onClick={() => {
																					setShowDropdown(!showDropdown);
																					setShowDropdownIndex(5);
																				}}
																			>
																				<div className={"flex flex-row items-center text-left"}>
																					<div className={"mr-2 grow"}>
																						{_.get(helpTicketBeingViewed, "issueType.name", "")}
																					</div>
																					<KeyboardArrowDownIcon className={"mr-1"} />
																				</div>
																			</Button>
																			{showDropdown && showDropdownIndex === 5 && (
																				<div className="absolute top-full left-0 z-10 bg-white border border-gray-200 min-w-[12rem] rounded-[4px] drop-shadow-[0_2px_8px_rgba(138,146,163,0.16)] max-h-[30vh] overflow-y-auto">
																					<ul>
																						{selectedCategory &&
																							selectedCategory.issueTypes &&
																							// @ts-ignore
																							selectedCategory.issueTypes.map((item) => {
																								return (
																									<li>
																										<button
																											onClick={() => {
																												let update: HelpTicketUpdateVO = {
																													helpTicketGUID: helpTicketGUID,
																													issueType: item.helpTicketIssueTypeID,
																												};
																												let message = `Ticket #${helpTicketBeingViewed?.ticketNumber} Updated`;
																												updateTicket(message, update);
																												setShowDropdown(false);
																											}}
																											className={"hover:bg-[#D3D3D4] w-full"}
																											disabled={selectedCategory === undefined}
																										>
																											<div className={"p-3"}>
																												<div
																													className={"md:text-nowrap grow text-left"}
																												>
																													{item.name}
																												</div>
																											</div>
																										</button>
																									</li>
																								);
																							})}
																					</ul>
																				</div>
																			)}
																		</div>
																	) : (
																		<div className="my-1 ml-2">{_.get(helpTicketBeingViewed, "issueType.name", "")}</div>
																	)}
																</div>
															) : (
																<div>{_.get(helpTicketBeingViewed, "issueType.name", "")}</div>
															)}
														</div>
													)}
												</div>
											</div>
										)}
										{isClosingTicket && (
											<div className={"p-4 flex flex-col"}>
												<div className={"mb-2 font-rubik text-[#171646]"}>Please provide a reason</div>
												<div className={"flex flex-wrap gap-1 mb-4"}>
													{resolutions.map((resolutionItem) => {
														if (resolutionItem.active) {
															return (
																<button
																	key={"resolution_" + resolutionItem.name}
																	value={resolutionItem.helpTicketResolutionID}
																	className={
																		"rounded-[24px] text-primary border border-primary p-1 px-2 " +
																		(resolutionItem.helpTicketResolutionID === resolution.helpTicketResolutionID
																			? "bg-primary text-white"
																			: "")
																	}
																	onClick={() => {
																		setResolution(resolutionItem);
																	}}
																>
																	{getResolutionName(resolutionItem.name)}
																</button>
															);
														}
													})}
												</div>
												{resolutionError && <div className="block text-error-text text-base">{resolutionError}</div>}
												<div className={"flex flex-col w-3/4"}>
													<textarea
														placeholder={"Explain the resolution"}
														rows={6}
														value={resolutionDescription}
														onChange={(event) => {
															if (event.target.value) {
																setResolutionDescription(event.target.value.substring(0, 1000));
															}
														}}
														className={"border border-[#D3D3D4] rounded-[8px] p-2 mb-2"}
													/>
													{descriptionError && <div className="block text-error-text text-base">Please enter a description.</div>}
													<div className={"flex flex-row"}>
														<div className={"grow"} />
														<CustomButton
															onClick={() => {
																setIsClosingTicket(false);
																setResolutionError(undefined);
																setDescriptionError(undefined);
															}}
															variant={"text"}
															ariaLabel="Cancel button for closing a ticket"
														>
															Cancel
														</CustomButton>
														<CustomButton
															onClick={() => {
																let update: HelpTicketUpdateVO = {
																	helpTicketGUID: helpTicketGUID,
																	status: getTicketStatus("Closed").id,
																};
																let ok = true;
																if (resolution.helpTicketResolutionID !== blankResolution.helpTicketResolutionID) {
																	update.resolutionID = resolution.helpTicketResolutionID;
																	setResolutionError(undefined);
																} else {
																	ok = false;
																	setResolutionError("Please select a resolution code." as any);
																}
																if (resolutionDescription !== "") {
																	update.resolutionDescription = resolutionDescription;
																	setDescriptionError(undefined);
																} else {
																	ok = false;
																	setDescriptionError("Please enter a description" as any);
																}
																if (ok) {
																	let message = `Ticket #${helpTicketBeingViewed?.ticketNumber} Updated`;
																	updateTicket(message, update);
																	setIsClosingTicket(false);
																}
															}}
															variant={"contained"}
															ariaLabel="Button for closing a ticket"
														>
															Close Ticket
														</CustomButton>
													</div>
												</div>
											</div>
										)}
									</div>
									<div className={"grow w-ful md:ml-2 border-[#D6D8DB] border rounded-[8px] p-4 h-fit"}>
										<div>
											<b>Requester</b>: {_.get(helpTicketBeingViewed, "ticketHistory[0].requestedPatronName", "not available")}
										</div>
										<div className={"mt-2 border-[#D6D8DB] border-t"}>
											{helpTicketBeingViewed?.status && helpTicketBeingViewed?.status.name !== "Closed" && (
												<div className={"mt-4"}>
													<TextField
														id="outlined-basic"
														variant="outlined"
														placeholder={"Add note..."}
														label={newNoteTitle ? "Add note : " + newNoteTitle.length + "/1000" : "Add note..."}
														multiline
														maxRows={5}
														size={"small"}
														value={newNoteTitle}
														onChange={(event) => {
															setNewNoteTitle(event.target.value);
														}}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<Button
																		onClick={() => {
																			addNote();
																		}}
																	>
																		<img alt={"Airplane icon button for submitting a note"} src={"/send-email-2.svg"} />
																	</Button>
																</InputAdornment>
															),
														}}
														onSubmit={() => {
															addNote();
														}}
														// onKeyDown={(e) => {
														// 	if (e.key === "Enter") {
														// 		addNote();
														// 	}
														// }}
														className={"w-full"}
													/>
												</div>
											)}
											{helpTicketBeingViewed?.ticketHistory &&
												helpTicketBeingViewed?.ticketHistory
													.slice()
													.reverse()
													.map((note) => {
														return (
															<div className={"mt-4 flex flex-col"}>
																<div className={"flex flex-row"}>
																	<img
																		src={"/dog.png"}
																		alt={"Requester profile picture"}
																		className={"w-6 h-6 mr-2 hidden"}
																	></img>
																	<div className={"w-full"}>
																		<div>
																			{" "}
																			<b>{note.performedBy ? note.performedBy : "Unknown"}</b> {getHistoryType(note.type)}
																			{note.description && ":"}
																		</div>
																		{note.description && (
																			<div className={"bg-[#EBEBEB] w-full rounded-[16px] p-2"}>
																				<MultiLineText text={note.description} />
																			</div>
																		)}
																		<div className={"text-[#313135] opacity-70"}>{getHistoryDuration(note)}</div>
																	</div>
																</div>
															</div>
														);
													})}
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</Drawer>
		</div>
	);
}
