// @ts-nocheck
import { CategoryOutlined, DynamicFormOutlined, EmailOutlined, FormatShapesOutlined, TaskAltOutlined } from "@mui/icons-material";
import { Card, CardContent, CardHeader } from "@mui/material";
import React, { FC, ReactNode, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hook";
import { getFeatureFlags } from "../../utilities/appContext";

interface AdminSettingsProps {
	gotoSettings: Function;
}
export default function AdminSettings(props: AdminSettingsProps) {
	const navigate = useNavigate();
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("ticket");
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const dispatch = useAppDispatch();
	const [updates, setUpdates] = useState({});
	const [showDropdown, setShowDropdown] = useState(false);
	const [showDropdownIndex, setShowDropdownIndex] = useState(0);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const featureFlags = getFeatureFlags();

	const settings = [
		{
			title: "Help Ticket Setup",
			featureFlag: true,
			categories: [
				{
					icon: <CategoryOutlined />,
					title: "Issue Categories",
					summary: "Manage issue categories and types for tickets",
					disabled: false,
					featureFlag: true,
				},
				{
					icon: <FormatShapesOutlined />,
					title: "Fields",
					summary: "Formulate fields and elements on customized forms",
					disabled: false,
					featureFlag: true,
				},
				{
					icon: <DynamicFormOutlined />,
					title: "Forms",
					summary: "Establish and manage customized forms",
					disabled: false,
					featureFlag: true,
				},
				{
					icon: <TaskAltOutlined />,
					title: "Resolution Codes",
					summary: "Formulate fields and elements on customized forms",
					disabled: false,
					featureFlag: true,
				},
			],
		},
		// {
		// 	title: "Workforce Management",
		// 	categories: [
		// 		{
		// 			icon: <AttributionOutlined />,
		// 			title: "Agents",
		// 			summary: "Construct user profiles and manage permissions",
		// 			disabled: true,
		// 		},
		// 		{
		// 			icon: <GroupsOutlined />,
		// 			title: "Service Teams",
		// 			summary: "Build and manage groups. Oversee activities and trends",
		// 			disabled: true,
		// 		},
		// 	],
		// },
		{
			title: "Automatic Processing",
			featureFlag: featureFlags.EMAIL_NOTIFICATIONS,
			categories: [
				{
					icon: <EmailOutlined />,
					title: "Email Notifications",
					summary: "Create and manage automatic email replies",
					disabled: false,
					featureFlag: featureFlags.EMAIL_NOTIFICATIONS,
				},
				// {
				// 	icon: <AutoModeOutlined />,
				// 	title: "Rule Automations",
				// 	summary: "Compose and monitor rule automations for all Agents",
				// 	disabled: true,
				// },
			],
		},
		// {
		// 	title: "Auditing",
		// 	categories: [
		// 		{
		// 			icon: <QueryStatsOutlined />,
		// 			title: "Activity Log",
		// 			summary: "track and view historic activity records",
		// 			disabled: true,
		// 		},
		// 		{
		// 			icon: <InsightsOutlined />,
		// 			title: "Reports",
		// 			summary: "Save and manage custom reports for all data",
		// 			disabled: true,
		// 		},
		// 	],
		// },
		{
			title: "Auditing",
			categories: [
				// {
				// 	icon: <QueryStatsOutlined />,
				// 	title: "Activity Log",
				// 	summary: "track and view historic activity records",
				// 	disabled: true,
				// },
				{
					icon: <img src={"/reports-icon.svg"} alt={"Reports"} className={"w-6 h-6"} />,
					title: "Reports",
					summary: "Save and manage custom reports for all data",
					disabled: false,
				},
			],
		},
		// {
		// 	title: "Other",
		// 	categories: [
		// 		{
		// 			icon: <HandshakeOutlined />,
		// 			title: "Satisfaction Survey",
		// 			summary: "track and view historic activity records",
		// 			disabled: true,
		// 		},
		// 	],
		// },
	];
	interface SettingCardProps {
		summary: string;
		title: string;
		icon: ReactNode;
		disabled: boolean;
		onClick: Function;
	}
	const SettingCard: FC<SettingCardProps> = (props) => {
		return (
			<div class="w-full sm:w-1/2 md:w-1/3 xl:w-1/5 p-2">
				{props.disabled ? (
					<Card className="w-full h-48 opacity-50 rounded-lg text-grey-600 cursor-not-allowed bg-grey-200">
						<CardHeader avatar={props.icon} className="pb-1" />
						<CardContent className="pt-0">
							<div className="text-altblack text-lg text-align-left font-medium">{props.title}</div>
							<div className="text-align-left text-base">{props.summary}</div>
						</CardContent>
					</Card>
				) : (
					<Card className="w-full h-48 hover:outline hover:outline-blue-500 cursor-pointer rounded-[8px] text-altblack" onClick={props.onClick}>
						<CardHeader avatar={props.icon} className="pb-1" />
						<CardContent className="pt-0 cursor-pointer">
							<div className="text-altblack text-lg cursor-pointer text-align-left font-medium">{props.title}</div>
							<div className="text-align-left cursor-pointer text-base">{props.summary}</div>
						</CardContent>
					</Card>
				)}
			</div>
		);
	};

	return (
		<div className={"mb-24 w-full drop-shadow-[0_2px_8px_rgba(138,146,163,0.16)]  "}>
			{settings.map((item, idx) => {
				if (item.featureFlag) {
					let classForDiv = "flex flex-wrap w-full";
					return (
						<div key={"setting" + idx} className="flex flex-col mb-8">
							<div className="text-2xl text-altblack mb-2">{item.title}</div>
							<div className={classForDiv}>
								{item.categories.map((category, idx) => {
									if (category.featureFlag) {
										return (
											<SettingCard
												key={"card" + idx}
												title={category.title}
												icon={category.icon}
												onClick={() => {
													props.gotoSettings(category.title);
												}}
												summary={category.summary}
												disabled={category.disabled}
											/>
										);
									}
								})}
							</div>
						</div>
					);
				}
			})}
		</div>
	);
}
