// import "./GeneralDialog.scss";
import NoPhotographyOutlinedIcon from "@mui/icons-material/NoPhotographyOutlined";
import { currentConfig } from "../../config/config";
import { AttachedFile } from "../../models/helpticket.model";
import { useGetImageQuery } from "../../utilities/apiApi";
import { getDistrictAppId } from "../../utilities/appContext";

interface ResourceImageProps {
	resourceGUID: string | undefined;
	id: string | number;
}

const getUrl = (category: string, endpath: string): string => {
	let appId: string | number | undefined = getDistrictAppId();
	let config = currentConfig();
	let baseUrl = config.helpdesk_backend_url;
	return `${baseUrl}/${appId}/${category}/${endpath}`;
};

function prepareHeaders(token: string): { [key: string]: string } {
	let headers: { [key: string]: string } = {};
	if (token) {
		headers["Authorization"] = `Bearer ${token}`;
	}
	return headers;
}

export default function ResourceImage(props: ResourceImageProps) {
	const { data, isLoading, isError, isFetching } = useGetImageQuery({ resourceGUID: props.resourceGUID });

	if (isLoading || isError || isFetching) {
		return (
			<div className="flex w-10 " title="No image">
				<NoPhotographyOutlinedIcon viewBox={"0 0 26 26"} className={"w-full h-full object-cover"} />
			</div>
		);
	} else {
		return (
			<div className="flex w-12 ">
				<img className={"object-cover w-full h-full "} src={URL.createObjectURL(data)} alt="Image of resource" />
			</div>
		);
	}
}

export interface DownloadStatus {
	status: "success" | "failure";
	error?: any;
}
export async function downloadFile(authToken: string, helpTicketGUID: string, file: AttachedFile): Promise<DownloadStatus> {
	let url = getUrl("helptickets", `helpticket/${helpTicketGUID}/attachment/file/${encodeURIComponent(file.name)}`);
	try {
		let headers = prepareHeaders(authToken || "");
		headers["Accept"] = "application/octet-stream";
		const response = await fetch(url, { headers: headers });
		if (!response.ok) {
			throw new Error("Network response was not ok");
		}
		const blob = await response.blob();
		const urlBlob = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = urlBlob;
		a.download = file.name;
		document.body.appendChild(a);
		a.click();
		a.remove();
		window.URL.revokeObjectURL(urlBlob);
	} catch (error) {
		// console.error("There was a problem with the fetch operation:", error);
		return { status: "failure", error: error };
	}
	return { status: "success" };
}
