import _ from "lodash";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonProgress from "../../components/CommonProgress/CommonProgress";
import Header from "../../components/Header/Header";
import { notifyDialog } from "../../components/Notification/Notification";
import Sidebar from "../../components/Sidebar/Sidebar";
import TicketsColdStart from "../../components/TicketsColdStart";
import TicketsListOverview from "../../components/TicketsListOverview";
import TicketsNoResults from "../../components/TicketsNoResults";
import { useAppSelector } from "../../hook";
import { FilterForm } from "../../models/filterForm.model";
import { HelpTicketsVO, SortSpec } from "../../models/helpticket.model";
import { selectHelpTicketFilterForm } from "../../slices/FiltersSlice";
import { useRequestedMyHelpTicketsFullQuery } from "../../utilities/apiApi";

export default function AgentPage() {
	const [isFiltering, setIsFiltering] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [sort, setSort]: [Array<SortSpec>, (value: Array<SortSpec>) => void] = useState([{ orderBy: "dateUpdated", order: "desc" }] as Array<SortSpec>);
	// const [updateHelpTicket, { isLoading }] = useUpdateHelpTicketMutation();
	// const [refreshMe, setRefreshMe] = useState(0);
	// const dispatch = useAppDispatch();
	const navigate = useNavigate();
	// const userType = useAppSelector(selectUserType);
	const ticketFilter: FilterForm = useAppSelector(selectHelpTicketFilterForm);

	// const handleRefresh = () => {
	// 	setRefreshMe(refreshMe + 1);
	// }

	const handleChangePage = (event: any, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleRequestSort = (event: any, property: string) => {
		let mySort: SortSpec[] = [];
		let order: "asc" | "desc" = "asc";
		if (sort && sort.length > 0) {
			let idx = _.findIndex(sort, (item) => {
				return property === item.orderBy;
			});
			if (idx >= 0) {
				order = sort[idx].order.toLowerCase() === "asc" ? "desc" : "asc";
			}
		}
		mySort.push({ orderBy: property, order });
		if (property !== "dateUpdated") {
			mySort.push({ orderBy: "dateUpdated", order: "desc" }); /// default secondary sort
		}
		setSort(mySort);
	};

	let totalCount: number = 0;
	let helpTicketsResult = useRequestedMyHelpTicketsFullQuery(
		{
			searchTerm,
			filter: ticketFilter,
		},
		{ refetchOnMountOrArgChange: true },
	);
	if (helpTicketsResult.isSuccess) {
		let result: HelpTicketsVO = helpTicketsResult.data;
		totalCount = _.get(result, "id", -1);
		if (totalCount < 0) {
			totalCount = _.get(result, "helpTickets", []).length;
		}
	}
	if (helpTicketsResult.isError) {
		notifyDialog("Error", "Unable to retrieve your tickets", true, () => {
			navigate("/helpdesk/401page");
		});
	}
	if (helpTicketsResult.isLoading) {
		return <CommonProgress message="" />;
	}
	return (
		<div className={"min-w-screen min-h-screen flex flex-row"}>
			<Sidebar
				pageMode={"Home"}
				setPageMode={(value: string) => {
					navigate("/helpdesk/newTicketAgent");
				}}
				disableSettings={true}
			/>
			<div className={"pl-[4rem] w-full"}>
				<div className={"px-4 "}>
					<header>
						<Header
							title={"Tickets"}
							onFilter={(value: boolean) => {
								setIsFiltering(value);
							}}
						/>
					</header>
					<main>
						<>
							{helpTicketsResult.isLoading || helpTicketsResult.isFetching ? (
								<CommonProgress message={"Checking for Tickets..."} />
							) : totalCount > 0 ? (
								<TicketsListOverview key="list_agent" />
							) : isFiltering ? (
								<TicketsNoResults />
							) : (
								<TicketsColdStart />
							)}
						</>
					</main>
				</div>
			</div>
		</div>
	);
}
