export interface StatusForm {
	open: boolean;
	closed: boolean;
	onHold: boolean;
	inProgress: boolean;
}
export const statusNames: { [key: string]: string } = {
	open: "Open",
	closed: "Closed",
	onHold: "On Hold",
};

export interface RequestTypeForm {}
export type ResourceTypeForm = Array<string>;

export type SiteForm = Array<string>;
export interface TicketNumberForm {
	ticketId: string;
}
export interface DateForm {
	from: string;
	to: string;
}

export interface FilterForm {
	searchTerm: string;
	statuses: StatusForm;
	requestTypes: RequestTypeForm;
	resourceType: ResourceTypeForm;
	site: SiteForm;
	ticketNumber: TicketNumberForm;
	createdDate: DateForm;
	completedDate: DateForm;
}
export type FilterFormKey = keyof FilterForm;
export const blankFilterForm: FilterForm = {
	searchTerm: "",
	statuses: { open: false, closed: false, onHold: false, inProgress: false },
	requestTypes: {},
	resourceType: [],
	site: [],
	ticketNumber: { ticketId: "" },
	createdDate: { from: "", to: "" },
	completedDate: { from: "", to: "" },
};
export interface SortOption {
	orderBy: string;
	order: "desc" | "asc" | "DESC" | "ASC";
}
