import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../hook";
import { AttachedFile, DownloadAttachedFile } from "../../models/helpticket.model";
import { selectDownload, setDownload } from "../../slices/DownloadSlice";
import store from "../../store";
import { useDoDownloadFileQuery } from "../../utilities/apiApi";
import CommonProgress from "../CommonProgress/CommonProgress";
import { notify } from "../Notification/Notification";

export const download = (helpTicketGUID: string, file: AttachedFile) => {
	store.dispatch(setDownload({ helpTicketGUID, file }));
};

const Download: FC<{}> = () => {
	const props: DownloadAttachedFile | undefined = useAppSelector(selectDownload);
	const dispatch = useAppDispatch();
	let skip: boolean = props === undefined || props.file === undefined;
	let downloadResult = useDoDownloadFileQuery({ downloadAttachedFile: props }, { skip });

	if (downloadResult.isLoading || downloadResult.isFetching) {
		return (
			<div id="Downloader" className="z-[1300]">
				<CommonProgress zIndex={1300} message={"Downloading..."} />
			</div>
		);
	}
	if (downloadResult.isError) {
		notify("Error", `Unable to download ${props?.file.name}`, true);
		dispatch(setDownload(undefined));
		return <div id="Downloader"></div>;
	}
	if (downloadResult.isSuccess && !downloadResult.isFetching) {
		if (!skip) {
			const blob = downloadResult.data;
			const urlBlob = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = urlBlob;
			a.download = props?.file.name || "";
			document.body.appendChild(a);
			a.click();
			a.remove();
			window.URL.revokeObjectURL(urlBlob);
		}
		dispatch(setDownload(undefined));
	}

	return <div id="Downloader"></div>;
};

export default Download;
