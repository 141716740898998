import { BaseQueryFn, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { FetchArgs } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import { goToUrl } from "../components/ErrorNavigation";
import configs from "../config/config";
import env from "../config/env";
import { getAppId, LoginTokenModel } from "../models/userLogin.model";
import { setAccessToken } from "../slices/AuthSlice";
import store, { RootState } from "../store";
import { nextRequestId } from "./appContext";

const myBaseUrl = configs.env[env].helpdesk_backend_url;

// Create a new mutex
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
	baseUrl: myBaseUrl,
	prepareHeaders: (headers, { getState }) => {
		const st: RootState = getState() as RootState;
		const token = st.auth.accessToken;
		const districtId: string | undefined = getAppId(st.auth.userLogin);

		// If we have a token set in state, let's assume that we should be passing it.
		if (token) {
			headers.set("Authorization", `Bearer ${token}`);
		}
		if (districtId) {
			headers.set("districtId", "" + districtId);
		}
		headers.set("Request-Id", nextRequestId("Base"));
		return headers;
	},
});

const customFetchBase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
	// wait until the mutex is available without locking it
	await mutex.waitForUnlock();
	let result = await baseQuery(args, api, extraOptions);
	let message = "";
	if (result.error && result.error) {
		let err: FetchBaseQueryError = result.error;
		message = JSON.stringify(err);
	}
	if (
		(result.error && result.error.status !== 404) ||
		message === "Access token is missing or invalid" ||
		message.includes("expired") ||
		message.includes("Unauthorized")
	) {
		if (!mutex.isLocked()) {
			const release = await mutex.acquire();
			try {
				const refreshResult = await baseQuery({ credentials: "include", url: "authenticate/refresh" }, api, extraOptions);
				// console.log("RefreshResult: " + JSON.stringify(refreshResult));
				if (refreshResult.data) {
					let loginToken: LoginTokenModel = refreshResult.data as any;
					store.dispatch(setAccessToken(loginToken.accessToken));
					// Retry the initial query
					result = await baseQuery(args, api, extraOptions);
				} else {
					// TODO make sure we notify portal and dont use window.location.href use navigate.
					// window.location.href = "401page";
					goToUrl("helpdesk/401page");
				}
			} finally {
				// release must be called once the mutex should be released again.
				release();
			}
		} else {
			// wait until the mutex is available without locking it
			await mutex.waitForUnlock();
			result = await baseQuery(args, api, extraOptions);
		}
	}

	return result;
};

export default customFetchBase;
