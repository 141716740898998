import { ResourceTypeVO } from "./device.model";
import { Field } from "./fields.model";
import { IssueType } from "./helpticket.model";

export interface Form {
	helpTicketFormID?: number;
	name: string;
	description: string;
	active: boolean;
	customFields: Field[];
	issueTypes: IssueType[];
	resourceTypes: ResourceTypeVO[];
}

export const blankForm: Form = {
	name: "",
	description: "",
	active: true,
	customFields: [],
	issueTypes: [],
	resourceTypes: [],
};
