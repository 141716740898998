export interface IdVO {
	id: number;
}

export interface BriefResourceTypeParentVO {
	assetTemplateID?: number;
	guid: string;
	name?: string;
}

export interface BriefResourceTypeVO {
	id: number;
	guid: string;
	name: string;
	softwareResourceTypeIdAssignment: number;
	category: number;
	inheritCategory: boolean;
	nameFields: IdVO[];
	parent: BriefResourceTypeParentVO;
	children: BriefResourceTypeVO[];
	accessible: boolean;
}

export const blankBriefResourceTypeVO: BriefResourceTypeVO = {
	id: 0,
	guid: "",
	name: "",
	softwareResourceTypeIdAssignment: 0,
	category: 0,
	inheritCategory: false,
	nameFields: [],
	parent: {
		assetTemplateID: 0,
		guid: "",
		name: "",
	},
	children: [],
	accessible: false,
};
