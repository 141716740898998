import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider } from "react-redux";
import { Outlet } from "react-router-dom";
import "tailwindcss/tailwind.css";
import store from "../../store";
import AuthMiddleware from "../../utilities/AuthMiddleware";
import Download from "../Download/Download";
import ErrorNavigation from "../ErrorNavigation";
import Grants from "../Grants/Grants";
import Notification from "../Notification/Notification";

interface AppProps {
	children: React.ReactNode;
}

const App = () => {
	const theme = createTheme({
		palette: {
			mode: "light",
			primary: {
				main: "#01338D",
			},
			text: {
				primary: "#171646",
			},

		},
	});
	// TODO: Keep this for turning on dark-mode based on OS preference
	// const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
	// const theme = React.useMemo(
	//     () =>
	//         createTheme({
	//             palette: {
	//                 mode: prefersDarkMode ? "dark" : "light",
	//             },
	//         }),
	//     [prefersDarkMode],
	// );
	// Or Create your Own theme:

	return (
		<div data-testid="App" className={"text-altblack"}>
			<ThemeProvider theme={theme}>
				<StyledEngineProvider injectFirst>
					<Provider store={store}>
						{/* <CookiesProvider> */}
						<AuthMiddleware>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DndProvider backend={HTML5Backend}>
									<>
										<Outlet />
										<Notification />
										<ErrorNavigation />
										<Grants />
										<Download />
									</>
								</DndProvider>
							</LocalizationProvider>
						</AuthMiddleware>
						{/* </CookiesProvider> */}
					</Provider>
				</StyledEngineProvider>
			</ThemeProvider>
		</div>
	);
};

export default App;
