import { Circle, CircleOutlined } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	InputAdornment,
	InputLabel,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	TextField,
} from "@mui/material";
import _ from "lodash";
import { createRef, FC, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hook";
import { blankDevice, Device } from "../../models/device.model";
import { IdNamePair } from "../../models/genericTypes.model";
import { HelpTicketForm } from "../../models/helpTicketForm.model";
import { Patron2 } from "../../models/Patron.model";
import { selectUserType } from "../../slices/AuthSlice";
import { selectHelpTicketForm, setFormTwoItem } from "../../slices/HelpTicketFormSlice";
import { useGetUserDevicesQuery } from "../../utilities/apiApi";
import { getCurrentUserGuid, getDistrictCustomerNumber, getLastFirstName, getSiteGuid, permissionsContain } from "../../utilities/appContext";
import { useGetPatronByIdQuery } from "../../utilities/userApi";
import CustomSwitch from "../CustomSwtich/CustomSwitch";
import ResourceImage from "../ResourceImage/ResourceImage";
import ResponsibleUserLookup from "../ResponsibleUserLookup/ResponsibleUserLookup";
import UserLookup from "../UserLookup/UserLookup";

export interface NewTicketStepTwoFormProps {
	onNext: () => void;
	hasError: boolean;
	setHasError: Function;
}

export interface DeviceListItem extends Device {
	selected: boolean;
}

const NewTicketStepTwoForm: FC<NewTicketStepTwoFormProps> = (props) => {
	let helpTicketForm: HelpTicketForm = useAppSelector(selectHelpTicketForm);
	const userType = useAppSelector(selectUserType);
	const dispatch = useAppDispatch();
	const [origUser, setOrigUser] = useState({
		id: getCurrentUserGuid(),
		name: getLastFirstName(),
	} as IdNamePair);
	const [forUser, setForUser]: [IdNamePair, (value: IdNamePair) => void] = useState({
		id: getCurrentUserGuid(),
		name: getLastFirstName(),
	} as IdNamePair);
	const [responsibleUser, setResponsibleUser]: [IdNamePair, (value: IdNamePair) => void] = useState({
		id: helpTicketForm.responsibleUserId,
		name: helpTicketForm.responsibleUserName,
	} as IdNamePair);
	const [searchString, setSearchString] = useState(helpTicketForm.deviceSearchString || "");
	const [searchValue, setSearchValue] = useState(helpTicketForm.deviceSearchString || "");
	const [modelName, setModelName] = useState(helpTicketForm.item.resource.name);
	let inputRef = createRef<HTMLInputElement>();

	let searchActive = !helpTicketForm.deviceUserEntered;
	let userSelectEnable = permissionsContain("AssetCreateHelpTickets");
	let isLoading = false;
	// start with logged in user
	let options: IdNamePair[] = [];
	const [devices, setDevices] = useState([]);
	//let devices: Array<Device> = [];
	const updateForWho = (value: IdNamePair) => {
		let fuse = {
			forWhoId: value.id,
			forWhoName: value.name,
		};

		setForUser(value);
		dispatch(setFormTwoItem(fuse));
	};
	useEffect(() => {
		updateForWho({ id: getCurrentUserGuid(), name: getLastFirstName() });
	}, []);
	useEffect(() => {
		// when user changes blank out selected device
		updateForDevice(_.cloneDeep(blankDevice));
	}, [forUser]);
	useEffect(() => {
		// when user changes blank out selected device
		dispatch(
			setFormTwoItem({
				deviceSearchString: searchString,
			}),
		);
	}, [searchString]);

	const updateForDevice = (value: Device) => {
		dispatch(
			setFormTwoItem({
				item: value,
				// deviceSearchString: searchString,
			}),
		);
	};
	const updateForResponsibleUser = (value: IdNamePair) => {
		let fuse: any = {
			responsibleUserId: value.id,
			responsibleUserName: value.name,
		};
		setResponsibleUser(value);
		dispatch(setFormTwoItem(fuse));
	};
	function mergeItems(searchDevices: Array<Device>) {
		let devices = [];
		if (helpTicketForm.item && helpTicketForm.item.guid && helpTicketForm.item.guid.trim() !== "") {
			devices.push(_.cloneDeep(helpTicketForm.item));
			let idx = _.findIndex(searchDevices, (item: Device) => item.guid === helpTicketForm.item.guid);
			if (idx >= 0) {
				searchDevices = _.cloneDeep(searchDevices);
				searchDevices.splice(idx, 1); // remove item
			}
		}
		devices = [...devices, ..._.sortBy(searchDevices, ["modelname"])];
		return devices;
	}
	let siteGuid = getSiteGuid();
	// patron search only if SiteGuid is not available from the login then we get it from
	// the user this happens when we are at district level and no site is in use.helpTicketForm.deviceSearchString
	let patron: Patron2 | undefined = undefined;
	let patronResult = useGetPatronByIdQuery({ id: "" + forUser.id }, { skip: forUser.id === "" || siteGuid !== undefined });

	if (patronResult.isSuccess) {
		patron = patronResult.data;
		siteGuid = patron.primarySiteGuid;
	}
	let skip = forUser.id === "" || siteGuid === undefined || (searchString.length > 0 && searchString.length < 3);
	let devicesResult: any = useGetUserDevicesQuery(
		{
			siteGuid: siteGuid,
			requestedUserGuid: (forUser.id || "") as string,
			searchTerm: searchString.trim(),
		},
		{
			skip: skip,
		},
	);
	useEffect(() => {
		if (devicesResult) {
			if (devicesResult.isSuccess) {
				let searchDevices = devicesResult.data || ([] as Array<Device>);
				setDevices(mergeItems(searchDevices) as any);
			}
			if (devicesResult.isError) {
				let searchDevices = [] as Array<Device>;
				setDevices(mergeItems(searchDevices) as any);
			}
		}
	}, [devicesResult]);

	isLoading = devicesResult.isLoading; // || optionsResult.isLoading;

	function handleToggle(value: Device): void {
		updateForDevice(value);
	}

	function handleTrackResponsibleToggle(value: boolean): void {
		dispatch(setFormTwoItem({ trackResponsible: value }));
	}
	function updateSearchValue(value: string): void {
		setSearchValue("");
		if (inputRef.current) {
			inputRef.current.value = "";
		}
	}
	const searchDebounce = useCallback(
		_.debounce((value) => {
			setSearchString(value);
		}, 500),
		[],
	);

	useEffect(() => {
		searchDebounce(searchValue);
	}, [searchValue]);

	return (
		<div data-testid="NewTicketCategoryForm" className={"max-h-min mb-6 flex flex-col w-full"}>
			<div data-testid="part1" className={"mb-2 w-full  h-1/3 "}>
				<UserLookup
					origUser={origUser}
					userName={forUser}
					// userName={{ name: forUser.name || getLastFirstName(), id: getCurrentUserGuid() }}
					onChange={(value: IdNamePair) => {
						updateForWho(value);
						updateSearchValue("");
					}}
					hasError={props.hasError}
					userSelectionEnable={userSelectEnable}
					setHasError={props.setHasError}
				/>
			</div>
			<div className={" flex w-full max-h-min mt-2"}>
				<div className={"flex flex-col w-full max-h-[70vh]"}>
					{userSelectEnable && (
						<div className="flex flex-col">
							<InputLabel id="select_item" className={"text-bold text-base mb-0.5 font-rubik text-[#171646]"}>
								Select or scan barcode
							</InputLabel>
							<div className={"flex flex-row items-center w-full"}>
								<TextField
									size={"small"}
									inputProps={{
										className: "w-full text-[#6E788C]  border-[#D3D3D4] bg-white",
										sx: {
											"&::placeholder": {
												color: "#6E788C",
												opacity: "85%",
											},
										},
									}}
									fullWidth={true}
									aria-label="Textfield for selecting/scanning an item"
									placeholder="Search by keyword, barcode, districtID"
									id="standard-search"
									defaultValue={searchValue}
									inputRef={inputRef}
									onChange={(e: any) => {
										let value = e.target.value || "";
										setSearchValue(value);
										props.setHasError(false);
									}}
									sx={{
										"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
											borderColor: "gray",
										},
										"& .MuiInputBase-input": {
											paddingLeft: "12px",
										},
										"& .MuiOutlinedInput-input": {
											paddingLeft: "8px !important",
										},
									}}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											e.preventDefault();
											// write your functionality here
										}
									}}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position="end">
												<img
													src={"/close.svg"}
													alt={"Clear input icon"}
													className={"cursor-pointer"}
													onClick={() => {
														updateSearchValue("");
													}}
												/>
											</InputAdornment>
										),
									}}
								></TextField>
							</div>
						</div>
					)}
					{((devices && devices.length > 0) || (searchValue && searchValue.trim().length > 1)) && (
						<div className="flex border-[#D3D3D4] w-full border overflow-y-scroll grow shrink">
							<List
								className={"px-2 bg-white"}
								dense
								autoFocus={true}
								sx={{
									backgroundColor: props.hasError && _.isNil(helpTicketForm.item.resource.guid) ? "#FFF7F7 !important" : "white",
									border: props.hasError && _.isNil(helpTicketForm.item.resource.guid) ? "1px solid #B43523 !important" : "",
								}}
							>
								{devices && devices.length > 0 ? (
									devices.map((value: Device, idx) => {
										const labelId = `checkbox-list-secondary-label-${idx}`;
										return (
											<ListItem key={"value" + idx} alignItems="flex-start" disablePadding>
												<ListItemButton disableRipple={true}>
													<Checkbox
														icon={<CircleOutlined className={"text-[#979797]"} />}
														checkedIcon={<Circle />}
														edge="start"
														sx={{ "& .MuiSvgIcon-root": { fontSize: 26.75 } }}
														checked={value.guid === helpTicketForm.item.guid}
														inputProps={{
															"aria-labelledby": labelId,
														}}
														disableRipple={true}
														onChange={(event: any) => {
															if (event.target.checked) {
																handleToggle(value);
															} else {
																handleToggle(_.cloneDeep(blankDevice));
															}
															props.setHasError(false);
														}}
														onKeyDown={(e) => {
															if (e.key === "Enter") {
																e.preventDefault();
																// write your functionality here
															}
														}}
													/>
													<ListItemAvatar>
														<ResourceImage id={idx} resourceGUID={value.resource.guid} />
													</ListItemAvatar>
													<ListItemText id={labelId}>
														<div>
															<div className={"text-bold font-rubik"}>{`${value.resource.name} (Barcode: ${value.barcode})`}</div>
															<div
																className={"text-normal font-rubik"}
															>{`District ID: ${value.districtID ? value.districtID : getDistrictCustomerNumber()}`}</div>
															<div
																className={"text-normal font-rubik"}
															>{`Serial Number: ${value.serialNumber ? value.serialNumber : ""}`}</div>
														</div>
													</ListItemText>
												</ListItemButton>
											</ListItem>
										);
									})
								) : (
									<ListItem key={"value-no-items"} disablePadding>
										<ListItemButton disableRipple={true}>
											<ListItemText id={"checkbox-list-secondary-label-no-items"} primary={"No items found."} />
										</ListItemButton>
									</ListItem>
								)}
							</List>
						</div>
					)}
					<div className={"flex w-2/3"}>
						<div className={"grow"}>
							{props.hasError && _.isNil(helpTicketForm.item.resource.guid) && <div className={"text-error-text"}>Please select a device</div>}
						</div>
					</div>
				</div>
			</div>
			<div className={"flex items-center mt-4"}>
				{userSelectEnable && (
					<div className={"max-h-min mb-2"}>
						<FormGroup>
							<FormControlLabel
								control={
									<div className="mt-1">
										<CustomSwitch
											checked={helpTicketForm.trackResponsible}
											disableRipple={true}
											onChange={(event: any) => {
												handleTrackResponsibleToggle(event.target.checked);
											}}
										/>
									</div>
								}
								label={"Track damage responsibility (optional)"}
							/>
						</FormGroup>
					</div>
				)}
			</div>
			{helpTicketForm.trackResponsible && (
				<div className={"w-full max-h-min mb-2"}>
					<ResponsibleUserLookup
						userName={responsibleUser.name}
						onChange={(value: IdNamePair) => {
							updateForResponsibleUser(value);
						}}
						hasError={props.hasError}
						userSelectionEnable={true}
						setHasError={props.setHasError}
					/>
				</div>
			)}
		</div>
	);
};

export default NewTicketStepTwoForm;
