import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../hook";
import { UserType } from "../models/user.model";
import { selectUserType } from "../slices/AuthSlice";
import CustomButton from "./CustomButton/CustomButton";

export default function TicketsColdStart(): ReactJSXElement {
	const navigate = useNavigate();
	const userType = useAppSelector(selectUserType);

	return (
		<div>
			<div className="opacity-73">
				<div className={"grid gap-8 grid-cols-2 md:grid-cols-3 opacity-[6%]"}>
					<div className="bg-gradient-to-b from-[#01338D] h-56 rounded-lg"></div>
					<div className="bg-gradient-to-b from-[#01338D] h-56 rounded-lg"></div>
					<div className="hidden md:block bg-gradient-to-b from-[#01338D] h-56 rounded-lg"></div>
				</div>
			</div>
			<div className={"grid justify-items-center -mt-44"}>
				<div className={"md:w-[28rem]"}>
					<div className={"grid justify-items-center"}>
						<div className="bg-primary/5 rounded-full h-32 w-32 mb-4">
							<div className={"grid place-items-center h-32"}>
								<img src={"/ticket-add.svg"} alt={"Magnifying glass icon for no search results"} className={"w-16 h-16"} />
							</div>
						</div>
						<div className="text-altblack font-rubik-medium text-xl font-medium tracking-normal text-center mb-4">
							<b>Get Help Fast</b>: Create Your First Help Ticket!
						</div>
						<div className="opacity-80 text-altblack font-rubik text-base font-normal tracking-normal text-center mb-4">
							Welcome to our Help Desk platform! To get started, please create a support ticket by clicking below.
						</div>
						<CustomButton
							onClick={() => {
								if (userType === UserType.Agent) {
									navigate("/helpdesk/newTicketAgent");
								} else {
									navigate("/helpdesk/newTicket");
								}
							}}
							variant={"contained"}
							ariaLabel={"Create a Help Ticket"}
						>
							Create a Help Ticket <AddIcon className={"ml-1"} />
						</CustomButton>
					</div>
				</div>
			</div>
		</div>
	);
}
