import { ReactNode } from "react";

interface CustomButtonProps {
	onClick: Function;
	variant: String;
	disabled?: boolean;
	children: ReactNode;
	ariaLabel: string;
	bgColor?: string;
	widthOverride?: string;
}

export default function CustomButton(props: CustomButtonProps) {
	let disabled = props.disabled === undefined ? false : props.disabled;

	if (props.variant === "contained") {
		let classes;
		let disabledClasses;

		if (props.widthOverride) {
			classes = props.widthOverride;
		}

		switch (props.bgColor) {
			case "red":
				classes += " flex items-center h-8 justify-center text-white rounded-full p-2 px-6 font-rubik-medium font-normal bg-error-text ";
				disabledClasses = disabled && " bg-gray-500 ";
				break;
			case "white":
				classes = "flex items-center text-primary rounded-full p-2 px-6 font-rubik-medium text-s font-normal bg-white border border-primary";
				disabledClasses = disabled ? " bg-gray-500 " : "";
				break;
			default:
				classes += " flex items-center h-8 justify-center text-white rounded-full p-2 px-6 font-rubik-medium font-normal ";
				disabledClasses = disabled ? " bg-gray-500 " : " bg-primary hover:bg-primary-darker active:bg-primary-even-darker";
				break;
		}

		if (classes) {
			classes = classes + disabledClasses;
		}

		return (
			<div>
				<button
					className={classes}
					aria-label={props.ariaLabel}
					onClick={(e: any) => {
						props.onClick(e);
					}}
					disabled={disabled}
				>
					{props.children}
				</button>
			</div>
		);
	} else {
		return (
			<div>
				<button
					className={"flex items-center h-8 rounded-full p-2 px-4 font-rubik-medium font-medium " + (disabled ? "text-gray-500" : "text-primary")}
					aria-label={props.ariaLabel}
					onClick={(e: any) => {
						props.onClick(e);
					}}
					disabled={disabled}
				>
					{props.children}
				</button>
			</div>
		);
	}
}
