import _ from "lodash";
import React, { ReactElement } from "react";
import { useAppDispatch, useAppSelector } from "../../hook";
import { Form } from "../../models/forms.model";
import { CustomFieldValue } from "../../models/helpticket.model";
import { HelpTicketForm } from "../../models/helpTicketForm.model";
import { selectHelpTicketForm, updateHelpTicketForm } from "../../slices/HelpTicketFormSlice";
import { CustomFormFields } from "../CustomField/CustomField";

export interface NewTicketStepFourFormProps {
	customForms: Form[];
	onNext: () => void;
	onError: (value: boolean) => void;
	errorMessages: (string | undefined)[];
}

const NewTicketStepFourForm: React.FC<NewTicketStepFourFormProps> = (props): ReactElement<any, any> | null => {
	let helpTicketForm: HelpTicketForm = useAppSelector(selectHelpTicketForm);
	const dispatch = useAppDispatch();
	// let customForms: Form[] = [];
	// let resourceTypeGUID = _.get(helpTicketForm, "item.resource.resourceType.guid", undefined);
	// let formsResult: any = useGetFormsForResourceQuery({ resourceTypeGUID }, { skip: resourceTypeGUID === undefined ? true : false });
	// if (formsResult.isSuccess) {
	// 	customForms = formsResult.data;

	// }
	// if (formsResult.isLoading) {
	// 	return <CommonProgress message="Collecting Custom fields..." />;
	// }

	return (
		<div className="flex flex-col">
			<CustomFormFields
				fieldValues={helpTicketForm.fieldValues}
				errorMessages={props.errorMessages}
				forms={props.customForms}
				update={(idx: number, value: CustomFieldValue) => {
					let newValues: { [key: string]: CustomFieldValue } = {};
					if (helpTicketForm.fieldValues) {
						newValues = _.cloneDeep(helpTicketForm.fieldValues);
					}
					newValues["" + idx] = value;
					dispatch(updateHelpTicketForm({ fieldValues: newValues }));
				}}
			/>
		</div>
	);
};

export default NewTicketStepFourForm;
