import { CustomFieldValue } from "./helpticket.model";

export interface ReportSortOption {
	column: string;
	asc: boolean;
}

export interface ReportFilter {
	column: string;
	options: Array<string>;
	include: boolean;
}

export interface ReportParams {
	sortOption: ReportSortOption;
	filters: Array<ReportFilter>;
}

export interface Report {
	helpTicketSavedReportViewID?: number;
	name: string;
	description: string;
	reportParams: ReportParams;
}

export const blankReport: Report = {
	helpTicketSavedReportViewID: undefined,
	name: "New Report",
	description: "",
	reportParams: {
		sortOption: {
			column: "ticketNumber",
			asc: true,
		},
		filters: [],
	},
};

export interface HelpTicketReport {
	helpTicketID: number;
	siteName: string;
	ticketNumber: string;
	displayableName: string;
	barcode: string;
	serialNumber: string;
	issueCategory: string;
	issueType: string;
	status: string;
	priority: string;
	summary: string;
	details: string;
	dateCreated: string;
	lastUpdated: string;
	requestedBy: string;
	assignedTo: string;
	damagedBy: string;
	filesAttached: boolean;
	customFields?: Array<CustomFieldValue>;
}
