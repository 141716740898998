import { Button as BaseButton } from "@mui/base/Button";
import { ButtonProps } from "@mui/material";
import _ from "lodash";
import React, { ReactElement, useRef, useState } from "react";
import { FILE_SIZE_UPLOAD_LIMIT } from "../../config/config";
import { useAppDispatch, useAppSelector } from "../../hook";
import { HelpTicketForm } from "../../models/helpTicketForm.model";
import { selectHelpTicketForm, setHelpTicketForm } from "../../slices/HelpTicketFormSlice";
import CustomTextField from "../CustomTextField";
import { notify } from "../Notification/Notification";

export interface NewTicketStepThreeFormProps {
	onNext: () => void;
	hasError: boolean;
	setHasError: Function;
}

const NewTicketStepThreeForm: React.FC<NewTicketStepThreeFormProps> = (props): ReactElement<any, any> | null => {
	let helpTicketForm: HelpTicketForm = useAppSelector(selectHelpTicketForm);
	const [summary, setSummary] = useState(helpTicketForm.summary);
	const [description, setDescription] = useState(helpTicketForm.details);
	const [files, setFiles] = useState<File[]>(helpTicketForm.addFiles || []);
	const uploadInputRef = useRef<HTMLInputElement | null>(null);
	const dispatch = useAppDispatch();
	const disallowedFileTypes = ["application/x-msdownload"];

	const CustomButton = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
		const { className, ...other } = props;

		return (
			<BaseButton
				ref={ref}
				className={"bg-[#F9F9F9] text-[#01338D] md:min-w-96 rounded-md border border-dashed border-blue-700 p-2 flex justify-center items-center"}
				onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
				{...other}
			></BaseButton>
		);
	});

	function fileSize(size: number) {
		if (size < 1000) {
			return size + "b";
		} else if (size < 1000000) {
			return (size / 1000).toFixed(2) + "kb";
		} else {
			return (size / 1000000).toFixed(2) + "mb";
		}
	}

	const addFiles = (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		let result: File[] = files ? [...files] : [];
		let filesNotUploaded: string[] = [];
		let filesLimit: number = result.reduce((acc, file) => acc + file.size, 0);

		// @ts-ignore
		const newFiles = event.target.files ? [...event.target.files] : event.dataTransfer ? [...event.dataTransfer.files] : [];
		newFiles.forEach((file) => {
			if (filesLimit + file.size <= FILE_SIZE_UPLOAD_LIMIT) {
				if (!disallowedFileTypes.includes(file.type)) {
					result.push(file);
				} else {
					filesNotUploaded.push(file.name);
				}
			}
		});

		let filesNotUploadedString = "";
		if (filesNotUploaded.length > 0) {
			filesNotUploadedString = filesNotUploaded[0];
			if (filesNotUploaded.length > 1) {
				for (let i = 0; i < filesNotUploaded.length; i++) {
					filesNotUploadedString += ", " + filesNotUploaded[i];
				}
			}
		}
		if (filesNotUploaded.length > 0) {
			notify("Error", "The following files were not uploaded because their file type is not allowed: " + filesNotUploadedString, true);
		}

		if (result.length > 0) {
			result = _.uniqWith(result, (a, b) => a.name === b.name && a.size === b.size && a.type === b.type && a.lastModified === b.lastModified);
			setFiles(result);
			dispatch(setHelpTicketForm({ addFiles: result }));
		}
	};

	// @ts-ignore
	const deleteFile = (index) => {
		// @ts-ignore
		const result = Array.from(files);
		result.splice(index, 1);
		// @ts-ignore
		setFiles(result);
		dispatch(
			setHelpTicketForm({
				fileList: result,
			}),
		);
	};
	let fileValue = "";
	return (
		<div data-testid="NewTicketStepThreeForm" className={"justify-left"}>
			<div className={"text-bold text-base text-altblack mb-0.5 font-rubik"}>Issue Summary</div>
			<div className={"mb-6"}>
				<CustomTextField
					ariaLabel={"Textfield input for issue summary"}
					rounded={false}
					placeholder={"Briefly describe the issue"}
					endAdornment={
						<div className={"inline text-searchtext font-roboto text-right float-right text-base " + (summary.length < 100 ? "-ml-14" : "-ml-16")}>
							{summary.length}/255
						</div>
					}
					value={summary}
					onChange={(event) => {
						if (event.target.value.length < 256) {
							setSummary(event.target.value);
							dispatch(
								setHelpTicketForm({
									summary: event.target.value,
								}),
							);
						}
						props.setHasError(false);
					}}
					error={props.hasError && summary === ""}
				></CustomTextField>
				{props.hasError && summary === "" && <div className={"text-error-text"}>Please add an issue summary</div>}
			</div>
			<div className={"text-bold text-base text-altblack mb-0.5 font-rubik"}>Describe the issue</div>
			<div className={"mb-6 font-roboto"}>
				<CustomTextField
					ariaLabel={"Textfield input for issue description"}
					rounded={false}
					placeholder={"Describe the issue in detail"}
					multiline={true}
					rows={8}
					value={description}
					onChange={(event) => {
						if (event.target.value.length < 1001) {
							setDescription(event.target.value);
							dispatch(
								setHelpTicketForm({
									details: event.target.value,
								}),
							);
						}
						props.setHasError(false);
					}}
					error={props.hasError && description === ""}
				></CustomTextField>
				{props.hasError && description === "" && <div className={"text-error-text"}>Please add an issue description</div>}
			</div>
			<div className={"text-bold text-base text-altblack mb-0.5 font-rubik"}>Add files</div>
			<div className={"mb-2 "}>
				<input
					hidden
					multiple
					type="file"
					id={"fileUploadButton"}
					ref={uploadInputRef}
					value={fileValue}
					onChange={(event) => {
						addFiles(event);
					}}
				/>
				<label htmlFor={"fileUploadButton"}>
					<div
						className={"w-fit"}
						onDrop={(event) => {
							event.preventDefault();
							addFiles(event);
						}}
						onDragOver={(event) => {
							event.preventDefault();
						}}
					>
						<CustomButton>
							<img src={"/upload-icon.svg"} alt={"Button to add a help ticket"} className={"mr-2 font-roboto"} />
							Drag and drop files here, or&nbsp;<u>browse</u>
						</CustomButton>
					</div>
				</label>
			</div>
			<div className="opacity-70 text-gray-900 font-normal font-rubik text-sm tracking-normal text-left">
				Max 25mb. Upload images or files that describe your problem
			</div>
			{files &&
				[...files].map((file, index) => (
					<div
						className={
							"min-w-96 min-h-16 flex items-center gap-x-2 p-2 font-rubik border-t border-[#D3D3D4] " +
							(index === files.length - 1 ? "border-b border-[#D3D3D4]" : "")
						}
					>
						<div className={"min-w-10 min-h-10 w-10 h-10"}>
							{file.type.startsWith("image/") ? (
								<img src={URL.createObjectURL(file)} alt={"Uploaded image"} className={"w-12 h-12 p-2"} />
							) : (
								<img src={"/common-file-text.svg"} alt={"Uploaded image"} className={"w-12 h-12 p-2"} />
							)}
						</div>
						<div className={"grow break-all text-[#01338D]"}>{file.name}</div>
						<div className={"text-[#313135] text-xs"}>{fileSize(file.size)}</div>
						<div className={"min-w-4"}>
							<button onClick={() => deleteFile(index)}>
								<img src={"/bin.svg"} alt={"Red recycle bin icon for deleting the associated file"} />
							</button>
						</div>
					</div>
				))}
		</div>
	);
};

export default NewTicketStepThreeForm;
