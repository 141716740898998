import { InputLabel, MenuItem, Select } from "@mui/material";
import _ from "lodash";
import { FC, useEffect } from "react";
import { IssueType } from "../../models/helpticket.model";

export interface IssueTypeSelectProps {
	issueTypes: Array<IssueType> | undefined;
	value: number | undefined;
	onChange: (value: number) => void;
	hasError: boolean;
}

const IssueTypeSelect: FC<IssueTypeSelectProps> = (props) => {
	const handleChange = (e: any) => {
		props.onChange(e.target.value);
	};
	useEffect(() => {
		if (props.issueTypes && props.issueTypes.length === 1) {
			props.onChange(0);
		}
	}, [props.issueTypes]);
	return (
		<div className={"w-full justify-left m4"} data-testId="IssueTypeSelect">
			{props.issueTypes && props.issueTypes.length === 0 && (
				<div>
					<div className={"text-bold text-base text-altblack mb-0.5"}>Issue Type</div>
					<div className={"mx-4 text-base text-altblack"}>Select a Category First</div>
				</div>
			)}
			{props.issueTypes !== undefined && props.issueTypes.length === 1 && (
				<div>
					<div className={"text-bold text-base text-altblack mb-0.5"}>Issue Types</div>
					<div className={"mx-4 text-base text-altblack p-1"}>{props.issueTypes[0].name}</div>
				</div>
			)}
			{props.issueTypes !== undefined && props.issueTypes.length > 1 && (
				<>
					<InputLabel id="select-label" className={"font-rubik text-bold text-base text-altblack mb-0.5"}>
						Issue Type
					</InputLabel>
					<Select
						className={"w-full text-base"}
						labelId="select-label"
						id="select"
						value={props.value}
						onChange={handleChange}
						sx={{
							backgroundColor: props.hasError && props.value === undefined ? "#FFF7F7 !important" : "white",
							border: props.hasError && props.value === undefined ? "1px solid #B43523 !important" : "",
						}}
						displayEmpty={true}
						renderValue={(selected) => {
							if (selected < 0) {
								return <span className={"text-[#6E788C]"}>Select an Issue Type</span>;
							}

							return _.get(props, "issueTypes[" + selected + "].name", "");
						}}
						IconComponent={() => <img src={"/down-icon.svg"} alt={"Down arrow icon"} className={"mr-2 pointer-events-none absolute right-[1px]"} />}
					>
						{props.issueTypes &&
							props.issueTypes.length > 0 &&
							props.issueTypes.map((item, idx) => {
								return (
									<MenuItem key={"issue" + item.helpTicketIssueTypeID} value={idx} className="text-base">
										{item.name}
									</MenuItem>
								);
							})}
					</Select>
					{props.hasError && props.value == undefined && <div className={"text-error-text"}>Please select an Issue Type.</div>}
				</>
			)}
		</div>
	);
};
export default IssueTypeSelect;
