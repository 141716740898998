import { CloseOutlined } from "@mui/icons-material";
import { ButtonGroup, Drawer, IconButton } from "@mui/material";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hook";
import { blankFilterForm, FilterForm } from "../../models/filterForm.model";
import { HelpTicketsVO } from "../../models/helpticket.model";
import User from "../../models/user.model";
import { selectHelpTicketFilterForm, setHelpTicketFilterForm } from "../../slices/FiltersSlice";
import { selectHelpTickets } from "../../slices/HelpTicketSlice";
import CustomButton from "../CustomButton/CustomButton";
import CustomTextField from "../CustomTextField";
import FilterFormList from "../FilterFormList/FilterFormList";

interface HeaderProps {
	onFilter?: (value: boolean) => void;
	title: string;
}
const Header: FC<HeaderProps> = (props: HeaderProps) => {
	const user: User = useAppSelector((state) => state.user);
	const [openFilter, setOpenFilter] = useState(false);
	const [filterPanelsOpen, setFilterPanelsOpen] = useState([]);
	const filterForm: FilterForm = useAppSelector(selectHelpTicketFilterForm);
	const helpTicketsVO: HelpTicketsVO = useAppSelector(selectHelpTickets);
	const toggleFilterDrawer = (open: boolean, reason: String) => {
		setOpenFilter(open);
	};
	const dispatch = useAppDispatch();
	// const helpTickets = useAppSelector( );
	useEffect(() => {
		if (props.onFilter) {
			props.onFilter(!_.isEqual(filterForm, blankFilterForm));
		}
	}, [filterForm]);

	return (
		<div data-testid="Header" className={"mt-6 mb-4 flex md:flex-row flex-col items-center"}>
			<div className="text-rgba-23-22-70 font-rubik-bold text-2xl text-altblack leading-normal grow mb-2 md:mb-0">{props.title}</div>
			<div className={"flex flex-row hidden"}>
				<div className={"flex grow min-w-[236px]"}>
					<CustomTextField
						ariaLabel={"Textfield input for searching tickets"}
						className={"text-searchtext"}
						placeholder={"Search Tickets"}
						defaultValue={filterForm.searchTerm}
						onChange={(e) => {
							let tempFilter: FilterForm = _.cloneDeep(filterForm);
							tempFilter.searchTerm = e?.target?.value ? e.target.value : "";
							dispatch(setHelpTicketFilterForm(tempFilter));
						}}
						debounce={500}
						endAdornment={
							<img src={"/input-icon.svg"} alt={"Magnifying glass icon within search textfield"} className={"w-5 inline -ml-8 -mt-1"} />
						}
						rounded={true}
					/>
				</div>
				<div className={"ml-6 w-10 h-10 rounded-full"}>
					<button
						className={"rounded-full hover:bg-black/[2%] focus:bg-black/[2%] focus:outline-0 active:bg-black/[4%]"}
						onClick={(e: any) => {
							toggleFilterDrawer(true, "mouseClick");
						}}
					>
						<img
							src={"/filter-icon.svg"}
							alt={"Filter icon button for filtering search results"}
							width={500}
							height={500}
							className={"focus:bg-red-500"}
						/>
					</button>
				</div>
			</div>
			<Drawer
				open={openFilter}
				onClose={(event, reason) => {
					toggleFilterDrawer(false, reason);
				}}
				anchor={"right"}
				sx={{
					"& .MuiBackdrop-root": {
						backgroundColor: "#171646",
						opacity: "70%!important",
					},
				}}
			>
				<div className={"m-2 text-3xl "}>Ticket Filter</div>
				<FilterFormList
					expandedPanels={filterPanelsOpen}
					onChangePanels={(panels: string[]) => {
						setFilterPanelsOpen(panels as any);
					}}
				/>

				<ButtonGroup className="m-2">
					<CustomButton
						onClick={() => {
							dispatch(
								setHelpTicketFilterForm({
									...blankFilterForm,
									searchTerm: filterForm.searchTerm,
								}),
							);
						}}
						ariaLabel="Clear Filters"
						variant={"standard"}
					>
						Clear Filters
					</CustomButton>
					<CustomButton
						variant={"contained"}
						ariaLabel="View Results"
						onClick={() => {
							setOpenFilter(false);
						}}
					>
						View Results ({helpTicketsVO?.helpTickets ? helpTicketsVO.helpTickets.length : 0})
					</CustomButton>
				</ButtonGroup>
				<IconButton
					className={"absolute top-2 right-1"}
					onClick={() => {
						setOpenFilter(false);
					}}
				>
					<CloseOutlined />
				</IconButton>
			</Drawer>
		</div>
	);
};

export default Header;
