const getPortalWindowUrl = () => {
	const host: string = window.location.host;
	if (host.includes("dly") || host.includes("localhost")) {
		return "https://dly-portal.follettdestiny.com/portal";
	} else if (host.includes("qat")) {
		return "https://qat-portal.follettdestiny.com/portal";
	} else {
		// prod
		return "https://portal.follettdestiny.com/portal";
	}
};

export const getPortalClientScriptUrl = (): string => {
	return `${getPortalWindowUrl()}/portaljs/portaljs.min.js`;
};

// We'll just use * for now until can the app running in dly
export const getPortalParentWindowMessagingUrl = (): string => {
	return "*";
};
