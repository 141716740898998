import * as _ from "lodash";
import { ClaimKey, ClaimsModel, DapAccessGrant } from "./claims.model";

export interface LoginTokenModel {
	idToken: string;
	accessToken: string;
	token_type?: string;
	expires_in?: number;
}
export interface UserLoginModel {
	claims: ClaimsModel | null;
	loginToken: LoginTokenModel;
}

export default function newUserLoginModel(claims: ClaimsModel | null, loginToken: LoginTokenModel): UserLoginModel {
	return { claims, loginToken };
}

export function isAccessGrantEnabled(ulm: UserLoginModel, accessGrant: DapAccessGrant): boolean {
	const accessGrants: string = _.get(ulm, "claims[" + ClaimKey.DAP_ACCESS_GRANTS + "]", "").toString();
	return accessGrants.indexOf(accessGrant) >= 0;
}

export function getClaimValue(ulm: UserLoginModel, key: ClaimKey): string | number | undefined {
	return _.get(ulm, 'claims["' + key + '"]', "");
}
export function isGuestUser(ulm: UserLoginModel): boolean {
	return getClaimValue(ulm, ClaimKey.IS_ANONYMOUS) === "guest";
}

export function isSharedUser(ulm: UserLoginModel): boolean {
	return "true" === getClaimValue(ulm, ClaimKey.USER_IS_GUEST);
}

export function isAuthenticatedUser(ulm: UserLoginModel): boolean {
	return !isGuestUser(ulm) && !isSharedUser(ulm);
}
export function isDestinyPatron(ulm: UserLoginModel): boolean {
	return isAuthenticatedUser(ulm) && !isDistrictUser(ulm) && !isDestinyAdmin(ulm);
}
export function isDistrictUser(ulm: UserLoginModel): boolean {
	return "true" === getClaimValue(ulm, ClaimKey.DISTRICT_USER);
}

export function isDestinyAdmin(ulm: UserLoginModel): boolean {
	return "true" === getClaimValue(ulm, ClaimKey.DESTINY_ADMIN);
}
export function getCustomerNumber(ulm: UserLoginModel): string | undefined {
	const val = getClaimValue(ulm, ClaimKey.FSS_DISTRICT_CUSTOMER_NUMBER);
	return val ? val.toString() : undefined;
}
export function getDistrictName(ulm: UserLoginModel): string | undefined {
	const val = getClaimValue(ulm, ClaimKey.DISTRICT_NAME);
	return val ? val.toString() : undefined;
}

export function getSiteGuid(ulm: UserLoginModel): string | undefined {
	const val = getClaimValue(ulm, ClaimKey.SITE_GUID);
	return val ? val.toString() : undefined;
}

export function getUserGuid(ulm: UserLoginModel): string | undefined {
	const val = getClaimValue(ulm, ClaimKey.USER_GUID);
	return val ? val.toString() : undefined;
}

export function getAppId(ulm: UserLoginModel | undefined): string | undefined {
	if (ulm) {
		const val = getClaimValue(ulm, ClaimKey.APP_ID);
		return val ? val.toString() : undefined;
	}
	return undefined;
}

export function getSub(ulm: UserLoginModel): string | undefined {
	const val = getClaimValue(ulm, ClaimKey.SUB);
	return val ? val.toString() : undefined;
}

export function getLocationName(ulm: UserLoginModel): string | undefined {
	const val = getClaimValue(ulm, ClaimKey.LOCATION_NAME);
	return val ? val.toString() : undefined;
}

export function getFollettShelfId(ulm: UserLoginModel): string | undefined {
	const val = getClaimValue(ulm, ClaimKey.FOLLETT_SHELF_ID);
	return val ? val.toString() : undefined;
}

export function getFirstName(ulm: UserLoginModel): string | undefined {
	const val = getClaimValue(ulm, ClaimKey.FIRST_NAME);
	return val ? val.toString() : undefined;
}

export function getLastName(ulm: UserLoginModel): string | undefined {
	const val = getClaimValue(ulm, ClaimKey.LAST_NAME);
	return val ? val.toString() : undefined;
}

export function getUserName(ulm: UserLoginModel): string | undefined {
	const val = getClaimValue(ulm, ClaimKey.USERNAME);
	return val ? val.toString() : undefined;
}

export function getPatronType(ulm: UserLoginModel): string | undefined {
	const val = getClaimValue(ulm, ClaimKey.PATRON_TYPE);
	return val ? val.toString() : undefined;
}

export function getPatronName(ulm: UserLoginModel): string | undefined {
	if (isGuestUser(ulm)) {
		return _.upperFirst("guest");
	} else if (isSharedUser(ulm)) {
		return getUserName(ulm);
	} else {
		const firstName = getFirstName(ulm) || "";
		const lastName = getLastName(ulm) || "";
		const spacer = firstName ? " " : "";
		return firstName + spacer + lastName;
	}
}
export function getUserSessionTimeoutSeconds(ulm: UserLoginModel): number | undefined {
	const val = getClaimValue(ulm, ClaimKey.USER_SESSION_TIMEOUT_SECONDS);
	return val ? Number(val.toString()) : undefined;
}

export function isEngageEnabled(ulm: UserLoginModel): boolean {
	return isAccessGrantEnabled(ulm, DapAccessGrant.ENGAGE);
}

export function isCollectionsEnabled(ulm: UserLoginModel): boolean {
	return isAccessGrantEnabled(ulm, DapAccessGrant.COLLECTIONS);
}

export function isClassSetEnabled(ulm: UserLoginModel): boolean {
	return isAccessGrantEnabled(ulm, DapAccessGrant.CLASS_SET);
}

export function isFollettShelfAdmin(ulm: UserLoginModel): boolean {
	const val = getClaimValue(ulm, ClaimKey.FOLLETT_SHELF_ADMIN);
	return val === "true";
}
