import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { blankHelpTicketForm, HelpTicketForm } from "../models/helpTicketForm.model";
import { RootState } from "../store";
// Define the initial state using that type

const initialState: HelpTicketForm = _.cloneDeep(blankHelpTicketForm);
const helpTicketFormSlice = createSlice({
	name: "helpTicketForm",
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setHelpTicketForm: (state, payload) => {
			return { ...state, ...payload.payload };
		},
		resetHelpTicketForm: () => {
			// remember state is only for read only you are returning the new state
			let ht = _.cloneDeep(blankHelpTicketForm);
			return ht;
		},
		setCategoryAndIssue: (state, payload) => {
			return { ...state, ...payload.payload };
		},
		setFormTwoItem: (state, payload) => {
			return { ...state, ...payload.payload };
		},
		updateHelpTicketForm: (state, payload) => {
			return { ...state, ...payload.payload };
		},
	},
});

export const selectHelpTicketForm = (state: RootState): HelpTicketForm => {
	return state.helpTicketForm;
};
// export const selectHelpTicketType = (state: RootState): HelpTicketType => state.helpTicketForm.createDate;
export const { setHelpTicketForm, resetHelpTicketForm, setCategoryAndIssue, setFormTwoItem, updateHelpTicketForm } = helpTicketFormSlice.actions;

export default helpTicketFormSlice.reducer;
