import { Backdrop, CircularProgress } from "@mui/material";

interface CommonProgressProps {
	variant?: "indeterminate" | "determinate" | undefined;
	color?: undefined | "primary" | "secondary" | "error" | "info" | "success" | "warning";
	size?: number;
	message?: string;
	zIndex?: number;
}
export default function CommonProgress(props: CommonProgressProps) {
	// let zIndex: string = "z-10";
	// if (props.zIndex !== undefined) {
	// 	zIndex = "z-[" + props.zIndex + "]";
	// }
	let zIndex = props.zIndex ? props.zIndex : 1000;
	return (
		<Backdrop sx={{ backgroundColor: "rgba(0, 0, 0, 0.2)", zIndex: zIndex }} open={true}>
			<div className={`flex flex-col items-center justify-center`}>
				<div className="flex mb-4">
					<CircularProgress variant={props.variant || "indeterminate"} color={props.color || "primary"} size={props.size || 100} />
				</div>
				<div className="flex">
					<p className="break-all align-center text-center text-gray-700 text-2xl">{props.message || ""}</p>
				</div>
			</div>
		</Backdrop>
	);
}
