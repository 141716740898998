export enum UserType {
	Unknown,
	Submitter,
	Agent,
	Admin,
}

export default interface User {
	id?: string;
	name: string;
	userType: UserType;
}
