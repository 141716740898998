import { AddCircleOutlineOutlined, Close, DeleteForeverOutlined, Edit } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Switch, TextField } from "@mui/material";
import _ from "lodash";
import { FC, SetStateAction, useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../hook";
import { Category, IssueType } from "../../models/helpticket.model";
import { setCategories } from "../../slices/ConfigurationSlice";
import { useCategoriesQuery, useDeleteCategoryMutation, useUpdateCategoryMutation } from "../../utilities/apiApi";
import CustomButton from "../CustomButton/CustomButton";
import GenericDialog from "../GenericDialog/GenericDialog";
import { ListEditor } from "../ListEditor/ListEditor";
import { notify } from "../Notification/Notification";

interface CategoryManagementProps {}
interface AddCategoryProps {
	mode: string;
	open: boolean;
	categories: Category[];
	category: Category | undefined;
	handleClose: () => void;
	onSave: (category: Category) => void;
}
interface EditIssueType extends IssueType {
	error: boolean;
	changed: boolean;
}
const AddCategoryDialog: FC<AddCategoryProps> = (props) => {
	const [category, setCategory]: [any, (value: any) => void] = useState(undefined);
	const [issues, setIssues] = useState([]);
	const [name, setName] = useState("");
	const [categoryName, setCategoryName] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [errorMessageName, setErrorMessageName] = useState("X");
	const [deleteIssueTypeDialogOpen, setDeleteIssueTypeDialogOpen] = useState(false);
	const [issueIdx, setIssueIdx] = useState(0);
	const fieldNameRef = useRef();
	const errMsgs = [
		"",
		"Duplicate issue type for this category. Please make the values unique.",
		"Cannot save an empty issue type.  Please update or delete.",
		"Cannot save when you have duplicates and empty issue types; please update issue type list.",
		"Cannot save when name is empty; please enter a name.",
		"Cannot save when name is duplicated. Please enter a unique name.",
	];

	useEffect(() => {
		if (fieldNameRef && fieldNameRef.current) {
			(fieldNameRef.current as any).focus(); // on startup put focus on Field Name
		}
	}, [fieldNameRef.current]);

	useEffect(() => {
		if (props.category) {
			setCategory(props.category);
		} else {
			setCategory({
				id: "",
				name: "",
				issueTypes: [],
				active: false,
			});
		}
		let v: any[] = [];
		let issueTypes = props.category?.issueTypes || [];
		issueTypes.forEach((item) => {
			let value = { changed: false, error: false, ...item };
			v.push(value);
		});
		setIssues(v as SetStateAction<never[]>);
		setCategoryName(_.get(props, "category.name", ""));
		setName("");
	}, [props.category]);

	useEffect(() => {
		setErrorMessage("");
	}, [issues, categoryName]);

	const issueDelete = (idx: number) => {
		let i: any[] = _.cloneDeep(issues);
		i.splice(idx, 1);
		setIssues(i as SetStateAction<never[]>);
	};
	const issueAdd = (value: any) => {
		let i: any[] = _.cloneDeep(issues);
		i.push({
			id: "",
			name: value,
			parentId: "",
			error: false,
			changed: false,
		});
		setIssues(i as SetStateAction<never[]>);
	};
	const isCategoryNameError = () => {
		if (
			category !== undefined &&
			_.find(props.categories, (item) => {
				return categoryName === item.name && category.helpTicketIssueTypeID != item.helpTicketIssueTypeID;
			}) !== undefined
		) {
			return true;
		}
		return false;
	};

	function hasName(name: string) {
		return categoryName !== "";
	}

	function hasDuplicateName(category: Category | undefined, name: string) {
		let retval: boolean = false;
		if (category) {
			for (let i = 0; i < props.categories.length; i++) {
				if (name === props.categories[i].name && props.categories[i].helpTicketIssueTypeID != category.helpTicketIssueTypeID) {
					return true;
				}
			}
		} else {
			for (let i = 0; i < props.categories.length; i++) {
				if (name === props.categories[i].name) {
					return true;
				}
			}
		}
		return retval;
	}

	const hasDuplicateFields = (array: Array<EditIssueType>) => {
		let retval = false;
		for (let i = 0; i < array.length; i++) {
			let count = 0;
			for (let j = 0; j < array.length; j++) {
				if (i === j) {
					continue;
				}
				if (array[i].name === array[j].name) {
					retval = true;
					break;
				}
			}
			if (retval) {
				break;
			}
		}
		return retval;
	};
	const hasEmptyIssues = (array: Array<EditIssueType>) => {
		let retval = false;
		for (let i = 0; i < array.length; i++) {
			let count = 0;
			if (array[i].name === "") {
				retval = true;
				break;
			}
		}
		return retval;
	};
	const isIssueDuplicate = (issueName: string) => {
		if (
			_.find(issues, (item: EditIssueType) => {
				return issueName === item.name;
			})
		) {
			return true;
		}
		return false;
	};

	function validated() {
		return hasName(categoryName) && !hasDuplicateFields(issues) && !hasEmptyIssues(issues);
	}

	return (
		<Dialog
			onClose={props.handleClose}
			aria-labelledby="customized-dialog-title"
			open={props.open}
			sx={{
				"& .MuiDialog-container": {
					"& .MuiPaper-root": {
						width: "100%",
						maxWidth: "900px", // Set your width here
						maxHeight: "900px",
						height: "80%",
					},
				},
			}}
		>
			<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
				{props.mode === "add" ? "New Category" : "Edit Category"}
			</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={(event) => {
					props.handleClose();
				}}
				sx={{
					position: "absolute",
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<Close />
			</IconButton>
			<DialogContent dividers>
				<div>
					<div className="flex ">
						<div className="flex-auto">
							<TextField
								variant={"standard"}
								label={"Name"}
								inputRef={fieldNameRef}
								value={categoryName}
								placeholder={"Add a category name."}
								onChange={(event) => {
									setCategoryName(event.target.value.substring(0, 50));
									setErrorMessageName("X");
								}}
								inputProps={{
									sx: {
										width: {
											xs: 350,
											sm: 450,
											md: 700,
											lg: 800,
										},
									},
								}}
							/>
							<div className={"text-error-text mt-2 " + (errorMessageName === "X" && "invisible")}>{errorMessageName}</div>
						</div>
						<div className="flex-none relative top-5 text-gray-400 text-basis">{"" + categoryName.length + "/50"}</div>
					</div>
					<ListEditor
						onAdd={(value: any) => {
							issueAdd(value);
						}}
						displayValue={(value: any) => {
							return value?.name;
						}}
						onChange={(idx: number, value: any) => {
							let i: any[] = _.cloneDeep(issues);
							i[idx].name = value;
							setIssues(i as SetStateAction<never[]>);
						}}
						onDelete={(index: number) => {
							issueDelete(index);
						}}
						showDeleteButtons={() => {
							return true;
						}}
						addLabel="Add Issue Type"
						title={"Associated Issue Types"}
						itemLabel={"IssueType"}
						array={issues}
						deleteTitle={"Delete Option"}
						deleteString={"Are you sure you want to delete this issue type?"}
						placeholder="Enter a new issue type here."
						dropDownErrorText={errorMessage}
					/>
				</div>
			</DialogContent>
			<DialogActions>
				<CustomButton
					variant={"uncontained"}
					ariaLabel={""}
					onClick={() => {
						setIssues([]);
						props.handleClose();
					}}
				>
					Cancel
				</CustomButton>
				<CustomButton
					variant={"contained"}
					ariaLabel={""}
					onClick={() => {
						let err = 0;
						let errorName = 0;
						let bad = false;
						if (hasDuplicateFields(issues)) {
							bad = true;
							err = 1;
						}
						if (hasEmptyIssues(issues)) {
							err = err === 1 ? 3 : 2;
							bad = true;
						}
						if (!hasName(categoryName)) {
							bad = true;
							setErrorMessageName("Name cannot be empty. Please specify a name.");
						}
						if (hasDuplicateName(props.category, categoryName)) {
							bad = true;
							setErrorMessageName("Duplicate category name.  Please make the name unique.");
						}
						setErrorMessage(errMsgs[err]);
						if (!bad) {
							let c: Category = _.cloneDeep(category);
							c.name = categoryName;
							c.issueTypes = [];
							issues.forEach((item: IssueType) => {
								c.issueTypes.push({
									name: item.name,
									helpTicketIssueTypeID: item.helpTicketIssueTypeID,
									parentId: category.helpTicketIssueTypeID,
									active: item.active,
								});
							});

							if (props.onSave) {
								props.onSave(c);
							}
							props.handleClose();
							setIssues([]);
							setCategoryName("");
						}
					}}
				>
					Save Category
				</CustomButton>
				<GenericDialog
					open={deleteIssueTypeDialogOpen}
					title={"Delete Type?"}
					onClose={() => {
						setDeleteIssueTypeDialogOpen(false);
					}}
					onConfirm={() => {
						setDeleteIssueTypeDialogOpen(false);
						issueDelete(issueIdx);
					}}
					confirmLabel={"Delete Type"}
					nodeContent={
						<div>
							<p>Issue types already linked to existing tickets will remain. They will not be available for future use.</p>
							<p className="text-center text-altblack text-lg mt-2">{`Are you sure you want to delete the issue type "${_.get(issues, "[" + issueIdx + "].name", "")}" ?`}</p>
						</div>
					}
				/>
			</DialogActions>
		</Dialog>
	);
};

const CategoryManagement: FC<CategoryManagementProps> = () => {
	const [addDialogOpen, setAddDialogOpen] = useState(false);
	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(undefined);
	const [upsertCategory, { isLoading }] = useUpdateCategoryMutation();
	const [deleteCategory, { isLoading: isDeleteLoading }] = useDeleteCategoryMutation();
	const [currentCategory, setCurrentCategory]: [any, (value: any) => void] = useState(undefined);
	const [currentIssueTypeIndex, setCurrentIssueTypeIndex]: [any, (value: any) => void] = useState(0);
	const dispatch = useAppDispatch();
	// const categories = useAppSelector(selectCategories);

	const categoriesResult = useCategoriesQuery();
	let categories: Category[] = [];
	if (categoriesResult.isSuccess) {
		// dispatch(setCategories(categoriesResult.data));
		categories = categoriesResult.data;
	}
	if (categoriesResult.isLoading) {
		return <div className="w-full h-full align-center item-center">Loading Categories....</div>;
	}
	if (categoriesResult.isError) {
		return (
			<div className="w-full h-full align-center item-center">
				<div>
					<div>{JSON.stringify(categoriesResult.error)}</div>
				</div>
			</div>
		);
	}

	const addCategory = (category: Category) => {
		let c: Category[] = _.cloneDeep(categories);
		c.push(category);
		setCategories(c as never[]);
	};

	const updateCategoryDev = (idx: number, cat: Category) => {
		let c = _.cloneDeep(categories);
		if (idx === -1) {
			idx = _.findIndex(c, (item: Category) => {
				return item.helpTicketIssueTypeID === cat.helpTicketIssueTypeID && item.name === cat.name;
			});
		}
		if (idx >= 0) {
			c.splice(idx, 1, cat as never);
			setCategories(c);
		}
	};
	const updateCategory = async (cat: Category) => {
		if (!isLoading) {
			try {
				await upsertCategory({ category: cat }).unwrap();
				if (!cat.helpTicketIssueTypeID) {
					notify("New Category Added!", `"${cat.name}" is available and ready to use.`, false);
				} else {
					notify(
						"Category Updated!",
						cat.active ? `"${cat.name}" is available and ready to use.` : `"${cat.name}" is available but is inactive.`,
						false,
					);
				}
			} catch (error) {
				console.error("Failed to update the category: " + error);
			}
		}
	};
	const removeCategory = async (cat: Category) => {
		if (!isDeleteLoading) {
			try {
				await deleteCategory({ category: cat }).unwrap();
			} catch (error) {
				console.error("Failed to delete the category: " + error);
			}
		}
	};

	return (
		<div>
			<div className={"flex flex-row flex-wrap gap-4 break-words"}>
				<button
					title={"New Category"}
					className={
						"flex flex-col items-center justify-center w-[200px] h-44 rounded-[8px] bg-[rgba(1,51,141,0.06)] drop-shadow-[0_2px_8px_rgba(138,146,163,0.16)]"
					}
					onClick={() => {
						setCurrentCategory({
							id: "",
							name: "",
							issueTypes: [],
							active: true,
						});
						setAddDialogOpen(true);
					}}
				>
					<AddCircleOutlineOutlined
						sx={{
							fill: "#01338D",
							width: "32px",
							height: "32px",
						}}
					/>
					<div className="text-primary text-lg text-center">New Category</div>
				</button>
				{categories.map((item: Category, idx) => {
					let sliderAriaLabel = `${item.name} is ${item.active ? "active" : "inactive"}`;
					return (
						<div className={"flex flex-col p-3 w-[200px] h-44 rounded-[8px] bg-white drop-shadow-[0_2px_8px_rgba(138,146,163,0.16)]"}>
							<div className={"text-lg text-ellipsis font-semibold mb-2"}>
								{item.name.length > 42 ? item.name.substring(0, 42) + "..." : item.name}
							</div>
							<div className={"grow"}>{item.issueTypes.length} issue types</div>
							<div className={"flex flex-row"}>
								<div className={"grow"}>
									<Switch
										checked={item.active}
										onChange={(event) => {
											updateCategory({
												...item,
												active: event.target.checked,
											});
										}}
										inputProps={{
											"aria-label": sliderAriaLabel,
										}}
									/>
								</div>
								<IconButton
									onClick={(e) => {
										setCurrentCategory(_.cloneDeep(item));
										setEditDialogOpen(true);
									}}
								>
									<Edit />
								</IconButton>
								<IconButton
									onClick={() => {
										setCurrentCategory(_.cloneDeep(item));
										setDeleteDialogOpen(true);
									}}
								>
									<DeleteForeverOutlined
										sx={{
											fill: "red",
										}}
									/>
								</IconButton>
							</div>
						</div>
					);
				})}
			</div>
			<>
				{addDialogOpen && (
					<AddCategoryDialog
						mode={"add"}
						open={addDialogOpen}
						categories={categories}
						onSave={(category) => {
							updateCategory(category);
							setCurrentCategory(undefined);
						}}
						handleClose={() => {
							setAddDialogOpen(false);
						}}
						category={currentCategory}
					/>
				)}
				{editDialogOpen && (
					<AddCategoryDialog
						mode={"edit"}
						categories={categories}
						open={editDialogOpen}
						onSave={(category) => {
							updateCategory(category);
							setCurrentCategory(undefined);
						}}
						handleClose={() => {
							setEditDialogOpen(false);
						}}
						category={currentCategory}
					/>
				)}
				{deleteDialogOpen && (
					<GenericDialog
						open={deleteDialogOpen}
						title={"Delete Category?"}
						onClose={() => {
							setDeleteDialogOpen(false);
						}}
						onConfirm={() => {
							setDeleteDialogOpen(false);
							removeCategory(currentCategory);
						}}
						confirmLabel={"Delete Category"}
						nodeContent={
							<div>
								<div className="pb-4">
									<p className="pb-4 text-basis ">
										The category and any associated issue types already linked to existing tickets will remain. They will not be available
										for future use.
									</p>
								</div>
								{_.get(currentCategory, "issueTypes", []).length == 0 ? (
									<div className="mx-3 pb-4">
										<p className="text-basis text-altblack text-lg">Are you sure you want to delete the category with no issue types?</p>
									</div>
								) : (
									<div className="mx-3 pb-4">
										<p className="text-basis text-altblack text-lg">
											Are you sure you want to delete the category '{_.get(currentCategory, "name", "")}' and the{" "}
											{_.get(currentCategory, "issueTypes", []).length} associated issue types?
										</p>
									</div>
								)}
							</div>
						}
					/>
				)}
			</>
		</div>
	);
};

export default CategoryManagement;
