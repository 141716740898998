export interface ResourceTemplateReference {
	internalId: number;
	name: string;
}
export type SiteAssociationStatus = "Active" | "Inactive" | "Restricted";

export interface SiteAssociation {
	siteId: number;
	siteGuid: string;
	type: string;
	barcode: string;
	primarySite: boolean;
	teacher: boolean;
	homeRoom: string;
	resourceTemplate: ResourceTemplateReference;
	status: SiteAssociationStatus;
}

export interface Patron {
	userGuid: string;
	userLoginID: string;
	userLastName: string;
	userFirstName: string;
	userMiddleName: string;
	userNickname: string;
	patronID: number;
	userImageUrl: string;
	patronExists: boolean;
}

export interface Patron2 {
	guid: string;
	internalId?: number;
	districtId: string;
	lastName: string;
	firstName: string;
	middleName: string;
	nickName: string;
	gradeLevel: string;
	primarySiteGuid: string;
	primarySiteName: string;
	primarySiteShortName: string;
	barcode: string;
	siteAssociations: SiteAssociation[];
}

export const blankPatron: Patron = {
	userGuid: "",
	userLoginID: "",
	userLastName: "",
	userFirstName: "",
	userMiddleName: "",
	userNickname: "",
	patronID: 0,
	userImageUrl: "",
	patronExists: false,
};

export const blankPatron2 = {
	guid: "",
	internalId: undefined,
	districtId: "",
	fullName: "",
	userLoginId: "",
	lastName: "",
	firstName: "",
	middleName: "",
	nickName: "",
	gradeLevel: "",
	primarySiteGuid: "",
	primarySiteName: "",
	primarySiteShortName: "",
	barcode: "",
};
