import {
	Add,
	AddCircleOutlineOutlined,
	CalendarMonthOutlined,
	CheckBoxOutlined,
	Close,
	DeleteForeverOutlined,
	Edit,
	EditNoteOutlined,
	InputOutlined,
	LocalAtmOutlined,
	MoneyOutlined,
	TitleOutlined,
	UpdateOutlined,
} from "@mui/icons-material";
import {
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
} from "@mui/material";
import _ from "lodash";
import { FC, SetStateAction, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hook";
import { Field, FieldType, fieldTypeKeys, fieldTypeString, ftInfo } from "../../models/fields.model";
import { Form } from "../../models/forms.model";
import { setFields } from "../../slices/ConfigurationSlice";
import { useDeleteFieldMutation, useFieldsQuery, useFormsQuery, useUpsertFieldMutation } from "../../utilities/apiApi";
import { renderFieldTypeIcon } from "../../utilities/helpTicketUtils";
import CommonProgress from "../CommonProgress/CommonProgress";
import CustomButton from "../CustomButton/CustomButton";
import CustomTextField from "../CustomTextField";
import GenericDialog from "../GenericDialog/GenericDialog";
import { ListEditor } from "../ListEditor/ListEditor";
import { notify, notifyDialog } from "../Notification/Notification";

interface NoFieldsProps {
	onClick: () => void;
}

const NoFields: FC<NoFieldsProps> = (props) => {
	return (
		<div>
			<div className="opacity-73">
				<div className={"grid gap-8 grid-cols-2 md:grid-cols-3 opacity-[6%]"}>
					<div className="bg-gradient-to-b from-[#01338D] h-56 rounded-lg"></div>
					<div className="bg-gradient-to-b from-[#01338D] h-56 rounded-lg"></div>
					<div className="hidden md:block bg-gradient-to-b from-[#01338D] h-56 rounded-lg"></div>
				</div>
			</div>
			<div className={"grid justify-items-center -mt-44"}>
				<div className={"md:w-[28rem]"}>
					<div className={"grid justify-items-center"}>
						<div className="bg-primary/5 rounded-full h-32 w-32 mb-4">
							<div className={"grid place-items-center h-32"}>
								<img src={"/no_results_fields.svg"} alt={"No field icon "} className={"w-12 h-12"} />
							</div>
						</div>
						<div className="text-altblack font-rubik-medium text-xl font-medium tracking-normal text-center mb-4">No fields at this time.</div>
						<div className="opacity-80 text-altblack font-rubik text-base font-normal tracking-normal text-center mb-4">
							Fields are the key to inputs on Forms. To create a new field, please create a field by clicking below.
						</div>
						<CustomButton
							onClick={() => {
								props.onClick();
							}}
							variant={"contained"}
							ariaLabel={"Create a Help Ticket"}
						>
							Create a Field <Add className={"ml-1"} />
						</CustomButton>
					</div>
				</div>
			</div>
		</div>
	);
};
const FieldsInUse: FC<{ field: Field; forms: Form[] }> = (props) => {
	let field = props.field;
	return (
		<div className="flex flex-col mx-2">
			<div className="w-full  break-words text-altblack  text-lg ">
				{`The field '${field.name}' cannot be deleted because 
	              it is in use by the following ${props.forms.length > 0 ? "forms" : "form"}:`}
			</div>
			<div className="w-full ml-4 mb-2 break-words text-basic ">
				<ul style={{ listStyleType: "circle" }}>
					{_.sortBy(props.forms, ["name"]).map((f) => {
						return <li>{f.name}</li>;
					})}
				</ul>
			</div>
			<div className="w-full  break-words text-altblack  text-lg ">{`Please remove the field from the ${props.forms.length > 0 ? "forms" : "form"} before deleting`}</div>
		</div>
	);
};
const fieldTypeInfo: ftInfo[] = [
	{
		icon: <TitleOutlined />,
		title: "Text",
		type: FieldType.Text,
	},
	{
		icon: <EditNoteOutlined />,
		title: "Memo",
		type: FieldType.Memo,
	},
	{
		icon: <LocalAtmOutlined />,
		title: "Currency",
		type: FieldType.Currency,
	},
	{
		icon: <CalendarMonthOutlined />,
		title: "Date",
		type: FieldType.Date,
	},
	{
		icon: <InputOutlined />,
		title: "Drop Down List",
		type: FieldType.DropDownList,
	},
	{
		icon: <MoneyOutlined />,
		title: "Number",
		type: FieldType.Number,
	},
	{
		icon: <UpdateOutlined />,
		title: "Year",
		type: FieldType.Year,
	},
	{
		icon: <CheckBoxOutlined />,
		title: "Yes/No Checkbox",
		type: FieldType.YesNoCheckbox,
	},
];

interface FieldsConfigurationProps {
	onBack: (value: string) => void;
}

interface AddFieldsProps {
	mode: string;
	open: boolean;
	fields: Field[];
	field: Field | undefined;
	handleClose: () => void;
	onSave: (field: Field) => void;
}

interface EditFieldType extends Field {
	error: boolean;
	changed: boolean;
}

const AddFieldsDialog: FC<AddFieldsProps> = (props) => {
	const [field, setField]: [any, (value: any) => void] = useState(undefined);
	const [fields, setFields] = useState([]);
	const [name, setName] = useState("");
	const [fieldName, setFieldName] = useState("");
	const [description, setDescription] = useState("");
	const [fieldType, setFieldType] = useState(FieldType.Text);
	const [active, setActive] = useState(false);
	const [importance, setImportance] = useState(false);
	const [dropDownOptions, setDropdownOptions] = useState([]);
	const [saveDisable, setSaveDisable] = useState(true);
	const [showFieldTypeError, setShowFieldTypeError] = useState(false);
	const fieldNameRef = useRef();
	const errMsgs = [
		"",
		"Please specify the options for the dropdown list.",
		"Cannot save when duplicate dropdown options exist.",
		"Cannot save an empty dropdown option; please delete or update. ",
		"Cannot save when both duplicates and empty dropdown options exist; please update dropdown list.",
	];

	const [dropDownErrorText, setDropDownErrorText]: [any, (value: any) => void] = useState(undefined);

	const [deleteIssueTypeDialogOpen, setDeleteIssueTypeDialogOpen] = useState(false);
	const [issueIdx, setIssueIdx] = useState(0);

	useEffect(() => {
		if (fieldNameRef && fieldNameRef.current) {
			(fieldNameRef.current as any).focus(); // on startup put focus on Field Name
		}
	}, [fieldNameRef.current]);

	useEffect(() => {
		if (props.field) {
			setField(props.field);
		} else {
			setField({
				id: -1,
				name: "",
				importance: false,
				fieldType: FieldType.Text,
				description: "",
				active: false,
				dropDownOptions: [],
			});
		}
		setFields(_.get(props, "fields", []) as SetStateAction<never[]>);
		setFieldName(_.get(props, "field.name", ""));
		setDescription(_.get(props, "field.description", ""));
		setFieldType(_.get(props, "field.fieldType", 0));
		setImportance(_.get(props, "field.importance", false));
		setActive(_.get(props, "field.active", false));
		setDropdownOptions(_.get(props, "field.dropDownOptions", []) as never[]);
		setDropDownErrorText(undefined);
		setSaveDisable(fieldName.trim().length === 0);
	}, [props.field]);

	useEffect(() => {
		if (fieldName.trim().length > 0) {
			setShowFieldTypeError(fieldType === undefined);
		}
		setSaveDisable(fieldName.trim().length === 0);
	}, [fieldType, fieldName]);

	useEffect(() => {
		setDropDownErrorText(errMsgs[0]);
	}, [dropDownOptions]);

	const hasDuplicateFields = (array: Array<string>) => {
		let retval = false;
		for (let i = 0; i < array.length; i++) {
			let count = 0;
			for (let j = 0; j < array.length; j++) {
				if (i === j) {
					continue;
				}
				if (array[i] === array[j]) {
					retval = true;
					break;
				}
			}
			if (retval) {
				break;
			}
		}
		return retval;
	};
	const hasBlankIssues = (array: Array<string>) => {
		let retval = false;
		for (let i = 0; i < array.length; i++) {
			if (array[i] === "") {
				retval = true;
				break;
			}
		}

		return retval;
	};
	const setFieldError = (idx: number, value: boolean) => {
		let i: any[] = _.cloneDeep(fields);
		i[idx].error = value;
		setFields(i as SetStateAction<never[]>);
	};

	const setThisFieldName = (idx: number, value: string) => {
		let i: any[] = _.cloneDeep(fields);
		i[idx].name = value;
		i[idx].changed = true;
		setFields(i as SetStateAction<never[]>);
	};
	const setThisField = (idx: number, value: EditFieldType) => {
		let i: any[] = _.cloneDeep(fields);
		i[idx].name = value;
		i[idx].changed = true;
		setFields(i as SetStateAction<never[]>);
	};
	const issueDelete = (idx: number) => {
		let i: any[] = _.cloneDeep(fields);
		i.splice(idx, 1);
		setFields(i as SetStateAction<never[]>);
	};
	const isFieldNameError = () => {
		if (
			_.find(fields, (item: Field) => {
				return fieldName === item.name && field.helpTicketFieldID != item.helpTicketFieldID;
			})
		) {
			return true;
		}
		return false;
	};

	return (
		<Dialog
			onClose={() => {
				props.handleClose;
			}}
			aria-labelledby="customized-dialog-title"
			open={props.open}
			sx={{
				"& .MuiDialog-container": {
					"& .MuiPaper-root": {
						width: "100%",
						maxWidth: "900px", // Set your width here
						maxHeight: "900px",
						height: "80%",
					},
				},
			}}
		>
			<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
				{props.mode === "add" ? "New Field" : "Edit Field"}
			</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={(event) => {
					props.handleClose();
				}}
				sx={{
					position: "absolute",
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<Close />
			</IconButton>
			<DialogContent dividers>
				<div className="grid gap-4 md:grid-cols-2">
					<div className="mt-4">
						<InputLabel id="Name-label" className="text-gray-800 text-xl">
							Name
						</InputLabel>
						<TextField
							variant={"outlined"}
							size={"small"}
							value={fieldName}
							inputRef={fieldNameRef}
							autoFocus
							error={isFieldNameError()}
							placeholder={"Add a field name."}
							className="w-[85%]"
							helperText={isFieldNameError() ? "Duplicate field name. Please make the name unique." : ""}
							onChange={(event) => {
								setFieldName(event.target.value.substring(0, 50));
							}}
							aria-labelledby={"Name-label"}
						/>
						<span className=" ml-2 relative top-2  text-gray-400 text-basis">{"" + fieldName.length + "/50"}</span>
					</div>
					<div className="pt-4  ">
						<InputLabel id="FileType-label" className="text-gray-800 text-xl">
							Type
						</InputLabel>
						<Select
							id={"select-fieldType"}
							error={showFieldTypeError}
							size={"small"}
							placeholder="Select a Field Type"
							onChange={(event: any) => {
								if (event.target.value !== undefined) {
									setFieldType(event.target.value);
								}
							}}
							autoWidth={true}
							value={fieldType}
							className={"text-altblack  text-basis w-full"}
							labelId="FileType-label"
							IconComponent={() => (
								<img src={"/down-icon.svg"} alt={"Down arrow icon"} className={"mr-2 pointer-events-none absolute right-[1px]"} />
							)}
						>
							{fieldTypeKeys.map((item, idx) => {
								return (
									<MenuItem key={"item_" + fieldTypeKeys[idx]} value={idx}>
										{fieldTypeString[idx]}
									</MenuItem>
								);
							})}
						</Select>
						{showFieldTypeError && <div className="text-xs text-red-400">Please select a Field Type.</div>}
					</div>
					<div>
						<InputLabel id="Description-label" className="text-gray-800 text-xl">
							Description
						</InputLabel>
						<CustomTextField
							ariaLabel={"Textfield input for field description"}
							rounded={false}
							placeholder={"Add a field description (optional)."}
							multiline={true}
							rows={8}
							value={description}
							onChange={(event: any) => {
								setDescription(event.target.value.substring(0, 200));
							}}
						></CustomTextField>
						<div className=" flex ">
							<div className=" ml-auto text-gray-400 text-basis">{"" + description.length + "/200"}</div>
						</div>
					</div>
					<div className="pt-4">
						{fieldType === FieldType.DropDownList && (
							<ListEditor
								displayValue={(value: any) => {
									return value;
								}}
								onAdd={(value: any) => {
									let dropdown: string[] = _.cloneDeep(dropDownOptions);
									dropdown.push(value);
									setDropdownOptions(dropdown as never[]);
								}}
								onChange={(idx: number, value: string) => {
									let dropdown: string[] = _.cloneDeep(dropDownOptions);
									dropdown[idx] = value;
									setDropdownOptions(dropdown as any);
								}}
								onDelete={(index: number) => {
									let dropdown = _.cloneDeep(dropDownOptions);
									dropdown.splice(index, 1);
									setDropdownOptions(dropdown);
								}}
								showDeleteButtons={() => {
									return dropDownOptions.length > 2;
								}}
								title={"Drop Down Options"}
								itemLabel={"Option"}
								array={dropDownOptions}
								deleteTitle={"Delete Option"}
								deleteString={"Are you sure you want to delete this dropdown option?"}
								placeholder="Enter a new dropdown option here."
								dropDownErrorText={dropDownErrorText}
							/>
						)}
					</div>
					<div className="pt-4">
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									checked={importance}
									inputProps={{
										"aria-labelledby": "required-label",
									}}
									onClick={(event: any) => {
										if (event.target?.checked !== undefined) {
											setImportance(event.target.checked);
										}
									}}
									aria-label="field is required"
									sx={{ "& .MuiSvgIcon-root": { fontSize: 26.75 } }}
								/>
							}
							label="Required"
						/>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<CustomButton variant={"uncontained"} ariaLabel={""} onClick={props.handleClose}>
					Cancel
				</CustomButton>
				<CustomButton
					variant={"contained"}
					ariaLabel={""}
					onClick={() => {
						let bad = false;
						let err = 0;
						if (fieldType === FieldType.DropDownList && dropDownOptions.length < 2) {
							err = 1;
							bad = true;
						} else {
							if (hasDuplicateFields(dropDownOptions)) {
								err = 2;
								bad = true;
							}
							if (hasBlankIssues(dropDownOptions)) {
								err = err > 0 ? 4 : 3;
								bad = true;
							}
						}
						setDropDownErrorText(errMsgs[err]);
						if (!isFieldNameError() && fieldType != undefined && !bad) {
							let c: Field = _.cloneDeep(field);
							c.name = fieldName;
							c.active = active;
							c.importance = importance;
							c.description = description;
							c.fieldType = fieldType;
							if (fieldType == FieldType.DropDownList) {
								c.dropDownOptions = dropDownOptions;
							} else {
								c.dropDownOptions = [];
							}
							if (props.onSave) {
								props.onSave(c);
							}
							setFields([]);
							setFieldName("");
							setName("");
							setField(undefined);
							setDescription("");
							setActive(false);
							setImportance(false);
							setFieldType(FieldType.Text);
							setDropdownOptions([]);
							props.handleClose();
						} else {
						}
					}}
					disabled={saveDisable}
				>
					Save Field
				</CustomButton>
			</DialogActions>
		</Dialog>
	);
};

const FieldsConfiguration: FC<FieldsConfigurationProps> = (props) => {
	const [addDialogOpen, setAddDialogOpen] = useState(false);
	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [openFieldInFormDialog, setOpenFieldInFormDialog] = useState(false);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(undefined);
	const [upsertField, { isLoading }] = useUpsertFieldMutation();
	const [deleteField, { isLoading: isDeleteLoading }] = useDeleteFieldMutation();
	const [currentField, setCurrentField]: [any, (value: any) => void] = useState(undefined);
	const [currentFieldTypeIndex, setCurrentFieldTypeIndex]: [any, (value: any) => void] = useState(0);
	const dispatch = useAppDispatch();
	// const fields = useAppSelector(selectCategories);
	const navigate = useNavigate();

	let fields: Field[] = [];
	let forms: Form[] = [];
	useEffect(() => {
		dispatch(setFields(fields));
	}, [fields]);

	const updateField = async (field: Field) => {
		if (!isLoading) {
			try {
				await upsertField({ field: field }).unwrap();
				if (_.get(field, "helpTicketFieldID", -1) < 0) {
					notify("New Field Added!", `"${field.name}" is available and ready to use.`, false);
				} else {
					notify(
						"Field Updated!",
						field.active ? `"${field.name}" is available and ready to use.` : `"${field.name}" is available but is inactive.`,
						false,
					);
				}
			} catch (error) {
				notify("Error", `Custom Field ${field.name} was not updated`, true);
			}
		}
	};
	function formsUsingField(field: Field, forms: Form[]): Form[] {
		let retval: Form[] = [];
		if (field && forms && forms.length > 0) {
			forms.forEach((form) => {
				if (form.customFields && form.customFields.length > 0) {
					for (let i = 0; i < form.customFields.length; i++) {
						let cField = form.customFields[i];
						if (cField.helpTicketFieldID === field.helpTicketFieldID) {
							retval.push(form);
							break;
						}
					}
				}
			});
		}
		return retval;
	}
	function isFieldUsedInAForm(field: Field, forms: Form[]): boolean {
		let f = formsUsingField(field, forms);
		return f.length > 0;
	}
	const removeFields = async (cat: Field) => {
		if (!isDeleteLoading) {
			try {
				await deleteField({ field: cat }).unwrap();
			} catch (error) {
				notify("Error", "Failed to delete the field", true);
				// console.error("Failed to delete the field: " + error);
			}
		}
	};
	const formsResult = useFormsQuery();
	const fieldsResult = useFieldsQuery();
	if (fieldsResult.isError) {
		notifyDialog("Network error", "Unable to retrieve fields at this time", true, () => {
			props.onBack("Settings");
		});
	}
	if (formsResult.isError) {
		notifyDialog("Network error", "Unable to retrieve fields at this time", true, () => {
			props.onBack("Settings");
		});
	}
	if (fieldsResult.isSuccess) {
		fields = fieldsResult.data;
	}
	if (formsResult.isSuccess) {
		forms = formsResult.data;
	}
	if (fieldsResult.isLoading || formsResult.isLoading) {
		return <CommonProgress message={"Loading fields...."} />;
	}

	return (
		<div>
			{fields.length > 0 && (
				<div className={"flex flex-wrap gap-4 break-words mb-12"}>
					<button
						title="New Field"
						className={
							"flex flex-col w-full sm:w-[250px]  items-center justify-center   h-44 sm:h-60 rounded-[8px] bg-[rgba(1,51,141,0.06)] drop-shadow-[0_2px_8px_rgba(138,146,163,0.16)]"
						}
						onClick={() => {
							setCurrentField({
								id: -1,
								name: "",
								importance: false,
								fieldType: FieldType.Text,
								description: "",
								active: true,
							});
							setAddDialogOpen(true);
						}}
					>
						<AddCircleOutlineOutlined
							sx={{
								fill: "#01338D",
								width: "32px",
								height: "32px",
							}}
						/>
						<div className="text-primary text-lg text-center">New Field</div>
					</button>
					{fields.map((item: Field, idx) => {
						let sliderAriaLabel = `${item.name} is ${item.active ? "active" : "inactive"}`;
						let fieldInfo = _.find(fieldTypeInfo, (info: ftInfo) => info.type === item.fieldType);
						return (
							<div
								className={
									"flex flex-col w-full sm:w-[250px] p-3  h-44 sm:h-60 rounded-[8px] bg-white drop-shadow-[0_2px_8px_rgba(138,146,163,0.16)]"
								}
							>
								<div className={"text-lg text-ellipsis font-semibold mb-2"}>
									{item.name.length > 50 ? item.name.substring(0, 50) + "..." : item.name}
								</div>
								<div className={"grow"}>{item.description.length > 70 ? item.description.substring(0, 70) + "..." : item.description}</div>
								<div className="flex flex-row items-center my-2">
									{fieldInfo && (
										<img
											alt={"Field type image"}
											src={renderFieldTypeIcon(fieldInfo.type ? fieldInfo.type : FieldType.Text)}
											className={"mr-2"}
										/>
									)}
									<span>{fieldInfo ? fieldInfo.title : "None"}</span>
								</div>
								<div className={"flex flex-row"}>
									<div className={"grow"}>
										<Switch
											checked={item.active}
											onChange={(event) => {
												updateField({
													...item,
													active: event.target.checked,
												});
											}}
											inputProps={{
												"aria-label": sliderAriaLabel,
											}}
										/>
									</div>
									<IconButton
										onClick={(e) => {
											setCurrentField(_.cloneDeep(item));
											setEditDialogOpen(true);
										}}
									>
										<Edit />
									</IconButton>
									<IconButton
										onClick={() => {
											setCurrentField(_.cloneDeep(item));
											if (isFieldUsedInAForm(item, forms)) {
												setOpenFieldInFormDialog(true);
											} else {
												setDeleteDialogOpen(true);
											}
										}}
									>
										<DeleteForeverOutlined
											sx={{
												fill: "red",
											}}
										/>
									</IconButton>
								</div>
							</div>
						);
					})}
				</div>
			)}
			{fields.length == 0 && (
				<NoFields
					onClick={() => {
						setCurrentField({
							id: -1,
							name: "",
							importance: false,
							fieldType: FieldType.Text,
							description: "",
							active: true,
						});
						setAddDialogOpen(true);
					}}
				/>
			)}
			<>
				<AddFieldsDialog
					mode={"add"}
					open={addDialogOpen}
					fields={fields}
					onSave={(field) => {
						updateField(field);
						setCurrentField(undefined);
					}}
					handleClose={() => {
						setAddDialogOpen(false);
					}}
					field={currentField}
				/>
				<AddFieldsDialog
					mode={"edit"}
					fields={fields}
					open={editDialogOpen}
					onSave={(field) => {
						updateField(field);
						setCurrentField(undefined);
					}}
					handleClose={() => {
						setEditDialogOpen(false);
					}}
					field={currentField}
				/>
				<GenericDialog
					open={deleteDialogOpen}
					title={"Delete Field?"}
					onClose={() => {
						setDeleteDialogOpen(false);
					}}
					onConfirm={() => {
						setDeleteDialogOpen(false);
						removeFields(currentField);
					}}
					confirmLabel={"Delete Field"}
					content={'Are you sure you want to delete the field "' + _.get(currentField, "name", "") + '"'}
				/>
				<GenericDialog
					open={openFieldInFormDialog}
					title={"Delete Field?"}
					onClose={() => {
						setOpenFieldInFormDialog(false);
					}}
					onConfirm={() => {
						setOpenFieldInFormDialog(false);
					}}
					confirmLabel={"Ok"}
					showCancel={false}
					nodeContent={<FieldsInUse field={currentField} forms={formsUsingField(currentField, forms)} />}
				/>
			</>
		</div>
	);
};

export default FieldsConfiguration;
