import _ from "lodash";
import React, { ReactElement } from "react";
import { useAppDispatch, useAppSelector } from "../../hook";
import { Category, IssueType } from "../../models/helpticket.model";
import { HelpTicketForm } from "../../models/helpTicketForm.model";
import { selectHelpTicketForm, setCategoryAndIssue } from "../../slices/HelpTicketFormSlice";
import { useCategoriesQuery } from "../../utilities/apiApi";
import CategorySelect from "../CategorySelect/CategorySelect";
import CommonProgress from "../CommonProgress/CommonProgress";
import IssueTypeSelect from "../IssueTypeSelect/IssueTypeSelect";

export interface NewTicketCategoryFormProps {
	onNext: () => void;
	hasError: boolean;
}

const NewTicketCategoryForm: React.FC<NewTicketCategoryFormProps> = (props): ReactElement<any, any> | null => {
	let helpTicketForm: HelpTicketForm = useAppSelector(selectHelpTicketForm);
	let selectedValue: number = 0;
	let selectedIssueValue: number | undefined = undefined;
	// const [category, setCategory]: [any, (value: any) => void] = useState(undefined);
	let isLoading = false;
	const dispatch = useAppDispatch();

	// useEffect(() => {
	// 	// console.log(
	// 		"selectedValue:" +
	// 			selectedValue +
	// 			" selectedIssueValue:" +
	// 			selectedIssueValue,
	// 	);
	// }, [selectedValue, selectedIssueValue]);

	const updateCategory = (value: number) => {
		let cat = categories[value];
		dispatch(
			setCategoryAndIssue({
				categoryId: cat.helpTicketIssueTypeID,
				categoryName: cat.name,
				issueTypeId: undefined,
				issueTypeName: undefined,
				issueTypeRequired: cat.issueTypes.length > 0,
			}),
		);
	};
	const updateIssue = (value: number) => {
		let cat = categories[selectedValue];
		selectedIssueValue = value;
		dispatch(
			setCategoryAndIssue({
				categoryId: helpTicketForm.categoryId,
				categoryName: helpTicketForm.categoryName,
				issueTypeId: cat.issueTypes[value].helpTicketIssueTypeID,
				issueTypeName: cat.issueTypes[value].name,
				issueTypeRequired: cat.issueTypes.length > 0,
			}),
		);
	};
	let categoriesResult: any = useCategoriesQuery();
	let categories = [] as Array<Category>;
	let issueTypes: IssueType[] = [];
	// let categoryIssues: Array<IssueType> = [] as Array<IssueType>;

	isLoading = categoriesResult.isLoading;
	if (categoriesResult.hasError) {
		// setCategories([]);
		categories = [] as Array<Category>;
	}
	if (categoriesResult.isSuccess) {
		categories = categoriesResult.data as Array<Category>;
		categories = _.filter(categories, (item) => item.active);
		categories = _.sortBy(categories, ["name"]);
		selectedValue = _.findIndex(categories, (item) => item.helpTicketIssueTypeID === helpTicketForm.categoryId);
		if (selectedValue < 0) {
			selectedValue = 0;
			updateCategory(selectedValue);
		}
		issueTypes = _.get(categories, "[" + selectedValue + "].issueTypes", []);
		selectedIssueValue = _.findIndex(issueTypes, (item) => item.helpTicketIssueTypeID === helpTicketForm.issueTypeId);
		if (selectedIssueValue < 0) {
			selectedIssueValue = undefined;
		}
	}

	return (
		<div data-testid="NewTicketCategoryForm">
			{isLoading && <CommonProgress message={"Loading Existing Categories..."} />}
			<div className={"mb-3"}>
				<CategorySelect categories={categories} value={selectedValue} onChange={updateCategory} hasError={props.hasError} takeFocus={true} />
			</div>
			{issueTypes && issueTypes.length > 0 && (
				<IssueTypeSelect issueTypes={issueTypes} value={selectedIssueValue} onChange={updateIssue} hasError={props.hasError} />
			)}
		</div>
	);
};

export default NewTicketCategoryForm;
