import { createApi } from "@reduxjs/toolkit/query/react";
import newUserLoginModel, {
	getAppId,
	getCustomerNumber,
	getFirstName,
	getLastName,
	getSiteGuid,
	getSub,
	getUserGuid,
	getUserName,
	isDestinyAdmin,
	LoginTokenModel,
	UserLoginModel,
} from "../models/userLogin.model";
// import { userApi } from "./userApi";
import { jwtDecode } from "jwt-decode";
import { goToUrl } from "../components/ErrorNavigation";
import configs from "../config/config";
import env from "../config/env";
import { ClaimsModel } from "../models/claims.model";
import { GrantsModel } from "../models/grants.model";
import { setAccessToken, setUserGrants, setUserLogin } from "../slices/AuthSlice";
import { apiApi } from "./apiApi";
import {
	setDistrictAppId,
	setDistrictCustomerNumber,
	setIsDestinyAdmin,
	setSiteGuid,
	setSub,
	setUserFirstName,
	setUserGuid,
	setUserLastName,
	setUserName,
} from "./appContext";
import authCustomFetchBase from "./authCustomFetchBase";

const BASE_URL = configs.env[env].helpdesk_backend_url;
// Token handling
export function getClaimsFromToken(idToken: string): ClaimsModel | null {
	if (idToken) {
		const decoded: ClaimsModel = jwtDecode(idToken);
		// console.log("decoded idToken:\n" + JSON.stringify(decoded));
		return decoded;
	}
	return null;
}
// TODO put this in appContext instead of here

// function getUserGuidFromClaims(ulm: UserLoginModel): string | undefined {
// 	return ulm?.isDestinyAdmin() ? ulm.getSub() : ulm?.getUserGuid();
// }
// function getUserNameFromClaims(ulm: UserLoginModel): string | undefined {
// 	return ulm?.getUserName();
// }
// function getUserFirstNameFromClaims(ulm: UserLoginModel): string {
// 	return ulm?.getFirstName() || "";
// }
// function getUserLastNameFromClaims(ulm: UserLoginModel): string {
// 	return ulm?.getLastName() || "";
// }
// function getDistrictAppId(ulm: UserLoginModel): string | undefined {
// 	return ulm?.getAppId();
// }
// function getDistrictCustomerNumber(ulm: UserLoginModel): string | undefined {
// 	return ulm?.getCustomerNumber();
// }
// function getSiteGuid(ulm: UserLoginModel): string | undefined {
// 	return ulm?.getSiteGuid();
// }
// function getIsDestinyAdmin(ulm: UserLoginModel): boolean {
// 	return ulm?.isDestinyAdmin();
// }

export const authApi = createApi({
	reducerPath: "authApi",
	baseQuery: authCustomFetchBase,
	endpoints: (builder) => ({
		login: builder.query<LoginTokenModel, string>({
			query: (authCode) => {
				return {
					url: `${BASE_URL}/authenticate/redeem?token=${authCode}`,
					method: "GET", // GET is the default, this could be skipped
					credentials: "include",
					withCredentials: true,
				};
			},
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					let response: {
						data: LoginTokenModel;
						meta: {} | undefined;
					} = await queryFulfilled;
					let loginToken: LoginTokenModel = response.data;
					const ulm: UserLoginModel = newUserLoginModel(getClaimsFromToken(loginToken.idToken), loginToken);
					// console.log("Login:" + JSON.stringify(ulm));
					dispatch(setUserLogin(ulm));
					dispatch(setAccessToken(loginToken.accessToken));
					setUserGuid(getUserGuid(ulm));
					setUserName(getUserName(ulm));
					setUserFirstName(getFirstName(ulm));
					setUserLastName(getLastName(ulm));
					setDistrictAppId(getAppId(ulm));
					setSiteGuid(getSiteGuid(ulm));
					setDistrictCustomerNumber(getCustomerNumber(ulm));
					setIsDestinyAdmin(isDestinyAdmin(ulm));
					setSub(getSub(ulm));
					// TODO accessToken to localstorage?
					await dispatch(
						authApi.endpoints.getGrants.initiate({
							siteGuid: getSiteGuid(ulm) || "",
							userGuid: getSub(ulm) || "",
							districtAppId: getAppId(ulm) || "",
						}),
					);
					await dispatch(apiApi.endpoints.getDestinyDateFormat.initiate());
					await dispatch(apiApi.endpoints.getFeatureFlags.initiate());
				} catch (error) {
					goToUrl("/error");
				}
			},
		}),
		// refreshUser: builder.query<{ access_token: string; status: string }, void>({
		// 	query() {
		// 		return {
		// 			url: `${BASE_URL}/authenticate/refresh`,
		// 			method: "GET", // GET is the default, this could be skipped
		// 			withCredentials: true,
		// 		};
		// 	},
		// 	transformResponse: (result: { data: { access_token: string; status: string } }) => result.data,
		// }),
		logout: builder.query<void, void>({
			// todo add filtering
			query: () => {
				return {
					url: `authenticate/logout/portal`,
					method: "GET", // GET is the default, this could be skipped
					withCredentials: true,
				};
			},
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					dispatch(setUserLogin(undefined));
					dispatch(setAccessToken(undefined));
					setUserGuid(undefined);
					setUserName(undefined);
					setUserFirstName(undefined);
					setUserLastName(undefined);
					setDistrictAppId(undefined);
					setSiteGuid(undefined);
					setDistrictCustomerNumber(undefined);
					setIsDestinyAdmin(false);
					setSub(undefined);
				} catch (error) {
					goToUrl("/error");
				}
			},
		}),
		getGrants: builder.query<
			GrantsModel,
			{
				siteGuid: string;
				userGuid: string;
				districtAppId: string;
			}
		>({
			query: ({ siteGuid, userGuid, districtAppId }) => {
				return {
					url: `${BASE_URL}/data/grants?district_Id=${districtAppId}&site_guid=${siteGuid}&user_guid=${userGuid}`,
					credentials: "include",
				};
			},
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(setUserGrants(data));
				} catch (error) {}
			},
		}),
	}),
});

export const { useLoginQuery, useLogoutQuery } = authApi;
