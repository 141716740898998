import { createRoot } from "react-dom/client";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import App from "./components/App/App";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import "./index.css";
import { UserType } from "./models/user.model";
import AdminPage from "./pages/AdminPage/AdminPage";
import AgentPage from "./pages/AgentPage/AgentPage";
import ErrorPage, { ErrorPage401 } from "./pages/ErrorPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import NewTicketAgentPage from "./pages/NewTicketAgentPage/NewTicketAgentPage";
import NewTicketPage from "./pages/NewTicketPage/NewTicketPage";
import SubmitterPage from "./pages/SubmitterPage/SubmitterPage";

export const VAR_CHAR_MAX = 65535;

const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
		errorElement: <ErrorPage />,
		children: [
			{
				index: true,
				element: <Navigate to="helpdesk" replace />,
			},
			{
				path: "helpdesk",
				element: <LoginPage />,
				errorElement: <ErrorPage />,
				children: [
					{
						path: "submitter",
						element: (
							<ProtectedRoute allowedusertypes={[UserType.Submitter]}>
								<SubmitterPage />
							</ProtectedRoute>
						),
						errorElement: <ErrorPage />,
					},
					{
						path: "admin",
						element: (
							<ProtectedRoute allowedusertypes={[UserType.Admin]}>
								<AdminPage />
							</ProtectedRoute>
						),
						errorElement: <ErrorPage />,
					},
					{
						path: "agent",
						element: (
							<ProtectedRoute allowedusertypes={[UserType.Agent]}>
								<AgentPage />
							</ProtectedRoute>
						),
						errorElement: <ErrorPage />,
					},
					{
						path: "newTicket",
						element: <NewTicketPage />,
						errorElement: <ErrorPage />,
					},
					{
						path: "newTicketAgent",
						element: <NewTicketAgentPage />,
						errorElement: <ErrorPage />,
					},
					{
						path: "401page",
						element: <ErrorPage401 />,
						errorElement: <ErrorPage />,
					},
				],
			},
			{
				path: "error",
				element: <ErrorPage />,
				errorElement: <ErrorPage />,
			},
		],
	},
]);

const container: HTMLElement | null = document.getElementById("root");
const root = createRoot(container! as Element); // createRoot(container!) if you use TypeScript

root.render(
	// <React.StrictMode>
	<RouterProvider router={router} />, // </React.StrictMode>,
);
