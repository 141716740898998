import { Input } from "@mui/base";

import { useTheme } from "@mui/system";
import _ from "lodash";
import React, { useCallback } from "react";

function useIsDarkMode() {
	const theme = useTheme();
	return theme.palette.mode === "light";
}

interface CustomTextFieldProps {
	rounded?: boolean;
	ariaLabel: string;
	placeholder?: string;
	endAdornment?: JSX.Element;
	multiline?: boolean | undefined;
	rows?: number | undefined;
	value?: string;
	defaultValue?: string;
	onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
	debounce?: number;
	error?: boolean;
	className?: string;
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
	rounded,
	ariaLabel,
	placeholder,
	endAdornment,
	multiline,
	rows,
	value,
	onChange,
	debounce,
	defaultValue,
	error,
	className,
}) => {
	const isDarkMode = useIsDarkMode();
	const searchDebounce = useCallback(
		_.debounce((e, value) => {
			if (onChange) {
				onChange(e);
			}
		}, debounce),
		[],
	);
	return (
		<Input
			className={"flex items-center " + (isDarkMode ? "dark" : "")}
			slotProps={{
				input: {
					className:
						"w-full p-2 text-altblack font-roboto font-normal text-base tracking-normal text-left leading-4 border border-[#D3D3D4] hover:border-primary focus:border-primary active:border-primary focus-visible:outline-0 " +
						(rounded ? "rounded-full " : "rounded-[8px] ") +
						(endAdornment ? "pr-[4.5rem] " : "") +
						(error ? "border-error-border bg-error-bg " : "bg-white") +
						(className ? " " + className : ""),
				},
			}}
			aria-label={ariaLabel}
			placeholder={placeholder}
			endAdornment={endAdornment}
			multiline={multiline as any}
			rows={rows}
			value={value}
			defaultValue={defaultValue}
			onChange={(e) => {
				if (debounce && debounce > 0) {
					searchDebounce(e, e?.target?.value || "");
				} else {
					if (onChange) {
						onChange(e);
					}
				}
			}}
		/>
	);
};

export default CustomTextField;
