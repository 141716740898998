const getEnv = () => {
	const host: string = window.location.host;
	let lowerEnvTemplate = "https://dly-helpdeskapi.follettsoftware.com";
	if (host.includes("dly")) {
		return "dly";
	} else if (host.includes("qat")) {
		return "qat";
	} else if (host.includes("localhost")) {
		return "local";
	} else if (host.includes("dlx")) {
		return "dlx";
	} else {
		// prod
		return "prd";
	}
};
const env: string = getEnv();
export default env;
