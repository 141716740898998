import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import TicketWizard from "../../components/TicketWizard/TicketWizard";
import { useAppDispatch } from "../../hook";
import { resetHelpTicketForm } from "../../slices/HelpTicketFormSlice";

function NewTicketPage(): ReactJSXElement {
	// const location = useLocation();
	// const navigate = useNavigate();
	const dispatch = useAppDispatch();
	dispatch(resetHelpTicketForm());
	return (
		<div className="h-full">
			<TicketWizard />
		</div>
	);
}

export default NewTicketPage;
