import { FC } from "react";

import { InputLabel, MenuItem, Select } from "@mui/material";
import _ from "lodash";
import { Category } from "../../models/helpticket.model";

export interface CategorySelectProps {
	categories: Array<Category> | undefined;
	value: number | undefined;
	onChange: (value: number) => void;
	hasError: boolean;
	takeFocus: boolean;
}

const CategorySelect: FC<CategorySelectProps> = (props) => {
	const handleChange = (e: any) => {
		props.onChange(e.target.value);
	};

	return (
		<div className={"w-full justify-left "} data-testId="CategorySelect">
			{(props.categories === undefined || props.categories.length === 0) && (
				<div>
					<div className={"text-bold text-base text-altblack mb-0.5"}>Issue Category</div>
					<div className={"mx-4 text-base text-altblack"}>No Issue Categories Configured</div>
				</div>
			)}
			{props.categories !== undefined && props.categories.length === 1 && (
				<div>
					<div className={"text-bold text-base text-altblack mb-0.5"}>Issue Category</div>
					<div className={"mx-4 text-base text-altblack"}>{props.categories[0].name}</div>
				</div>
			)}
			{props.categories !== undefined && props.categories.length > 1 && (
				<>
					<InputLabel id="select-label" className={"text-bold font-rubik text-base text-altblack mb-0.5"}>
						Issue Category
					</InputLabel>
					<Select
						labelId="select-label"
						id="select"
						value={props.value}
						className={"w-full text-base"}
						onChange={handleChange}
						sx={{
							backgroundColor: props.hasError && props.value === -1 ? "#FFF7F7 !important" : "white",
							border: props.hasError && props.value === -1 ? "1px solid #B43523 !important" : "",
						}}
						displayEmpty={true}
						renderValue={(selected) => {
							if (selected < 0) {
								return <span className={"text-[#6E788C]"}>Select an Issue Category</span>;
							}

							return _.get(props, "categories[" + selected + "].name", "");
						}}
						autoFocus={true}
						IconComponent={() => <img src={"/down-icon.svg"} alt={"Down arrow icon"} className={"mr-2 pointer-events-none absolute right-[1px]"} />}
					>
						{props.categories &&
							props.categories.map((item, idx) => {
								return (
									<MenuItem key={item.helpTicketIssueTypeID} value={idx} className="text-base">
										{item.name}
									</MenuItem>
								);
							})}
					</Select>
					{props.hasError && props.value === -1 && <div className={"text-error-text"}>Please select an Issue Category</div>}
				</>
			)}
		</div>
	);
};
export default CategorySelect;
