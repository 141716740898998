import { createSlice } from "@reduxjs/toolkit";
import { Field } from "../models/fields.model";
import { Form } from "../models/forms.model";
import { Category, DateFormat, ServicingTeam } from "../models/helpticket.model";
import { RootState } from "../store";

export interface Configuration {
	assignedGroups: Array<ServicingTeam>;
	destinyDateFormat: DateFormat;
	categories: Category[];
	fields: Field[];
	forms: Form[];
}

// Define the initial state using that type
const initialState: Configuration = {
	assignedGroups: [],
	destinyDateFormat: { format: "MM/DD/YYYY hh:mm a" },
	categories: [],
	fields: [],
	forms: [],
};

const ConfigurationSlice = createSlice({
	name: "configuration",
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setServicingTeams: (state, payload) => {
			return { ...state, ...{ assignedGroups: payload.payload } };
		},
		resetServicingTeams: (state) => {
			// remember state is only for read only you are returning the new state
			return { ...state, ...{ assignedGroups: [] } };
		},
		setDestinyDateFormat: (state, payload) => {
			return { ...state, ...{ destinyDateFormat: payload.payload } };
		},
		resetDestinyDateFormat: (state) => {
			// remember state is only for read only you are returning the new state
			return {
				...state,
				...{ destinyDateFormat: initialState.destinyDateFormat },
			};
		},
		setCategories: (state, payload) => {
			return { ...state, ...{ categories: payload.payload } };
		},
		setFields: (state, payload) => {
			return { ...state, ...{ fields: payload.payload } };
		},
		setForms: (state, payload) => {
			return { ...state, ...{ forms: payload.payload } };
		},
	},
});

export const selectServicingTeams = (state: RootState): Array<ServicingTeam> => {
	return state.configuration.assignedGroups;
};

export const selectCategories = (state: RootState): Array<Category> => {
	return state.configuration.categories;
};

export const selectDestinyDateFormat = (state: RootState): DateFormat => {
	return state.configuration.destinyDateFormat;
};

export const { setServicingTeams, setCategories, setFields, setForms, resetServicingTeams, setDestinyDateFormat, resetDestinyDateFormat } =
	ConfigurationSlice.actions;

export default ConfigurationSlice.reducer;
