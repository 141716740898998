import { IconButton } from "@mui/material";
import React from "react";
import { HelpDeskSettings, Home, NewItemIcon } from "../../icons/TableHeaderIcons";

interface SidebarProps {
	pageMode: string;
	setPageMode: (mode: string) => void;
	disableSettings?: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ pageMode, setPageMode, disableSettings }) => {
	return (
		<div className={"w-[60px] py-1 h-screen bg-white flex flex-col fixed items-center drop-shadow-[0_5px_12px_rgba(123,123,123,0.08)]"}>
			<div
				title={"New Ticket"}
				className={"border-r-4 border-l-4 border-r-white" + (pageMode === "Add Ticket" ? " border-l-primary" : " border-l-white text-altblack")}
			>
				<IconButton aria-label={"Add Ticket"} onClick={() => setPageMode("Add Ticket")} className={"mb-3 w-16 h-16"} disableRipple={true}>
					<NewItemIcon />
				</IconButton>
			</div>
			<div
				title={"Home"}
				className={"border-r-4 border-l-4 border-r-white" + (pageMode === "Home" ? " border-l-primary" : " border-l-white text-altblack")}
			>
				<IconButton aria-label={"Home"} onClick={() => setPageMode("Home")} className={"w-16 h-16"} disableRipple={true} disabled={pageMode === "Home"}>
					<Home fill={pageMode === "Home" ? "#01338D" : "#171646"} />
				</IconButton>
			</div>
			<div className={"grow"} />
			{(disableSettings === undefined || disableSettings === false) && (
				<div
					title={"Settings"}
					className={"border-r-4 border-l-4 border-r-white" + (pageMode === "Settings" ? " border-l-primary" : " border-l-white text-altblack")}
				>
					<IconButton
						aria-label={"Settings"}
						onClick={() => setPageMode("Settings")}
						className={"w-16 h-16"}
						disableRipple={true}
						disabled={disableSettings ? disableSettings : false}
					>
						<HelpDeskSettings fill={pageMode === "Home" ? "#01338D" : "#171646"} />
					</IconButton>
				</div>
			)}
		</div>
	);
};

export default Sidebar;
