import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { closedDialogNotification, closedNotification, NotificationProps } from "../models/notification.model";
import { RootState } from "../store";

// Define the initial state using that type

interface NotificationSliceState {
	notification: NotificationProps;
	errorNavigationUrl: string | undefined;
}

const initialState: NotificationSliceState = {
	// @ts-ignore
	notification: _.cloneDeep(closedNotification),
	errorNavigationUrl: undefined,
};

const notificationSlice = createSlice({
	name: "Notification",
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		openNotification: (state, payload) => {
			let notification = { ...state.notification, ...payload.payload };
			return { ...state, notification: notification };
		},
		closeNotification: (state, payload) => {
			if (payload.payload === undefined) {
				let notification = { ...state.notification, ...closedNotification };
				// remember state is only for read only you are returning the new state
				return { ...state, notification: notification };
			} else {
				let notification = { ...state.notification, ...payload.payload };
				return { ...state, notification: notification };
			}
		},
		closeNotificationDialog: (state, payload) => {
			if (payload.payload === undefined) {
				let notification = { ...state.notification, ...closedDialogNotification };
				// remember state is only for read only you are returning the new state
				return { ...state, notification: notification };
			} else {
				let notification = { ...state.notification, ...payload.payload };
				return { ...state, notification: notification };
			}
		},
		openErrorNavigationUrl: (state, payload) => {
			return { ...state, errorNavigationUrl: payload.payload.url };
		},
	},
});

export const selectNotification = (state: RootState): NotificationProps => {
	return state.notification.notification;
};

export const selectErrorNavigationUrl = (state: RootState): string | undefined => {
	return state.notification.errorNavigationUrl;
};

export const { openNotification, closeNotification, closeNotificationDialog, openErrorNavigationUrl } = notificationSlice.actions;

export default notificationSlice.reducer;
