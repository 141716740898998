import { Close } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import _ from "lodash";
import { FC, useState } from "react";
import { Report } from "../../models/reports.model";
import CustomButton from "../CustomButton/CustomButton";

interface SaveReportsDialogProps {
	report: Report;
	reports: Report[];
	onSave: (report: Report) => void;
	onClose: () => void;
}

const SaveReportsDialog: FC<SaveReportsDialogProps> = (props) => {
	const [name, setName] = useState(props.report.name || "");
	const [nameValidated, setNameValidated] = useState(true);
	const [nameValidatedDuplicate, setNameValidatedDuplicate] = useState(true);
	const [description, setDescription] = useState(props.report.description || "");

	function validate() {
		let validated = true;

		if (name === "") {
			validated = false;
			setNameValidated(false);
		}

		for (let i = 0; i < props.reports.length; i++) {
			if (name === props.reports[i].name && props.report.helpTicketSavedReportViewID !== props.reports[i].helpTicketSavedReportViewID) {
				validated = false;
				setNameValidatedDuplicate(false);
				break;
			}
		}

		if (validated) {
			let r = _.cloneDeep(props.report);
			r.name = name;
			r.description = description;
			props.onSave(r);
		}
	}

	return (
		<div>
			<Dialog
				open={true}
				sx={{
					"& .MuiBackdrop-root": {
						backgroundColor: "#171646",
					},
					"& .MuiPaper-root": {
						minWidth: "30rem",
						minHeight: "24rem",
					},
				}}
			>
				<div className={"p-2"}>
					<DialogTitle>Save Report</DialogTitle>
					<IconButton
						aria-label="close"
						onClick={(e: any) => {
							props.onClose();
						}}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<Close />
					</IconButton>
					<DialogContent>
						<div className={"flex flex-col"}>
							<div className={"font-medium"}>Name</div>
							<input
								type={"text"}
								maxLength={30}
								value={name}
								placeholder={"Add a report name"}
								onChange={(e) => {
									setName(e.target.value);
									setNameValidated(true);
									setNameValidatedDuplicate(true);
								}}
								className={"border rounded-[8px] px-2 py-2 max-w-72"}
							/>
							<div className={"text-red-500"}>
								{!nameValidated && "Please specify a name."}
								{!nameValidatedDuplicate && "Duplicate report name. Please make the name unique."}
								{nameValidated && nameValidatedDuplicate && <br />}
							</div>

							<div className={"mt-6 font-medium"}>Description</div>
							<textarea
								value={description}
								maxLength={200}
								onChange={(e) => {
									setDescription(e.target.value);
								}}
								className={"mb-6 border rounded-[8px] px-2 py-2"}
								rows={3}
								placeholder={"Add a short description (optional)"}
							/>
						</div>
					</DialogContent>
					<DialogActions>
						<div className="flex flex-row">
							<div className={"grow"} />
							<CustomButton
								variant={"notContained"}
								ariaLabel={""}
								onClick={(e: Event) => {
									props.onClose();
								}}
							>
								Cancel
							</CustomButton>
							<CustomButton
								variant={"contained"}
								ariaLabel={""}
								onClick={(e: Event) => {
									validate();
								}}
							>
								Save Report
							</CustomButton>
						</div>
					</DialogActions>
				</div>
			</Dialog>
		</div>
	);
};

export default SaveReportsDialog;
