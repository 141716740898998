import { ReactNode } from "react";

export enum FieldType {
	Text = 0,
	Memo = 1,
	Currency = 2,
	Date = 3,
	DropDownList = 4,
	Number = 5,
	Year = 6,
	YesNoCheckbox = 7,
}

export const fieldTypeKeys: string[] = ["Text", "Memo", "Currency", "Date", "DropDownList", "Number", "Year", "YesNoCheckbox"];

export const fieldTypeString: string[] = ["Text", "Memo", "Currency", "Date", "Drop Down List", "Number", "Year", "Yes/No Checkbox"];

export const stringToFieldType: { [key: string]: FieldType } = {
	Text: FieldType.Text,
	Memo: FieldType.Memo,
	Currency: FieldType.Currency,
	Date: FieldType.Date,
	DropDownList: FieldType.DropDownList,
	Number: FieldType.Number,
	Year: FieldType.Year,
	YesNoCheckbox: FieldType.YesNoCheckbox,
};

export interface Field {
	helpTicketFieldID?: number;
	name: string;
	description: string;
	active: boolean;
	fieldType: number;
	importance: boolean;
	dropDownOptions?: string[];
}
export type ftInfo = {
	icon: ReactNode;
	title: string;
	type: FieldType;
};
