import { FC, ReactNode, useRef } from "react";

import {
	CalendarMonthOutlined,
	CheckBoxOutlined,
	EditNoteOutlined,
	InputOutlined,
	LocalAtmOutlined,
	MoneyOutlined,
	TitleOutlined,
	UpdateOutlined,
} from "@mui/icons-material";
import { FieldType } from "../../models/fields.model";
import { CustomFieldValue } from "../../models/helpticket.model";

type FieldTypeInfo = {
	icon: ReactNode;
	title: string;
	type: FieldType;
};
const fieldTypeInfo: FieldTypeInfo[] = [
	{
		icon: <LocalAtmOutlined />,
		title: "Currency",
		type: FieldType.Currency,
	},
	{
		icon: <CalendarMonthOutlined />,
		title: "Date",
		type: FieldType.Date,
	},
	{
		icon: <InputOutlined />,
		title: "Drop Down List",
		type: FieldType.DropDownList,
	},
	{
		icon: <EditNoteOutlined />,
		title: "Memo",
		type: FieldType.Memo,
	},
	{
		icon: <MoneyOutlined />,
		title: "Number",
		type: FieldType.Number,
	},
	{
		icon: <TitleOutlined />,
		title: "Text",
		type: FieldType.Text,
	},
	{
		icon: <UpdateOutlined />,
		title: "Year",
		type: FieldType.Year,
	},
	{
		icon: <CheckBoxOutlined />,
		title: "Yes/No Checkbox",
		type: FieldType.YesNoCheckbox,
	},
];
export interface CustomValueProps {
	field: CustomFieldValue;
}

const CustomValueText: FC<CustomValueProps> = (props) => {
	const fieldNameRef = useRef();
	return (
		<div className="flex flex-col mb-2" data-testid={"customValue_" + props.field.fieldName}>
			<div className={"text-altblack"}>
				<span>
					<b>{props.field.fieldName}:</b>
				</span>
			</div>
			<div className={"w-full"}>
				<p>{props.field.textValue}</p>
			</div>
		</div>
	);
};
const CustomValueCurrency: FC<CustomValueProps> = (props) => {
	const fieldNameRef = useRef();
	return (
		<div className="flex flex-col mb-2" data-testid={"customValue_" + props.field.fieldName}>
			<div className={"text-altblack"}>
				<span>
					<b>{props.field.fieldName}:</b>
				</span>
			</div>
			<div className={"w-full"}>
				<p>{props.field.textValue}</p>
			</div>
		</div>
	);
};
const CustomValueDate: FC<CustomValueProps> = (props) => {
	const fieldNameRef = useRef();
	return (
		<div className="flex flex-col mb-2" data-testid={"customValue_" + props.field.fieldName}>
			<div className={"text-altblack"}>
				<span>
					<b>{props.field.fieldName}:</b>
				</span>
			</div>
			<div className={"w-full"}>
				<p>{props.field.textValue}</p>
			</div>
		</div>
	);
};
const CustomValueDropDownList: FC<CustomValueProps> = (props) => {
	const fieldNameRef = useRef();
	return (
		<div className="flex flex-col mb-2" data-testid={"customValue_" + props.field.fieldName}>
			<div className={"text-altblack"}>
				<span>
					<b>{props.field.fieldName}:</b>
				</span>
			</div>
			<div className={"w-full"}>
				<p>{props.field.textValue}</p>
			</div>
		</div>
	);
};
const CustomValueMemo: FC<CustomValueProps> = (props) => {
	const fieldNameRef = useRef();
	return (
		<div className="flex flex-col mb-2" data-testid={"customValue_" + props.field.fieldName}>
			<div className={"text-altblack"}>
				<span>
					<b>{props.field.fieldName}:</b>
				</span>
			</div>
			<div className={"w-full"}>
				<p>{props.field.textValue}</p>
			</div>
		</div>
	);
};
const CustomValueNumber: FC<CustomValueProps> = (props) => {
	const fieldNameRef = useRef();
	return (
		<div className="flex flex-col mb-2" data-testid={"customValue_" + props.field.fieldName}>
			<div className={"text-altblack"}>
				<span>
					<b>{props.field.fieldName}:</b>
				</span>
			</div>
			<div className={"w-full"}>
				<p>{props.field.textValue}</p>
			</div>
		</div>
	);
};

const CustomValueYear: FC<CustomValueProps> = (props) => {
	const fieldNameRef = useRef();
	return (
		<div className="flex flex-col mb-2" data-testid={"customValue_" + props.field.fieldName}>
			<div className={"text-altblack"}>
				<span>
					<b>{props.field.fieldName}:</b>
				</span>
			</div>
			<div className={"w-full"}>
				<p>{props.field.textValue}</p>
			</div>
		</div>
	);
};
const CustomValueYesNoCheckbox: FC<CustomValueProps> = (props) => {
	const fieldNameRef = useRef();
	return (
		<div className="flex flex-col mb-2" data-testid={"customValue_" + props.field.fieldName}>
			<div className={"text-altblack"}>
				<span>
					<b>{props.field.fieldName}:</b>
				</span>
			</div>
			<div className={"text-gray-600 w-full"}>
				<p>{props.field.textValue}</p>
			</div>
		</div>
	);
};
function getFieldType(input: FieldType | string) {
	let lookup: { [key: string]: FieldType } = {
		CURRENCY: FieldType.Currency,
		DATE: FieldType.Date,
		DROPDOWNLIST: FieldType.DropDownList,
		DROP_DOWN_LIST: FieldType.DropDownList,
		DROPDOWN_LIST: FieldType.DropDownList,
		MEMO: FieldType.Memo,
		NUMBER: FieldType.Number,
		TEXT: FieldType.Text,
		YEAR: FieldType.Year,
		YES_NO_CHECKBOX: FieldType.YesNoCheckbox,
	};
	if (typeof input === "string") {
		return lookup[input.toUpperCase()];
	}
	return input;
}
const CustomValue: FC<CustomValueProps> = (props) => {
	switch (getFieldType(props.field.fieldType)) {
		case FieldType.Currency:
			return <CustomValueCurrency {...props} />;
			break;
		case FieldType.Date:
			return <CustomValueDate {...props} />;
			break;
		case FieldType.DropDownList:
			return <CustomValueDropDownList {...props} />;
			break;
		case FieldType.Memo:
			return <CustomValueMemo {...props} />;
			break;
		case FieldType.Number:
			return <CustomValueNumber {...props} />;
			break;
		case FieldType.Text:
			return <CustomValueText {...props} />;
			break;
		case FieldType.Year:
			return <CustomValueYear {...props} />;
			break;

		case FieldType.YesNoCheckbox:
			return <CustomValueYesNoCheckbox {...props} />;
			break;
		default:
			return <CustomValueText {...props} />;
			break;
	}
};
export interface CustomFieldsValueProps {
	fields: CustomFieldValue[];
}
export const CustomFormValues: FC<CustomFieldsValueProps> = (props) => {
	return (
		<div className={"w-full flex flex-col justify-left break-all"}>
			{props.fields && props.fields.length > 0 && props.fields.map((field) => <CustomValue field={field} />)}
		</div>
	);
};
export default CustomValue;
