import {
	Box,
	Checkbox,
	ClickAwayListener,
	DialogActions,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Tooltip,
} from "@mui/material";
import _ from "lodash";
import { FC, useState } from "react";
import { Field } from "../../models/fields.model";
import { HelpTicketReport, Report, ReportFilter } from "../../models/reports.model";
import { useFieldsQuery, useReportFacetsQuery, useReportTicketsQuery } from "../../utilities/apiApi";
import CommonProgress from "../CommonProgress/CommonProgress";
import CustomButton from "../CustomButton/CustomButton";

const FacetList = (props: { column: string; filter: ReportFilter; setFilter: Function }) => {
	const facetsQuery = useReportFacetsQuery({ columns: [props.column] });
	let facets: ReportFilter = {} as ReportFilter;
	if (facetsQuery.isSuccess) {
		facets = facetsQuery.data.find((rf: ReportFilter) => rf.column == props.column);
	}

	return (
		<div>
			<Paper
				sx={{
					borderRadius: "8px",
				}}
			>
				<TableContainer sx={{ maxHeight: "50vh" }} className={"overflow-x-hidden"}>
					<Table size={"medium"} stickyHeader className={""}>
						<TableHead>
							<TableRow>
								<TableCell className={"p-0"}>
									<Checkbox
										checked={!props.filter.include && props.filter.options.length == 0}
										indeterminate={props.filter.options.length > 0}
										onClick={(_event: any) => {
											let f = _.cloneDeep(props.filter);

											if (f.options.length > 0 || f.include) {
												f.options = [];
												f.include = false;
											} else {
												f.include = true;
											}

											props.setFilter(f);
										}}
									/>
								</TableCell>
								<TableCell className={"pt-0 pb-0 max-w-xs text-ellipsis whitespace-nowrap"}>All</TableCell>
							</TableRow>
						</TableHead>

						<TableBody className="font-rubik">
							{facets.options?.map((facet) => (
								<TableRow>
									<TableCell className={"pt-0 pb-0"}>
										<Checkbox
											checked={props.filter.include == props.filter.options.includes(facet)}
											onClick={(_event: any) => {
												let f = _.cloneDeep(props.filter);

												const index = f.options.indexOf(facet);
												if (index == -1) {
													f.options.push(facet);
													if (f.options.length == facets.options.length) {
														f.include = !f.include;
														f.options = [];
													}
												} else {
													f.options.splice(index, 1);
												}

												props.setFilter(f);
											}}
										/>
									</TableCell>
									<TableCell className={"pt-0 pb-0 max-w-xs truncate"}>{facet == null ? "N/A" : facet}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</div>
	);
};

interface ReportsDataGridFormProps {
	setSaveDialogOpen: Function;
	setExportDialogOpen: Function;
	setDataGridOpen: Function;
	setCurrentReport: Function;
	report: Report;
	getFacets: Function;
}

const ReportsDataGridForm: FC<ReportsDataGridFormProps> = (props) => {
	const [toolTipColumn, setToolTipColumn] = useState("");

	let helpTickets: Array<HelpTicketReport> = [];
	let helpTicketQuery = useReportTicketsQuery({ params: props.report.reportParams });
	let isLoadingTickets = helpTicketQuery?.isLoading || helpTicketQuery?.isFetching;
	if (helpTicketQuery.isSuccess) {
		helpTickets = helpTicketQuery.data;
	}

	let columns = [
		{
			columnID: "siteName",
			label: "Site Name",
			fieldValue: (row: HelpTicketReport) => row.siteName,
			canFilter: true,
			visible: true,
		},
		{
			columnID: "ticketNumber",
			label: "Ticket Number",
			fieldValue: (row: HelpTicketReport) => row.ticketNumber,
			canFilter: false,
			visible: true,
		},
		{
			columnID: "displayableName",
			label: "Displayable Name",
			fieldValue: (row: HelpTicketReport) => row.displayableName,
			canFilter: true,
			visible: true,
		},
		{
			columnID: "barcode",
			label: "Barcode",
			fieldValue: (row: HelpTicketReport) => row.barcode,
			canFilter: false,
			visible: true,
		},
		{
			columnID: "serialNumber",
			label: "Serial Number",
			fieldValue: (row: HelpTicketReport) => row.serialNumber,
			canFilter: false,
			visible: true,
		},
		{
			columnID: "issueCategory",
			label: "Issue Category",
			fieldValue: (row: HelpTicketReport) => row.issueCategory,
			canFilter: true,
			visible: true,
		},
		{
			columnID: "issueType",
			label: "Issue Type",
			fieldValue: (row: HelpTicketReport) => row.issueType,
			canFilter: true,
			visible: true,
		},
		{
			columnID: "status",
			label: "Status",
			fieldValue: (row: HelpTicketReport) => row.status,
			canFilter: true,
			visible: true,
		},
		{
			columnID: "priority",
			label: "Priority",
			fieldValue: (row: HelpTicketReport) => row.priority,
			canFilter: true,
			visible: true,
		},
		{
			columnID: "summary",
			label: "Summary",
			fieldValue: (row: HelpTicketReport) => row.summary,
			canFilter: false,
			visible: true,
		},
		{
			columnID: "details",
			label: "Details",
			fieldValue: (row: HelpTicketReport) => row.details,
			canFilter: false,
			visible: true,
		},
		{
			columnID: "dateCreated",
			label: "Date Created",
			fieldValue: (row: HelpTicketReport) => row.dateCreated,
			canFilter: true,
			visible: true,
		},
		{
			columnID: "lastUpdated",
			label: "Last Updated",
			fieldValue: (row: HelpTicketReport) => row.lastUpdated,
			canFilter: true,
			visible: true,
		},
		{
			columnID: "requestedBy",
			label: "Requested by",
			fieldValue: (row: HelpTicketReport) => row.requestedBy,
			canFilter: true,
			visible: true,
		},
		{
			columnID: "assignedTo",
			label: "Assigned to",
			fieldValue: (row: HelpTicketReport) => row.assignedTo,
			canFilter: true,
			visible: true,
		},
		{
			columnID: "damagedBy",
			label: "Damaged by",
			fieldValue: (row: HelpTicketReport) => row.damagedBy,
			canFilter: true,
			visible: true,
		},
		{
			columnID: "filesAttached",
			label: "Files attached",
			fieldValue: (row: HelpTicketReport) => (row.filesAttached && "Yes") || "No",
			canFilter: true,
			visible: true,
		},
	];

	let customFields: Array<Field> = [];
	let customFieldsQuery = useFieldsQuery();
	if (customFieldsQuery.isSuccess) {
		customFields = customFieldsQuery.data;

		customFields.map((customField) => {
			columns.push({
				columnID: customField.helpTicketFieldID as unknown as string,
				label: customField.name,
				fieldValue: (row: HelpTicketReport) => {
					let field = row.customFields?.find((rowField) => rowField.helpTicketFieldID == customField.helpTicketFieldID);
					return field === undefined ? "N/A" : field.textValue;
				},
				canFilter: true,
				visible: true,
			});
		});
	}

	return (
		<div className={"mb-24 drop-shadow-[0_2px_12px_rgba(138,146,163,0.28)]"}>
			{isLoadingTickets && <CommonProgress message="Loading Tickets..." />}

			<Box
				sx={{
					width: "100%",
					height: "100%",
				}}
			>
				<Paper
					sx={{
						width: "100%",
						borderRadius: "8px",
					}}
					className={"pl-5 pt-5"}
				>
					<TableContainer sx={{ maxHeight: "calc(100vh - 172px)" }}>
						<Table stickyHeader aria-labelledby="tableTitle" size={"medium"}>
							<TableHead>
								<TableRow>
									{columns
										.filter((column) => column.visible)
										.map((column) => {
											return (
												<TableCell className={"pt-0 pb-0 max-w-xs text-ellipsis whitespace-nowrap overflow-visible"}>
													<div>
														<TableSortLabel
															active={props.report.reportParams.sortOption.column === column.columnID}
															direction={props.report.reportParams.sortOption.asc ? "asc" : "desc"}
															IconComponent={() => (
																<div className={"w-4 h-4 inline-grid"}>
																	<img
																		src={
																			props.report.reportParams.sortOption.asc
																				? "/Sort-Ascending.svg"
																				: "/Sort-Descending.svg"
																		}
																		alt={"Sort"}
																		className={
																			props.report.reportParams.sortOption.column === column.columnID ? "" : "invisible"
																		}
																	/>
																</div>
															)}
															onClick={() => {
																let r: Report = _.cloneDeep(props.report);

																if (r.reportParams.sortOption.column === column.columnID) {
																	r.reportParams.sortOption.asc = !r.reportParams.sortOption.asc;
																} else {
																	r.reportParams.sortOption.column = column.columnID;
																	r.reportParams.sortOption.asc = true;
																}

																props.setCurrentReport(r);
															}}
														>
															{column.label}
														</TableSortLabel>

														{column.canFilter && (
															<ClickAwayListener onClickAway={() => column.columnID == toolTipColumn && setToolTipColumn("")}>
																<span className={"p-0 max-w-none"}>
																	<Tooltip
																		slotProps={{
																			popper: {
																				sx: {
																					"& .MuiTooltip-tooltip": {
																						padding: "0px",
																						maxWidth: "none",
																					},
																				},
																			},
																		}}
																		onClose={() => setToolTipColumn("")}
																		open={column.columnID == toolTipColumn}
																		arrow
																		disableFocusListener
																		disableHoverListener
																		disableTouchListener
																		title={
																			<FacetList
																				column={column.columnID}
																				filter={
																					props.report.reportParams.filters.find(
																						(filter) => filter.column == column.columnID,
																					) ||
																					({
																						column: column.columnID,
																						options: [],
																						include: false,
																					} as ReportFilter)
																				}
																				setFilter={(filter: ReportFilter | undefined) => {
																					let r: Report = _.cloneDeep(props.report);

																					const index = r.reportParams.filters.findIndex(
																						(existing) => existing.column == column.columnID,
																					);
																					if (index == -1) {
																						if (filter && (filter.include || filter.options.length > 0)) {
																							r.reportParams.filters.push(filter);
																						}
																					} else {
																						if (filter && (filter.include || filter.options.length > 0)) {
																							r.reportParams.filters[index] = filter;
																						} else {
																							r.reportParams.filters.splice(index, 1);
																						}
																					}

																					props.setCurrentReport(r);
																				}}
																			/>
																		}
																	>
																		<IconButton
																			onClick={() =>
																				setToolTipColumn(toolTipColumn == column.columnID ? "" : column.columnID)
																			}
																		>
																			<img
																				src={
																					props.report.reportParams.filters.findIndex(
																						(existing) => existing.column == column.columnID,
																					) == -1
																						? "/filter-outline.svg"
																						: "/filter-filled.svg"
																				}
																				alt={"Filter"}
																				className={"w-4 h-4"}
																			/>
																		</IconButton>
																	</Tooltip>
																</span>
															</ClickAwayListener>
														)}
													</div>
												</TableCell>
											);
										})}
								</TableRow>
							</TableHead>

							<TableBody className="font-rubik">
								{!isLoadingTickets && (!helpTickets || helpTickets.length == 0) && (
									<TableRow>
										<TableCell className={"text-2xl items-center"} colSpan={columns.length}>
											{props.report.reportParams.filters.length == 0
												? "No Tickets exist yet"
												: "Based on current filter settings, there is no data to display."}
										</TableCell>
									</TableRow>
								)}
								{helpTickets.map((helpTicket) => (
									<TableRow>
										{columns
											.filter((column) => column.visible)
											.map((column) => (
												<TableCell
													className={"truncate py-2"}
													sx={{
														maxWidth: "200px",
													}}
												>
													{column.fieldValue(helpTicket)}
												</TableCell>
											))}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<DialogActions>
						<div className="flex flex-row">
							<div className={"grow"} />
							<CustomButton
								variant={"notContained"}
								ariaLabel={""}
								onClick={() => {
									props.setDataGridOpen(false);
								}}
							>
								Cancel
							</CustomButton>
							<CustomButton
								variant={"contained"}
								ariaLabel={""}
								onClick={() => {
									props.setSaveDialogOpen(true);
								}}
							>
								Save
							</CustomButton>
						</div>
					</DialogActions>
				</Paper>
			</Box>
		</div>
	);
};

export default ReportsDataGridForm;
