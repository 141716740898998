import env from "./env";
import localConfig from "./localconfig";

const app: AppConfig = {
	version: "0.0.1",
	build: "1",
};

export const FILE_SIZE_UPLOAD_LIMIT: number = 25000000;

const configs: Configs = {
	app: app,
	legalLink: `https://www.follettdestiny.com/_files/fsc/file/cms/DestinyEndUserTerm.pdf`,
	env: {
		dlx: {
			env: "dlx",
			helpdesk_backend_url: "https://dlx-helpdeskapi.follettsoftware.com/helpdeskapi/tenants",
			helpdesk_host: "https://dlx-helpdesk.follettdestiny.com",
		},
		dly: {
			env: "dly",
			helpdesk_backend_url: "https://dly-helpdeskapi.follettsoftware.com/helpdeskapi/tenants",
			helpdesk_host: "https://dly-helpdesk.follettdestiny.com",
		},
		qat: {
			env: "qat",
			helpdesk_backend_url: "https://qat-helpdeskapi.follettsoftware.com/helpdeskapi/tenants",
			helpdesk_host: "https://qat-helpdesk.follettdestiny.com",
		},
		prd: {
			env: "prd",
			helpdesk_backend_url: "https://helpdeskapi.follettsoftware.com/helpdeskapi/tenants",

			helpdesk_host: "https://helpdesk.follettdestiny.com",
		},
		local: localConfig,
	},
};

export function currentConfig(): EnvironmentConfig {
	return configs.env[env];
}
export default configs;
