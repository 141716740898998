import CloseIcon from "@mui/icons-material/Close";
import {
	Button,
	ButtonGroup,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormLabel,
	IconButton,
	Paper,
	useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import _ from "lodash";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hook";
import { blankDevice } from "../../models/device.model";
import { Form } from "../../models/forms.model";
import HelpTicketFull, { blankResolution, CreateHelpTicket, IssueType, Resolution } from "../../models/helpticket.model";
import { blankHelpTicketForm, HelpTicketForm } from "../../models/helpTicketForm.model";
import { resetHelpTicketForm, selectHelpTicketForm, setHelpTicketForm } from "../../slices/HelpTicketFormSlice";
import {
	useCreateHelpTicketMutation,
	useGetFormsForResourceQuery,
	useHelpTicketQuery,
	useResolutionsQuery,
	useUploadFileMutation,
} from "../../utilities/apiApi";
import { getTicketPriority, getTicketStatus, getTicketStatusIconBlack, helpTicketForm2Create } from "../../utilities/helpTicketUtils";
import CommonProgress from "../CommonProgress/CommonProgress";
import CustomButton from "../CustomButton/CustomButton";
import { validateCustomFieldTypes } from "../CustomField/CustomField";
import NewTicketCategoryForm from "../NewTicketCategoryForm/NewTicketCategoryForm";
import NewTicketStepFourForm from "../NewTicketStepFourForm/NewTicketStepFourForm";
import NewTicketStepThreeForm from "../NewTicketStepThreeForm/NewTicketStepThreeForm";
import NewTicketStepTwoForm from "../NewTicketStepTwoForm/NewTicketStepTwoForm";
import { notify } from "../Notification/Notification";

const steps = [
	{
		title: "Issue Category / Type",
		description: "Specify a category / type suiting the issue best",
	},
	{
		title: "User and Item",
		description: "Identify the user and the specific item",
	},
	{
		title: "Description",
		description: "Write a clear and concise description of the problem",
	},
	{
		title: "Details",
		description: "Define additional data fields",
	},
];

const headers = ["Specify an Issue Category", "Select the User and Item", "Description", "Details", "Step 5"];

const FormStep: FC<{ children: ReactNode }> = (props: { children: ReactNode }) => {
	return <Paper elevation={3}>{props.children}</Paper>;
};
const renderStep1: FC<{ stepIndex: number; ht: HelpTicketForm }> = (props) => {
	if (props.ht.categoryId === blankHelpTicketForm.categoryId && props.ht.issueTypeId === blankHelpTicketForm.issueTypeId) {
		return <p>{steps[0].description}</p>;
	} else {
		return (
			<div>
				{props.ht.categoryId !== blankHelpTicketForm.categoryId && <span className="text_bold">{props.ht.categoryName}</span>}
				{props.ht.issueTypeId !== blankHelpTicketForm.issueTypeId && <span className="text_bold"> : {props.ht.issueTypeName}</span>}
			</div>
		);
	}
};
const renderStep2: FC<{ stepIndex: number; ht: HelpTicketForm }> = (props) => {
	if (props.ht.forWhoId === blankHelpTicketForm.forWhoId && props.ht.item.resource.name === blankHelpTicketForm.item.resource.name) {
		return <p>{steps[1].description}</p>;
	} else {
		return (
			<div>
				{props.ht.forWhoName !== blankHelpTicketForm.forWhoName && <span className="text_bold">{props.ht.forWhoName}</span>}
				{props.ht.item.resource.name !== blankHelpTicketForm.item.resource.name && <span className="text_bold"> : {props.ht.item.resource.name}</span>}
			</div>
		);
	}
};
const renderStep3: FC<{ stepIndex: number; ht: HelpTicketForm }> = (props) => {
	return <p>{steps[2].description}</p>;
};
const renderStep4: FC<{ stepIndex: number; ht: HelpTicketForm }> = (props) => {
	return <p>{steps[3].description}</p>;
};
const renderStep: React.FC<{
	stepIndex: number;
	ht: HelpTicketForm;
}>[] = [renderStep1, renderStep2, renderStep3, renderStep4];

export interface NewTicketFullProps {
	setPageMode: (value: string) => void;
}

const AgentTicketHeader: FC<{ text: string }> = (props) => {
	return <div className={"font-rubik-bold text-2xl my-4"}>{props.text} </div>;
};

const NewTicketFull: FC<NewTicketFullProps> = (props) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [stepIndex, setStepIndex] = useState(0);
	const [saveDisabled, setSaveDisabled] = useState(false);
	const { register, handleSubmit } = useForm();
	const [isError, setIsError] = useState(false);
	const [isStep2Error, setIsStep2Error] = useState(false);
	const [isStep3Error, setIsStep3Error] = useState(false);
	const [isStep4Error, setIsStep4Error] = useState(false);
	const [hasCustomFieldError, setHasCustomFieldError] = useState(false);
	const [cancelDialog, setCancelDialog] = useState(false);
	const [selectedResolution, setSelectedResolution]: [Resolution, (value: Resolution) => void] = useState(blankResolution);
	const [descriptionError, setDescriptionError] = useState(undefined);
	const [resolutionError, setResolutionError] = useState(undefined);
	const [createDisabled, setCreateDisabled] = useState(false);
	const [priorityUnset, setPriorityUnset] = useState(false);
	const [statusUnset, setStatusUnset] = useState(false);
	const [createHelpTicket, updateResult] = useCreateHelpTicketMutation();
	const [uploadFile, uploadFileResult] = useUploadFileMutation();
	let helpTicketFull: HelpTicketFull | undefined = undefined;
	const [errorMessages, setErrorMessages] = useState(undefined);
	const [helpTicketGUID, setHelpTicketGUID] = useState();
	const [waitCount, setWaitCount] = useState(0);
	const helpTicketForm = useAppSelector(selectHelpTicketForm);
	let hasSpecial = false; // when we have additional fields customer configured.
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
	let customForms: Form[] = [];
	let resolutionsQuery: any = useResolutionsQuery();
	let resolutions: Array<Resolution> = [];
	if (resolutionsQuery.isError) {
		resolutions = [];
	}
	if (resolutionsQuery.isSuccess) {
		resolutions = resolutionsQuery.data;
	}
	// useEffect(() => {
	// 	if (updateResult) {
	// 		if (updateResult.isSuccess) {
	// 			setHelpTicketGUID(updateResult.data.id as any);
	// 		}
	// 		if (updateResult.isError) {
	// 			notify("Error", "Failed to create the ticket", true);
	// 			// console.log("Error creating Ticket: " + JSON.stringify(updateResult.error));
	// 			// console.log("..." + JSON.stringify(updateResult.data));
	// 			// navigate to landing page
	// 			props.setPageMode("Home");
	// 		}
	// 	}
	// }, [updateResult]);

	const onSubmit = (data: any) => {
		// console.log(data);
	};

	function updateHelpTicketForm(value: any) {
		let ht = { ...helpTicketForm, ...value };
		dispatch(setHelpTicketForm(ht));
	}

	function step1Validated() {
		if (
			helpTicketForm.categoryId === blankHelpTicketForm.categoryId ||
			helpTicketForm.categoryName === blankHelpTicketForm.categoryName ||
			(helpTicketForm.issueTypeRequired &&
				(helpTicketForm.issueTypeId === blankHelpTicketForm.issueTypeId || helpTicketForm.issueTypeName === blankHelpTicketForm.issueTypeName))
		) {
			return false;
		}
		return true;
	}

	function step2Validated() {
		if (helpTicketForm.forWhoId === blankHelpTicketForm.forWhoId || _.isNil(helpTicketForm.item.resource.guid)) {
			return false;
		}
		if (helpTicketForm.trackResponsible && helpTicketForm.responsibleUserId === blankHelpTicketForm.responsibleUserId) {
			return false;
		}
		return true;
	}

	function step3Validated() {
		if (
			helpTicketForm.summary === blankHelpTicketForm.summary ||
			helpTicketForm.details === blankHelpTicketForm.details ||
			helpTicketForm.details === blankHelpTicketForm.details
		) {
			return false;
		}
		return true;
	}
	function step4Validated() {
		let errMessages = validateCustomFieldTypes(customForms, helpTicketForm.fieldValues);
		if (errMessages) {
			let retval: boolean = true;
			errMessages.forEach((item) => {
				if (item !== undefined) {
					retval = false;
				}
			});
			setErrorMessages(errMessages as any);
			return retval;
		} else {
			setErrorMessages(undefined);
			return true;
		}
	}

	function priorityValidated() {
		return helpTicketForm.priority === undefined ? false : true;
	}
	function statusValidated() {
		return helpTicketForm.status === undefined ? false : true;
	}
	function resolutionValidated() {
		if (helpTicketForm.status && helpTicketForm.status.name === "Closed") {
			return helpTicketForm.resolution !== undefined;
		}
		return true;
	}
	function resolutionDescriptionValidated() {
		if (helpTicketForm.status && helpTicketForm.status.name === "Closed") {
			return !(helpTicketForm.resolutionDescription === undefined || helpTicketForm.resolutionDescription === "");
		}
		return true;
	}

	function goNext() {}

	function getResourceGUID(form: HelpTicketForm): string | undefined {
		return _.get(form, "item.resource.resourceType.guid", undefined);
	}
	function getIssueTypeId(form: HelpTicketForm): number | undefined {
		return _.get(form, "issueTypeId", undefined);
	}
	function getCategoryId(form: HelpTicketForm): number | undefined {
		return _.get(form, "categoryId", undefined);
	}

	let formsResult: any = useGetFormsForResourceQuery(
		{ resourceTypeGUID: getResourceGUID(helpTicketForm) },
		{ skip: getResourceGUID(helpTicketForm) === undefined ? true : false },
	);
	if (formsResult.isSuccess) {
		customForms = getFormFields();
	}

	let response = useHelpTicketQuery(
		{
			helpTicketGUID: helpTicketGUID || "",
		},
		{ skip: helpTicketGUID === undefined },
	);
	if (response.isSuccess) {
		// helpTicketFull = response.data as any;
		let ht: HelpTicketFull = response.data || {
			ticketNumber: "",
			item: _.cloneDeep(blankDevice),
		};
		let buffer = `Ticket #${ht.ticketNumber} is now in the queue `;
		buffer = buffer + `for ${_.get(ht, "item.resource.name", "N/A")} (Item barcode: ${_.get(ht, "item.barcode", "Unknown item")}.`;
		buffer = buffer + " An email will be sent to you shortly with more information.";
		notify("New Ticket added!", buffer, false);
		props.setPageMode("Home");
	}
	if (response.isError) {
		notify("New Ticket added!", "", false);
		props.setPageMode("Home");
	}
	useEffect(() => {
		if (formsResult.isSuccess) {
			customForms = getFormFields();
		}
	}, [helpTicketForm.categoryId, helpTicketForm.issueTypeId]);

	// useEffect(() => {
	// 	if (helpTicketFull) {
	// 		let ht: HelpTicketFull = helpTicketFull || {
	// 			ticketNumber: "",
	// 			item: _.cloneDeep(blankDevice),
	// 		};
	// 		let buffer = `Ticket #${ht.ticketNumber} is now in the queue `;
	// 		buffer = buffer + `for ${_.get(ht, "item.resource.name", "N/A")} (Item barcode: ${_.get(ht, "item.barcode", "Unknown item")}.`;
	// 		buffer = buffer + " An email will be sent to you shortly with more information.";
	// 		notify("New Ticket added!", buffer, false);
	// 		props.setPageMode("Home");
	// 	}
	// }, [helpTicketFull]);

	if (response.isLoading) {
		return <CommonProgress />;
	}

	function getFormFields() {
		let id = getIssueTypeId(helpTicketForm);
		let cId = getCategoryId(helpTicketForm);
		let compareArray: number[] = [];
		if (id !== undefined) {
			compareArray.push(id);
		}
		if (cId !== undefined) {
			compareArray.push(cId);
		}
		let forms: Form[] = formsResult.data;
		let retval: Form[] = [];
		forms.forEach((f, idx) => {
			let found: IssueType | undefined = _.find(f.issueTypes, (item) =>
				item.helpTicketIssueTypeID ? compareArray.includes(item.helpTicketIssueTypeID) : false,
			);
			if (found) {
				retval.push(f);
			}
		});
		return retval;
	}

	function getResolutionName(name: string) {
		switch (name) {
			case "CANNOT_REPRODUCE":
				return "Cannot reproduce";
			case "DUPLICATE":
				return "Duplicate";
			case "NON_RESOLVABLE":
				return "Non-resolvable";
			case "OTHER":
				return "Other";
			default:
				return name;
		}
	}

	return (
		<>
			{formsResult.isLoading && <CommonProgress zIndex={2000} message="Loading Custom Fields...." />}
			{createDisabled && <CommonProgress />}
			<div className={"flex justify-center"}>
				<div data-testid="NewTicketFull" className={"px-4 lg:px-12   lg:w-fit"}>
					<div className={"mb-4"}>
						<AgentTicketHeader text={"Create a New Ticket"} />
					</div>
					<div className={"max-w-[720px] bg-white drop-shadow-[0_2px_12px_rgba(138,146,163,0.28)] rounded-tl-[8px] rounded-tr-[8px] p-8"}>
						<div className={"flex flex-col"}>
							<form onSubmit={handleSubmit(onSubmit)} className={"flex flex-col"}>
								<div className={"mb-6"}>
									<div className={"text-altblack text-xl mb-6"}>Issue Category</div>
									<NewTicketCategoryForm onNext={goNext} hasError={isError} />
								</div>
								<div className={"mb-2"}>
									<div className={"border-t-[#D3D3D4] border mb-2"} />
									<div className={"text-altblack text-xl mb-6"}>User and Item</div>
									<NewTicketStepTwoForm onNext={goNext} hasError={isStep2Error} setHasError={setIsStep2Error} />
								</div>
								<div className={"mb-4"}>
									<div className={"border-t-[#D3D3D4] border mb-2"} />
									<div className={"text-altblack text-xl mb-6"}>Description</div>
									<NewTicketStepThreeForm onNext={goNext} hasError={isStep3Error} setHasError={setIsStep3Error} />
								</div>
								{customForms && customForms.length > 0 && (
									<div className={"w-full mb-8"}>
										<div className="flex flex-col">
											<div className={"border-t-[#D3D3D4] border mb-2"} />
											<div className={"text-altblack text-xl mb-6"}>Additional Details</div>
											<NewTicketStepFourForm
												customForms={customForms}
												onNext={goNext}
												onError={(value) => {
													setHasCustomFieldError(value);
												}}
												errorMessages={errorMessages || []}
											/>
										</div>
									</div>
								)}
								<FormControl className="block">
									<FormLabel id="ticket-priority-label" className="mb-0.5 block text-altblack font-rubik text-l">
										Priority
									</FormLabel>
									<ButtonGroup
										className="flex-wrap"
										size="medium"
										aria-label="Priority button group"
										sx={{
											backgroundColor: priorityUnset ? "#FFF7F7 !important" : "",
											border: priorityUnset ? "1px solid #B43523 !important" : "",
											width: "fit-content",
										}}
									>
										<Button
											variant={"outlined"}
											className={
												helpTicketForm.priority && helpTicketForm.priority.id === 0
													? "bg-[#01338D]/10 hover:bg-[#01338D]/10"
													: "bg-white"
											}
											onClick={() => {
												updateHelpTicketForm({
													priority: getTicketPriority(0),
												});
												setPriorityUnset(false);
											}}
											sx={{
												borderColor:
													helpTicketForm.priority && helpTicketForm.priority.id === 0 ? "#01338D !important" : "#D3D3D4 !important",
												borderRadius: "4px",
												marginRight: "1px",
												backgroundColor: priorityUnset ? "#FFF7F7 !important" : "",
											}}
											disableRipple={true}
										>
											<span className={"w-[100px] text-altblack"}>Low</span>
										</Button>
										<Button
											variant={"outlined"}
											className={
												helpTicketForm.priority && helpTicketForm.priority.id === 1
													? "bg-[#01338D]/10 hover:bg-[#01338D]/10"
													: "bg-white"
											}
											onClick={() => {
												updateHelpTicketForm({
													priority: getTicketPriority(1),
												});
												setPriorityUnset(false);
											}}
											sx={{
												borderColor:
													helpTicketForm.priority && helpTicketForm.priority.id === 1 ? "#01338D !important" : "#D3D3D4 !important",
												borderRadius: "4px",
												marginRight: "1px",
												backgroundColor: priorityUnset ? "#FFF7F7 !important" : "",
											}}
											disableRipple={true}
										>
											<span className={"w-[100px] text-altblack"}>Medium</span>
										</Button>
										<Button
											variant={"outlined"}
											className={
												helpTicketForm.priority && helpTicketForm.priority.id === 2
													? "bg-[#01338D]/10 hover:bg-[#01338D]/10"
													: "bg-white"
											}
											onClick={() => {
												updateHelpTicketForm({
													priority: getTicketPriority(2),
												});
												setPriorityUnset(false);
											}}
											sx={{
												borderColor:
													helpTicketForm.priority && helpTicketForm.priority.id === 2 ? "#01338D !important" : "#D3D3D4 !important",
												borderRadius: "4px",
												marginRight: "1px",
												backgroundColor: priorityUnset ? "#FFF7F7 !important" : "",
											}}
											disableRipple={true}
										>
											<span className={"w-[100px] text-altblack"}>High</span>
										</Button>
									</ButtonGroup>
									{priorityUnset && <div className={"mb-0.5 font-rubik   text-error-text"}>Please provide a priority</div>}
								</FormControl>
								<FormControl className="mt-8">
									<FormLabel id="ticket-status-label" className="block mb-0.5 text-altblack font-rubik text-l">
										Status
									</FormLabel>
									<ButtonGroup
										className="flex-wrap"
										size="medium"
										aria-label="Status button group"
										sx={{
											backgroundColor: statusUnset ? "#FFF7F7 !important" : "",
											border: statusUnset ? "1px solid #B43523 !important" : "",
											width: "fit-content",
										}}
									>
										<Button
											variant={"outlined"}
											className={
												helpTicketForm.status && helpTicketForm.status.name === "Open"
													? "bg-[#01338D]/10 hover:bg-[#01338D]/10"
													: "bg-white"
											}
											onClick={() => {
												updateHelpTicketForm({
													status: getTicketStatus("Open"),
												});
												setStatusUnset(false);
											}}
											sx={{
												borderColor:
													helpTicketForm.status && helpTicketForm.status.name === "Open"
														? "#01338D !important"
														: "#D3D3D4 !important",
												borderRadius: "4px",
												marginRight: "1px",
												backgroundColor: statusUnset ? "#FFF7F7 !important" : "",
											}}
											disableRipple={true}
										>
											<div className="flex flex-row align-items w-[125px] text-altblack">
												<img src={getTicketStatusIconBlack("Open")} />
												<span className="pl-2">Open</span>
											</div>
										</Button>
										<Button
											variant={"outlined"}
											className={
												helpTicketForm.status && helpTicketForm.status.name === "In Progress"
													? "bg-[#01338D]/10 hover:bg-[#01338D]/10"
													: "bg-white"
											}
											onClick={() => {
												updateHelpTicketForm({
													status: getTicketStatus("InProgress"),
												});
												setStatusUnset(false);
											}}
											sx={{
												borderColor:
													helpTicketForm.status && helpTicketForm.status.name === "In Progress"
														? "#01338D !important"
														: "#D3D3D4 !important",
												borderRadius: "4px",
												marginRight: "1px",
												backgroundColor: statusUnset ? "#FFF7F7 !important" : "",
											}}
											disableRipple={true}
										>
											<div className="flex flex-row align-items w-[125px] text-altblack">
												<img src={getTicketStatusIconBlack("InProgress")} />
												<span className="pl-2">In Progress</span>
											</div>
										</Button>
										<Button
											variant={"outlined"}
											className={
												helpTicketForm.status && helpTicketForm.status.name === "On Hold"
													? "bg-[#01338D]/10 hover:bg-[#01338D]/10"
													: "bg-white"
											}
											onClick={() => {
												updateHelpTicketForm({
													status: getTicketStatus("OnHold"),
												});
												setStatusUnset(false);
											}}
											sx={{
												borderColor:
													helpTicketForm.status && helpTicketForm.status.name === "On Hold"
														? "#01338D !important"
														: "#D3D3D4 !important",
												borderRadius: "4px",
												marginRight: "1px",
												backgroundColor: statusUnset ? "#FFF7F7 !important" : "",
											}}
											disableRipple={true}
										>
											<div className="flex flex-row align-items w-[125px] text-altblack">
												<img src={getTicketStatusIconBlack("OnHold")} />
												<span className="pl-2">On Hold</span>
											</div>
										</Button>
										<Button
											variant={"outlined"}
											className={
												helpTicketForm.status && helpTicketForm.status.name === "Closed"
													? "bg-[#01338D]/10 hover:bg-[#01338D]/10"
													: "bg-white"
											}
											onClick={() => {
												updateHelpTicketForm({
													status: getTicketStatus("Closed"),
												});
												setStatusUnset(false);
											}}
											sx={{
												borderColor:
													helpTicketForm.status && helpTicketForm.status.name === "Closed"
														? "#01338D !important"
														: "#D3D3D4 !important",
												borderRadius: "4px",
												marginRight: "1px",
												backgroundColor: statusUnset ? "#FFF7F7 !important" : "",
											}}
											disableRipple={true}
										>
											<div className="flex flex-row align-items w-[125px] text-altblack">
												<img src={getTicketStatusIconBlack("Closed")} />
												<span className="pl-2">Closed</span>
											</div>
										</Button>
									</ButtonGroup>
									{statusUnset && <div className={"mb-2 font-rubik   text-error-text"}>Please provide a status</div>}
								</FormControl>
								{helpTicketForm.status && helpTicketForm.status.name === "Closed" && (
									<div className={"flex flex-col mt-6"}>
										<div className={"mb-2 font-rubik"}>Please provide a reason</div>
										<div className={"flex flex-wrap gap-1 mb-4"}>
											{resolutions.map((resolution) => {
												if (resolution.active) {
													return (
														<button
															value={resolution.helpTicketResolutionID}
															className={
																"rounded-[24px] text-primary border border-primary p-1 px-2 " +
																(helpTicketForm.resolution?.name === (resolution.name as string) ? "bg-primary text-white" : "")
															}
															onClick={() => {
																updateHelpTicketForm({
																	resolution: { ...resolution },
																});
																setResolutionError(undefined);
															}}
														>
															{getResolutionName(resolution.name)}
														</button>
													);
												}
											})}
										</div>
										<div className={"mb-2 font-rubik text-error-text"}>{resolutionError ? resolutionError : ""}</div>
										<div className={"flex flex-col w-3/4"}>
											<textarea
												placeholder={"Explain the resolution."}
												rows={6}
												value={helpTicketForm.resolution?.description}
												onChange={(event) => {
													if (event.target.value.length < 1001) {
														updateHelpTicketForm({
															resolutionDescription: event.target.value.substring(0, 1000),
														});
														if (event.target.value.length > 0) {
															setDescriptionError(undefined);
														} else {
															setDescriptionError("Please enter a description" as any);
														}
													}
												}}
												className={"border border-[#D3D3D4] rounded-[8px] p-2 mb-2"}
											></textarea>
											<div className={"mb-2 font-rubik text-error-text"}>{descriptionError ? descriptionError : ""}</div>
										</div>
									</div>
								)}
							</form>
						</div>
					</div>
					<div
						className={
							"flex flex-row items-center h-[5rem] 2xl:h-[7vh] px-4 bg-white drop-shadow-[0_2px_8px_rgba(138,146,163,0.16)] rounded-bl-[8px] rounded-br-[8px]"
						}
					>
						<div className={"grow"}></div>
						<CustomButton
							onClick={() => {
								setCancelDialog(true);
							}}
							variant={"text"}
							ariaLabel="Cancel Ticket"
						>
							Cancel
						</CustomButton>
						<CustomButton
							onClick={async () => {
								setIsError(!step1Validated());
								setIsStep2Error(!step2Validated());
								setIsStep3Error(!step3Validated());
								setIsStep4Error(!step4Validated());
								setPriorityUnset(!priorityValidated());
								setStatusUnset(!statusValidated());
								setResolutionError((resolutionValidated() ? undefined : "Please select a resolution code") as any);
								setDescriptionError((resolutionDescriptionValidated() ? undefined : "Please enter a description") as any);
								if (
									step1Validated() &&
									step2Validated() &&
									step3Validated() &&
									step4Validated() &&
									priorityValidated() &&
									statusValidated() &&
									resolutionValidated() &&
									resolutionDescriptionValidated()
								) {
									setCreateDisabled(true);
									try {
										let helpTicketCreate: CreateHelpTicket = helpTicketForm2Create(helpTicketForm);
										let response: { id: string; name: string } = await createHelpTicket({ helpTicketCreate }).unwrap();

										if (helpTicketForm.addFiles && helpTicketForm.addFiles.length > 0) {
											let filesNotUploaded: string[] = [];
											for (let i = 0; i < helpTicketForm.addFiles.length; i++) {
												try {
													await uploadFile({ helpTicketGuid: response.id, file: helpTicketForm.addFiles[i] });
												} catch (error) {
													filesNotUploaded.push(helpTicketForm.addFiles[i].name);
													// console.log("failed to upload file: " + JSON.stringify(error));
												}
											}
											if (filesNotUploaded.length > 0) {
												let filesNotUploadedString: string = filesNotUploaded[0];
												if (filesNotUploaded.length > 1) {
													for (let i = 1; i < filesNotUploaded.length; i++) {
														filesNotUploadedString += ", " + filesNotUploaded[i];
													}
												}
												notify("Error", `Failed uploading the following files: ${filesNotUploadedString}`, true);
											}
										}
										setHelpTicketGUID(response.id as any);
									} catch (error) {
										notify("Error", "Failed to create the ticket", true);
										// console.log(`Failed creating the ticket: ${error}`);
									}
								} else {
									notify("Validation Failed", "Some fields are required or need correction", true, 3000);
								}
								setCreateDisabled(false);
							}}
							variant={"contained"}
							ariaLabel={"Save button"}
							disabled={createDisabled}
						>
							Create Ticket
						</CustomButton>
					</div>
				</div>
				<Dialog
					open={cancelDialog}
					sx={{
						"& .MuiPaper-root": {
							width: {
								xs: 300, // theme.breakpoints.up('xs')
								sm: 478, // theme.breakpoints.up('sm')
							},
							height: {
								xs: 300, // theme.breakpoints.up('xs')
								sm: 215, // theme.breakpoints.up('sm')
							},
							borderRadius: "8px",
						},
						"& .MuiDialogActions-root": {
							padding: "16px 24px",
						},
					}}
					fullScreen={fullScreen}
				>
					<DialogTitle>
						<div className={"flex items-center"}>
							<div className="font-2xl text-[#171646] grow font-rubik">Cancel Ticket Submission?</div>
							<IconButton
								aria-label={"Close"}
								onClick={() => {
									setCancelDialog(false);
								}}
								disableRipple={true}
							>
								<CloseIcon />
							</IconButton>
						</div>
					</DialogTitle>
					<DialogContent>
						<p className="text-base text-left text-[#171646] font-rubik">
							Are you sure you want to cancel this ticket?
							<br />
							Your will lose all progress made.
						</p>
					</DialogContent>
					<DialogActions>
						<div className={"grow"}></div>
						<div>
							<CustomButton
								onClick={(e: any) => {
									setCancelDialog(false);
								}}
								variant={"text"}
								ariaLabel="Continue with Ticket"
							>
								Continue Ticket
							</CustomButton>
						</div>
						<div>
							<CustomButton
								onClick={(e: any) => {
									setCancelDialog(false);
									dispatch(resetHelpTicketForm());
									props.setPageMode("Home");
									// navigate(-1); // go Back
								}}
								variant={"contained"}
								ariaLabel="Cancel Ticket"
								bgColor={"red"}
							>
								Cancel Ticket
							</CustomButton>
						</div>
					</DialogActions>
				</Dialog>
			</div>
		</>
	);
};

export default NewTicketFull;
