import React from "react";

import { authApi } from "./authApi";
import { portalInit } from "./portalSupport";

type IAuthMiddleware = {
	children: React.ReactElement;
};

const AuthMiddleware: React.FC<IAuthMiddleware> = ({ children }) => {
	// const [splashActive, setSplashActive] = useState(true);
	// useEffect(() => {
	// 	setTimeout(() => {
	// 		setSplashActive(false);
	// 	}, 3000);
	// }, []);
	const theSearch: any = location.search;
	portalInit(theSearch);
	let authCode = undefined;
	if (location && location.search && location.search.includes("authCode")) {
		// skip = false;
		const queryStr: string | undefined = location?.search;
		if (queryStr) {
			let query = queryStr.substring(1);
			let args = query.split("&");
			args.forEach((keyValue) => {
				if (keyValue.startsWith("authCode")) {
					authCode = keyValue.split("=")[1];
				}
			});
		}
	}
	// const [login, { isLoading, isSuccess, error, isError }] =
	//     useLoginQuery();
	let retval = authApi.endpoints.login.useQuery(authCode || "", {
		skip: authCode ? false : true,
	});

	// if (retval.isLoading || splashActive) {
	// 	return (
	// 		<div>
	// 			{splashActive && (
	// 				<SplashScreen
	// 					minTimeMillis={3000}
	// 					onComplete={() => {
	// 						setSplashActive(false);
	// 					}}
	// 				/>
	// 			)}
	// 		</div>
	// 	);
	// }
	// if (retval.isSuccess) {
	// 	// // console.log("From middleware: cookies: " + JSON.stringify(cookies));
	// 	setSkip(true);
	// }
	return children;
};

export default AuthMiddleware;
