import { createSlice } from "@reduxjs/toolkit";

import { HelpTicketsVO } from "../models/helpticket.model";
import { RootState } from "../store";

export interface HelpTicketInfo {
	helpTicketsVO: HelpTicketsVO;
}

// Define the initial state using that type
const initialState: HelpTicketInfo = {
	helpTicketsVO: { totalCount: 0, helpTickets: [] },
};

const helpTicketSlice = createSlice({
	name: "helpTicket",
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setHelpTickets: (state, payload) => {
			return { ...state, helpTickets: payload.payload };
		},
		// updateHelpTickets: (state, payload) => {
		// 	let idx = _.findIndex(state.helpTicketsVO.helpTickets, (item) => item.helpTicketGUID === (payload.payload as HelpTicketFull).helpTicketGUID);
		// 	if (idx >= 0) {
		// 		let helpTickets = _.cloneDeep(state.helpTicketsVO.helpTickets);
		// 		helpTickets.splice(idx, 1, payload.payload as HelpTicketFull);
		// 		return { ...state, helpTicketsVO : { totalCount: }};
		// 	}
		// },
		// appendHelpTickets: (state, payload) => {
		// 	return {
		// 		...state,
		// 		helpTicketsVO: state.helpTicketsVO.helpTickets.concat(payload.payload),
		// 	};
		// },
	},
});

export const selectHelpTickets = (state: RootState): HelpTicketsVO => state.helpTicket.helpTicketsVO;

// export const selectHelpTicketType = (state: RootState): HelpTicketType => state.helpTicket.createDate;
export const {
	setHelpTickets,
	// updateHelpTickets, appendHelpTickets
} = helpTicketSlice.actions;

export default helpTicketSlice.reducer;
