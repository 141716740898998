import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton } from "@mui/material";
import * as React from "react";
// import "./GeneralDialog.scss";
import { Close, ErrorOutlineOutlined } from "@mui/icons-material";
import CustomButton from "../CustomButton/CustomButton";

interface GenericDialogProps extends DialogProps {
	onConfirm: (event?: Event) => void;
	// onClose: (event: Event, reason: string) => void;
	nodeContent?: React.ReactNode;
	content?: string;
	confirmLabel?: string;
	cancelLabel?: string;
	showCancel?: boolean; // if not used then cancel is shown
	confirmColor?: string;
	isError?: boolean;
}

export default function GenericDialog(props: GenericDialogProps) {
	let changed = false;
	let showCancel = props.showCancel === undefined;
	return (
		<div>
			<Dialog open={props.open} sx={{ "& .MuiPaper-root": { minWidth: "20rem" } }}>
				<div className={"p-2"}>
					<DialogTitle sx={{ overflowWrap: "anywhere" }}>
						{props.isError ? (
							<div className={"flex flex-row items-center"}>
								<div className={"text-error-text"}>
									<ErrorOutlineOutlined sx={{ width: "2em", height: "2em", marginRight: "1rem" }} />
								</div>
								{props.title}
							</div>
						) : (
							<div>{props.title}</div>
						)}
					</DialogTitle>
					<IconButton
						aria-label="close"
						onClick={(e: any) => {
							if (props.onClose) {
								props.onClose(e, "backdropClick");
							}
						}}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
						disableRipple={true}
					>
						<Close />
					</IconButton>
					<DialogContent sx={{ overflowWrap: "anywhere" }}>
						{props.content && <p className="text-lg text-altblack">{props.content}</p>}
						{props.nodeContent && props.nodeContent}
					</DialogContent>
					<DialogActions>
						<div className="flex flex-row">
							<div className={"grow"} />
							{showCancel && (
								<CustomButton
									variant={"notcontained"}
									ariaLabel={""}
									onClick={(e: Event) => {
										if (props.onClose) {
											props.onClose(e, "backdropClick");
										}
									}}
								>
									{props.cancelLabel ? props.cancelLabel : "Cancel"}
								</CustomButton>
							)}
							<CustomButton
								variant={"contained"}
								ariaLabel={""}
								onClick={(e: Event) => {
									props.onConfirm(e);
									if (props.onClose) {
										props.onClose(e, "backdropClick");
									}
								}}
								bgColor={props.confirmColor && "red"}
							>
								{props.confirmLabel ? props.confirmLabel : "Ok"}
							</CustomButton>
						</div>
					</DialogActions>
				</div>
			</Dialog>
		</div>
	);
}
