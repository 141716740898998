import { createApi, FetchArgs } from "@reduxjs/toolkit/query/react";
import dayjs from "dayjs";
import _ from "lodash";
import { Device } from "../models/device.model";
import {
	HelpTicketEmailNotificationSettingsVO,
	HelpTicketEmailRecepientVO,
	HelpTicketInboundSMTPServerSettingsVO,
	HelpTicketOutboundSMTPServerSettingsVO,
	HelpTicketSMTPServerSettingsVO,
} from "../models/emailSMTP.model";
import { Field } from "../models/fields.model";
import { FilterForm, SortOption } from "../models/filterForm.model";
import { Form } from "../models/forms.model";
import { IdNamePair } from "../models/genericTypes.model";
import { Health } from "../models/health.model";
import {
	Category,
	CreateHelpTicket,
	default as HelpTicket,
	default as HelpTicketFull,
	DownloadAttachedFile,
	DownloadResponse,
	FetchImageResponse,
	HelpTicketAssigneesVO,
	HelpTicketsVO,
	HelpTicketUpdateVO,
	NumberNamePair,
	Resolution,
} from "../models/helpticket.model";
import { HelpTicketReport, Report, ReportFilter, ReportParams } from "../models/reports.model";
import { BriefResourceTypeVO } from "../models/resourceType.model";
import User from "../models/user.model";
import { getCurrentUserGuid, getDistrictAppId, getSiteGuid, setDateFormat, setFeatureFlags } from "./appContext";
import customFetchBase from "./customFetchBase";

const leadingPath = "tenants/${appId}/{category}/${userGuid}";
const getUrl = (category: string, endpath: string): string => {
	let appId: string | number | undefined = getDistrictAppId();
	return `${appId}/${category}/${endpath}`;
};

interface GetTicketParams {
	userGuid?: string;
	siteGuid?: string;
	top?: number;
	skip?: number;
	searchTerm: string;
	filter?: string[];
	orderBy?: string[];
}

const getTicketsParams = (
	searchTerm: string,
	top: number = 100,
	pageNumber: number = 0,
	orderByFields: SortOption[],
	filterFields: FilterForm,
): GetTicketParams => {
	const orderLookup: { [key: string]: string } = {
		status: "StatusSortKey",
		priority: "PrioritySortKey",
		dateUpdated: "DateUpdated",
		dateCreated: "DateCreated",
	};
	let userGuid: string | undefined = getCurrentUserGuid();
	let siteGuid: string | undefined = getSiteGuid();

	let retval: GetTicketParams = { top, skip: pageNumber * top, searchTerm };
	if (userGuid) {
		retval = { ...retval, userGuid };
	}
	if (siteGuid) {
		retval = { ...retval, siteGuid };
	}
	// order supported from tests in destiny api
	// TODO  need sort by summary, and servicingTeam
	/// "StatusSortKey:ASC"
	//     "PrioritySortKey:DESC"
	//     "DateCreated:DESC"
	//     "DateUpdated:DESC"
	if (orderByFields && orderByFields.length > 0) {
		let orderBy: string[] = [];
		orderByFields.forEach((item) => {
			orderBy.push(`${orderLookup[item.orderBy]}:${item.order.toUpperCase()}`);
		});
		retval = { ...retval, orderBy };
	}
	// Filters allowed
	//      "DateCreated"
	//      "DateCompleted"
	//      "StatusName"
	//      "IssueTypeID"
	//      "CategoryIssueTypeID"
	//      "ResourceTypeGUID"
	//      "SiteGUID"
	if (filterFields) {
		const { statuses, resourceType, site, completedDate, createdDate } = filterFields;
		let filter: string[] = [];
		if (statuses) {
			if (statuses.open) {
				filter.push("StatusName:Open");
			}
			if (statuses.onHold) {
				filter.push("StatusName:OnHold"); // swagger says Pending?
			}
			if (statuses.closed) {
				filter.push("StatusName:Closed");
			}
			// if (statuses.inProgress) {
			// 	filter.push("StatusName:InProgress");
			// }
		}
		if (resourceType && resourceType.length > 0) {
			for (const rType of resourceType) {
				filter.push(`ResourceTypeGUID:${rType}`);
			}
		}
		if (site && site.length > 0) {
			for (const sGUID of site) {
				filter.push(`SiteGUID:${sGUID}`);
			}
		}
		if (createdDate && createdDate.from && createdDate.from !== "") {
			filter.push(`DateCreated:${dayjs(createdDate.from).format("YYYY-MM-DD")}`);
		}
		if (createdDate && createdDate.to && createdDate.to !== "") {
			filter.push(`DateCreated:${dayjs(createdDate.to).format("YYYY-MM-DD")}`);
		}
		if (completedDate && completedDate.from && completedDate.from !== "") {
			filter.push(`DateCompleted=${dayjs(completedDate.from).format("YYYY-MM-DD")}`);
		}
		if (completedDate && completedDate.to && completedDate.to !== "") {
			filter.push(`DateCompleted=${dayjs(createdDate.to).format("YYYY-MM-DD")}`);
		}

		if (filter.length > 0) {
			retval = { ...retval, filter };
		}
	}
	return retval;
};

function logApiCall(method: string, url: string) {
	// console.log(`method: ${method} > ${url}`);
}

export const apiApi: any = createApi({
	reducerPath: "api",
	baseQuery: customFetchBase,
	tagTypes: [
		"HelpDesk",
		"HelpTickets",
		"HelpTicketsCount",
		"HelpTicket",
		"Categories",
		"Category",
		"Fields",
		"Field",
		"Form",
		"Forms",
		"Devices",
		"Report",
		"Reports",
		"ResourceForms",
		"Resolution",
		"Resolutions",
		"Upload",
		"SMTPServerSettings",
		"EmailNotificationSettings",
		"EmailNotificationSetting",
	],
	endpoints: (build) => ({
		assignees: build.query<HelpTicketAssigneesVO, { resourceGUID: string; personGUID: string }>({
			// todo add filtering
			query({ resourceGUID, personGUID }) {
				return {
					url: getUrl("helptickets", `resources/${resourceGUID}/assignees`),
					params: { personGUID },
				};
			},
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					// console.log(JSON.stringify(data));
				} catch (error) {}
			},
		}),
		categories: build.query<Array<Category>, void>({
			// todo add filtering
			query() {
				return {
					url: getUrl("helptickets", "categories"),
				};
			},
			transformResponse: (responseData: Array<Category>) => {
				let data = _.sortBy(responseData, [(item) => !item.active, "name"]);
				return data;
			},
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					// console.log(JSON.stringify(data));
				} catch (error) {}
			},
			providesTags: ["Categories"],
		}),
		category: build.query<Array<Category>, { categoryId: string }>({
			// todo add filtering
			query({ categoryId }) {
				return {
					url: getUrl("helptickets", `categories/${categoryId}`),
				};
			},
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					// console.log(JSON.stringify(data));
				} catch (error) {}
			},
			providesTags: ["Category"],
		}),
		updateCategory: build.mutation<Category, { category: Category }>({
			query({ category }) {
				if (category.helpTicketIssueTypeID) {
					return {
						url: getUrl("helptickets", `categories/category/${category.helpTicketIssueTypeID}`),
						method: "PUT",
						body: category,
					};
				} else {
					return {
						url: getUrl("helptickets", `categories/category`),
						method: "POST",
						body: category,
					};
				}
			},
			invalidatesTags: ["Categories", "Category"],
		}),
		deleteCategory: build.mutation<Category, { category: Category }>({
			query({ category }) {
				return {
					url: getUrl("helptickets", `categories/category/${category.helpTicketIssueTypeID}`),
					method: "DELETE",
				};
			},
			invalidatesTags: ["Categories", "Category"],
		}),
		updateCategories: build.mutation<Category, { categories: Array<Category> }>({
			query({ categories }) {
				return {
					url: getUrl("helptickets", `categories`),
					method: "PUT",
					body: categories,
				};
			},
			invalidatesTags: ["Categories", "Category"],
		}), // FIELDS
		resolutions: build.query<Array<Resolution>, void>({
			query() {
				return {
					url: getUrl("helptickets", "resolutions"),
				};
			},
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					// console.log(JSON.stringify(data));
				} catch (error) {}
			},
			providesTags: ["Resolutions"],
		}),
		createResolution: build.mutation<Resolution, { resolution: Resolution }>({
			query({ resolution }) {
				return {
					url: getUrl("helptickets", `resolution`),
					method: "POST",
					body: resolution,
				};
			},
			invalidatesTags: ["Resolution", "Resolutions"],
		}),
		updateResolution: build.mutation<Resolution, { resolution: Resolution }>({
			query({ resolution }) {
				return {
					url: getUrl("helptickets", `resolution/${resolution.helpTicketResolutionID}`),
					method: "PUT",
					body: resolution,
					transformResponse: (responseData: void) => {
						return resolution.helpTicketResolutionID;
					},
				};
			},
			invalidatesTags: ["Resolution", "Resolutions"],
		}),
		deleteResolution: build.mutation<number, { helpTicketResolutionID: number }>({
			query({ helpTicketResolutionID }) {
				return {
					url: getUrl("helptickets", `resolution/${helpTicketResolutionID}`),
					method: "DELETE",
					transformResponse: (responseData: void) => {
						return helpTicketResolutionID;
					},
				};
			},
			invalidatesTags: ["Resolution", "Resolutions"],
		}),
		forms: build.query<Array<Form>, void>({
			query() {
				return {
					url: getUrl("helptickets", "forms"),
				};
			},
			transformResponse: (responseData: Array<Form>) => {
				return _.sortBy(responseData, [(item) => !item.active, "name"]);
			},
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					// console.log(JSON.stringify(data));
				} catch (error) {}
			},
			providesTags: ["Forms"],
		}),
		form: build.query<Form, { formId: string }>({
			query({ formId: formId }) {
				return {
					url: getUrl("helptickets", `forms/form/${formId}`),
				};
			},
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					// console.log(JSON.stringify(data));
				} catch (error) {}
			},
			providesTags: ["Form"],
		}),
		getFormsForResource: build.query<Form, { resourceTypeGUID: string }>({
			query({ resourceTypeGUID }) {
				return {
					url: getUrl("helptickets", `forms/resource/${resourceTypeGUID}`),
				};
			},
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					// console.log(JSON.stringify(data));
				} catch (error) {}
			},
			providesTags: ["ResourceForms"],
		}),
		upsertForm: build.mutation<number, { form: Form }>({
			query({ form }) {
				if (form.helpTicketFormID) {
					let id = form.helpTicketFormID;
					return {
						url: getUrl("helptickets", `forms/form/${id}`),
						method: "PUT",
						body: form,
						transformResponse: (responseData: void) => {
							return id;
						},
					};
				} else {
					return {
						url: getUrl("helptickets", `forms/form`),
						method: "POST",
						body: form,
					};
				}
			},
			invalidatesTags: ["Forms", "Form"],
		}),
		deleteForm: build.mutation<Form, { form: Form }>({
			query({ form }) {
				return {
					url: getUrl("helptickets", `forms/form/${form.helpTicketFormID}`),
					method: "DELETE",
				};
			},
			invalidatesTags: ["Forms", "Form"],
		}),
		fields: build.query<Array<Field>, void>({
			query() {
				return {
					url: getUrl("helptickets", "fields"),
				};
			},
			transformResponse: (responseData: Array<Field>) => {
				let data = _.sortBy(responseData, [(item) => !item.active, "name"]);
				return data;
			},
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					// console.log(JSON.stringify(data));
				} catch (error) {}
			},
			providesTags: ["Fields"],
		}),
		field: build.query<Field, { fieldId: string }>({
			// todo add filtering
			query({ fieldId }) {
				return {
					url: getUrl("helptickets", `fields/field/${fieldId}`),
				};
			},
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					// console.log(JSON.stringify(data));
				} catch (error) {}
			},
			providesTags: ["Field"],
		}),
		upsertField: build.mutation<number, { field: Field }>({
			query({ field }) {
				let id = field.helpTicketFieldID;
				if (field.helpTicketFieldID) {
					return {
						url: getUrl("helptickets", `fields/field/${id}`),
						method: "PUT",
						body: field,
						transformResponse: (responseData: void) => {
							return id;
						},
					};
				} else {
					return {
						url: getUrl("helptickets", `fields/field`),
						method: "POST",
						body: field,
					};
				}
			},
			invalidatesTags: ["Fields", "Field", "Forms", "Form"],
		}),
		deleteField: build.mutation<Field, { field: Field }>({
			query({ field }) {
				return {
					url: getUrl("helptickets", `fields/field/${field.helpTicketFieldID}`),
					method: "DELETE",
				};
			},
			invalidatesTags: ["Fields", "Field", "Forms", "Form"],
		}),
		userList: build.query<Array<User>, void>({
			query() {
				return {
					// these are specific to `fetchBaseQuery`
					url: "user",
					params: { limit: 9 },
					// all the different arguments that you could also pass into the `fetch` "init" option
					// see https://developer.mozilla.org/en-US/docs/Web/API/fetch#parameters
					method: "GET", // GET is the default, this could be skipped
				};
			},
		}),
		user: build.query<User, { id: string }>({
			query({ id }) {
				let userGuid: string | number | undefined = getCurrentUserGuid();
				return {
					url: getUrl("helptickets", `user/${id}`),
				};
			},
		}),
		healthStatus: build.query<Health, void>({
			// this may not belong here since it may not need auth
			query() {
				return {
					method: "GET",
					url: `health`,
				};
			},
		}),
		helpTicket: build.query<HelpTicketFull, { helpTicketGUID: string }>({
			query({ helpTicketGUID }) {
				let siteGuid: string | number | undefined = getSiteGuid();

				return {
					url: getUrl("helptickets", `helpticket/${helpTicketGUID.toUpperCase()}`),
					params: { siteGUID: siteGuid },
				};
			},
			providesTags: ["HelpTicket"],
		}),
		createHelpTicket: build.mutation<IdNamePair, { helpTicketCreate: CreateHelpTicket }>({
			query({ helpTicketCreate }) {
				let siteGuid: string | number | undefined = getSiteGuid();
				return {
					url: getUrl("helptickets", "helpticket"),
					method: "POST",
					body: helpTicketCreate,
				};
			},
			transformResponse: (response: IdNamePair) => {
				let newId: string = "" + response.id;
				response.id = newId.toUpperCase();
				return response;
			},
			invalidatesTags: ["HelpTickets", "HelpTicket", "HelpTicketsCount"],
		}),
		updateHelpTicket: build.mutation<
			void,
			{
				update: HelpTicketUpdateVO;
			}
		>({
			query({ update }) {
				let siteGuid: string | number | undefined = getSiteGuid();
				let helpTicketGUID = update.helpTicketGUID;
				return {
					url: getUrl("helptickets", `helpticket/${helpTicketGUID}`),
					method: "PUT",
					body: update,
				};
			},
			invalidatesTags: ["HelpTickets", "HelpTicket"],
		}),
		assignedPatronHelpTickets: build.query<Array<HelpTicket>, { userId: string }>({
			// todo add filtering
			query({ userId }) {
				let userGuid: string | number | undefined = getCurrentUserGuid();
				logApiCall("assignedPatronHelpTickets", getUrl("helptickets", `assigneduser/${userId}`));
				return {
					url: getUrl("helptickets", `assigneduser/${userId}`),
				};
			},
		}),
		requestedMyHelpTicketsFull: build.query<
			HelpTicketsVO,
			{
				searchTerm: string;
				pageSize: number;
				pageNumber: number;
				sort: Array<SortOption>;
				filter: FilterForm;
			}
		>({
			// todo add filtering
			query({ searchTerm, pageSize = 10, pageNumber = 0, sort, filter }) {
				// only get one sort column but we want the dateUpdate to be secondary sort
				// unless it is selected
				let retval: FetchArgs = {
					url: getUrl("helptickets", `loggedinuser`),
					params: getTicketsParams(searchTerm, pageSize, pageNumber, sort, filter),
				};
				logApiCall("requestedMyHelpTicketsFull", retval.url + JSON.stringify(retval.params));
				return retval;
			},
			providesTags: ["HelpTickets", "HelpTicket"],
		}),
		requestedMyHelpTicketsCount: build.query<
			NumberNamePair,
			{
				searchTerm: string;
				filter: FilterForm;
			}
		>({
			// todo add filtering
			query({ searchTerm, filter }) {
				// only get one sort column but we want the dateUpdate to be secondary sort
				// unless it is selected
				let params = getTicketsParams(searchTerm, undefined, undefined, [], filter);
				delete params.orderBy;
				delete params.skip;
				delete params.top;
				let retval: FetchArgs = {
					url: getUrl("helptickets", `loggedinuser/count`),
					params: params,
				};
				logApiCall("requestedMyHelpTicketsCount", retval.url + JSON.stringify(retval.params));
				return retval;
			},
			providesTags: ["HelpTicketsCount"],
			transformResponse(responseData: IdNamePair): NumberNamePair {
				let retval: NumberNamePair = { name: responseData.name, id: parseInt(_.get(responseData, "id", "0"), 10) };
				return retval;
			},
		}),
		getUserDevices: build.query<Array<Device>, { siteGuid?: string; requestedUserGuid: string; searchTerm: string }>({
			// todo add filtering
			query({ requestedUserGuid, searchTerm, siteGuid }) {
				// let userGuid: string | number | undefined = getCurrentUserGuid();
				if (siteGuid === undefined) {
					siteGuid = getSiteGuid();
				}
				let top: number = 25;
				let skip: number = 0;
				let retval: FetchArgs = {
					url: getUrl("helptickets", `sites/${siteGuid}/users/${requestedUserGuid}/devices`),
				};
				if (searchTerm && searchTerm.trim().length !== 0) {
					retval["params"] = {
						top: top,
						skip: skip,
						queryText: searchTerm.trim(),
					};
				} else {
					retval["params"] = { top: top, skip: skip };
				}
				logApiCall("getUserDevices", getUrl("helptickets", retval.url) + JSON.stringify(retval.params));
				return retval;
			},
			providesTags: ["Devices"],
		}),
		getUsersByName: build.query<Array<IdNamePair>, { searchTerm: string }>({
			// todo add filtering
			query({ searchTerm }) {
				let siteGuid: string | number | undefined = getSiteGuid();
				let top: number = 25;
				return {
					url: getUrl("helptickets", "users"),
					params: { searchTerm, siteGuid, top },
				};
			},
		}),
		getUserById: build.query<Array<IdNamePair>, { id: string }>({
			// todo add filtering
			query({ id }) {
				let userGuid: string | number | undefined = getCurrentUserGuid();
				return {
					url: getUrl("helptickets", `user/${id}`),
				};
			},
		}),
		getResourceThumbnail: build.query<Array<IdNamePair>, { resourceGuid: string }>({
			// todo add filtering
			query({ resourceGuid }) {
				return {
					url: getUrl("helptickets", `resources/${resourceGuid}/thumbnail`),
				};
			},
		}),
		getResourceTypeHierarchy: build.query<BriefResourceTypeVO, void>({
			query() {
				return {
					url: getUrl("helptickets/resources", `resourcetypes`),
				};
			},
		}),
		getDestinyDateFormat: build.query<{ format: string }, void>({
			// Configuration
			query: () => {
				let userGuid: string | number | undefined = getCurrentUserGuid();
				return {
					url: getUrl("configuration", "destinydateformat"),
				};
			},
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					setDateFormat(data?.format);
				} catch (error) {}
			},
		}),
		uploadFile: build.mutation<{ message: string }, { helpTicketGuid: string; file: File }>({
			// Configuration
			query: ({ helpTicketGuid, file }) => {
				let userGuid: string | number | undefined = getCurrentUserGuid();
				// const formData = new FormData();
				// formData.append("file", file);
				return {
					url: getUrl("helptickets", `helpticket/${helpTicketGuid}/attachment/file/${encodeURIComponent(file.name)}`),
					params: {
						filename: file.name,
					},
					method: "PUT",
					body: file,
					headers: {
						"Content-Type": "application/octet-stream",
					},
					responseHandler: (response) => response.text(),
				};
			},
			transformResponse: (response: { data: string }) => ({ message: response.data }),
			invalidatesTags: ["HelpTicket"],
		}),
		deleteUploadedFile: build.mutation<void, { helpTicketGuid: string; name: string }>({
			// Configuration
			query: ({ helpTicketGuid, name }) => {
				return {
					url: getUrl("helptickets", `helpticket/${helpTicketGuid}/attachment/file/${encodeURIComponent(name)}`),
					method: "DELETE",
				};
			},
			invalidatesTags: ["HelpTicket"],
		}),
		getImage: build.query<FetchImageResponse, { resourceGUID: string }>({
			query: ({ resourceGUID }) => {
				return {
					url: getUrl("helptickets/resources", `${resourceGUID}/thumbnail`),
					responseHandler: (response: any) => response.blob(),
				};
			},
		}),
		doDownloadFile: build.query<DownloadResponse, { downloadAttachedFile: DownloadAttachedFile }>({
			query: ({ downloadAttachedFile }) => {
				return {
					url: getUrl("helptickets", `helpticket/${downloadAttachedFile.helpTicketGUID}/attachment/file/${downloadAttachedFile.file.name}`),
					responseHandler: (response: any) => response.blob(),
				};
			},
		}),
		getSMTPServerSettings: build.query<HelpTicketSMTPServerSettingsVO, void>({
			query: () => {
				return {
					url: getUrl("helptickets", `smtpsettings`),
				};
			},
			providesTags: ["SMTPServerSettings"],
		}),
		updateSMTPServerSettings: build.mutation<
			void,
			{
				update: HelpTicketSMTPServerSettingsVO;
			}
		>({
			query({ update }) {
				return {
					url: getUrl("helptickets", `smtpsettings`),
					method: "PUT",
					body: update,
				};
			},
			invalidatesTags: ["SMTPServerSettings"],
		}),
		testEmail: build.mutation<Boolean, { helpTicketOutboundSettings: HelpTicketOutboundSMTPServerSettingsVO }>({
			query({ helpTicketOutboundSettings }) {
				return {
					url: getUrl("helptickets", "smtpsettings/testemail"),
					method: "POST",
					body: helpTicketOutboundSettings,
				};
			},
		}),
		testServer: build.mutation<Boolean, { helpTicketInboundSettings: HelpTicketInboundSMTPServerSettingsVO }>({
			query({ helpTicketInboundSettings }) {
				return {
					url: getUrl("helptickets", "smtpsettings/testserver"),
					method: "POST",
					body: helpTicketInboundSettings,
				};
			},
		}),
		getEmailNotificationSettings: build.query<HelpTicketEmailNotificationSettingsVO, void>({
			query: () => {
				return {
					url: getUrl("helptickets", `emailsettings`),
				};
			},
			providesTags: ["EmailNotificationSettings"],
		}),
		getEmailNotificationSetting: build.query<HelpTicketEmailRecepientVO, { emailNotificationID: number }>({
			query: ({ emailNotificationID }) => {
				return {
					url: getUrl("helptickets", `emailsettings/${emailNotificationID}`),
				};
			},
			providesTags: ["EmailNotificationSetting"],
		}),
		updateEmailNotificationSetting: build.mutation<Response, { update: HelpTicketEmailRecepientVO; emailNotificationID: number }>({
			query: ({ update, emailNotificationID }) => {
				return {
					url: getUrl("helptickets", `emailsettings/${emailNotificationID}`),
					method: "PUT",
					body: update,
				};
			},
			invalidatesTags: ["EmailNotificationSettings", "EmailNotificationSetting"],
		}),
		getFeatureFlags: build.query<{ featureFlags: any }, void>({
			query: () => {
				return {
					url: getUrl("configuration", "featureflag"),
				};
			},
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					setFeatureFlags(data);
				} catch (error) {}
			},
		}),
		reports: build.query<Array<Report>, void>({
			query() {
				return {
					url: getUrl("helptickets", "reports"),
				};
			},
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					// console.log(JSON.stringify(data));
				} catch (error) {}
			},
			providesTags: ["Reports"],
		}),
		report: build.query<Report, { helpTicketSavedReportViewID: number }>({
			query({ helpTicketSavedReportViewID }) {
				return {
					url: getUrl("helptickets", `reports/${helpTicketSavedReportViewID}`),
				};
			},
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					// console.log(JSON.stringify(data));
				} catch (error) {}
			},
			providesTags: ["Report"],
		}),
		createReport: build.mutation<number, { report: Report }>({
			query({ report }) {
				return {
					url: getUrl("helptickets", `reports`),
					method: "POST",
					body: report,
				};
			},
			invalidatesTags: ["Report", "Reports"],
		}),
		updateReport: build.mutation<number, { report: Report }>({
			query({ report }) {
				return {
					url: getUrl("helptickets", `reports/${report.helpTicketSavedReportViewID}`),
					method: "PUT",
					body: report,
					transformResponse: (responseData: number) => {
						return responseData;
					},
				};
			},
			invalidatesTags: ["Report", "Reports"],
		}),
		deleteReport: build.mutation<number, { helpTicketSavedReportViewID: number }>({
			query({ helpTicketSavedReportViewID }) {
				return {
					url: getUrl("helptickets", `reports/${helpTicketSavedReportViewID}`),
					method: "DELETE",
					transformResponse: (responseData: number) => {
						return responseData;
					},
				};
			},
			invalidatesTags: ["Report", "Reports"],
		}),
		reportTickets: build.query<Array<HelpTicketReport>, { params: ReportParams }>({
			query({ params }) {
				let retval: FetchArgs = {
					url: getUrl("helptickets", `reports/tickets`),
					method: "POST",
					body: params,
				};
				logApiCall("reportTickets", retval.url + JSON.stringify(retval.params));
				return retval;
			},
			providesTags: ["HelpTickets", "HelpTicket"],
		}),
		reportFacets: build.query<Array<ReportFilter>, { columns: Array<string> }>({
			query({ columns }) {
				let retval: FetchArgs = {
					url: getUrl("helptickets", `reports/facets`),
					method: "POST",
					body: columns,
				};
				logApiCall("reportFacets", retval.url + JSON.stringify(retval.params));
				return retval;
			},
			providesTags: ["HelpTickets", "HelpTicket"],
		}),
	}),
});

export const {
	useCategoriesQuery,
	useCategoryQuery,
	useUpdateCategoryMutation,
	useDeleteCategoryMutation,
	useResolutionsQuery,
	useUserListQuery,
	useUserQuery,
	useHelpTicketQuery,
	useCreateHelpTicketMutation,
	useUpdateHelpTicketMutation,
	useRequestedPatronHelpTicketsQuery,
	useRequestedPatronHelpTicketsFullQuery,
	useAssignedPatronHelpTicketsQuery,
	useRequestedMyHelpTicketsFullQuery,
	useRequestedMyHelpTicketsCountQuery,
	useHealthStatusQuery,
	useGetUserDevicesQuery,
	useGetUsersByNameQuery,
	useGetUserByIdQuery,
	useGetDestinyDateFormatQuery,
	// useGetPatronByIdQuery,  see userApi
	useUpsertFieldMutation,
	useDeleteFieldMutation,
	useFieldsQuery,
	useFieldQuery,
	useGetResourceTypeHierarchyQuery,
	useFormsQuery,
	useFormQuery,
	useGetFormsForResourceQuery,
	useUpsertFormMutation,
	useDeleteFormMutation,
	useCreateResolutionMutation,
	useUpdateResolutionMutation,
	useDeleteResolutionMutation,
	useReportsQuery,
	useReportQuery,
	useCreateReportMutation,
	useUpdateReportMutation,
	useDeleteReportMutation,
	useReportTicketsQuery,
	useReportFacetsQuery,
	useUploadFileMutation,
	useDeleteUploadedFileMutation,
	useGetImageQuery,
	useDoDownloadFileQuery,
	useGetSMTPServerSettingsQuery,
	useUpdateSMTPServerSettingsMutation,
	useTestEmailMutation,
	useTestServerMutation,
	useGetEmailNotificationSettingsQuery,
	useGetEmailNotificationSettingQuery,
	useUpdateEmailNotificationSettingMutation,
	useGetFeatureFlagsQuery,
} = apiApi;
