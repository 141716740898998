import { AddCircleOutlineOutlined, Close, DeleteForeverOutlined, Edit } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Switch } from "@mui/material";
import _ from "lodash";
import { FC, useState } from "react";
import { useAppDispatch } from "../../hook";
import { blankResolution, Resolution } from "../../models/helpticket.model";
import { setCategories } from "../../slices/ConfigurationSlice";
import { useCreateResolutionMutation, useDeleteResolutionMutation, useResolutionsQuery, useUpdateResolutionMutation } from "../../utilities/apiApi";
import CommonProgress from "../CommonProgress/CommonProgress";
import CustomButton from "../CustomButton/CustomButton";
import GenericDialog from "../GenericDialog/GenericDialog";
import { notify } from "../Notification/Notification";

interface AddResolutionCodesDialogProps {
	resolution: Resolution;
	resolutions: Resolution[];
	mode: string;
	open: boolean;
	onSave: (resolution: Resolution) => void;
	onClose: () => void;
	confirmLabel: string;
}

const AddResolutionCodesDialog: FC<AddResolutionCodesDialogProps> = (props) => {
	const [resolution, setResolution]: [any, (value: any) => void] = useState(props.resolution);
	const [name, setName] = useState(props.resolution.name || "");
	const [nameValidated, setNameValidated] = useState(true);
	const [nameValidatedDuplicate, setNameValidatedDuplicate] = useState(true);
	const [description, setDescription] = useState(props.resolution.description || "");
	const [queryRan, setQueryRan] = useState(false);

	function validate() {
		let validated = true;

		if (name === "") {
			validated = false;
			setNameValidated(false);
		}

		for (let i = 0; i < props.resolutions.length; i++) {
			if (name === props.resolutions[i].name && resolution.helpTicketResolutionID !== props.resolutions[i].helpTicketResolutionID) {
				validated = false;
				setNameValidatedDuplicate(false);
				break;
			}
		}

		if (validated) {
			let r = _.cloneDeep(resolution);
			r.name = name;
			r.description = description;
			props.onSave(r);
		}
	}

	return (
		<div>
			<Dialog
				open={props.open}
				sx={{
					"& .MuiBackdrop-root": {
						backgroundColor: "#171646",
					},
					"& .MuiPaper-root": {
						minWidth: "30rem",
						minHeight: "24rem",
					},
				}}
			>
				<div className={"p-2"}>
					<DialogTitle>{props.mode === "add" ? "New" : "Edit"} Resolution Code</DialogTitle>
					<IconButton
						aria-label="close"
						onClick={(e: any) => {
							props.onClose();
						}}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<Close />
					</IconButton>
					<DialogContent>
						<div className={"flex flex-col"}>
							<div className={"font-medium"}>Name</div>
							<input
								type={"text"}
								maxLength={30}
								value={name}
								placeholder={"Add a resolution code name"}
								onChange={(e) => {
									setName(e.target.value);
									setNameValidated(true);
									setNameValidatedDuplicate(true);
								}}
								className={"border rounded-[8px] px-2 py-2 max-w-72"}
							/>
							<div className={"text-error-text"}>
								{!nameValidated && "Please specify a name."}
								{!nameValidatedDuplicate && "Duplicate resolution code name. Please make the name unique."}
								{nameValidated && nameValidatedDuplicate && <br />}
							</div>

							<div className={"mt-6 font-medium"}>Description</div>
							<textarea
								value={description}
								maxLength={200}
								onChange={(e) => {
									setDescription(e.target.value);
								}}
								className={"mb-6 border rounded-[8px] px-2 py-2"}
								rows={3}
								placeholder={"Add a short description (optional)"}
							/>
						</div>
					</DialogContent>
					<DialogActions>
						<div className="flex flex-row">
							<div className={"grow"} />
							<CustomButton
								variant={"notContained"}
								ariaLabel={""}
								onClick={(e: Event) => {
									props.onClose();
								}}
							>
								Cancel
							</CustomButton>
							<CustomButton
								variant={"contained"}
								ariaLabel={""}
								onClick={(e: Event) => {
									validate();
								}}
							>
								{props.confirmLabel ? props.confirmLabel : "Ok"}
							</CustomButton>
						</div>
					</DialogActions>
				</div>
			</Dialog>
		</div>
	);
};

const ResolutionCodesConfiguration = () => {
	const [addDialogOpen, setAddDialogOpen] = useState(false);
	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [createResolutionMutation, { isLoading }] = useCreateResolutionMutation();
	const [deleteResolutionMutation, { isDeleteLoading }] = useDeleteResolutionMutation();
	const [currentResolution, setCurrentResolution] = useState(blankResolution);
	const [currentResolutionIDToBeDeleted, setCurrentResolutionIDToBeDeleted] = useState(0);
	const [selectedResolutionId, setSelectedResolutionId] = useState(undefined);
	const [update, setUpdate] = useState({});
	const [updateResolutionMutation, { isLoadingUpdate }] = useUpdateResolutionMutation();
	const dispatch = useAppDispatch();

	// Resolution codes
	const resolutionCodesQuery = useResolutionsQuery();
	let resolutions: Resolution[] = [];
	if (resolutionCodesQuery.isSuccess) {
		dispatch(setCategories(resolutionCodesQuery.data));
		resolutions = resolutionCodesQuery.data;
	}

	if (resolutionCodesQuery.isLoading) {
		return <CommonProgress message={"Loading forms"} />;
	}

	const createResolution = async (resolution: Resolution) => {
		if (!isLoading) {
			try {
				await createResolutionMutation({ resolution: resolution }).unwrap();
				notify("New Resolution Code Added!", `"${resolution.name}" is available and ready to use.`, false);
			} catch (error) {
				console.error("Failed to create the resolution code: " + error);
			}
		}
	};

	const updateResolution = async (resolution: Resolution) => {
		if (!isLoadingUpdate) {
			try {
				await updateResolutionMutation({ resolution: resolution }).unwrap();
				notify(
					"Resolution Code Updated!",
					resolution.active ? `"${resolution.name}" is available and ready to use.` : `"${resolution.name}" is available but is inactive.`,
					false,
				);
			} catch (error) {
				console.error("Failed to update the resolution code: " + error);
				notify("Failed to update resolution code!", `"${resolution.name}" was not updated.`, true);
			}
		}
	};

	const deleteResolution = async (input: number) => {
		if (!isDeleteLoading) {
			try {
				await deleteResolutionMutation({ helpTicketResolutionID: input }).unwrap();
			} catch (error) {
				console.error("Failed to delete the resolution code: " + error);
			}
		}
	};

	return (
		<div className={"mt-2"}>
			{resolutions.length > 0 && !addDialogOpen && !editDialogOpen && !deleteDialogOpen && (
				<div className={"flex flex-wrap flex-row gap-4"}>
					<button
						title="New Resolution Code"
						className={
							"flex flex-col items-center justify-center w-[200px] h-44 rounded-[8px] bg-[rgba(1,51,141,0.06)] drop-shadow-[0_2px_8px_rgba(138,146,163,0.16)]"
						}
						onClick={() => {
							setCurrentResolution(_.cloneDeep(blankResolution));
							setAddDialogOpen(true);
						}}
					>
						<AddCircleOutlineOutlined
							sx={{
								fill: "#01338D",
								width: "32px",
								height: "32px",
							}}
						/>
						<div className="text-primary text-lg text-center">New Resolution Code</div>
					</button>
					{resolutions.map((resolution: Resolution) => {
						let sliderAriaLabel = `${resolution.name} is ${resolution.active ? "active" : "inactive"}`;
						return (
							<div className={"flex flex-col p-3 w-[200px] h-44 rounded-[8px] bg-white drop-shadow-[0_2px_8px_rgba(138,146,163,0.16)]"}>
								<div className={"text-lg font-semibold mb-2 break-words"}>{resolution.name}</div>
								<div className={"grow"}>{resolution.description}</div>
								{resolution.helpTicketResolutionID && resolution.helpTicketResolutionID > 5 && (
									<div className={"flex flex-row"}>
										<div className={"grow"}>
											<Switch
												checked={resolution.active}
												onChange={(event) => {
													let result = _.cloneDeep(resolution);
													result.active = !result.active;
													updateResolution(result);
												}}
												inputProps={{
													"aria-label": sliderAriaLabel,
												}}
											/>
										</div>
										<IconButton
											onClick={(e) => {
												setCurrentResolution(resolution);
												setEditDialogOpen(true);
											}}
										>
											<Edit />
										</IconButton>
										<IconButton
											onClick={() => {
												setCurrentResolutionIDToBeDeleted(resolution.helpTicketResolutionID as number);
												setCurrentResolution(resolution);
												setDeleteDialogOpen(true);
											}}
										>
											<DeleteForeverOutlined
												sx={{
													fill: "red",
												}}
											/>
										</IconButton>
									</div>
								)}
							</div>
						);
					})}
				</div>
			)}
			{addDialogOpen && (
				<AddResolutionCodesDialog
					mode={"add"}
					open={addDialogOpen}
					onSave={(resolution: Resolution) => {
						createResolution(resolution);
						setCurrentResolution(_.cloneDeep(blankResolution));
						setAddDialogOpen(false);
					}}
					resolution={currentResolution}
					confirmLabel={"Save Resolution Code"}
					onClose={() => {
						setAddDialogOpen(false);
					}}
					resolutions={resolutions}
				/>
			)}
			{editDialogOpen && (
				<AddResolutionCodesDialog
					mode={"edit"}
					open={editDialogOpen}
					onSave={(resolution: Resolution) => {
						updateResolution(resolution);
						setCurrentResolution(_.cloneDeep(blankResolution));
						setEditDialogOpen(false);
					}}
					resolution={currentResolution}
					confirmLabel={"Save Resolution Code"}
					onClose={() => {
						setEditDialogOpen(false);
					}}
					resolutions={resolutions}
				/>
			)}
			{deleteDialogOpen && (
				<GenericDialog
					open={deleteDialogOpen}
					title={"Delete Resolution Code?"}
					onClose={() => {
						setDeleteDialogOpen(false);
						setCurrentResolution(_.cloneDeep(blankResolution));
					}}
					onConfirm={() => {
						setDeleteDialogOpen(false);
						deleteResolution(currentResolutionIDToBeDeleted);
						setCurrentResolution(_.cloneDeep(blankResolution));
					}}
					confirmLabel={"Delete Resolution Code"}
					content={`Resolution Codes already linked to existing tickets will remain. They will not be available for future use. Are you sure you want to delete the resolution code "${currentResolution.name}"?`}
					confirmColor={"red"}
					isError={true}
				/>
			)}
		</div>
	);
};

export default ResolutionCodesConfiguration;
