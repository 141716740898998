import _ from "lodash";
import React from "react";
import { useNavigate, useRouteError } from "react-router-dom";
import { useAppDispatch } from "../hook";
import { useLogoutQuery } from "../utilities/authApi";

interface RouterError {
	statusText: string | undefined;
	message: string | undefined;
}
export default function ErrorPage() {
	const navigate = useNavigate();
	const error = useRouteError();
	console.error(error);

	return (
		<div id="error-page" className={"flex flex-col w-[100vw] h-[100vh] items-center justify-center"}>
			<div className={"text-4xl text-error-text m-4"}>Oops!</div>
			<div className={"text-2xl m-4"}>Sorry, an unexpected error has occurred.</div>
			{!_.isNil(error) && <div className={"text-lg mb-16"}>{(error as RouterError).statusText || (error as RouterError).message}</div>}
		</div>
	);
}

export function ErrorPage401() {
	const navigate = useNavigate();
	const [skip, setSkip] = React.useState(false);
	const dispatch = useAppDispatch();

	let result = useLogoutQuery(undefined, { skip: skip });
	if (result.isSuccess || result.isError) {
		setSkip(true);
	}

	return (
		<div id="error-page-401" className={"flex flex-col w-[100vw] h-[100vh] items-center justify-center"}>
			<div className={"text-4xl text-error-text mb-4"}>Oops!</div>
			<div className={"text-2xl m-4 flex flex-row flex-wrap"}>
				<span className="flex">Authorization has expired.</span>
				<span className="flex">Please refresh the page.</span>
			</div>
		</div>
	);
}

export function ErrorPageInvalidUser() {
	const navigate = useNavigate();
	const [skip, setSkip] = React.useState(false);
	const dispatch = useAppDispatch();

	let result = useLogoutQuery(undefined, { skip: skip });
	if (result.isSuccess || result.isError) {
		setSkip(true);
	}

	return (
		<div id="error-page-401" className={"flex flex-col w-[100vw] h-[100vh] items-center justify-center"}>
			<div className={"text-4xl text-error-text mb-4"}>Sorry!</div>
			<div className={"text-2xl mb-16"}>You cannot use this application at this time. </div>
			<div className={"text-xl mb-16"}>Contact an administrator to give you the correct permissions.</div>
		</div>
	);
}
