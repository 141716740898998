import { combineReducers, configureStore } from "@reduxjs/toolkit";
import AuthSliceReducer from "./slices/AuthSlice";
import ConfigurationSliceReducer from "./slices/ConfigurationSlice";
import DownloadSliceReducer from "./slices/DownloadSlice";
import FiltersSliceReducer from "./slices/FiltersSlice";
import healthSliceReducer from "./slices/HealthSlice";
import HelpTicketFormReducer from "./slices/HelpTicketFormSlice";
import HelpTicketReducer from "./slices/HelpTicketSlice";
import NotificationReducer from "./slices/NotificationSlice";
import UserSliceReducer from "./slices/UserSlice";
import { apiApi } from "./utilities/apiApi";
import { authApi } from "./utilities/authApi";
import { userApi } from "./utilities/userApi";

const rootReducer = combineReducers({
	health: healthSliceReducer,
	helpTicketForm: HelpTicketFormReducer,
	notification: NotificationReducer,
	download: DownloadSliceReducer,
	user: UserSliceReducer,
	auth: AuthSliceReducer,
	helpTicket: HelpTicketReducer,
	filters: FiltersSliceReducer,
	configuration: ConfigurationSliceReducer,
	[apiApi.reducerPath]: apiApi.reducer,
	[authApi.reducerPath]: authApi.reducer,
	[userApi.reducerPath]: userApi.reducer,
});

export function setupStore(preloadedState?: Partial<RootState>) {
	return configureStore({
		reducer: rootReducer,
		preloadedState,
		middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([apiApi.middleware, authApi.middleware, userApi.middleware]),
	});
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

const store = setupStore();

export default store;
