import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Button, ButtonGroup } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import CommonProgress from "../../components/CommonProgress/CommonProgress";
import { useAppSelector } from "../../hook";
import { UserType } from "../../models/user.model";
import { selectAccessToken, selectUserType, setUserType } from "../../slices/AuthSlice";

function LoginPage(): ReactJSXElement {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const accessToken = useSelector(selectAccessToken);
	let developmentButtons = false;
	const userType = useAppSelector(selectUserType);
	// need AuthSlice to hold user and access token stuff
	const queryStr: string | undefined = location?.search;

	useEffect(() => {
		if (userType !== undefined && userType > 0) {
			navigate("/helpdesk/" + UserType[userType].toLowerCase());
		}
	}, [userType, navigate]);

	if (userType > 0) {
		return (
			<div>
				{developmentButtons && (
					<div className={"flex justify-center h-[32px] absolute ml-[4rem]"}>
						<ButtonGroup variant="contained" aria-label="Basic button group">
							<Button
								onClick={() => {
									dispatch(setUserType(UserType.Submitter));
								}}
								className={"bg-primary"}
							>
								Submitter
							</Button>
							<Button
								onClick={() => {
									dispatch(setUserType(UserType.Agent));
								}}
								className={"bg-primary"}
							>
								Agent
							</Button>
							<Button
								onClick={() => {
									dispatch(setUserType(UserType.Admin));
								}}
								className={"bg-primary"}
							>
								Admin
							</Button>
						</ButtonGroup>
					</div>
				)}
				<div>
					<Outlet />
				</div>
			</div>
		);
	} else {
		return <CommonProgress />;
	}
}

export default LoginPage;
