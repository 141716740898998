import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { blankFilterForm, FilterForm } from "../models/filterForm.model";
import { RootState } from "../store";

export interface FilterForms {
	ticketFilterForm: FilterForm;
}

// Define the initial state using that type
const initialState: FilterForms = {
	ticketFilterForm: blankFilterForm,
};
const FiltersSlice = createSlice({
	name: "filters",
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setHelpTicketFilterForm: (state, payload) => {
			return { ...state, ...{ ticketFilterForm: payload.payload } };
		},
		resetHelpTicketFilterForm: (state) => {
			// remember state is only for read only you are returning the new state
			let ht = _.cloneDeep(blankFilterForm);
			return { ...state, ...{ ticketFilterForm: ht } };
		},
	},
});

export const selectHelpTicketFilterForm = (state: RootState): FilterForm => {
	return state.filters.ticketFilterForm;
};
// export const selectHelpTicketType = (state: RootState): HelpTicketType => state.helpTicketForm.createDate;
export const { setHelpTicketFilterForm, resetHelpTicketFilterForm } = FiltersSlice.actions;

export default FiltersSlice.reducer;
