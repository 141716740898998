import { AddCircleOutlineOutlined, DeleteForeverOutlined } from "@mui/icons-material";
import { IconButton, InputAdornment, InputLabel, List, ListItem, TextField } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import _ from "lodash";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import GenericDialog from "../GenericDialog/GenericDialog";

interface EditableFieldProps {
	// onBlur: ( value: string ) => void;
	// onEnter: (value: string) => void;
	onChange: (value: string) => void;
	onDelete: () => void;
	isValidMessage: (value: string) => string; // return undefined if value ok else error message to display if not
	value: string;
	placeholder: string;
	showDelete: boolean;
	lengthLimit: number;
}
export const EditableField: FC<EditableFieldProps> = (props) => {
	const [value, setValue] = useState(props.value);
	const [errorMessage, setErrorMessage] = useState(props.isValidMessage(props.value));
	const [hasFocus, setHasFocus] = useState(false);
	const [sx, setSx]: [any, (value: any) => void] = useState({});
	const [inputProps, setInputProps]: [any, (value: any) => void] = useState({});
	const inputRef = useRef();
	const handleClearClick = () => {
		setValue("");
		if (inputRef && inputRef.current) {
			(inputRef.current as any).focus();
		}
	};
	// console.log("errorMessage set to:" + props.isValidMessage(props.value));
	const endAdornment: ReactNode = (
		<InputAdornment position="end">
			<IconButton
				sx={{ visibility: value == undefined || value.length == 0 ? "hidden" : "visible" }}
				onFocus={() => {
					setHasFocus(true);
				}}
				onBlur={() => {
					setHasFocus(false);
				}}
				onClick={(event) => {
					handleClearClick();
				}}
			>
				<ClearIcon />
			</IconButton>
		</InputAdornment>
	);
	const onBlur = () => {
		// not disregards any error
		// enclosing component must make check
		props.onChange(value);
		setHasFocus(false);
	};
	useEffect(() => {
		let message = props.isValidMessage(value);
		setErrorMessage(message);
	}, [value]);

	useEffect(() => {
		setErrorMessage(props.isValidMessage(value));
		if (hasFocus) {
			setSx({ width: "100%" });
			setInputProps({
				endAdornment: endAdornment,
				sx: { width: "100%" },
			});
		} else {
			setSx({ width: "100%", "& fieldset": { border: "none" } });
			setInputProps({
				sx: { width: "100%" },
			});
		}
	}, [hasFocus]);

	return (
		<div className="flex flex-auto">
			<div className="flex-auto">
				<TextField
					key={"key_" + value}
					inputRef={inputRef}
					variant={"outlined"}
					label={hasFocus ? `${value.length}/${props.lengthLimit}` : undefined}
					size={"small"}
					value={value}
					sx={sx}
					autoFocus
					onChange={(event) => {
						setValue(event.target.value.substring(0, props.lengthLimit));
						setErrorMessage(props.isValidMessage(event.target.value));
					}}
					onBlur={(event) => {
						onBlur();
					}}
					onFocus={() => {
						setHasFocus(true);
					}}
					error={errorMessage.length > 0}
					helperText={errorMessage}
					InputProps={inputProps}
					placeholder={props.placeholder}
				/>
			</div>
			{props.showDelete && (
				<div className="flex-none">
					<IconButton
						onClick={() => {
							props.onDelete();
						}}
					>
						<DeleteForeverOutlined
							sx={{
								fill: "red",
							}}
						/>
					</IconButton>
				</div>
			)}
		</div>
	);
};

interface ListEditorProps {
	onAdd: (value: any) => void;
	onDelete: (index: number) => void;
	onChange: (index: number, value: string) => void;
	title?: string;
	itemLabel: string;
	array: any[];
	displayValue: (value: any) => string;
	deleteString: string;
	deleteTitle: string;
	placeholder?: string;
	showDeleteButtons?: () => boolean;
	dropDownErrorText?: string;
	addLabel?: string;
}
// editor for lists to items with an id and name property. You can change
// what is displayed from the name property by using the toString prop.
export const ListEditor: FC<ListEditorProps> = (props) => {
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [itemIndex, setItemIndex] = useState(0);
	const [name, setName] = useState("");
	const showTrashCans =
		props.showDeleteButtons ||
		(() => {
			return true;
		});
	const dropDownError = _.get(props, "dropDownErrorText", undefined);
	const deleteItem = (idx: number) => {
		props.onDelete(idx);
	};
	const addItem = (value: any) => {
		props.onAdd(value);
	};
	const changeItem = (idx: number, value: any) => {
		props.onChange(idx, value);
	};
	const showDelete = () => {
		if (props.showDeleteButtons) {
			return props.showDeleteButtons();
		} else {
			return false;
		}
	};
	const isDuplicate = (value: string, idx: number) => {
		if (
			_.find(props.array, (item, arrayIdx) => {
				return props.displayValue(value) === props.displayValue(item) && idx != arrayIdx;
			})
		) {
			return true;
		}
		return false;
	};
	useEffect(() => {}, [props.array]);
	return (
		<div className="min-w-60 w-full">
			{props.title && (
				<InputLabel className="py-2 text-lg text-altblack" id="ListEditor-title">
					{props.title}
				</InputLabel>
			)}
			<div className="w-full">
				<List className="border-2 border-gray-600 min-h-16 max-h-fit mb-4">
					{props.array.map((item: any, idx: number) => {
						return (
							<ListItem key={"listEditorItem_" + props.displayValue(item) + idx}>
								<div className="flex flex-auto items-end">
									<EditableField
										onChange={(value) => {
											changeItem(idx, value);
										}}
										lengthLimit={50}
										value={props.displayValue(item)}
										placeholder={props.placeholder || ""}
										onDelete={() => {
											props.onDelete(idx);
										}}
										isValidMessage={(value: any) => {
											if (isDuplicate(value, idx)) {
												// console.log("errorMessage: Duplicate field name. Please make the name unique. ");
												return "Duplicate field name. Please make the name unique.";
											}
											// console.log("errorMessage: ''");
											return "";
										}}
										showDelete={showDelete()}
									/>
								</div>
							</ListItem>
						);
					})}
				</List>
				{dropDownError && <div className="text-basis text-red-400 relative top-[-12px] ">{dropDownError}</div>}
			</div>
			<div className="flex">
				<button
					className={"bg-none border-none text-underline cursor-pointer text-primary disabled:text-blue-200"}
					onClick={() => {
						addItem("");
					}}
				>
					<AddCircleOutlineOutlined />
					<span>{props.addLabel ? props.addLabel : "Add"}</span>
				</button>
			</div>
			<GenericDialog
				open={deleteDialogOpen}
				title={props.deleteTitle + "?"}
				onClose={() => {
					setDeleteDialogOpen(false);
				}}
				onConfirm={() => {
					setDeleteDialogOpen(false);
					deleteItem(itemIndex);
				}}
				confirmLabel={props.deleteTitle}
				content={props.deleteString}
			/>
		</div>
	);
};
