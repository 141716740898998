import SearchIcon from "@mui/icons-material/SearchOutlined";
import { Autocomplete, InputAdornment, InputLabel, TextField } from "@mui/material";
import _ from "lodash";
import { createRef, FC, useCallback, useEffect, useState } from "react";
import { IdNamePair } from "../../models/genericTypes.model";
import { useGetUsersByNameQuery } from "../../utilities/apiApi";

export interface UserLookupProps {
	onChange: (value: IdNamePair) => void;
	userName: IdNamePair;
	origUser: IdNamePair;
	hasError: boolean;
	setHasError: Function;
	userSelectionEnable: boolean;
}

function sleep(duration: number): Promise<void> {
	return new Promise<void>((resolve) => {
		setTimeout(() => {
			resolve();
		}, duration);
	});
}

const UserLookup: FC<UserLookupProps> = (props) => {
	const [searchValue, setSearchValue] = useState(""); // for input
	const [searchTerm, setSearchTerm] = useState(""); // search after debounce
	let userSelectionAllowed: boolean = props.userSelectionEnable;
	let textRef = createRef<HTMLInputElement>();

	let optionsResult: any = useGetUsersByNameQuery({ searchTerm: searchTerm.trim() || "" }, { skip: searchTerm.trim().length < 2 });
	let options: string[] = [];
	let data: Array<IdNamePair> = [];
	if (props.origUser) {
		options.push(props.origUser.name);
		data.push(props.origUser);
	}
	if (optionsResult.isSuccess && !optionsResult.isFetching) {
		data = optionsResult.data as Array<IdNamePair>;
		let idx = _.findIndex(data, (item) => item.id === _.get(props, "origUser.id", ""));
		if (idx === -1) {
			data = [props.origUser, ...data];
		}
		options = _.map(data as Array<IdNamePair>, "name"); // as Array<IdNamePair>;
	}

	let loading: boolean = optionsResult.isLoading;
	const searchDebounce = useCallback(
		_.debounce((value) => {
			setSearchTerm(value.trim());
		}, 500),
		[],
	);

	useEffect(() => {
		searchDebounce(searchValue);
	}, [searchValue]);
	return (
		<div data-testId={"UserLookup"} className={"w-full h-full"}>
			{userSelectionAllowed ? (
				<>
					<InputLabel id="whoFor-label" className={"text-bold text-base text-[#171646] font-rubik mb-0.5 w-full text-justify-left"}>
						Who is this for?
					</InputLabel>
					<Autocomplete
						className="w-full"
						options={options}
						inputValue={searchValue}
						autoSelect
						value={props.userName.name}
						getOptionLabel={(option) => option}
						onChange={(event, newValue) => {
							let foundUser: any = _.find(data, (item) => item.name === newValue);
							props.onChange(foundUser ? foundUser : { id: "", name: "" });
						}}
						onInputChange={(e, newValue) => {
							setSearchValue(newValue);
						}}
						isOptionEqualToValue={(option, value) => option === value}
						loading={loading}
						clearIcon={<img src="/close.svg" alt="clear icon" />}
						renderInput={(params) => (
							<TextField
								className={"bg-white w-full font-roboto text-[#171646] rounded-[8px]"}
								{...params}
								placeholder="Search Patrons"
								variant="outlined"
								size={"small"}
								inputRef={textRef}
								sx={{
									"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
										borderColor: "gray",
									},
									"& .MuiOutlinedInput-root": {
										paddingLeft: "12px !important",
										paddingRight: "14px !important",
									},
									backgroundColor: props.hasError && props.userName.name === "" ? "#FFF7F7 !important" : "white",
									border: props.hasError && props.userName.name === "" ? "1px solid #B43523 !important" : "",
								}}
								inputProps={{
									...params.inputProps,
									sx: {
										"&::placeholder": {
											color: "#6E788C",
											opacity: "85%",
										},
									},
								}}
								InputProps={{
									...params.InputProps,
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
								}}
							/>
						)}
						sx={{
							"& .MuiAutocomplete-popupIndicator": {
								display: "none",
							},
						}}
					/>
				</>
			) : (
				<>
					<InputLabel id="whoFor-label" className={"text-bold text-base font-rubik mb-0.5 w-full text-justify-left text-[#171646]"}>
						Who is this for?
					</InputLabel>
					<TextField
						label=""
						className={"bg-white w-full font-roboto text-[#171646] rounded-[8px]"}
						// variant="outlined"
						defaultValue={props.userName.name}
						inputProps={{ readOnly: true }}
						// onChange={(event) => {
						// 	let foundUser: IdNamePair | undefined = _.find(data, (item) => item.name === props.userName.name);
						// 	props.onChange(foundUser ? foundUser : props.userName);
						// }}
						sx={{
							"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
								borderColor: "gray",
							},
							backgroundColor: props.hasError && props.userName.name === "" ? "#FFF7F7 !important" : "white",
							border: props.hasError && props.userName.name === "" ? "1px solid #B43523 !important" : "",
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								e.preventDefault();
								// write your functionality here
							}
						}}
					/>
				</>
			)}
			{props.hasError && props.userName.name === "" && <div className={"text-error-text"}>Please select a patron</div>}
		</div>
	);
};

export default UserLookup;
