export interface NotificationProps {
	notificationOpen?: boolean;
	dialogOpen?: boolean;
	title?: string;
	message?: string;
	list?: string[];
	dialogTitle?: string;
	dialogMessage?: string;
	timeout?: number;
	notificationIsError?: boolean;
	dialogIsError?: boolean;
	callback?: () => void;
}

export const closedNotification = {
	notificationOpen: false,
	title: "",
	message: "",
	list: [],
	timeout: 5000,
	notificationIsError: false,
};

export const closedDialogNotification = {
	dialogOpen: false,
	dialogTitle: "",
	dialogMessage: "",
	dialogIsError: false,
};
