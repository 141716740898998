import SearchIcon from "@mui/icons-material/SearchOutlined";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import _ from "lodash";
import { createRef, FC, useCallback, useEffect, useState } from "react";
import { IdNamePair } from "../../models/genericTypes.model";
import { useGetUsersByNameQuery } from "../../utilities/apiApi";

export interface ResponsibleUserLookupProps {
	onChange: (value: IdNamePair) => void;
	userName: string | undefined;
	hasError: boolean;
	setHasError: Function;
	userSelectionEnable: boolean;
}

function sleep(duration: number): Promise<void> {
	return new Promise<void>((resolve) => {
		setTimeout(() => {
			resolve();
		}, duration);
	});
}

const ResponsibleUserLookup: FC<ResponsibleUserLookupProps> = (props) => {
	const [searchValue, setSearchValue] = useState("");
	const [searchTerm, setSearchTerm] = useState("");
	let userSelectionAllowed: boolean = props.userSelectionEnable;
	let textRef = createRef<HTMLInputElement>();

	let optionsResult: any = useGetUsersByNameQuery({ searchTerm: searchTerm || "" }, { skip: searchTerm.trim().length < 2 });
	let options: string[] = [];
	let data: Array<IdNamePair> = [];
	if (optionsResult.isError) {
		options = [];
	}
	if (optionsResult.isSuccess) {
		data = optionsResult.data as Array<IdNamePair>;
		options = _.map(optionsResult.data as Array<IdNamePair>, "name"); // as Array<IdNamePair>;
	}
	let loading: boolean = optionsResult.isLoading;
	const searchDebounce = useCallback(
		_.debounce((value) => {
			setSearchTerm(value.trim());
		}, 500),
		[],
	);

	useEffect(() => {
		searchDebounce(searchValue);
	}, [searchValue]);

	return (
		<div data-testId={"ResponsibleUserLookup"} className={"h-full"}>
			<>
				<Autocomplete
					className="w-full"
					options={searchValue === undefined || searchValue.trim().length < 2 ? [] : options}
					inputValue={searchValue}
					autoSelect
					value={props.userName}
					noOptionsText={searchValue === undefined || searchValue.trim().length < 2 ? "" : "No users found"}
					getOptionLabel={(option) => option}
					onChange={(event, newValue) => {
						let foundUser: any = _.find(data, (item) => item.name === newValue);
						props.onChange(foundUser ? foundUser : { id: "", name: "" });
					}}
					onInputChange={(e, newValue) => {
						setSearchValue(newValue);
					}}
					isOptionEqualToValue={(option, value) => option === value}
					loading={loading}
					// sx={{
					// 	"& .MuiAutocomplete-popupIndicator": {
					// 		backgroundColor: "white",
					// 	},
					// 	"& .MuiTouchRipple-root": {
					// 		opacity: "0%",
					// 	},
					// }}
					renderInput={(params) => (
						<TextField
							className={"bg-white font-roboto text-[#171646] rounded-[8px]"}
							{...params}
							placeholder="Search Patrons"
							variant="outlined"
							size={"small"}
							inputRef={textRef}
							sx={{
								"& .Mui-focused .MuiOutlinedInput-notchedOutline": {
									borderColor: "gray",
								},
								"& .MuiOutlinedInput-root": {
									paddingLeft: "12px !important",
									paddingRight: "14px !important",
								},
								backgroundColor: props.hasError && _.get(props, "userName.name", "") === "" ? "#FFF7F7 !important" : "white",
								border: props.hasError && _.get(props, "userName.name", "") === "" ? "1px solid #B43523 !important" : "",
							}}
							inputProps={{
								...params.inputProps,
								sx: {
									"&::placeholder": {
										color: "#6E788C",
										opacity: "85%",
									},
								},
							}}
							InputProps={{
								...params.InputProps,
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
							}}
						/>
					)}
					sx={{
						"& .MuiAutocomplete-popupIndicator": {
							display: "none",
						},
					}}
				/>
			</>
			{props.hasError && <div className={"text-error-text"}>Please select a responsible patron</div>}
		</div>
	);
};

export default ResponsibleUserLookup;
