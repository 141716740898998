import { createApi } from "@reduxjs/toolkit/query/react";
import _ from "lodash";
import configs from "../config/config";
import env from "../config/env";
import { Patron2 } from "../models/Patron.model";
import { getDistrictAppId } from "./appContext";
import customUserFetchBase from "./customUserFetchBase";

const BASE_URL = configs.env[env].helpdesk_backend_url;

const getUrl = (category: string, endpath: string): string => {
	let appId: string | number | undefined = getDistrictAppId();
	return `${appId}/${category}/${endpath}`;
};

export const userApi = createApi({
	reducerPath: "userApi",
	baseQuery: customUserFetchBase,
	tagTypes: ["User"],
	endpoints: (build) => ({
		getPatronById: build.query<Patron2, { id: string }>({
			query({ id }) {
				// let userGuid: string | number = getCurrentUserGuid() || "";
				let appId: string | number = getDistrictAppId() || "";
				return {
					url: getUrl("destiny", `patrons/${id}`),
					credentials: "include",
				};
			},
			transformResponse: (patron: Patron2) => {
				if (patron.primarySiteGuid === undefined) {
					let siteAssociations = _.get(patron, "siteAssociations", []);
					for (let i = 0; i < siteAssociations.length; i++) {
						if (siteAssociations[i].primarySite) {
							patron.primarySiteGuid = siteAssociations[i].siteGuid;
							break;
						}
					}
				}
				return patron;
			},
		}),
		getPatronForSiteByGuid: build.query<Patron2, { siteGuid: string; userGuid: string }>({
			query: ({ siteGuid, userGuid }) => {
				return {
					url: getUrl("destiny", `sites/${siteGuid}/patrons/${userGuid}`),
					credentials: "include",
				};
			},
			transformResponse: (patron: Patron2) => {
				if (patron.primarySiteGuid === undefined) {
					let siteAssociations = _.get(patron, "siteAssociations", []);
					for (let i = 0; i < siteAssociations.length; i++) {
						if (siteAssociations[i].primarySite) {
							patron.primarySiteGuid = siteAssociations[i].siteGuid;
							break;
						}
					}
				}
				return patron;
			},
		}),
	}),
});
export const { useGetPatronByIdQuery, useGetPatronForSiteByGuidQuery } = userApi;
