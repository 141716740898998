import {
	Add,
	AddCircleOutlineOutlined,
	ArrowBack,
	CalendarMonthOutlined,
	CheckBoxOutlined,
	CloseOutlined,
	DeleteForeverOutlined,
	Edit,
	EditNoteOutlined,
	InputOutlined,
	KeyboardArrowDownOutlined,
	KeyboardArrowUpOutlined,
	LocalAtmOutlined,
	MoneyOutlined,
	TitleOutlined,
	UpdateOutlined,
} from "@mui/icons-material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, Checkbox, Chip, Drawer, FormControl, IconButton, ListItemText, MenuItem, OutlinedInput, Select, Stack, Switch } from "@mui/material";
import { Reorder } from "framer-motion";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useAppDispatch } from "../../hook";
import { ResourceTypeVO } from "../../models/device.model";
import { Field, FieldType, ftInfo } from "../../models/fields.model";
import { blankForm, Form } from "../../models/forms.model";
import { Category, IssueType } from "../../models/helpticket.model";
import { blankBriefResourceTypeVO, BriefResourceTypeVO } from "../../models/resourceType.model";
import { setCategories } from "../../slices/ConfigurationSlice";
import {
	useCategoriesQuery,
	useDeleteFormMutation,
	useFieldsQuery,
	useFormQuery,
	useFormsQuery,
	useGetResourceTypeHierarchyQuery,
	useUpsertFormMutation,
} from "../../utilities/apiApi";
import { renderFieldTypeIcon } from "../../utilities/helpTicketUtils";
import CommonProgress from "../CommonProgress/CommonProgress";
import CustomButton from "../CustomButton/CustomButton";
import GenericDialog from "../GenericDialog/GenericDialog";
import { notify } from "../Notification/Notification";

interface NoFormsProps {
	onClick: () => void;
}

const NoForms: FC<NoFormsProps> = (props) => {
	return (
		<div>
			<div className={"grid justify-items-center"}>
				<div className={"md:w-[28rem]"}>
					<div className={"grid justify-items-center"}>
						<div className="bg-primary/5 rounded-full h-32 w-32 mb-4">
							<div className={"grid place-items-center h-32"}>
								<img src={"/no_results_forms.svg"} alt={"No field icon "} className={"w-12 h-12"} />
							</div>
						</div>
						<div className="text-altblack font-rubik-medium text-xl font-medium tracking-normal text-center mb-4">No forms at this time.</div>
						<div className="opacity-80 text-altblack font-rubik text-base font-normal tracking-normal text-center mb-4">
							Forms links custom fields together with issue categories/types and resource types to allow for additional data input during help
							ticket creation. To get started, please create a custom form by clicking below.
						</div>
						<CustomButton
							onClick={() => {
								props.onClick();
							}}
							variant={"contained"}
							ariaLabel={"Create a Help Ticket"}
						>
							Create a form <Add className={"ml-1"} />
						</CustomButton>
					</div>
				</div>
			</div>
		</div>
	);
};

const FieldTypeInfo: ftInfo[] = [
	{
		icon: <TitleOutlined />,
		title: "Text",
		type: FieldType.Text,
	},
	{
		icon: <EditNoteOutlined />,
		title: "Memo",
		type: FieldType.Memo,
	},
	{
		icon: <LocalAtmOutlined />,
		title: "Currency",
		type: FieldType.Currency,
	},
	{
		icon: <CalendarMonthOutlined />,
		title: "Date",
		type: FieldType.Date,
	},
	{
		icon: <InputOutlined />,
		title: "Drop Down List",
		type: FieldType.DropDownList,
	},
	{
		icon: <MoneyOutlined />,
		title: "Number",
		type: FieldType.Number,
	},
	{
		icon: <UpdateOutlined />,
		title: "Year",
		type: FieldType.Year,
	},
	{
		icon: <CheckBoxOutlined />,
		title: "Yes/No Checkbox",
		type: FieldType.YesNoCheckbox,
	},
];

interface AddFormsProps {
	mode: string;
	open: boolean;
	fields: Field[];
	handleClose: () => void;
	onSave: (form: Form) => void;
	forms: Form[];
	form?: Form;
}

interface FlaggedResourceType {
	name: string;
	hasITTechnology: boolean;
	hasChildrenWithITTechnology: boolean;
}
interface FlaggedResourceTypeLookup {
	[guid: string]: FlaggedResourceType;
}
const AddFormsDialog: FC<AddFormsProps> = (props) => {
	const [form, setForm]: [any, (value: any) => void] = useState(props.form);
	const [queryRan, setQueryRan] = useState(false);
	const [fieldsAdded, setFieldsAdded] = useState<Field[]>([]);
	const [fieldsNotAdded, setFieldsNotAdded] = useState<Field[]>([...props.fields]);
	const [fieldsValidated, setFieldsValidated] = useState(true);
	const [name, setName] = useState(props.form?.name || "");
	const [nameValidated, setNameValidated] = useState(true);
	const [nameValidatedDuplicate, setNameValidatedDuplicate] = useState(true);
	const [description, setDescription] = useState(props.form?.description || "");
	const [selectedResourceType, setSelectedResourceType] = useState<string>("All"); // "All" or "Custom"
	const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
	const [selectedIssueTypes, setSelectedIssueTypes] = useState<IssueType[]>(props.form?.issueTypes || []);
	const [selectedCategoryAndIssueTypesValidated, setSelectedCategoryAndIssueTypesValidated] = useState(true);
	const [selectedResourceTypes, setSelectedResourceTypes] = useState([]);
	const [open, setOpen] = useState(false);
	const [hiddenResourceTypes, setHiddenResourceTypes] = useState([]);
	const [dragged, setDragged] = useState<number | null>(null);
	const [customResourceTypesValidated, setCustomResourceTypesValidated] = useState(true);
	let allResourceTypes: BriefResourceTypeVO[] = [];
	let categoryAndIssueTypesSorted: any = [];

	const getLimitedResourceTypes = (node: BriefResourceTypeVO, parentCategory: number): BriefResourceTypeVO[] => {
		let result: BriefResourceTypeVO[] = [];

		const traverseTree = (node: BriefResourceTypeVO, parentCategory: number) => {
			if (node.inheritCategory ? parentCategory === 1 : node.category === 1) {
				result.push(node);
			}

			if (node.children) {
				node.children.forEach((childNode) => {
					const showChildNode = node.inheritCategory ? parentCategory === 1 : node.category === 1;
					traverseTree(childNode, showChildNode ? 1 : 0);
				});
			}
		};

		traverseTree(node, parentCategory);
		return result;
	};
	const getFlaggedResourceTypes = (node: BriefResourceTypeVO, parentCategory: number): FlaggedResourceTypeLookup => {
		let result: FlaggedResourceTypeLookup = {};

		const traverseTree = (node: BriefResourceTypeVO, parentCategory: number): boolean => {
			let retval: boolean = false;
			result[node.guid] = { name: node.name, hasChildrenWithITTechnology: false, hasITTechnology: false };
			if (node.inheritCategory ? parentCategory === 1 : node.category === 1) {
				result[node.guid].hasITTechnology = true;
			}

			if (node.children) {
				let hasChildrenWithITTechnology: boolean = false;
				node.children.forEach((childNode) => {
					const showChildNode = node.inheritCategory ? parentCategory === 1 : node.category === 1;
					let hasChildWithITTechnology: boolean = traverseTree(childNode, showChildNode ? 1 : 0);
					hasChildrenWithITTechnology = hasChildWithITTechnology || hasChildrenWithITTechnology;
				});
				result[node.guid].hasChildrenWithITTechnology = hasChildrenWithITTechnology;
				retval = result[node.guid].hasChildrenWithITTechnology;
			} else {
				retval = result[node.guid].hasITTechnology;
			}
			return retval;
		};

		traverseTree(node, parentCategory);
		return result;
	};
	// Categories
	let categoriesResult: any = useCategoriesQuery();
	let categories = [] as Array<Category>;
	if (categoriesResult.hasError) {
		categories = [] as Array<Category>;
	}
	if (categoriesResult.isSuccess) {
		categories = categoriesResult.data as Array<Category>;
		categories = _.sortBy(categories, ["name"]);
		for (let i = 0; i < categories.length; i++) {
			categoryAndIssueTypesSorted.push(categories[i]);
			if (categories[i].issueTypes && categories[i].issueTypes.length > 0) {
				for (let j = 0; j < categories[i].issueTypes.length; j++) {
					categoryAndIssueTypesSorted.push(categories[i].issueTypes[j]);
				}
			}
		}
	}

	// Resource types
	let resourceTypesResult: any = useGetResourceTypeHierarchyQuery();
	let resourceTypes: BriefResourceTypeVO;
	let flaggedResourceTypes: FlaggedResourceTypeLookup;
	if (resourceTypesResult.hasError) {
		resourceTypes = blankBriefResourceTypeVO;
	}
	if (resourceTypesResult.isSuccess) {
		resourceTypes = resourceTypesResult.data as BriefResourceTypeVO;
		allResourceTypes = getLimitedResourceTypes(resourceTypes, resourceTypes.category);
		flaggedResourceTypes = getFlaggedResourceTypes(resourceTypes, resourceTypes.category);
	}

	// Form
	const formResult = useFormQuery({ formId: props.form?.helpTicketFormID }, { skip: props.mode === "add" });
	if (formResult.isSuccess && !queryRan) {
		setQueryRan(true);
		setForm(formResult.data);
		setName((formResult.data as Form).name);
		setDescription((formResult.data as Form).description);

		let categories: Category[] = [];
		let issueTypes: IssueType[] = [];
		for (let i = 0; i < (formResult.data as Form).issueTypes.length; i++) {
			// @ts-ignore
			if ((formResult.data as Form).issueTypes[i].parentID) {
				issueTypes.push((formResult.data as Form).issueTypes[i]);
			} else {
				categories.push((formResult.data as Form).issueTypes[i] as Category);
			}
		}
		setSelectedIssueTypes(issueTypes);
		setSelectedCategories(categories as never);

		if ((formResult.data as Form).resourceTypes.length > 0 && (formResult.data as Form).resourceTypes.length < allResourceTypes.length) {
			setSelectedResourceType("Custom");
		} else {
			setSelectedResourceType("All");
		}
		let selectedResourceTypesResult: ResourceTypeVO[] = [];
		for (let i = 0; i < (formResult.data as Form).resourceTypes.length; i++) {
			let resourceType = (formResult.data as Form).resourceTypes[i];
			selectedResourceTypesResult.push(resourceType);
		}
		setSelectedResourceTypes(selectedResourceTypesResult as any);
		setFieldsAdded((formResult.data as Form).customFields);
		let fieldsNotAddedResult = [];
		for (let i = 0; i < props.fields.length; i++) {
			let found = false;
			let field;
			for (let j = 0; j < (formResult.data as Form).customFields.length; j++) {
				field = (formResult.data as Form).customFields[j];
				if (field.helpTicketFieldID === props.fields[i].helpTicketFieldID) {
					found = true;
					break;
				}
			}
			if (!found && field) {
				fieldsNotAddedResult.push(props.fields[i]);
			}
		}
		setFieldsNotAdded(fieldsNotAddedResult);
	}

	useEffect(() => {
		setCustomResourceTypesValidated(true);
	}, [selectedResourceTypes]);

	const renderTree = (node: BriefResourceTypeVO, selectedResourceTypes: BriefResourceTypeVO[], parentCategory: number, depth: number = 0) => {
		const isSelected = selectedResourceTypes.some((item) => item.guid === node.guid);
		let index = _.findIndex(hiddenResourceTypes, (e) => {
			return e === node.id;
		});

		function isSelectable(node: BriefResourceTypeVO, parentCategory: number) {
			return node.inheritCategory ? parentCategory === 1 : node.category === 1;

			// TODO: Add this back when API is updated
			// return node.category === 1 || (node.inheritCategory && parentCategory === 1);
		}

		function treeRow(node: BriefResourceTypeVO, parentCategory: number, isSelected: boolean, depth: number = 0) {
			depth = depth * 30 + 6;

			return (
				<div className={"flex flex-row text-sm items-center"}>
					<div>
						{isSelectable(node, parentCategory) ? (
							<Checkbox
								checked={isSelected}
								onChange={(event) => {
									handleCheckboxChange(event, node);
								}}
								className={"w-4 h-4"}
								sx={{
									"&.Mui-checked": {
										color: "#000000",
									},
								}}
								size={"small"}
								disableRipple={true}
							></Checkbox>
						) : (
							<div className={"w-[16px]"}></div>
						)}
					</div>
					<div className={`h-1`} style={{ minWidth: depth + "px" }} />
					<div key={node.guid} id={node.guid}>
						{node.children && depth !== 6 && (
							<div className={"-ml-1"}>
								<button
									onClick={() => {
										let result = _.cloneDeep(hiddenResourceTypes);
										if (index === -1) {
											result.push(node.id as never);
										} else {
											result.splice(index, 1);
										}
										setHiddenResourceTypes(result);
									}}
								>
									{index === -1 ? <KeyboardArrowDownOutlined /> : <KeyboardArrowUpOutlined />}
								</button>
							</div>
						)}
					</div>
					<div
						className={
							(node.children ? " font-semibold" : " font-normal") + (!isSelectable(node, parentCategory) ? " text-gray-500" : " text-[#01338D] ")
						}
					>
						{node.name}
					</div>
				</div>
			);
		}

		return flaggedResourceTypes[node.guid].hasChildrenWithITTechnology || flaggedResourceTypes[node.guid].hasITTechnology ? (
			<div className={"flex flex-col"}>
				{treeRow(node, parentCategory, isSelected, depth)}
				{Array.isArray(node.children) &&
					_.findIndex(hiddenResourceTypes, (e) => {
						return e === node.id;
					}) === -1 &&
					node.children.map((childNode) => {
						const showChildNode = childNode.inheritCategory ? parentCategory === 1 : childNode.category === 1;
						return renderTree(childNode, selectedResourceTypes, showChildNode ? 1 : 0, depth + 1);

						// TODO: Add this back when API is updated
						// const showChildNode = childNode.category === 1 || (childNode.inheritCategory && parentCategory === 1);
						// return renderTree(childNode, selectedResourceTypes, showChildNode ? 1 : 0);
					})}
			</div>
		) : null;
	};

	function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>, node: BriefResourceTypeVO) {
		let result = _.cloneDeep(selectedResourceTypes);

		// Add resource type(s)
		if (event.target.checked) {
			if (result.includes(node as never)) {
				result = result.filter((item) => item !== node);
			} else {
				result.push(node as never);
			}
		} else {
			// Remove resource type
			for (let i = 0; i < selectedResourceTypes.length; i++) {
				// @ts-ignore
				if (selectedResourceTypes[i].guid === node.guid) {
					result.splice(i, 1);
					break;
				}
			}
		}

		setSelectedResourceTypes(result);
	}

	const handleDrop = (item: Field) => {
		let resultFieldsAdded = _.cloneDeep(fieldsAdded);
		//@ts-ignore
		resultFieldsAdded.push(item.field);
		setFieldsAdded(resultFieldsAdded);

		let resultFieldsNotAdded = _.cloneDeep(fieldsNotAdded);
		let index: number = -1;
		for (let i = 0; i < resultFieldsNotAdded.length; i++) {
			// @ts-ignore
			if (resultFieldsNotAdded[i].helpTicketFieldID === item.field.helpTicketFieldID) {
				index = i;
				break;
			}
		}
		if (index !== -1) {
			resultFieldsNotAdded.splice(index, 1);
			setFieldsNotAdded(resultFieldsNotAdded);
		}

		setFieldsValidated(true);
	};

	interface DroppableAreaProps {
		onDrop: (item: Field) => void;
	}

	const DroppableArea: React.FC<DroppableAreaProps> = ({ onDrop }) => {
		const [{ isOver }, drop] = useDrop({
			accept: "FIELD",
			drop: (item: Field) => {
				onDrop(item);
			},
			collect: (monitor) => ({
				isOver: monitor.isOver(),
			}),
		});

		return fieldsAdded.length === 0 ? (
			<div ref={drop}>
				<div className={"flex items-center justify-center rounded-[8px] border border-[#01338D] border-dashed h-72 mr-6"}>
					<div className={"text-primary"}>Drag and drop fields here</div>
				</div>
			</div>
		) : (
			<Reorder.Group axis={"y"} values={fieldsAdded} onReorder={setFieldsAdded}>
				<div ref={drop} className={"flex flex-col rounded-[8px] border border-[#01338D] border-dashed min-h-72 mr-6 p-4"}>
					<div className={"w-fit"}>
						{fieldsAdded.map((field, index) => {
							return (
								<Reorder.Item key={field.helpTicketFieldID} value={field}>
									<div key={index} className={"flex flex-row items-center border border-[#D3D3D4] rounded-[8px] min-w-72 min-h-10 mb-2 px-2"}>
										<img alt={"Field type image"} src={renderFieldTypeIcon(field.fieldType)} />
										<div className={"ml-2 mr-2 grow break-all"}>{field.name}</div>
										<button
											onClick={() => {
												let resultFieldsAdded = _.cloneDeep(fieldsAdded);
												resultFieldsAdded.splice(index, 1);
												setFieldsAdded(resultFieldsAdded);

												let resultFieldsNotAdded = _.cloneDeep(fieldsNotAdded);
												resultFieldsNotAdded.push(field);
												setFieldsNotAdded(resultFieldsNotAdded);
											}}
											className={"mr-2"}
										>
											<img src={"/bin.svg"} alt={"Trash can icon to remove a field from the custom form"} className={"w-4 h-4"} />
										</button>
										<DragIndicatorIcon className={"text-[#01338D]"} />
									</div>
								</Reorder.Item>
							);
						})}
					</div>
				</div>
			</Reorder.Group>
		);
	};

	function validate() {
		let result = true;

		// Name
		if (name === "") {
			setNameValidated(false);
			result = false;
		}

		for (let i = 0; i < props.forms.length; i++) {
			if (props.forms[i].name === name && (form as Form).helpTicketFormID !== props.forms[i].helpTicketFormID) {
				setNameValidatedDuplicate(false);
				result = false;
			}
		}

		// Category and issue types
		let categoryAndIssueTypeOptions = 0;
		for (let i = 0; i < categories.length; i++) {
			categoryAndIssueTypeOptions += 1;
			for (let j = 0; j < categories[i].issueTypes.length; j++) {
				categoryAndIssueTypeOptions += 1;
			}
		}
		if (selectedCategories.length === 0 && selectedIssueTypes.length === 0 && categoryAndIssueTypeOptions > 1) {
			setSelectedCategoryAndIssueTypesValidated(false);
			result = false;
		}

		// Fields
		if (fieldsAdded.length === 0) {
			setFieldsValidated(false);
			result = false;
		}

		// Resource types
		if (selectedResourceTypes.length === 0 && selectedResourceType === "Custom") {
			setCustomResourceTypesValidated(false);
			result = false;
		}

		return result;
	}

	function saveForm() {
		if (validate() && props.onSave) {
			let result: Form = _.cloneDeep(form);

			result.name = name;
			result.description = description;
			result.customFields = fieldsAdded;

			let categoriesAndIssueTypes = [];
			for (let i = 0; i < selectedIssueTypes.length; i++) {
				categoriesAndIssueTypes.push(selectedIssueTypes[i]);
			}
			for (let i = 0; i < selectedCategories.length; i++) {
				categoriesAndIssueTypes.push(selectedCategories[i]);
			}
			result.issueTypes = categoriesAndIssueTypes;

			if (selectedResourceType === "Custom") {
				// Selected resource types can be a BriefResourceTypeVO or ResourceTypeVO
				let resourceTypes: ResourceTypeVO[] = [];
				for (let i = 0; i < selectedResourceTypes.length; i++) {
					let resourceType: any = selectedResourceTypes[i];
					let resource: ResourceTypeVO = {
						assetTemplateID: resourceType.assetTemplateID || resourceType.id,
						GUID: resourceType.GUID || resourceType.guid,
						name: resourceType.name,
					};
					resourceTypes.push(resource);
				}
				result.resourceTypes = resourceTypes;
			} else {
				let resourceTypes: ResourceTypeVO[] = [];
				for (let i = 0; i < allResourceTypes.length; i++) {
					let resourceType: any = allResourceTypes[i];
					let resource: ResourceTypeVO = {
						assetTemplateID: resourceType.id,
						GUID: resourceType.GUID || resourceType.guid,
						name: resourceType.name,
					};
					resourceTypes.push(resource);
				}
				result.resourceTypes = resourceTypes;
			}

			props.onSave(result);
			props.handleClose();
		}
	}

	return (
		<div>
			<IconButton
				className="text-primary text-2xl"
				onClick={() => {
					props.handleClose();
				}}
				disableRipple={true}
			>
				<ArrowBack />
				<span className="text-altblack">{props.mode === "add" ? "New form" : "Edit form"}</span>
			</IconButton>
			<div className={"flex flex-col p-4 bg-white rounded-[8px] drop-shadow-[0_2px_8px_rgba(138,146,163,0.16)] w-full h-full"}>
				<div className={"text-xl mb-3"}>Describe the form</div>
				<div className={"lg:grid lg:grid-cols-2 gap-10 mb-3"}>
					<div className={"flex flex-col grow"}>
						<div>Name</div>
						<textarea
							placeholder={"Type a form name"}
							className={"border p-2 rounded-[8px] border-[#D3D3D4]"}
							rows={1}
							maxLength={100}
							onChange={(event) => {
								setName(event.target.value);
								setNameValidated(true);
								setNameValidatedDuplicate(true);
							}}
							value={name}
						></textarea>
						<div className={"text-error-text"}>
							{!nameValidated && "Form name is required. Please specify a name value."}
							{!nameValidatedDuplicate && "Duplicate form name. Please make the name unique."}
							{nameValidated && nameValidatedDuplicate && <br />}
						</div>
					</div>
					<div className={"flex flex-col grow "}>
						Description (optional)
						<textarea
							placeholder={"Describe the form"}
							className={"border p-2 rounded-[8px] border-[#D3D3D4]"}
							rows={1}
							maxLength={200}
							onChange={(event) => {
								setDescription(event.target.value);
							}}
							value={description}
						></textarea>
					</div>
				</div>
				<div className={"lg:grid lg:grid-cols-2 gap-10 mb-3"}>
					<div className={"flex flex-col grow"}>
						<div>Issue category / type(s) </div>
						{categories.length === 1 && categories[0].issueTypes.length === 0 ? (
							<div>{categories[0].name}</div>
						) : (
							<FormControl>
								<Select
									labelId="demo-multiple-checkbox-label"
									id="demo-multiple-checkbox"
									multiple
									value={selectedIssueTypes}
									displayEmpty
									input={<OutlinedInput />}
									onChange={() => {
										setSelectedCategoryAndIssueTypesValidated(true);
									}}
									IconComponent={() => (
										<img src={"/down-icon.svg"} alt={"Down arrow icon"} className={"mr-2 pointer-events-none absolute right-[1px]"} />
									)}
									renderValue={() => {
										if (selectedCategories.length === 0 && selectedIssueTypes.length === 0) {
											return <em>Add issue category / type(s)</em>;
										} else {
											let resultArray = [];
											let result = "";
											for (let i = 0; i < categoryAndIssueTypesSorted.length; i++) {
												for (let j = 0; j < selectedCategories.length; j++) {
													// @ts-ignore
													if (selectedCategories[j].helpTicketIssueTypeID === categoryAndIssueTypesSorted[i].helpTicketIssueTypeID) {
														resultArray.push((selectedCategories[j] as Category).name);
													}
												}
												for (let k = 0; k < selectedIssueTypes.length; k++) {
													// @ts-ignore
													if (selectedIssueTypes[k].helpTicketIssueTypeID === categoryAndIssueTypesSorted[i].helpTicketIssueTypeID) {
														resultArray.push((selectedIssueTypes[k] as IssueType).name);
													}
												}
											}
											for (let i = 0; i < resultArray.length; i++) {
												result += resultArray[i];
												if (i < resultArray.length - 1) {
													result += ", ";
												}
											}
											return result;
										}
									}}
								>
									{categories.map((category) => (
										<div>
											<MenuItem key={category.helpTicketIssueTypeID} value={category.name} disableRipple={true}>
												<Checkbox
													checked={selectedCategories.some(
														(item) => (item as any).helpTicketIssueTypeID === category.helpTicketIssueTypeID,
													)}
													disableRipple={true}
													sx={{ "& .MuiSvgIcon-root": { fontSize: 26.75 } }}
													onChange={(event) => {
														if (event.target.checked) {
															// Add category
															if (
																_.find(
																	selectedCategories,
																	(item) => (item as any).helpTicketIssueTypeID === category.helpTicketIssueTypeID,
																) === undefined
															) {
																let result = _.cloneDeep(selectedCategories);
																result.push(category as never);
																setSelectedCategories(result);
															}

															// Add issue types
															if (category.issueTypes.length > 0) {
																let resultIssueTypes = _.cloneDeep(selectedIssueTypes);
																category.issueTypes.forEach((issueType) => {
																	if (
																		_.find(
																			resultIssueTypes,
																			(item) => (item as any).helpTicketIssueTypeID === issueType.helpTicketIssueTypeID,
																		) === undefined
																	) {
																		resultIssueTypes.push(issueType as never);
																	}
																});
																if (resultIssueTypes.length > 0) {
																	setSelectedIssueTypes(resultIssueTypes);
																}
															}
														} else {
															// Remove category
															let result = _.cloneDeep(selectedCategories);
															let index = _.findIndex(
																result,
																(item) => (item as any).helpTicketIssueTypeID === category.helpTicketIssueTypeID,
															);
															result.splice(index, 1);
															setSelectedCategories(result);
														}
													}}
												/>
												<ListItemText primary={category.name} />
											</MenuItem>
											{category.issueTypes.map((issueType) => (
												<div className={"ml-4"}>
													<MenuItem key={issueType.helpTicketIssueTypeID} value={issueType.name} disableRipple={true}>
														<Checkbox
															checked={selectedIssueTypes.some(
																(item) => (item as any).helpTicketIssueTypeID === issueType.helpTicketIssueTypeID,
															)}
															sx={{ "& .MuiSvgIcon-root": { fontSize: 26.75 } }}
															disableRipple={true}
															onChange={(event) => {
																let updatedIssueTypes = [...selectedIssueTypes];
																let updatedCategories = [...selectedCategories];

																if (event.target.checked) {
																	// Add issue type if not already included
																	if (
																		!updatedIssueTypes.some(
																			(item: IssueType) => item.helpTicketIssueTypeID === issueType.helpTicketIssueTypeID,
																		)
																	) {
																		updatedIssueTypes.push(issueType as never);
																	}
																} else {
																	// Remove issue type
																	updatedIssueTypes = updatedIssueTypes.filter(
																		(item: IssueType) => item.helpTicketIssueTypeID !== issueType.helpTicketIssueTypeID,
																	);

																	// Check if all issue types of the parent category are deselected
																	const allDeselected = category.issueTypes.every(
																		(it: IssueType) =>
																			!updatedIssueTypes.some(
																				(selectedIssueType: IssueType) =>
																					selectedIssueType.helpTicketIssueTypeID === it.helpTicketIssueTypeID,
																			),
																	);

																	if (allDeselected) {
																		// Remove the category if all its issue types are deselected
																		updatedCategories = updatedCategories.filter(
																			(cat: IssueType) => cat.helpTicketIssueTypeID !== category.helpTicketIssueTypeID,
																		);
																	}
																}

																setSelectedIssueTypes(updatedIssueTypes);
																setSelectedCategories(updatedCategories);
															}}
														/>
														<ListItemText primary={issueType.name} />
													</MenuItem>
												</div>
											))}
										</div>
									))}
								</Select>
							</FormControl>
						)}
						<div className={"text-error-text"}>
							{!selectedCategoryAndIssueTypesValidated && "Please specify an issue category / type."}
							{selectedCategoryAndIssueTypesValidated && <br />}
						</div>
					</div>
					<div className={"flex flex-col sm:grow "}>
						Resource type(s)
						<div className={"flex flex-col sm:flex-row sm:items-center lg:mt-0 mt-2 h-full"}>
							<div className={"flex"}>
								<div className={"flex flex-row items-center justify-center rounded-full border border-primary w-6 h-6"}>
									<button
										className={"rounded-full w-4 h-4 " + (selectedResourceType === "All" ? "bg-primary" : "")}
										onClick={() => {
											setSelectedResourceType("All");
										}}
									></button>
								</div>
								<div className={"ml-2"}>All resource types</div>
							</div>
							<div className={"flex sm:mt-0 mt-2"}>
								<div className={"flex flex-row items-center justify-center rounded-full border border-primary w-6 h-6 sm:ml-8"}>
									<button
										className={"rounded-full w-4 h-4 " + (selectedResourceType === "Custom" ? "bg-primary" : "")}
										onClick={() => {
											setOpen(true);
											setSelectedResourceType("Custom");
										}}
									></button>
								</div>
								<div className={"ml-2"}>Customize</div>
							</div>
						</div>
						<div className={"text-error-text"}>
							{!customResourceTypesValidated && "Please select at least one resource type."}
							{customResourceTypesValidated && <br />}
						</div>
					</div>
					<div></div>
					{selectedResourceType === "Custom" && (
						<Stack direction="row" spacing={1} useFlexGap={true} flexWrap={"wrap"} className={"lg:-mt-6"}>
							{selectedResourceTypes &&
								selectedResourceTypes.map((resourceType) => (
									<Chip
										label={(resourceType as BriefResourceTypeVO).name}
										onDelete={() => {
											let result = _.cloneDeep(selectedResourceTypes);
											let index = _.findIndex(result, (item) => (item as any).guid === (resourceType as BriefResourceTypeVO).guid);
											result.splice(index, 1);
											setSelectedResourceTypes(result);
										}}
										className={"text-[#01338D] bg-blue-100 border"}
									/>
								))}
						</Stack>
					)}
				</div>
				<div className={"my-4 h-[1px] w-full bg-[#D3D3D4]"}></div>
				<div className={"flex flex-col lg:flex-row"}>
					<div className={"grow flex flex-col mb-2"}>
						<div className={"text-xl mb-2"}>Custom form</div>
						<DroppableArea onDrop={handleDrop} />
						{!fieldsValidated && <span className={"text-error-text"}>Please select fields to link to the form.</span>}
					</div>
					<div className={"flex flex-col min-w-60"}>
						<div className={"text-xl mb-2"}>Custom fields</div>
						<div>{fieldsNotAdded.length > 0 && fieldsNotAdded.map((field, index) => <DraggableField field={field} index={index} />)}</div>
					</div>
				</div>
				<div className={"flex flex-row mt-4"}>
					<div className={"grow"} />
					<CustomButton variant={"uncontained"} ariaLabel={""} onClick={props.handleClose}>
						Cancel
					</CustomButton>
					<CustomButton
						variant={"contained"}
						ariaLabel={""}
						onClick={() => {
							saveForm();
						}}
					>
						Save Form
					</CustomButton>
				</div>
			</div>
			<Drawer
				open={open}
				anchor={"right"}
				onClose={() => {
					setOpen(false);
				}}
				sx={{
					"& .MuiBackdrop-root": {
						backgroundColor: "#171646",
						opacity: "70%!important",
					},
				}}
			>
				<Box className={"w-[100vw] md:w-[60vw]"} role="presentation">
					<div className={"p-4"}>
						<div className={"flex flex-row items-center"}>
							<div className={"text-xl mb-8 grow text-left"}>Select resource type(s)</div>
							<IconButton
								className={"absolute top-2 right-1"}
								onClick={() => {
									setOpen(false);
								}}
								disableRipple={true}
							>
								<CloseOutlined />
							</IconButton>
						</div>
						<div className={"flex flex-col sm:flex-row min-h-72 rounded-[8px] border border-[#D6D8DB] p-4"}>
							<div className={"flex flex-col w-full sm:w-2/3 mb-8 sm:mb-0"}>
								<div className={"mb-2"}>Resource Types</div>
								{/* @ts-ignore */}
								{resourceTypes && renderTree(resourceTypes, selectedResourceTypes, resourceTypes.category)}
							</div>
							<div className={"flex flex-col w-full sm:w-1/3"}>
								<div className={"mb-2"}>Selected Resource Types</div>
								<Stack direction="row" spacing={1} useFlexGap={true} flexWrap={"wrap"}>
									{selectedResourceTypes &&
										selectedResourceTypes.map((resourceType) => (
											<Chip
												label={(resourceType as BriefResourceTypeVO).name}
												onDelete={() => {
													let result = _.cloneDeep(selectedResourceTypes);
													let index = _.findIndex(
														result,
														(item) => (item as any).guid === (resourceType as BriefResourceTypeVO).guid,
													);
													result.splice(index, 1);
													setSelectedResourceTypes(result);
												}}
												className={"text-[#01338D] bg-blue-100 border"}
											/>
										))}
								</Stack>
							</div>
						</div>
					</div>
				</Box>
			</Drawer>
		</div>
	);
};

interface DraggableFieldProps {
	field: Field;
	index: number;
}

const DraggableField: React.FC<DraggableFieldProps> = ({ field, index }) => {
	const [{ isDragging }, drag] = useDrag({
		type: "FIELD",
		item: { field },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	return (
		<div
			key={index}
			ref={drag}
			style={{ opacity: isDragging ? 0.5 : 1 }}
			className={"flex flex-row items-center border border-[#D3D3D4] rounded-[8px] p-2 mb-2 min-w-60"}
		>
			<img alt={"Field type image"} src={renderFieldTypeIcon(field.fieldType)} />
			<div className={"ml-2 grow break-all"}>{field.name}</div>
			<DragIndicatorIcon className={"text-[#01338D]"} />
		</div>
	);
};

interface FieldsConfigurationProps {
	showFormsHeader: boolean;
	setShowFormsHeader: Function;
}

const FormsConfiguration: FC<FieldsConfigurationProps> = (props) => {
	const [addDialogOpen, setAddDialogOpen] = useState(false);
	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [upsertFormQuery, { isLoading }] = useUpsertFormMutation();
	const [deleteFormQuery, { isDeleteLoading }] = useDeleteFormMutation();
	const [currentForm, setCurrentForm]: [any, (value: any) => void] = useState(undefined);
	const [selectedFormId, setSelectedFormId] = useState(undefined);
	const [update, setUpdate] = useState({});
	const dispatch = useAppDispatch();

	// Forms
	const formsResult = useFormsQuery();
	let forms: Form[] = [];
	if (formsResult.isSuccess) {
		dispatch(setCategories(formsResult.data));
		forms = formsResult.data;
	}

	const upsertForm = async (form: Form) => {
		if (!isLoading) {
			try {
				await upsertFormQuery({ form: form }).unwrap();
				if (_.get(form, "helpTicketFormID") === undefined) {
					notify("New Form Added!", `"${form.name}" is available and ready to use.`, false);
				} else {
					notify("Form Updated!", `"${form.name}" is available and ready to use.`, false);
				}
			} catch (error) {
				console.error("Failed to update the form: " + error);
				notify("Failed to update the form!", `"${form.name}" has not been updated.`, true);
			}
		}
	};

	// Form
	const formResult = useFormQuery({ formId: selectedFormId }, { skip: selectedFormId === undefined });
	let selectedForm: Form = _.cloneDeep(blankForm);
	if (formResult.isSuccess) {
		selectedForm = formResult.data;
		selectedForm = { ...selectedForm, ...update };
		upsertForm(selectedForm);
		setSelectedFormId(undefined);
	}

	// Fields
	const fieldsResult = useFieldsQuery();
	let fields: Field[] = [];
	if (fieldsResult.isSuccess) {
		dispatch(setCategories(fieldsResult.data));
		fields = fieldsResult.data;
	}

	if (formsResult.isLoading || formResult.isLoading || fieldsResult.isLoading) {
		return <CommonProgress message={"Loading forms"} />;
	}

	const deleteForm = async (input: Form) => {
		if (!isDeleteLoading) {
			try {
				await deleteFormQuery({ form: input }).unwrap();
			} catch (error) {
				console.error("Failed to delete the form: " + error);
			}
		}
	};

	return (
		<div>
			{forms.length > 0 && !addDialogOpen && !editDialogOpen && !deleteDialogOpen && (
				<div className={"flex flex-wrap gap-4 break-words mb-12"}>
					<button
						title="New Form"
						className={
							"flex flex-col w-full sm:w-[250px] items-center justify-center  h-44 sm:h-60 rounded-[8px] bg-[rgba(1,51,141,0.06)] drop-shadow-[0_2px_8px_rgba(138,146,163,0.16)]"
						}
						onClick={() => {
							setCurrentForm(_.cloneDeep(blankForm));
							setAddDialogOpen(true);
							props.setShowFormsHeader(false);
						}}
					>
						<AddCircleOutlineOutlined
							sx={{
								fill: "#01338D",
								width: "32px",
								height: "32px",
							}}
						/>
						<div className="text-primary text-lg text-center">New Form</div>
					</button>
					{forms.map((item: Form) => {
						let sliderAriaLabel = `${item.name} is ${item.active ? "active" : "inactive"}`;
						return (
							<div
								className={
									"flex flex-col w-full sm:w-[250px] p-3  h-44 sm:h-60 rounded-[8px] bg-white drop-shadow-[0_2px_8px_rgba(138,146,163,0.16)]"
								}
							>
								<div className={"text-lg text-ellipsis font-semibold mb-2"}>
									{item.name.length > 50 ? item.name.substring(0, 50) + "..." : item.name}
								</div>
								<div className={"grow"}>{item.description.length > 70 ? item.description.substring(0, 70) + "..." : item.description}</div>
								<div className={"flex flex-row"}>
									<div className={"grow"}>
										<Switch
											checked={item.active}
											onChange={(event) => {
												setSelectedFormId(item.helpTicketFormID as any);
												setUpdate({ active: event.target.checked });
											}}
											inputProps={{
												"aria-label": sliderAriaLabel,
											}}
										/>
									</div>
									<IconButton
										onClick={(e) => {
											setCurrentForm(item);
											setEditDialogOpen(true);
											props.setShowFormsHeader(false);
										}}
									>
										<Edit />
									</IconButton>
									<IconButton
										onClick={() => {
											setCurrentForm(_.cloneDeep(item));
											setDeleteDialogOpen(true);
										}}
									>
										<DeleteForeverOutlined
											sx={{
												fill: "red",
											}}
										/>
									</IconButton>
								</div>
							</div>
						);
					})}
				</div>
			)}
			{forms.length == 0 && !(addDialogOpen || editDialogOpen || deleteDialogOpen) && (
				<NoForms
					onClick={() => {
						setCurrentForm(blankForm);
						setAddDialogOpen(true);
						props.setShowFormsHeader(false);
					}}
				/>
			)}
			{addDialogOpen && (
				<AddFormsDialog
					mode={"add"}
					open={addDialogOpen}
					fields={fields}
					onSave={(form) => {
						upsertForm(form);
						setCurrentForm(undefined);
					}}
					handleClose={() => {
						setAddDialogOpen(false);
						props.setShowFormsHeader(true);
					}}
					form={currentForm}
					forms={forms}
				/>
			)}
			{editDialogOpen && (
				<AddFormsDialog
					mode={"edit"}
					fields={fields}
					open={editDialogOpen}
					onSave={(form) => {
						upsertForm(form);
						setCurrentForm(undefined);
					}}
					handleClose={() => {
						setEditDialogOpen(false);
						props.setShowFormsHeader(true);
					}}
					form={currentForm}
					forms={forms}
				/>
			)}
			{deleteDialogOpen && (
				<GenericDialog
					open={deleteDialogOpen}
					title={"Delete Form?"}
					onClose={() => {
						setDeleteDialogOpen(false);
					}}
					onConfirm={() => {
						setDeleteDialogOpen(false);
						deleteForm(currentForm);
					}}
					confirmLabel={"Delete Form"}
					content={'Are you sure you want to delete the form "' + _.get(currentForm, "name", "") + '"'}
					confirmColor={"red"}
				/>
			)}
		</div>
	);
};

export default FormsConfiguration;
