import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import EventEmitter from "events";
import _ from "lodash";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { getTicketStatus, getTicketStatusColor, getTicketStatusIcon, getTicketStatusTextColor } from "../../utilities/helpTicketUtils";

interface StatusTextProps {
	status: string;
	disabled: boolean;
	children?: ReactNode;
}
const StatusText: React.FC<StatusTextProps> = ({ status, disabled, children }): ReactElement<any, any> | null => {
	if (!disabled) {
		switch (status) {
			case "Open":
				return <span className="text-open-dark">{children}</span>;
				break;
			case "OnHold":
				return <span className="text-on-hold-dark">{children}</span>;
				break;
			case "InProgress":
				return <span className="text-in-progress-dark">{children}</span>;
				break;
			case "Closed":
			default:
				return <span>{children}</span>;
				break;
		}
	} else {
		return <span className="text-grey-light">{children}</span>;
	}
};

const DEFAULT_MENU_OFFSET = "40px";
function calcPosition(id: string, minHeight: number) {
	// Select the button element
	const buttonElem = document.getElementById(id);
	const boundingElem = document.getElementById("ticketsContainer");
	if (!_.isNil(buttonElem) && !_.isNil(boundingElem)) {
		// Get the button's bounding rectangle
		const rect = buttonElem.getBoundingClientRect();
		const brect = boundingElem.getBoundingClientRect();
		// Get the viewport height
		// const viewportHeight = window.innerHeight;
		const viewportHeight = brect.y + brect.height;
		// console.error("button rect: ", JSON.stringify(rect));
		// console.error("boundaryrect: ", JSON.stringify(brect));
		if (!_.isNil(viewportHeight) && !_.isNil(_.get(rect, "bottom"))) {
			// Calculate the distance from the bottom of the expected dropd down to the bottom of the viewport
			const distance = Math.round(viewportHeight - (rect.bottom + minHeight));
			// console.log("distance: " + distance);
			if (distance < 0) {
				// we want to put menu above button so return - top value relative to the position of the top
				// of the button
				return "-" + Math.round(minHeight) + "px";
			} else {
				// we have room under button to show dropdown so top value is just the height.
				return "" + Math.round(rect.height) + "px";
			}
		}
	}
	return DEFAULT_MENU_OFFSET;
}

const eventEmitter = new EventEmitter();
interface StatusSelectProps {
	onChange: (value: string) => void;
	status: string;
	disabled: boolean;
	keyIdx: string;
}
const StatusSelect: React.FC<StatusSelectProps> = (props): ReactElement<any, any> | null => {
	const dc = "absolute left-0 z-30 bg-white border border-gray-200 min-w-[10rem] rounded-[4px] drop-shadow-[0_2px_8px_rgba(138,146,163,0.16)]";
	const [showDropdown, setShowDropdown] = useState(false);
	const [dropdownClasses, setDropdownClasses] = useState(dc);
	const [position, setPosition] = useState(DEFAULT_MENU_OFFSET);
	useEffect(() => {
		const handleToggle = (data: { [key: string]: string }) => {
			if (props.keyIdx !== data.key) {
				setShowDropdown(false);
			} else {
				setShowDropdown(true);
			}
		};
		eventEmitter.on("toggleState", handleToggle);

		return () => {
			eventEmitter.off("toggleState", handleToggle);
		};
	}, []);
	function handleClick() {
		if (!showDropdown) {
			eventEmitter.emit("toggleState", { key: props.keyIdx });
		} else {
			setShowDropdown(false);
		}
	}
	useEffect(() => {
		if (showDropdown) {
			setPosition(calcPosition(props.keyIdx, 189) as any);
		} else {
			setPosition(DEFAULT_MENU_OFFSET);
		}
	}, [showDropdown]);

	return (
		<div className={"dropdown-container relative"}>
			<Backdrop sx={{ color: "#fff", backgroundColor: "transparent", zIndex: 29 }} open={showDropdown} onClick={handleClick} />
			<Button
				id={props.keyIdx}
				className={
					"flex items-center justify-between w-[148px] h-8 px-2 rounded-lg " +
					getTicketStatusColor(props.status) +
					" text-" +
					getTicketStatusTextColor(props.status) +
					" hover:" +
					getTicketStatusColor(props.status)
				}
				onClick={() => {
					handleClick();
				}}
				disabled={props.disabled}
				disableRipple={true}
				// sx={{
				// 	"&:hover": {
				// 		backgroundColor: "unset !important",
				// 	},
				// }}
			>
				<img src={getTicketStatusIcon(props.status)} alt={"Ticket status icon"} className="w-6 h-6" />
				<div
					className={
						"flex-1 text-left ml-2   normal-case font-openSans font-semibold text-sm md:text-nowrap text-" + getTicketStatusColor(props.status)
					}
				>
					<StatusText status={props.status} disabled={props.disabled}>
						{getTicketStatus(props.status).name}
					</StatusText>
				</div>
				{props.status === "Closed" ? (
					<div className={"w-6 h-6 ml-auto"}> </div>
				) : (
					<div className={"w-6 h-6 ml-auto"}>
						<KeyboardArrowDownIcon />
					</div>
				)}
			</Button>

			{showDropdown && (
				<Box className={dc} sx={{ top: position }} id={"statusDropDown" + props.keyIdx}>
					<ul>
						<li>
							<button
								onClick={() => {
									props.onChange("Open");
									setShowDropdown(false);
								}}
								className={"hover:bg-[#F9F9F9] w-full"}
							>
								<div className={"flex flex-row items-center p-3"}>
									<img src={"/sign-badge-circle-black.svg"} alt={"Ticket status icon"} className={"mr-4 text-altblack"}></img>
									<div className={"font-bold md:text-nowrap grow text-left"}>Open</div>
								</div>
							</button>
						</li>
						<li>
							<button
								onClick={() => {
									props.onChange("InProgress");
									setShowDropdown(false);
								}}
								className={"hover:bg-[#F9F9F9] w-full"}
							>
								<div className={"flex flex-row items-center p-3"}>
									<img src={"/in-progress-icon-black.svg"} alt={"Ticket status icon"} className={"mr-4"}></img>
									<div className={"font-roboto break-words font-semibold text-base  md:text-nowrap grow text-left"}>In Progress</div>
								</div>
							</button>
						</li>
						<li>
							<button
								onClick={() => {
									props.onChange("OnHold");
									setShowDropdown(false);
								}}
								className={"hover:bg-[#F9F9F9] w-full"}
							>
								<div className={"flex flex-row items-center p-3"}>
									<img src={"/subtract-circle-black.svg"} alt={"Ticket status icon"} className={"mr-4"}></img>
									<div className={"text-left font-roboto font-semibold text-base  md:text-nowrap grow"}>On Hold</div>
								</div>
							</button>
						</li>
						<li>
							<button
								onClick={() => {
									props.onChange("Closed");
									setShowDropdown(false);
								}}
								className={"hover:bg-[#F9F9F9] w-full"}
							>
								<div className={"flex flex-row items-center p-3"}>
									<img src={"/check-circle-black.svg"} alt={"Ticket status icon"} className={"mr-4"}></img>
									<div className={"text-left font-roboto font-semibold text-base  md:text-nowrap grow"}>Closed</div>
								</div>
							</button>
						</li>
					</ul>
				</Box>
			)}
		</div>
	);
};

export default StatusSelect;
