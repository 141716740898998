import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { blankPatron, Patron } from "../models/Patron.model";
import { UserType } from "../models/user.model";
import { RootState } from "../store";

interface UserSliceState {
	name: string;
	userType: UserType;
	loginUserPatron: Patron;
}
// Define the initial state using that type
const initialState: UserSliceState = {
	name: "Guest",
	userType: UserType.Submitter,
	loginUserPatron: _.cloneDeep(blankPatron),
};

const userSlice = createSlice({
	name: "user",
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setUser: (state, payload) => {
			return { ...state, user: payload.payload };
		},
		setLoginUserPatron: (state, payload) => {
			return { ...state, loginUserPatron: payload.payload };
		},
	},
});

// export const selectName = (state: RootState): string => state.user.message;
// export const selectUserType = (state: RootState): UserType => state.user.createDate;
export const selectLoginUserPatron = (state: RootState): Patron => {
	return state.user.loginUserPatron;
};
export const { setUser, setLoginUserPatron } = userSlice.actions;

export default userSlice.reducer;
