import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hook";
import { GrantsModel } from "../../models/grants.model";
import { UserType } from "../../models/user.model";
import { isDestinyAdmin, isDistrictUser, UserLoginModel } from "../../models/userLogin.model";
import { selectUserGrants, selectUserLogin, setUserType } from "../../slices/AuthSlice";
import { setPermissions } from "../../utilities/appContext";

const Grants: FC<{}> = () => {
	const grants: GrantsModel | undefined = useAppSelector(selectUserGrants);
	const user: UserLoginModel | undefined = useAppSelector(selectUserLogin);
	let dispatch = useAppDispatch();

	function determineUserType(user: UserLoginModel, grants: GrantsModel): UserType {
		let retval: UserType = UserType.Unknown;
		if (grants !== undefined) {
			if (isDestinyAdmin(user)) {
				retval = UserType.Admin;
			} else if (isDistrictUser(user)) {
				retval = UserType.Agent;
			} else {
				if (grants.permissions.includes("AssetManageHelpTickets") && grants.permissions.includes("AssetCreateHelpTickets")) {
					retval = UserType.Agent;
				} else if (grants.permissions.includes("AssetCreateHelpTickets") || grants.permissions.includes("CatAssetCreateHelpTicketForSelf")) {
					retval = UserType.Submitter;
				}
			}
		}
		return retval;
	}
	useEffect(() => {
		if (grants && user) {
			if (isDistrictUser(user) || isDestinyAdmin(user)) {
				let permissions = [...grants.permissions, ...["AssetCreateHelpTickets", "CatAssetCreateHelpTicketForSelf", "AssetManageHelpTickets"]];
				setPermissions(permissions);
			} else {
				setPermissions(grants.permissions);
			}
			dispatch(setUserType(determineUserType(user, grants)));
		}
	}, [grants]);
	return <div> </div>;
};

export default Grants;
