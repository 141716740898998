export interface HelpTicketOutboundSMTPServerSettingsVO {
	displayName: string;
	replyToEmailAddress: string;
}

export const blankHelpTicketOutboundSMTPServerSettingsVO = {
	displayName: "",
	replyToEmailAddress: "",
};

export interface HelpTicketInboundSMTPServerSettingsVO {
	smtpServerAddress: string;
	port: number;
	connectionType: number;
	authenticationUserID: string;
	authenticationPassword: string;
	allowUpsertFromEmail: boolean;
}

export const blankHelpTicketInboundSMTPServerSettingsVO = {
	smtpServerAddress: "",
	port: 0,
	connectionType: 0,
	authenticationUserID: "",
	authenticationPassword: "",
	allowUpsertFromEmail: false,
};

export interface HelpTicketSMTPServerSettingsVO {
	outboundSettings?: HelpTicketOutboundSMTPServerSettingsVO;
	inboundSettings?: HelpTicketInboundSMTPServerSettingsVO;
}

export const blankHelpTicketSMTPServerSettingsVO = {
	outboundSettings: blankHelpTicketOutboundSMTPServerSettingsVO,
	inboundSettings: blankHelpTicketInboundSMTPServerSettingsVO,
};

export interface HelpTicketEmailRecepientsVO {
	newTicketCreated?: HelpTicketEmailRecepientVO;
	requesterComments?: HelpTicketEmailRecepientVO;
	agentComments?: HelpTicketEmailRecepientVO;
	agentChangesStatus?: HelpTicketEmailRecepientVO;
	agentChangesPriority?: HelpTicketEmailRecepientVO;
}

export interface HelpTicketEmailRecepientVO {
	subject: string;
	body: string;
	active: boolean;
}

export interface HelpTicketEmailNotificationSettingsVO {
	requesters?: HelpTicketEmailRecepientsVO;
	agents?: HelpTicketEmailRecepientsVO;
	admins?: HelpTicketEmailRecepientsVO;
}

export const blankHelpTicketEmailRecepientVO: HelpTicketEmailRecepientVO = {
	subject: "",
	body: "",
	active: false,
};

export const blankHelpTicketEmailRecepientsVO: HelpTicketEmailRecepientsVO = {
	newTicketCreated: blankHelpTicketEmailRecepientVO,
	requesterComments: blankHelpTicketEmailRecepientVO,
	agentComments: blankHelpTicketEmailRecepientVO,
	agentChangesStatus: blankHelpTicketEmailRecepientVO,
	agentChangesPriority: blankHelpTicketEmailRecepientVO,
};

export const blankHelpTicketEmailNotificationSettingsVO: HelpTicketEmailNotificationSettingsVO = {
	requesters: blankHelpTicketEmailRecepientsVO,
	agents: blankHelpTicketEmailRecepientsVO,
	admins: blankHelpTicketEmailRecepientsVO,
};
