import { LinearProgress } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useUploadFileMutation } from "../../utilities/apiApi";

export interface UploadFile {
	helpTicketGuid: string;
	file: File;
}
export interface UploadSpinnerProps {
	color?: undefined | "primary" | "secondary" | "error" | "info" | "success" | "warning";
	size?: number;
	uploadFiles: Array<UploadFile>;
	onComplete: () => void;
}
export default function UploadSpinner(props: UploadSpinnerProps) {
	const [maxSize, setMaxSize] = useState(0);
	// const [upFile, setUpFile] = useState(undefined)
	const [uploading, setUploading] = useState(false);
	const [progressArray, setProgressArray] = useState([0, 100]);
	const [count, setCount] = useState(0);
	const [successCount, setSuccessCount] = useState(0);
	const [message, setMessage] = useState("");
	const [percent, setPercent] = useState(0);
	const [errors, setErrors]: [any, (value: any) => void] = useState([]);
	const [uploadFiles, uploadFileResult] = useUploadFileMutation();

	useEffect(() => {
		function totalSize(files: UploadFile[]) {
			let sum: number = 0;
			for (let i = 0; i < files.length; i++) {
				sum += files[i].file.size;
			}
			return sum;
		}
		function generateProgressArray(totalSize: number, files: UploadFile[]) {
			let retval: number[] = [0];
			let accum: number = 0;
			for (let i = 0; i < files.length; i++) {
				let percent = Math.round((files[i].file.size / totalSize) * 100);
				accum += percent;
				retval.push(accum);
			}
			retval.splice(retval.length - 1, 1, 100); //ensure last is 100
			// console.log("ProgressArray: " + JSON.stringify(retval));
			return retval;
		}
		let total = totalSize(props.uploadFiles);
		setMaxSize(total);
		setProgressArray(generateProgressArray(total, props.uploadFiles));
	}, [props.uploadFiles]);

	useEffect(() => {
		function uploadAFile(upFile: UploadFile): void {
			uploadFiles(upFile)
				.then((uploadResult: any) => {
					setCount(count + 1);
				})
				.catch((error: any) => {
					setErrors([...errors, "upload of '" + _.get(upFile, "file.name", "file") + "' failed"]);
					setCount(count + 1);
				});
		}
		// if no errors close spinner after 3 seconds
		if (count == props.uploadFiles.length) {
			setPercent(progressArray[count]);
			if (errors.length === 0) {
				setTimeout(() => {
					props.onComplete();
				}, 3000);
			}
		} else {
			// setUpFile(props.uploadFiles[count] as any)
			setPercent(progressArray[count]);
			setMessage(`Uploading ${count + 1} of ${props.uploadFiles.length}: ${props.uploadFiles[count].file.name}`);
			uploadAFile(props.uploadFiles[count]);
		}
	}, [count, props.uploadFiles]);

	return (
		<div className={"absolute inset-0 bg-gray-800 bg-opacity-50 pointer-events-none"}>
			<div id="UploadSpinner" className="absolute inset-0 z-20 flex items-center justify-center pointer-events-auto">
				<div className="bg-white p-8 w-5/6 m:w-1/2  xl:w-1/2 rounded shadow-lg">
					<h2 className="text-2xl font-semibold">Uploading Files ...</h2>
					<div className="flex flex-col ">
						<div className="flex flex-row m-4 items-center justify-center">
							<div className={"ml-auto text-lg mr-2"}>{"0%"}</div>
							<LinearProgress className={"w-[90%]"} variant="determinate" value={percent} color={props.color || "primary"} />
							<div className={"text-lg ml-2"} hidden={percent === 0}>
								{"" + percent + "%"}
							</div>
						</div>
						<div className="flex align-center text-left text-gray-700 text-xl">
							<p className="break-all">{message || ""}</p>
						</div>
						{errors && errors.length > 0 && (
							<div className="flex w-full  mb-4 text-error-text text-lg">
								<ul className="text-left w-full ml-4">
									{errors.map((item: string) => {
										return (
											<li>
												<p className="text-left break-all">{item}</p>
											</li>
										);
									})}
								</ul>
							</div>
						)}
						{errors && errors.length > 0 && (
							<div className="flex flex-col items-center text-center ">
								<button
									className={
										"flex align-center text-center items-center rounded-full p-2 px-4 font-rubik-medium text-lg font-medium text-white bg-primary"
									}
									aria-label={"Acknowledge Errors"}
									onClick={() => {
										props.onComplete();
									}}
								>
									&nbsp;OK&nbsp;
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
