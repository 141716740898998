import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
// NOTE: background image is base64 encoding of checkmark.svg
const CustomSwitch = styled(Switch)(({ theme }) => ({
	width: 68,
	height: 44,
	"& .MuiSwitch-switchBase": {
		"& + .MuiSwitch-track": {
			position: "relative",
			height: 22,
			width: 39,
			top: -5,
			borderRadius: 11,
		},
		"& .MuiSwitch-thumb": {
			height: 19,
			width: 19,
			position: "relative",
			left: 4,
			backgroundColor: "#FFF",
			backgroundPosition: "center",
		},
	},
	"& .MuiSwitch-switchBase.Mui-checked": {
		color: "#FFF",
		"& + .MuiSwitch-track": {
			backgroundColor: "#01338D",
			position: "relative",
			height: 22,
			width: 39,
			top: -5,
			borderRadius: 11,
		},
		"& .MuiSwitch-thumb": {
			height: 19,
			width: 19,
			position: "relative",
			left: 1,
			backgroundColor: "#FFF",
			backgroundImage:
				'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEwIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNy4zNjAwNiAxLjAzQzcuNzcwMDYgMC40OTAwMDMgOC41NTAwNiAwLjM3MDAwMyA5LjExMDA2IDAuNzcwMDAzQzkuNjcwMDYgMS4xNyA5Ljc5MDA2IDEuOTIgOS4zODAwNiAyLjQ2TDUuMzkwMDYgNy43M0M1LjA2MDA2IDguMTcgNC40MzAwNiA4LjI2IDMuOTkwMDYgNy45MkMzLjk2MDA2IDcuOSAzLjkzMDA2IDcuODcgMy45MDAwNiA3Ljg0TDAuNzQwMDU5IDQuNzhDMC4yNTAwNTkgNC4zMSAwLjI1MDA1OSAzLjU0IDAuNzQwMDU5IDMuMDdDMS4yMzAwNiAyLjYgMi4wMzAwNiAyLjYgMi41MTAwNiAzLjA3TDQuNDEwMDYgNC45MUw3LjM1MDA2IDEuMDNINy4zNjAwNloiIGZpbGw9IiMwMTMzOEQiLz4KPC9zdmc+Cg==")',
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center",
		},
	},
}));
export default CustomSwitch;
