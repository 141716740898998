import { ErrorOutlineOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Slide, SlideProps } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../hook";
import { closeNotification, closeNotificationDialog, openNotification, selectNotification } from "../../slices/NotificationSlice";
import store from "../../store";
import GenericDialog from "../GenericDialog/GenericDialog";

export const notify = (title: string, message: string, isError: boolean, timeout: number | null = 5000) => {
	let open = true;
	store.dispatch(openNotification({ title, message, timeout: timeout, notificationOpen: open, notificationIsError: isError }));
};

export const notifyList = (title: string, list: string[], isError: boolean, timeout: number | null = 5000) => {
	let open = true;
	store.dispatch(openNotification({ title, list: list, timeout: timeout, notificationOpen: open, notificationIsError: isError }));
};

export const notifyDialog = (title: string, message: string, isError: boolean, callback: () => void) => {
	store.dispatch(openNotification({ dialogTitle: title, dialogMessage: message, dialogOpen: true, dialogIsError: isError, callback }));
};

function SlideTransition(props: SlideProps) {
	return <Slide {...props} direction="left" />;
}

const Notification: FC<{}> = () => {
	const props = useAppSelector(selectNotification);
	const dispatch = useAppDispatch();

	const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
		if (reason !== "clickaway") {
			dispatch(closeNotification(undefined));
		}
	};

	const handleCloseDialog = (event: React.SyntheticEvent | Event, reason?: string) => {
		if (reason !== "clickaway") {
			dispatch(closeNotificationDialog(undefined));
		}
	};

	return (
		<div>
			<Snackbar
				data-testid={"Notification"}
				className={"w-full sm:w-5/6 md:w-1/2 lg:w-1/3 rounded-[8px] drop-shadow-[0_2px_12px_rgba(138,146,163,0.28)] border max-w-fit"}
				open={props.notificationOpen}
				TransitionComponent={SlideTransition}
				autoHideDuration={props.timeout}
				onClose={handleClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
			>
				<div className="flex flex-col relative h-full bg-white text-gray-500 rounded-[8px] ">
					<div className="flex flex-row p-4 text-altblack">
						<div className={"mr-4"}>
							{!props.notificationIsError ? (
								<img src={"/alarm.svg"} alt={"Alarm icon for a notification"} className={"min-w-8 min-h-8"} />
							) : (
								<ErrorOutlineOutlined sx={{ width: "1em", height: "1em", color: "red" }} />
							)}
						</div>
						<div className={"grow"}>
							<div className={"grow mb-4"}>
								<span className="text-base font-normal text-altblack ">{props.title}</span>
							</div>
							<div className={""}>
								{props.message && <p className={"text-sm font-roboto text-altblack leading-[1.125rem]"}>{props.message}</p>}
								{props.list && (
									<ul className={"list-disc text-sm font-roboto text-altblack leading-[1.125rem]"}>
										{props.list.map((item, index) => (
											<li key={index}>
												<p>{item}</p>
											</li>
										))}
									</ul>
								)}
							</div>
						</div>
						<div className={"invisible"}>
							{!props.notificationIsError ? (
								<img src={"/alarm.svg"} alt={"Alarm icon for a notification"} />
							) : (
								<ErrorOutlineOutlined sx={{ width: "1em", height: "1em", color: "red" }} />
							)}
						</div>
					</div>
					<div className="absolute top-0 right-4 w-3 h-3 ">
						<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} disableRipple={true}>
							<CloseIcon fontSize="small" />
						</IconButton>
					</div>
				</div>
			</Snackbar>
			<GenericDialog
				open={props.dialogOpen || false}
				title={props.dialogTitle}
				onClose={(event: any) => {
					if (props.callback) {
						props.callback();
					}
					handleCloseDialog(event, "");
				}}
				onConfirm={(event: any) => {
					if (props.callback) {
						props.callback();
					}
					handleCloseDialog(event, "");
				}}
				content={props.dialogMessage}
				confirmColor={"red"}
				isError={true}
				showCancel={false}
			/>
		</div>
	);
};

export default Notification;
