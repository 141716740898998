import HelpTicketFull, { blankHelpTicketFull, CustomFieldValue } from "./helpticket.model";

export interface HelpTicketForm extends HelpTicketFull {
	addFiles?: File[];
	categoryId?: number;
	categoryName?: string;
	issueTypeId?: number;
	issueTypeName?: string;
	issueTypeRequired: boolean;
	forWhoId?: string;
	forWhoName?: string;
	deviceUserEntered?: boolean;
	deviceSearchString?: string;
	trackResponsible?: boolean;
	responsibleUserId?: string;
	responsibleUserName?: string;
	fieldValues?: { [key: string]: CustomFieldValue };
	errorMessages?: any[];
}

export const blankHelpTicketForm: HelpTicketForm = { ...blankHelpTicketFull, issueTypeRequired: false };
