import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";
import { Button, Checkbox, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import _ from "lodash";
import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../hook";
import {
	DateForm,
	FilterForm,
	FilterFormKey,
	RequestTypeForm,
	ResourceTypeForm,
	SiteForm,
	StatusForm,
	statusNames,
	TicketNumberForm,
} from "../../models/filterForm.model";
import { selectHelpTicketFilterForm, setHelpTicketFilterForm } from "../../slices/FiltersSlice";
import GenericFilterListItem from "../GenericFilterListItem/GenericFilterListItem";

interface StatusesProps {
	onChange: Function;
	filterForm: FilterForm;
}
interface DateFromToProps {
	onChange: Function;
	dateForm: DateForm;
}

const DateFromTo: FC<DateFromToProps> = (props) => {
	let dates: any = _.cloneDeep(props.dateForm);

	// if (dates.to === "") {
	// 	dates.to = dayjs().format();
	// }
	// if (dates.from === "") {
	// 	dates.from = dayjs().format();
	// }
	return (
		<div data-testid="DateFromTo">
			<div className="mb-2">
				<DateTimePicker
					label={"From "}
					onChange={(value) => {
						dates.from = value;
						props.onChange(dates);
					}}
					value={dayjs(dates.from)}
					views={["year", "day", "month"]}
					slotProps={{ popper: { placement: "auto" } }}
				/>
			</div>
			<div>
				<DateTimePicker
					label={"To "}
					onChange={(value) => {
						dates.to = value;
						props.onChange(dates);
					}}
					value={dayjs(dates.to)}
					views={["year", "day", "month"]}
					slotProps={{ popper: { placement: "auto" } }}
				/>
			</div>
			<Button
				onClick={() => {
					dates.to = "";
					dates.from = "";
					props.onChange(dates);
				}}
			>
				Reset
			</Button>
		</div>
	);
};

const StatusCheckList: FC<StatusesProps> = (props) => {
	let statuses: any = _.cloneDeep(props.filterForm.statuses);
	return (
		<div data-testid="StatusCheckList">
			<List className={"px-2 bg-white"} dense autoFocus={true}>
				{statuses &&
					Object.keys(statuses).map((value, idx) => {
						const labelId = `checkbox-status-filter-${value}`;
						return (
							<ListItem key={"value" + idx} alignItems="flex-start" disablePadding>
								<ListItemButton disableRipple={true} className={"h-4 mb-2"}>
									<Checkbox
										icon={<CheckBoxOutlineBlank className={"text-[#979797]"} />}
										checkedIcon={<CheckBoxOutlined />}
										edge="start"
										checked={statuses[value]}
										inputProps={{
											"aria-labelledby": labelId,
										}}
										disableRipple={true}
										onChange={(event: any) => {
											if (event.target.checked !== undefined) {
												statuses[value] = event.target.checked;
												props.onChange(statuses);
											}
										}}
										onKeyDown={(e) => {
											if (e.key === "Enter") {
												e.preventDefault();
												// write your functionality here
											}
										}}
										sx={{ "& .MuiSvgIcon-root": { fontSize: 26.75 } }}
									/>

									<ListItemText id={labelId}>
										<div className={"text-bold font-rubik"}>{statusNames[value as string]}</div>
									</ListItemText>
								</ListItemButton>
							</ListItem>
						);
					})}
			</List>
		</div>
	);
};
const NotImplementedListItem: FC<StatusesProps> = (props) => {
	return (
		<div className="">
			<span className="text-xl text-altblack">Not Implemented</span>
		</div>
	);
};
interface FilterFormListProps {
	expandedPanels: string[];
	onChangePanels: Function;
}

const FilterFormList: FC<FilterFormListProps> = (props) => {
	const filterForm: FilterForm = useAppSelector(selectHelpTicketFilterForm);
	const dispatch = useAppDispatch();
	const updateFilterForm = (name: FilterFormKey, value: any) => {
		let tempFilter: FilterForm = _.cloneDeep(filterForm);
		tempFilter[name] = value;
		dispatch(setHelpTicketFilterForm(tempFilter));
	};
	const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
		let idx = _.findIndex(props.expandedPanels, (item) => item === panel);
		if (newExpanded === true) {
			if (idx < 0) {
				let x = _.cloneDeep(props.expandedPanels);
				x.push(panel as never);
				props.onChangePanels(x);
			}
		} else if (newExpanded === false) {
			if (idx >= 0) {
				let x = _.cloneDeep(props.expandedPanels);
				x.splice(idx, 1);
				props.onChangePanels(x);
			}
		}

		// setExpandedPanel(newExpanded ? panel : "none");
	};
	return (
		<List className={"h-full"}>
			<GenericFilterListItem
				className={"mb-3"}
				panelName="panel1"
				handleChange={handleChange("panel1")}
				name={"Status"}
				updateFilterForm={updateFilterForm}
				expanded={props.expandedPanels}
			>
				<StatusCheckList
					filterForm={filterForm}
					onChange={(statuses: StatusForm) => {
						updateFilterForm("statuses", statuses);
					}}
				/>
			</GenericFilterListItem>
			<GenericFilterListItem
				className={"mb-3"}
				panelName="panel2"
				handleChange={handleChange("panel2")}
				name={"Request Type "}
				updateFilterForm={updateFilterForm}
				expanded={props.expandedPanels}
			>
				<NotImplementedListItem
					filterForm={filterForm}
					onChange={(requestTypes: RequestTypeForm) => {
						updateFilterForm("requestTypes", requestTypes);
					}}
				/>
			</GenericFilterListItem>
			<GenericFilterListItem
				className={"mb-3"}
				panelName="panel3"
				handleChange={handleChange("panel3")}
				name={"Resource Type "}
				updateFilterForm={updateFilterForm}
				expanded={props.expandedPanels}
			>
				<NotImplementedListItem
					filterForm={filterForm}
					onChange={(value: ResourceTypeForm) => {
						updateFilterForm("resourceType", value);
					}}
				/>
			</GenericFilterListItem>
			<GenericFilterListItem
				className={"mb-3"}
				panelName="panel4"
				handleChange={handleChange("panel4")}
				name={"Created Date "}
				updateFilterForm={updateFilterForm}
				expanded={props.expandedPanels}
			>
				<DateFromTo
					dateForm={filterForm.createdDate}
					onChange={(createdDate: DateForm) => {
						updateFilterForm("createdDate", createdDate);
					}}
				/>
			</GenericFilterListItem>
			<GenericFilterListItem
				className={"mb-3"}
				panelName="panel5"
				handleChange={handleChange("panel5")}
				name={"Site "}
				updateFilterForm={updateFilterForm}
				expanded={props.expandedPanels}
			>
				<NotImplementedListItem
					filterForm={filterForm}
					onChange={(value: SiteForm) => {
						updateFilterForm("site", value);
					}}
				/>
			</GenericFilterListItem>
			<GenericFilterListItem
				className={"mb-3"}
				panelName="panel6"
				handleChange={handleChange("panel6")}
				name={"Ticket Number "}
				updateFilterForm={updateFilterForm}
				expanded={props.expandedPanels}
			>
				<NotImplementedListItem
					filterForm={filterForm}
					onChange={(value: TicketNumberForm) => {
						updateFilterForm("ticketNumber", value);
					}}
				/>
			</GenericFilterListItem>
			<GenericFilterListItem
				className={"mb-3"}
				panelName="panel7"
				handleChange={handleChange("panel7")}
				name={"Completed Date "}
				updateFilterForm={updateFilterForm}
				expanded={props.expandedPanels}
			>
				<DateFromTo
					dateForm={filterForm.completedDate}
					onChange={(completedDate: DateForm) => {
						updateFilterForm("completedDate", completedDate);
					}}
				/>
			</GenericFilterListItem>
		</List>
	);
};

export default FilterFormList;
