import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import _ from "lodash";
import { Navigate, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hook";
import { UserType } from "../../models/user.model";
import { selectAccessToken, selectUserType } from "../../slices/AuthSlice";

export interface ProtectedRouteProps {
	allowedusertypes: Array<UserType>;
	notallowedpath?: string;
	children: React.ReactNode;
}

export const ProtectedRoute = (props: ProtectedRouteProps): ReactJSXElement => {
	const navigate = useNavigate();
	const userType: UserType = useAppSelector(selectUserType);
	const accessToken: string | undefined = useAppSelector(selectAccessToken);
	const isAuthenticated: boolean = accessToken && accessToken.length > 0 ? true : false;
	const isAllowed: boolean = _.includes(props.allowedusertypes || [], userType);
	let redirectPath = "/";
	if (isAuthenticated && isAllowed) {
		return <div {...props}></div>;
	}
	if (isAuthenticated && !isAllowed) {
		return <Navigate to={"/"} replace={true} />; // the current entry in the history stack will be replaced with the new one with { replace: true }
	}
	// const renderComponent = () => <Redirect to={{ pathname: redirectPath }} />
	// return <Route {...props} Component={renderComponent}   />
	return <Navigate to={redirectPath} />;
};

export default ProtectedRoute;
