import dayjs from "dayjs";
import _ from "lodash";
import { FieldType } from "../models/fields.model";
import HelpTicketFull, {
	AssigneeMenuItem,
	blankHelpTicketFull,
	CreateHelpTicket,
	DestinyUser,
	HelpTicketAssigneeVO,
	NumberNamePair,
} from "../models/helpticket.model";
import { HelpTicketForm } from "../models/helpTicketForm.model";
import { Patron } from "../models/Patron.model";
import { getDateFormat } from "./appContext";

const localizedDate = (dateTime: string) => {
	let destinyDateFormat = getDateFormat();
	return dayjs(dateTime).format(destinyDateFormat);
};

export function getTicketStatus(status: number | string): NumberNamePair {
	let lookup: { [key: string]: NumberNamePair } = {
		"0": { id: 0, name: "Open" },
		"1": { id: 1, name: "On Hold" },
		"2": { id: 2, name: "Closed" },
		"3": { id: 3, name: "In Progress" },
		Open: { id: 0, name: "Open" },
		OnHold: { id: 1, name: "On Hold" },
		Pending: { id: 1, name: "On Hold" },
		Closed: { id: 2, name: "Closed" },
		InProgress: { id: 3, name: "In Progress" },
		"In Progress": { id: 3, name: "In Progress" },
	};
	if (status) {
		return lookup[status];
	}
	return lookup["0"];
}

export function getTicketStatusColor(status: number | string): string {
	let key = "" + status;
	let lookup: { [key: string]: string } = {
		"0": "bg-open-light",
		"1": "bg-on-hold-light",
		"2": "bg-resolved-light",
		"3": "bg-in-progress-light",
		Open: "bg-open-light",
		Pending: "bg-on-hold-light",
		OnHold: "bg-on-hold-light",
		Closed: "bg-resolved-light",
		InProgress: "bg-in-progress-light",
		"In Progress": "bg-in-progress-light",
	};
	return lookup[key];
}

export function getTicketStatusTextColor(status: number | string): string {
	let key = "" + status;
	let lookup: { [key: string]: string } = {
		"0": "open-dark",
		"1": "on-hold-dark",
		"2": "resolved-dark",
		"3": "in-progress-dark",
		Open: "open-dark",
		Pending: "on-hold-dark",
		OnHold: "on-hold-dark",
		Closed: "resolved-dark",
		InProgress: "in-progress-dark",
		"In Progress": "in-progress-dark",
	};
	return lookup[key];
}

export function getTicketStatusIcon(status: number | string): string {
	let key = "" + status;
	let lookup: { [key: string]: string } = {
		"0": "/sign-badge-circle.svg",
		"1": "/subtract-circle.svg",
		"2": "/check-circle.svg",
		"3": "/in-progress-icon.svg",
		Open: "/sign-badge-circle.svg",
		OnHold: "/subtract-circle.svg",
		Pending: "/subtract-circle.svg",
		"On Hold": "/subtract-circle.svg",
		Closed: "/check-circle.svg",
		InProgress: "/in-progress-icon.svg",
		"In Progress": "/in-progress-icon.svg",
	};
	return lookup[key];
}

export function getTicketStatusIconBlack(status: number | string): string {
	let key = "" + status;
	let lookup: { [key: string]: string } = {
		"0": "/sign-badge-circle-black.svg",
		"1": "/subtract-circle-black.svg",
		"2": "/check-circle-black.svg",
		"3": "/in-progress-icon-black.svg",
		Open: "/sign-badge-circle-black.svg",
		OnHold: "/subtract-circle-black.svg",
		Pending: "/subtract-circle-black.svg",
		"On Hold": "/subtract-circle-black.svg",
		Closed: "/check-circle-black.svg",
		InProgress: "/in-progress-icon-black.svg",
		"In Progress": "/in-progress-icon-black.svg",
	};
	return lookup[key];
}

export function getDuration(date: string | undefined): string {
	if (date) {
		const now = new Date();
		const then = new Date(date);
		const duration = Math.abs(Math.round(now.getTime() - then.getTime()));
		const seconds = Math.floor(duration / 1000);
		const minutes = Math.floor(seconds / 60);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);

		if (seconds < 60) {
			return "Just now";
		} else if (minutes < 60) {
			return `${minutes} minutes ago`;
		} else if (hours < 2) {
			return "1 hour ago";
		} else if (days < 1) {
			return `${hours} hours ago`;
		} else if (days < 7) {
			return `${days} days ago`;
		} else {
			return localizedDate(date);
		}
	}
	return "Unavailable";
}

export function getTicketPriority(priority: number | string): NumberNamePair {
	return getPriority(priority);
}

export function getPriority(priority: number | string): NumberNamePair {
	let priorities: NumberNamePair[] = [
		{ id: 0, name: "Low" },
		{ id: 1, name: "Medium" },
		{ id: 2, name: "High" },
	];
	if (priority !== undefined) {
		let key: string = "" + priority;
		const lookup: { [key: string]: NumberNamePair } = {
			"0": priorities[0],
			"1": priorities[1],
			"2": priorities[2],
			Low: priorities[0],
			Medium: priorities[1],
			High: priorities[2],
		};
		return lookup[key];
	}
	return priorities[0];
}

export function getFileSize(size: number) {
	if (size < 1000) {
		return size + "b";
	} else if (size < 1000000) {
		return (size / 1000).toFixed(2) + "kb";
	} else {
		return (size / 1000000).toFixed(2) + "mb";
	}
}

export function HelpTicketForm2Full(helpTicketForm: any): HelpTicketFull {
	let retval: any = _.cloneDeep(blankHelpTicketFull);
	retval.issueCategory = {
		id: helpTicketForm.categoryId,
		name: helpTicketForm.categoryName,
	};
	retval.issueType = {
		id: helpTicketForm.issueTypeId,
		name: helpTicketForm.issueTypeName,
	};
	retval.requestedForPatron.guid = helpTicketForm.forWhoId;
	retval.requestedForPatron.lastName = helpTicketForm.forWhoName ? helpTicketForm.forWhoName.split(",")[0] : "";
	Object.keys(blankHelpTicketFull).forEach((item) => {
		if (item === "categoryId" || item === "categoryName" || item === "issueTypeId" || item === "issueTypeName" || item === "requestedForPatron") {
			return;
		}
		if (helpTicketForm[item] !== undefined) {
			retval[item] = helpTicketForm[item];
		}
	});
	return retval as HelpTicketFull;
}
export function helpTicketForm2Create(helpTicketForm: HelpTicketForm): CreateHelpTicket {
	let retval: CreateHelpTicket = {
		issueCategory: helpTicketForm.categoryId,
		details: helpTicketForm.details,
		issueType: helpTicketForm.issueTypeId,
		itemGUID: _.get(helpTicketForm, "item.guid"),
		priority: helpTicketForm.priority && helpTicketForm.priority.id,
		requestedPatronGUID: helpTicketForm.forWhoId,
		submittedForRepair: false,
		status: helpTicketForm.status && helpTicketForm.status.id,
		summary: helpTicketForm.summary,
		assignedDistrictUserGUID: undefined,
		assignedPatronGUID: undefined,
		assignedServicingTeamID: undefined, // need to get from item.resource
		email: undefined,
		expectedCompletion: undefined,
		offendingPatronGUID: helpTicketForm.responsibleUserId,
		phone: undefined,
		resolutionID: _.get(helpTicketForm, "resolution.helpTicketResolutionID"),
		resolutionDescription: _.get(helpTicketForm, "resolutionDescription"),
		customFieldValues: undefined,
	};
	// we use object for fieldValues so correct order can be maintained
	if (helpTicketForm.fieldValues) {
		let z = Object.keys(helpTicketForm.fieldValues).sort((a: any, b: any) => a - b);
		if (z.length > 0) {
			let cfv = [];
			for (let i = 0; i < z.length; i++) {
				cfv.push(helpTicketForm.fieldValues[z[i]]);
			}
			retval.customFieldValues = cfv;
		}
	}
	return retval;
}

export function convertAssigneesToMenu(assignees: Array<HelpTicketAssigneeVO>): AssigneeMenuItem[] {
	let retval: AssigneeMenuItem[] = [];
	if (assignees && assignees.length > 0) {
		let serviceTeams: { [key: string]: HelpTicketAssigneeVO[] } = {};
		assignees.forEach((item) => {
			let st = item.servicingTeamName || "123der56qw3";
			if (serviceTeams[st] === undefined) {
				serviceTeams[st] = [] as HelpTicketAssigneeVO[];
			}
			serviceTeams[st].push(item);
		});
		let mt: AssigneeMenuItem[] = [];
		Object.keys(serviceTeams).forEach((key) => {
			let team: AssigneeMenuItem = {
				type: "team",
				name: key === "123der56qw3" ? "No Team" : key,
				serviceTeamID: key === "123der56qw3" ? undefined : serviceTeams[key][0].servicingTeamID,
				id: key === "123der56qw3" ? undefined : "" + serviceTeams[key][0].servicingTeamID,
			};

			mt.push(team);
			serviceTeams[key].forEach((item) => {
				mt.push({
					type: "individual",
					isDistrictUser: item.isDistrictUser,
					id: item.userGUID,
					name: _.get(item, "lastFirstMiddleName", _.get(item, "userLoginID", "n/a")),
					serviceTeamID: team.serviceTeamID,
				});
			});
		});
		if (mt.length > 0) {
			retval = mt;
		}
	}
	return retval;
}

export function getLastFirstMiddleNamePatron(patron: Patron | undefined) {
	let retval = "";
	if (patron) {
		retval = patron.userLastName + ", " + patron.userFirstName + " " + patron.userMiddleName;
		retval = retval.trim();
		if (retval.charAt(retval.length - 1) === ",") {
			retval = retval.slice(0, -1);
		}
	}
	return retval;
}

export function getLastFirstMiddleNameDestinyUser(destinyUser: DestinyUser | undefined) {
	let retval = "";
	if (destinyUser) {
		retval = destinyUser.lastName + ", " + destinyUser.firstName + " " + destinyUser.middleName;
		retval = retval.trim();
		if (retval.charAt(retval.length - 1) === ",") {
			retval = retval.slice(0, -1);
		}
	}
	return retval;
}

export const getHistoryType = (type: number): string => {
	switch (type) {
		case 0:
			return "created the help ticket";
		case 1:
			return "added a note";
		case 2:
			return "resolved the help ticket";
		case 3:
			return "reopened the help ticket";
		case 4:
			return "changed the status";
		case 5:
			return "changed the priority";
		case 6:
			return "changed the assignee";
		case 7:
			return "changed the requester";
		case 8:
			return "changed the email";
		case 9:
			return "changed the phone number";
		case 10:
			return "changed the expected completion date";
		case 11:
			return "resolved the help ticket";
		case 12:
			return "changed the resolution name";
		case 13:
			return "submitted the item for repair";
		case 14:
			return "changed the assigned servicing team";
		case 15:
			return "changed the issue category/type";
		case 16:
			return "removed an attached file";
		case 17:
			return "added an attached file";
		default:
			return "performed an unknown action";
	}
};

export const renderFieldTypeIcon = (fieldType: FieldType) => {
	switch (fieldType) {
		case FieldType.Currency:
			return "/fields/Currency_Icon.svg";
		case FieldType.Date:
			return "/fields/Date_Icon.svg";
		case FieldType.DropDownList:
			return "/fields/DropdownList_Icon.svg";
		case FieldType.Memo:
			return "/fields/Memo_Icon.svg";
		case FieldType.Number:
			return "/fields/Number_Icon.svg";
		case FieldType.Text:
			return "/fields/Text_Icon.svg";
		case FieldType.Year:
			return "/fields/Year_Icon.svg";
		case FieldType.YesNoCheckbox:
			return "/fields/Checkbox_Icon.svg";
		default:
			return "";
	}
};
