import React, { FC, ReactNode, useRef, useState } from "react";

import {
	CalendarMonthOutlined,
	CheckBoxOutlined,
	EditNoteOutlined,
	InputOutlined,
	LocalAtmOutlined,
	MoneyOutlined,
	TitleOutlined,
	UpdateOutlined,
} from "@mui/icons-material";
import { FormControlLabel, MenuItem, Radio, RadioGroup, Select } from "@mui/material";
import dayjs from "dayjs";
import _ from "lodash";
import { Field, FieldType, fieldTypeString } from "../../models/fields.model";
import { Form } from "../../models/forms.model";
import { blankCustomFieldValue, CustomFieldValue } from "../../models/helpticket.model";
import { useFieldQuery } from "../../utilities/apiApi";
import CustomTextField from "../CustomTextField";

type FieldTypeInfo = {
	icon: ReactNode;
	title: string;
	type: FieldType;
};
const fieldTypeInfo: FieldTypeInfo[] = [
	{
		icon: <LocalAtmOutlined />,
		title: "Currency",
		type: FieldType.Currency,
	},
	{
		icon: <CalendarMonthOutlined />,
		title: "Date",
		type: FieldType.Date,
	},
	{
		icon: <InputOutlined />,
		title: "Drop Down List",
		type: FieldType.DropDownList,
	},
	{
		icon: <EditNoteOutlined />,
		title: "Memo",
		type: FieldType.Memo,
	},
	{
		icon: <MoneyOutlined />,
		title: "Number",
		type: FieldType.Number,
	},
	{
		icon: <TitleOutlined />,
		title: "Text",
		type: FieldType.Text,
	},
	{
		icon: <UpdateOutlined />,
		title: "Year",
		type: FieldType.Year,
	},
	{
		icon: <CheckBoxOutlined />,
		title: "Yes/No Checkbox",
		type: FieldType.YesNoCheckbox,
	},
];
export interface CustomFieldProps {
	field: Field;
	value: CustomFieldValue;
	onChange: (orderIdx: number, value: CustomFieldValue) => void;
	errorMessage?: string;
	orderIdx: number;
}
export interface FieldHeaderProps {
	field: Field;
}
const FieldHeader: FC<FieldHeaderProps> = (props: FieldHeaderProps) => {
	if (props.field.active) {
		return (
			<div className="flex_1 align-center text-l" title={fieldTypeString[props.field.fieldType]}>
				<span>{props.field.name}</span>
				{!props.field.importance && <span>{" (optional)"}</span>}
			</div>
		);
	} else {
		return (
			<div className="flex_1 align-center text-l" title="{fieldTypeString[props.field.type]} field is disabled by Administrator">
				<span>{props.field.name}</span>
				{props.field.importance && <span>{" (optional)"}</span>}
			</div>
		);
	}
};
const CustomFieldText: FC<CustomFieldProps> = (props: CustomFieldProps) => {
	const fieldNameRef = useRef();
	const [hasFocus, setHasFocus] = useState(false);
	return (
		<div className="flex flex-auto flex-col mb-2">
			<div className={"flex-1  flex-col mb-2"} title={props.field.description}>
				<FieldHeader field={props.field} />
				<CustomTextField
					rounded={false}
					ariaLabel={"Enter " + props.field.name}
					// className={"flex-1 "}
					// variant={"standard"}
					// sx={{ width: "100%", border: "0px solid white" }}
					// size={"small"}
					// // label={hasFocus ? `${props.field.name}` : undefined}
					// inputRef={fieldNameRef}
					// inputProps={{ border: 0 }}
					// disabled={!props.field.active}
					value={props.value.textValue}
					placeholder={"Enter " + props.field.name}
					// error={props.errorMessage ? true : false}
					// helperText={props.errorMessage ? props.errorMessage : ""}
					// helperText={`${props.value.textValue.length}/50`}
					// title={props.field.description}
					// onFocus={() => {
					// 	setHasFocus(true);
					// }}
					onChange={(event) => {
						let fv = _.cloneDeep(props.value);
						fv.textValue = "" + event.target.value;
						fv.textValue = fv.textValue.substring(0, 50);
						props.onChange(props.orderIdx, fv);
					}}
				/>
			</div>
			{props.errorMessage && <div className="block text-error-text text-base">{props.errorMessage}</div>}
		</div>
	);
};

const CustomFieldCurrency: FC<CustomFieldProps> = (props) => {
	const fieldNameRef = useRef();
	return (
		<div className="flex flex-auto flex-col mb-2">
			<div className={"flex-1  flex-row mb-2"} title={props.field.description}>
				<FieldHeader field={props.field} />
				<CustomTextField
					// className={"flex-1 ml-1"}
					// variant={"standard"}
					// size={"small"}
					// label={props.field.name}
					// disabled={!props.field.active}
					// inputRef={fieldNameRef}
					// title={props.field.description}
					rounded={false}
					ariaLabel={"Enter " + props.field.name}
					value={props.value.textValue}
					placeholder={"Enter " + props.field.name}
					// error={props.errorMessage ? true : false}
					// helperText={props.errorMessage ? props.errorMessage : ""}
					onChange={(event: any) => {
						let fv = _.cloneDeep(props.value);
						fv.textValue = "" + event.target.value;
						props.onChange(props.orderIdx, fv);
					}}
				/>
			</div>
			{props.errorMessage && <div className="block text-error-text text-base">{props.errorMessage}</div>}
		</div>
	);
};
const CustomFieldDate: FC<CustomFieldProps> = (props) => {
	const fieldNameRef = useRef();

	let title = `${props.field.name}${props.field.importance ? "" : "(optional)"}`;
	return (
		<div className="flex flex-auto flex-col mb-2">
			<div className={"flex-1  flex-row mb-2"} title={props.field.description}>
				<FieldHeader field={props.field} />
				<CustomTextField
					rounded={false}
					ariaLabel={"Enter " + props.field.name}
					value={props.value.textValue}
					placeholder={"Enter " + props.field.name}
					onChange={(event: any) => {
						let fv = _.cloneDeep(props.value);
						fv.textValue = "" + event.target.value;
						props.onChange(props.orderIdx, fv);
					}}
					// onChange={(event) => {
					// 	let fv = _.cloneDeep(props.value);
					// 	fv.textValue = "" + event?.format(getDateOnlyFormat());
					// 	props.onChange(props.orderIdx, fv);
					// }}
				/>
			</div>
			{props.errorMessage && <div className="block text-error-text text-base">{props.errorMessage}</div>}
		</div>
	);
};
const CustomFieldDropDownList: FC<CustomFieldProps> = (props) => {
	const fieldNameRef = useRef();
	let field: Field = props.field;
	let textValue = _.get(props, "value.textValue", undefined);
	let valueIndex = -1;
	let dropdownResult = useFieldQuery({ fieldId: field.helpTicketFieldID });
	if (dropdownResult.isLoading) {
		return (
			<div className="flex flex-auto  w-full   flex-col mb-2">
				<div className={"flex-1  flex-row mb-2"} title={field.description}>
					<FieldHeader field={field} />
					waiting for options...
				</div>
			</div>
		);
	}
	if (dropdownResult.isError) {
		return (
			<div className="flex flex-auto   w-full  flex-col mb-2">
				<div className={"flex-1  flex-row mb-2"} title={field.description}>
					<FieldHeader field={field} />
				</div>
			</div>
		);
	}
	if (dropdownResult.isSuccess) {
		field = dropdownResult.data;
		if (textValue) {
			valueIndex = _.findIndex(field.dropDownOptions, (item) => item === textValue);
		}
	}
	return (
		<div className="flex flex-auto   w-full  flex-col mb-2">
			<div className={"flex-1  w-full  flex-row mb-2"} title={field.description}>
				<FieldHeader field={field} />
				{field.dropDownOptions && (
					<Select
						className="w-full md:w-1/2"
						disabled={!field.active}
						size={"small"}
						title={field.description}
						value={valueIndex}
						IconComponent={() => <img src={"/down-icon.svg"} alt={"Down arrow icon"} className={"mr-2 pointer-events-none absolute right-[1px]"} />}
						onChange={(event) => {
							let fv = _.cloneDeep(props.value);
							if (field.dropDownOptions) {
								fv.textValue = "" + field.dropDownOptions[event.target.value as number];
							}
							props.onChange(props.orderIdx, fv);
						}}
					>
						<MenuItem disabled={true} key={"none"} value={-1}>
							No Value Selected
						</MenuItem>
						{field.dropDownOptions.map((item, index) => {
							return (
								<MenuItem key={item} value={index}>
									{item}
								</MenuItem>
							);
						})}
					</Select>
				)}
			</div>
			{props.errorMessage && <div className="block text-error-text text-base">{props.errorMessage}</div>}
		</div>
	);
};
const CustomFieldMemo: FC<CustomFieldProps> = (props) => {
	const fieldNameRef = useRef();
	const [hasFocus, setHasFocus] = useState(false);

	return (
		<div className="flex flex-auto flex-col mb-2 w-full">
			<div className={"flex-1  flex-row mb-2"} title={props.field.description}>
				<FieldHeader field={props.field} />
				<CustomTextField
					ariaLabel={"Enter " + props.field.name}
					rounded={false}
					placeholder={"Enter " + props.field.name}
					multiline={true}
					rows={8}
					value={props.value.textValue}
					onChange={(event) => {
						let fv = _.cloneDeep(props.value);
						fv.textValue = "" + event.target.value;
						fv.textValue = fv.textValue.substring(0, 1000);
						props.onChange(props.orderIdx, fv);
					}}
				/>
				{props.errorMessage && <div className="block text-error-text text-sm ml-5 ">{props.errorMessage}</div>}
			</div>
		</div>
	);
};
const CustomFieldNumber: FC<CustomFieldProps> = (props) => {
	return (
		<div className="flex flex-auto flex-col mb-2">
			<div className={"flex-1  flex-row mb-2"} title={props.field.description}>
				<FieldHeader field={props.field} />
				<CustomTextField
					rounded={false}
					ariaLabel={"Enter " + props.field.name}
					// className={"flex-1 ml-1"}
					// variant={"standard"}
					// size={"small"}
					// label={props.field.name}
					// disabled={!props.field.active}
					value={props.value.textValue}
					// title={props.field.description}
					placeholder={"Enter " + props.field.name}
					// error={props.errorMessage ? true : false}
					// helperText={props.errorMessage ? props.errorMessage : ""}
					onChange={(event) => {
						let fv = _.cloneDeep(props.value);
						fv.textValue = "" + event.target.value;
						fv.textValue = fv.textValue.substring(0, 50);
						props.onChange(props.orderIdx, fv);
					}}
				/>
			</div>
			{props.errorMessage && <div className="block text-error-text text-base">{props.errorMessage}</div>}
		</div>
	);
};

const CustomFieldYear: FC<CustomFieldProps> = (props) => {
	// const [hasFocus, setHasFocus] = useState(false);
	return (
		<div className="flex flex-auto flex-col mb-2">
			<div className={"flex-1  flex-row mb-2"} title={props.field.description}>
				<FieldHeader field={props.field} />
				<CustomTextField
					// className={"flex-1 ml-1"}
					// variant={"standard"}
					// size={"small"}
					// label={hasFocus ? `${props.field.name}` : undefined}
					// inputRef={fieldNameRef}
					rounded={false}
					ariaLabel={"Enter " + props.field.name}
					value={props.value.textValue}
					// disabled={!props.field.active}
					// title={props.field.description}
					placeholder={"Enter " + props.field.name}
					// error={props.errorMessage ? true : false}
					// helperText={props.errorMessage ? props.errorMessage : ""}
					// helperText={`${props.value.textValue.length}/4`}
					// onFocus={() => {
					// 	setHasFocus(true);
					// }}
					onChange={(event: any) => {
						let fv = _.cloneDeep(props.value);
						fv.textValue = "" + event.target.value;
						fv.textValue = fv.textValue.substring(0, 4);
						props.onChange(props.orderIdx, fv);
					}}
				/>
			</div>
			{props.errorMessage && <div className="block text-error-text text-base">{props.errorMessage}</div>}
		</div>
	);
};
const CustomFieldYesNoCheckbox: FC<CustomFieldProps> = (props) => {
	const fieldNameRef = useRef();
	return (
		<div className="flex flex-auto flex-col mb-2">
			<div className={"flex-1  flex-row mb-2"}>
				<FieldHeader field={props.field} />
				<RadioGroup
					className="ml-10"
					aria-labelledby="yesno-group-label"
					defaultValue=""
					name="yesno-group"
					title={props.field.description}
					row
					onChange={(event) => {
						let fv = _.cloneDeep(props.value);
						fv.textValue = "" + event.target.value;
						props.onChange(props.orderIdx, fv);
					}}
				>
					<FormControlLabel value="Yes" control={<Radio disabled={!props.field.active} />} label="Yes" />
					<FormControlLabel value="No" control={<Radio disabled={!props.field.active} />} label="No" />
				</RadioGroup>
				{/* <div className="flex flex-row " >
					<div><Radio /><span>Yes</span></div>
					<div><Radio /><span>No </span></div>
				</div> */}
			</div>
			{props.errorMessage && <div className="block text-error-text text-base">{props.errorMessage}</div>}
		</div>
	);
};
function getUniqueActiveFields(forms: Form[] | undefined) {
	let fields: Field[] = [];
	let fieldIds: (number | undefined)[] = [];
	if (forms && forms.length > 0) {
		forms.forEach((form) => {
			if (form && form.active && form.customFields && form.customFields.length > 0) {
				form.customFields.forEach((item: Field, index: number) => {
					if (item.helpTicketFieldID && !fieldIds.includes(item.helpTicketFieldID) && item.active) {
						fieldIds.push(item.helpTicketFieldID);
						fields.push(item);
					}
				});
			}
		});
	}
	return fields;
}

export function validateCustomFieldTypeArray(forms: Form[] | undefined, fieldValues: CustomFieldValue[] | undefined) {
	let retval: (string | undefined)[] = [];
	let fvs: CustomFieldValue[] = [];
	let fields = getUniqueActiveFields(forms);
	if (forms) {
		let count = 0;
		fields.forEach((fItem: Field) => {
			let cvf: CustomFieldValue | undefined;
			if (fieldValues) {
				let cvf = fieldValues[count];
			}
			count++;
			if (cvf === undefined) {
				cvf = {
					helpTicketFieldID: fItem.helpTicketFieldID,
					fieldName: fItem.name,
					fieldType: fItem.fieldType,
					editable: true,
					textValue: "",
					required: fItem.importance,
				};
			}
			fvs.push(cvf);
		});
		fvs.forEach((fv) => {
			retval.push(validateCustomFieldType(fv));
		});
		return retval;
	} else {
		return undefined;
	}
}

export function validateCustomFieldTypes(forms: Form[] | undefined, fieldValues: { [key: string]: CustomFieldValue } | undefined) {
	let retval: (string | undefined)[] = [];
	let fvs: CustomFieldValue[] = [];
	let fields = getUniqueActiveFields(forms);
	if (forms) {
		let count = 0;
		fields.forEach((fItem: Field) => {
			let cvf: CustomFieldValue | undefined;
			if (fieldValues) {
				cvf = fieldValues[count];
			}
			count++;
			if (cvf === undefined) {
				cvf = {
					helpTicketFieldID: fItem.helpTicketFieldID,
					fieldName: fItem.name,
					fieldType: fItem.fieldType,
					editable: true,
					textValue: "",
					required: fItem.importance && fItem.active,
				};
			}
			fvs.push(cvf);
		});
		fvs.forEach((fv) => {
			retval.push(validateCustomFieldType(fv));
		});
		return retval;
	} else {
		return undefined;
	}
}
export function validateCustomFieldType(fieldValue: CustomFieldValue): string | undefined {
	let retval: string | undefined;

	if (fieldValue.required && _.get(fieldValue, "textValue", "").trim() === "") {
		retval = "" + fieldValue.fieldName + " is a required field!";
	}
	if (retval === undefined && fieldValue.textValue !== "") {
		switch (fieldValue.fieldType) {
			case FieldType.Currency:
				if (!/^[0-9]+([.,]?[0-9]{2})?$/.test(fieldValue.textValue.trim())) {
					retval = "" + fieldValue.fieldName + " is invalid currency value.";
				}
				break;
			case FieldType.Date:
				if (!dayjs(fieldValue.textValue).isValid()) {
					retval = "" + fieldValue.fieldName + " is invalid date value.";
				}
				break;
			case FieldType.DropDownList:
				// must be value of dropdown list so no check needed
				break;
			case FieldType.Memo:
				// just text?
				break;
			case FieldType.Number:
				if (!/^[0-9]+$/.test(fieldValue.textValue.trim())) {
					retval = "" + fieldValue.fieldName + " is invalid number.";
				}
				break;
			case FieldType.Year:
				if (!/^[0-9]{4}$/.test(fieldValue.textValue.trim())) {
					retval = "" + fieldValue.fieldName + " is invalid year.";
				}
				break;
			case FieldType.YesNoCheckbox:
				// must be value of YesNoCheckbox list so no check needed
				break;
			case FieldType.Text:
				// just text?
				break;
			default:
				break;
		}
	}
	return retval;
}
const CustomField: FC<CustomFieldProps> = (props) => {
	let ref: React.RefObject<any> = React.useRef();

	switch (props.field.fieldType) {
		case FieldType.Currency:
			return <CustomFieldCurrency {...props} />;
			break;
		case FieldType.Date:
			return <CustomFieldDate {...props} />;
			break;
		case FieldType.DropDownList:
			return <CustomFieldDropDownList {...props} />;
			break;
		case FieldType.Memo:
			return <CustomFieldMemo {...props} />;
			break;
		case FieldType.Number:
			return <CustomFieldNumber {...props} />;
			break;
		case FieldType.Text:
			return <CustomFieldText {...props} />;
			break;
		case FieldType.Year:
			return <CustomFieldYear {...props} />;
			break;
		case FieldType.YesNoCheckbox:
			return <CustomFieldYesNoCheckbox {...props} />;
			break;

		default:
			return (
				// <CustomFieldText field={props.field} value={props.value} onChange={props.onChange} hasError={props.hasError} takeFocus={false} />
				<CustomFieldText {...props} />
			);
			break;
	}
};
export interface CustomFormProps {
	forms: Form[];
	fieldValues?: { [key: string]: CustomFieldValue };
	update: (idx: number, value: CustomFieldValue) => void;
	errorMessages: (string | undefined)[];
}

export const CustomFormFields: FC<CustomFormProps> = (props) => {
	let ref: React.RefObject<any> = React.useRef();

	const handleChange = (fieldIndex: number, value: CustomFieldValue) => {
		props.update(fieldIndex, value);
	};
	let fieldIndex = -1;
	let fields: Field[] = [];
	let fieldIds: (number | undefined)[] = [];
	fields = getUniqueActiveFields(props.forms);
	return (
		<div className={"w-full flex flex-col justify-left"}>
			{fields.map((item: Field, index: number) => {
				fieldIndex++;
				let fValues = props.fieldValues;
				if (fValues === undefined) {
					fValues = {};
				}
				let fieldValue = fValues["" + fieldIndex];
				if (fieldValue === undefined) {
					fieldValue = _.cloneDeep(blankCustomFieldValue);
					fieldValue.helpTicketFieldID = item.helpTicketFieldID;
					fieldValue.fieldName = item.name;
					fieldValue.fieldType = item.fieldType;
					fieldValue.required = item.importance;
				} else {
					fieldValue = _.cloneDeep(fValues["" + fieldIndex]);
				}

				return (
					<CustomField
						orderIdx={fieldIndex}
						field={item}
						value={fieldValue}
						errorMessage={props.errorMessages ? props.errorMessages[fieldIndex] : undefined}
						onChange={handleChange}
					/>
				);
			})}
		</div>
	);
};
export default CustomField;
