import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import configs from "../config/config";
import env from "../config/env";
import { getAppId } from "../models/userLogin.model";
import { RootState } from "../store";
// import { nextRequestId } from './appContext';
//   import { logout } from '../features/userSlice';

const baseUrl = configs.env[env].helpdesk_backend_url;

const baseQuery = fetchBaseQuery({
	baseUrl,
	prepareHeaders: (headers, { getState }) => {
		const st: RootState = getState() as RootState;
		const token = st.auth.accessToken;
		const districtId: string | undefined = getAppId(st.auth.userLogin);

		// If we have a token set in state, let's assume that we should be passing it.
		if (token) {
			headers.set("Authorization", `Bearer ${token}`);
		}
		if (districtId) {
			headers.set("districtId", "" + districtId);
		}
		// headers.set("Request-Id", nextRequestId("Base"));
		return headers;
	},
});

const authCustomFetchBase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
	// wait until the mutex is available without locking it
	let result = await baseQuery(args, api, extraOptions);
	return result;
};

export default authCustomFetchBase;
