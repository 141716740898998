import { ArrowForwardIos } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, ListItem } from "@mui/material";
import React, { FC, ReactNode } from "react";

interface GenericFilterListItemProps {
	name: string;
	children: ReactNode;
	handleChange: (event: React.SyntheticEvent, newExpanded: boolean) => void;
	updateFilterForm: Function;
	expanded: string[];
	panelName: string;
	className?: string;
}

const GenericFilterListItem: FC<GenericFilterListItemProps> = (props) => {
	return (
		<ListItem className={props.className} key={`${props.name}_listitem`.toLowerCase().replace(" ", "_")} disablePadding>
			<Accordion expanded={props.expanded.includes(props.panelName) ? true : false} onChange={props.handleChange} style={{ boxShadow: "unset" }}>
				<AccordionSummary
					aria-controls={`${props.panelName}-content`}
					id={`${props.panelName}-header`}
					expandIcon={<ArrowForwardIos className={"p-1"} />}
					className={"!min-h-4 h-4"}
					sx={{
						"& .MuiAccordionSummary-expandIconWrapper": {
							position: "absolute",
							top: "-4px",
							left: "5px",
						},
					}}
				>
					<div className="text-altblack ml-4">{props.name}</div>
				</AccordionSummary>
				<AccordionDetails
					className={"h-full p-2"}
					// sx={{ "& MuiAccordionDetails-root": { padding: "0px" } }}
				>
					{props.children}
				</AccordionDetails>
			</Accordion>
		</ListItem>
	);
};

export default GenericFilterListItem;
