import { createSlice } from "@reduxjs/toolkit";
import * as _ from "lodash";
import { ClaimsModel } from "../models/claims.model";
import { GrantsModel } from "../models/grants.model";
import { UserType } from "../models/user.model";
import { UserLoginModel } from "../models/userLogin.model";
import { RootState } from "../store";

interface Auth {
	accessToken: string | undefined;
	userLogin: UserLoginModel | undefined;
	userGrants: GrantsModel | undefined;
	userType: UserType;
}

// Define the initial state using that type
const initialState: Auth = {
	accessToken: undefined,
	userLogin: undefined,
	userGrants: undefined,
	userType: UserType.Unknown,
};

const authSlice = createSlice({
	name: "auth",
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setUserLogin: (state, payload) => {
			state.userLogin = payload.payload;
		},
		setAccessToken: (state, payload) => {
			state.accessToken = payload.payload;
		},
		setUserGrants: (state, payload) => {
			state.userGrants = payload.payload;
		},
		setUserType: (state, payload) => {
			state.userType = payload.payload;
		},
	},
});

export const selectAccessToken = (state: RootState): string | undefined => state.auth.accessToken;
export const selectUserLogin = (state: RootState): UserLoginModel | undefined => state.auth.userLogin;

export const selectUserClaims = (state: RootState): ClaimsModel | null => _.get(state, "auth.userLogin.claims", null);

export const selectUserGrants = (state: RootState): GrantsModel | undefined => state.auth.userGrants;

export const selectUserType = (state: RootState): UserType => state.auth.userType;

export const { setUserLogin, setAccessToken, setUserGrants, setUserType } = authSlice.actions;

export default authSlice.reducer;
