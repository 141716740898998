import { createSlice } from "@reduxjs/toolkit";
import { DownloadAttachedFile } from "../models/helpticket.model";
import { RootState } from "../store";

// Define the initial state using that type

interface DownloadSliceState {
	download: DownloadAttachedFile | undefined;
}

const initialState: DownloadSliceState = {
	// @ts-ignore
	download: undefined,
};

const DownloadSlice = createSlice({
	name: "Download",
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setDownload: (state, payload) => {
			let download = { ...state, download: payload.payload };
			return download;
		},
	},
});

export const selectDownload = (state: RootState): DownloadAttachedFile | undefined => {
	return state.download.download;
};

export const { setDownload } = DownloadSlice.actions;

export default DownloadSlice.reducer;
