import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../hook";
import { openErrorNavigationUrl, selectErrorNavigationUrl } from "../slices/NotificationSlice";
import store from "../store";

export const goToUrl = (url: string) => {
	store.dispatch(openErrorNavigationUrl({ url }));
};

export default function ErrorNavigation() {
	const url = useAppSelector(selectErrorNavigationUrl);
	const navigate = useNavigate();

	useEffect(() => {
		if (url) {
			navigate(url);
		}
	}, [url]);

	return <div></div>;
}
