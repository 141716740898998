import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { useNavigate } from "react-router-dom";
import NewTicketFull from "../../components/NewTicketFull/NewTicketFull";
import { useAppDispatch } from "../../hook";
import { resetHelpTicketForm } from "../../slices/HelpTicketFormSlice";

function NewTicketAgentPage(): ReactJSXElement {
	// const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	dispatch(resetHelpTicketForm());
	return (
		<div className="h-full">
			<NewTicketFull
				setPageMode={(value: string) => {
					navigate("/helpdesk/agent");
				}}
			/>
		</div>
	);
}

export default NewTicketAgentPage;
