import { BriefResourceTypeParentVO } from "./resourceType.model";

export interface Resource {
	assetTemplateId?: number;
	guid?: string;
	name: string;
	imageFileName?: string;
	resourceType?: BriefResourceTypeParentVO;
}

export interface ResourceTypeVO {
	assetTemplateID?: number;
	GUID?: string;
	name: string;
}
export interface SiteApiVO {
	guid: string;
	name: string;
}

export interface Device {
	id?: number;
	guid?: string;
	barcode?: string;
	districtID: string;
	serialNumber?: string;
	resource: Resource;
	location?: string;
	site?: SiteApiVO;
}
export const blankDevice: Device = {
	id: undefined,
	guid: undefined,
	barcode: undefined,
	districtID: "",
	serialNumber: undefined,
	resource: {
		guid: undefined,
		name: "",
		imageFileName: "",
	},
};
